import React, { useEffect, useState } from 'react'
import { cookieNames, localStoreNames, sessionStoreNames } from '../../constants';
import { GetCookie, GetSetting, SetSetting } from '../../functions';

import {
    CBadge,
    CDropdown,
    CDropdownDivider,
    CDropdownToggle,
    CDropdownItem,
    CDropdownMenu,
    CHeader,
    CTooltip,
} from '@coreui/react'

import {
    cilHamburgerMenu,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import ChangeLog from '../miscellaneous/changeLog';
import SearchBar from './searchBar';
import Tutorial from '../miscellaneous/tutorial';

export default function Header({ logout, onCollapse, setToast }) {

    //#region Variables

    // var for keeping track of user login 
    const [loginData,] = useState(JSON.parse(GetCookie(cookieNames.userName)));
    const [showChangeLogModal, setShowChangeLogModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);
    const [changeLogRead, setChangeLogRead] = useState(true); // false will show the badge

    //#endregion Variables

    //#region Functions

    /** When the change log is read, update the setting as well as session var */
    function OnChangeLogRead() {
        let mostRecentChangeLogVersion = localStorage.getItem(localStoreNames.changeLogMostRecentVersion);
        let settings = [{
            Name: 'LastReadChangeLogVersion',
            Value: mostRecentChangeLogVersion
        }];

        setShowChangeLogModal(true);
        setChangeLogRead(true);

        SetSetting(settings)
            .finally(() => {
                sessionStorage.setItem(
                    sessionStoreNames.changeLogReadVersion,
                    mostRecentChangeLogVersion
                );
            })
            .catch(console.error);
    }

    //#endregion Functions

    // Check if most recent change log has been viewed
    useEffect(() => {
        /**
         * Checks that the version read matches the current version of change log
         * @param {string} versionRead Version of change log that has been read
         */
        const checkMatch = (versionRead) => {
            let currentVersion = localStorage.getItem(localStoreNames.changeLogMostRecentVersion);

            if (currentVersion != null && versionRead !== currentVersion)
                setChangeLogRead(false);
        };

        let versionRead = sessionStorage.getItem(sessionStoreNames.changeLogReadVersion);
        let settingsToGet = ['LastReadChangeLogVersion'];
        if (versionRead == null) {
            GetSetting(settingsToGet)
                .then(settings => {
                    sessionStorage.setItem(sessionStoreNames.changeLogReadVersion, settings[0]);
                    checkMatch(settings[0]);
                })
                .catch(console.error);
        } else {
            checkMatch(versionRead);
        }
    }, []);

    return <>
        <ChangeLog visible={showChangeLogModal} setVisible={setShowChangeLogModal} setToast={setToast} />

        <Tutorial visible={showTutorialModal} setVisible={setShowTutorialModal} setToast={setToast} />

        <CHeader style={{ backgroundColor: '#303c54', zIndex: '1000', padding: '4px 8px' }}>
            <CTooltip content='Show/Hide Menu'>
                <CIcon
                    className='text-light mt-1 mx-2 btn'
                    icon={cilHamburgerMenu}
                    height={34}
                    onClick={onCollapse}
                />
            </CTooltip>

            {/* Search for Accounts and Leads */}
            <SearchBar setToast={setToast} />

            {/* User Menu */}
            <CDropdown>
                <CDropdownToggle color='#303c54' className='text-light'>
                    {loginData}
                    {!changeLogRead && <CBadge color='danger' className='ms-1'>New!</CBadge>}
                </CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem onClick={OnChangeLogRead}>
                        Change Log
                        {!changeLogRead && <CBadge color='danger' className='ms-1'>New!</CBadge>}
                    </CDropdownItem>
                    <CDropdownItem onClick={() => setShowTutorialModal(true)}>Tutorials</CDropdownItem>
                    <CDropdownDivider />
                    <CDropdownItem onClick={logout}>Logout of Skynet</CDropdownItem>
                    <CDropdownItem onClick={() => logout(true)}>Logout of Microsoft</CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
        </CHeader>
    </>;
}