import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { cookieNames, engagementTypeName, GetErrorMessages } from '../../constants';
import {
    GetEngagementType,
    GetPlaylists,
    GetErrorToastComponent,
    GetBearer,
    GetToastComponent,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    SetToastComponentValuesInCookie,
    UpdateSWFetch,
    GetCookie,
} from '../../functions';

import {
    CCard,
    CCardBody,
    CCardTitle,
    CDropdown,
    CDropdownDivider,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CHeader,
    CHeaderText,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CToaster,
} from '@coreui/react';

// Top section
import ProfileDetails from '../../components/profiles/profileDetails';
import PaymentQualityBar from '../../components/profiles/paymentQualityBar';

// Tab section items
import Notes from '../../components/company/notes';
import Todo from '../../components/todo/todo';
import AllMeetingsBooked from '../../components/todo/allMeetingsBooked';
import FormalRfp from '../../components/company/formalRfp';
import CreditRequestHistory from '../../components/company/creditRequestHistory';
import CollectionDetails from '../../components/company/collectionDetails';
import OrderHistory from '../../components/company/orderHistory';

// Modals
import AddToPlaylistModal from '../../components/company/AddToPlaylistModal';
import ConfirmModal from '../../components/miscellaneous/confirmModal';
import FlipAccountModal from '../../components/miscellaneous/flipAccountModal';
import SubmitCreditRequestModal from '../../components/credit/submitCreditRequestModal';

import '../../style/profile.css';
import Placeholder from '../../components/miscellaneous/placeholder';
import CustomerPortalOnboardingModal from '../../components/miscellaneous/customerPortalOnboardingModal';

export default function AccountProfile() {

    //#region Variables

    const [companyInfo, setCompanyInfo] = useState(null);
    const [creditInfo, setCreditInfo] = useState(null);
    const [paymentBarUpdating, setBarUpdating] = useState(false);
    const [activeTab, setActiveTab] = useState('Notes');
    const [engagementTypes, setEngagementTypes] = useState();

    const { companyId } = useParams();
    const navigate = useNavigate();
    const [toast, setToast] = useState(null);
    const [loading, setLoading] = useState(true);
    const [playlists, setPlaylists] = useState(); // Used for adding company to playlist in AddToPlaylistModal component
    const [todoDefaultTitle, setTodoTitle] = useState(null);
    const companySelected = useRef() // Passes current account to modal as account selected

    // Modal Show/Hide
    const [showConvertConfirmModal, setShowConvertConfirmModal] = useState(false);
    const [showAddToPlaylistModal, setShowAddToPlaylistModal] = useState(false);
    const [showSubmitCreditRequestModal, setShowSubmitCreditRequestModal] = useState(false);
    const [showFlipAccountModal, setShowFlipAccountModal] = useState(false);
    const [showCustomerPortalOnboardingModal, setShowCustomerPortalOnboardingModal] = useState(false);

    const [id, setId] = useState(parseInt(companyId)); // Get id of company

    // Force updates when there are changes
    const [todosUpdateCounter, forceUpdateNotes] = useReducer(x => x + 1, 0);
    const [meetingsBookedUpdateCounter, forceUpdateMeetingsBooked] = useReducer(x => x + 1, 0);

    //#endregion

    //#region Functions

    /**
     * Gets Company Account information
     */
    function GetCompanyAccount() {
        fetch('/api/companyaccounts/get-company-by-id-profile?id=' + id, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setCompanyInfo(data[0]);
                setLoading(false); // Only matter initially on first load
            })
            .catch(err => {
                console.error(err);
                SetToastComponentValuesInCookie('Error: Could not get company account information!', err.message);
                navigate('/company-accounts');
            });
    }

    //#region Playlists

    // After adding to playlist
    function OnAddedToPlaylist() {
        setToast(GetToastComponent(companyInfo.CompanyName + ' added to playlist successfully'));
        setShowAddToPlaylistModal(false);

        UpdateSWFetch('/api/playlist/get', (data) => setPlaylists(data));

        // Update playlists 
        GetPlaylists()
            .then(x => setPlaylists(x))
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(GetErrorMessages.Playlists, err.message));
            });
    }

    // Fetches all playlists if needed and opens modal so user can choose playlist to add this company to
    function OnAddToPlaylist() {
        // Only need to get playlist when company account changes or first load
        if (companySelected.current == null || companySelected.current[0].CompanyAccountID !== id) {
            // Only need to update playlists after fetch since id and name doesn't change here
            UpdateSWFetch('/api/playlist/get', (data) => setPlaylists(data));

            GetPlaylists()
                .then(x => {
                    setPlaylists(x);
                    companySelected.current = [{
                        CompanyAccountID: id,
                        Name: companyInfo.CompanyName
                    }]; // Set the company selected as current one
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent(GetErrorMessages.Playlists, err.message));
                });
        }

        // Show the modal
        setShowAddToPlaylistModal(true);
    }

    //#endregion Playlists

    /**
     * Convert user back to lead
     */
    function ConvertBackToLead() {
        fetch('/api/companyaccounts/convert-prospect-to-lead?id=' + companyInfo.CompanyAccountID, {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data.Converted) {
                    SetToastComponentValuesInCookie('Converted Back To Leads Successfully');

                    // Navigate to lead
                    navigate('/company-leads/' + data.LeadID + '/profile');
                } else { // Could not convert, show message explaining why
                    throw Error(data.Message);
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not convert back to lead!', err.message));
            });
    }

    function OnContractLink() {
        window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=f--iQ3fKu02icN24B4hBM8Zem6UtpIlGkjw1CN0o-rBUMENRNjJSVVcwWVQzRDdFTlVBQjNQSUdPRy4u', '_blank');

        var formData = new FormData();
        formData.append('CompanyAccountID', companyId);
        formData.append('NoteText', 'Add contract link used');
        formData.append('NoteCreatedByUserID', parseInt(JSON.parse(GetCookie(cookieNames.userID))));
        formData.append('IsSystemNote', true);

        // Add note
        fetch('/api/companyaccounts/add-note', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST',
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    forceUpdateNotes();
                } else {
                    throw Error('Could not add note!');
                }
            })
            .catch(err => {
                setToast(GetErrorToastComponent(err));
                console.log(err);
            });
    }

    function OnEdiLink() {
        window.open('https://accounts.zoho.com/signin?servicename=SDPOnDemand&&hide_title=true&&hideyahoosignin=true&&hidefbconnect=true&&hide_secure=true&&serviceurl=https://sdpondemand.manageengine.com/app/itdesk/ui/requests/add?reqTemplate=182405000008208235&&signupurl=https://sdpondemand.manageengine.com/AccountCreation.do&&portal_name=SDPOnDemand', '_blank');

        var formData = new FormData();
        formData.append('CompanyAccountID', companyId);
        formData.append('NoteText', 'Add EDI link used');
        formData.append('NoteCreatedByUserID', parseInt(JSON.parse(GetCookie(cookieNames.userID))));
        formData.append('IsSystemNote', true);

        // Add note
        fetch('/api/companyaccounts/add-note', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST',
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    forceUpdateNotes();
                } else {
                    throw Error('Could not add note!');
                }
            })
            .catch(err => {
                setToast(GetErrorToastComponent(err));
                console.log(err);
            });
    }

    /**
     * Update account's engagement type
     * @param {int} typeId Id of engagement type
     */
    function UpdateEngagementType(typeId) {
        var request = {
            CompanyAccountID: id,
            CompanyAccountEngagementTypeID: typeId,
            UserID: JSON.parse(GetCookie(cookieNames.userID))
        };

        fetch('/api/companyaccounts/update-engagement-type', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(request)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                // Should return true/false
                data = HandleDataReturn(data);

                // If update successfull
                if (data) {
                    var engagementType = engagementTypes.find(x => x.CompanyAccountEngagementTypeID === typeId);

                    if (engagementType == null) throw Error('Could not find matching engagement type from list');

                    // Update company info to show current status
                    setCompanyInfo((prevState) => ({
                        ...prevState,
                        EngagementType: engagementType.Name
                    }))

                    forceUpdateNotes(); // Force notes to rerender
                }

            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not update engagement type!', err.message));
            });
    }

    //#region Component Functions

    // Change engagement type dropdown Component
    function ChangeEngagementTypeComponent() {
        // Only allow engagement type change if not customer
        if (companyInfo == null || companyInfo.EngagementType === 'Customer') return null;

        return <CDropdown className='mx-2'>
            <CDropdownToggle color='secondary'>Change Engagement Type</CDropdownToggle>
            <CDropdownMenu>
                {engagementTypes == null ?
                    <CDropdownHeader>Loading...</CDropdownHeader>
                    :
                    engagementTypes.map((type, i) => {

                        return (
                            <div key={i + 'item'}>
                                <CDropdownItem
                                    key={type.CompanyAccountEngagementTypeID}
                                    value={type.CompanyAccountEngagementTypeID + '-type'}
                                    onClick={() => UpdateEngagementType(type.CompanyAccountEngagementTypeID)}
                                >
                                    {type.Name}
                                </CDropdownItem>
                            </div>
                        );
                    })
                }
            </CDropdownMenu>
        </CDropdown>;
    }

    //#endregion Component Functions

    //#endregion Functions

    //#region Use Effect

    // On companyId change, reload everything
    useEffect(() => {
        setId(parseInt(companyId));
    }, [companyId]);

    // Get company account information to display
    useEffect(() => {
        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);

        GetCompanyAccount();
    }, [id]);

    // Get the Credit Payment Info
    useEffect(() => {
        const getPaymentQuality = async () => {
            let url = '/api/credit/get-payment-quality?TMWCustomerId=' + companyInfo.TMWCustomerId;
            setBarUpdating(true);

            const response = await fetch(url, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            });
            var data = await HandleResponseReturn(response);
            data = HandleDataReturn(data);

            setBarUpdating(false);
            return data;
        };

        if (companyInfo != null) {
            getPaymentQuality()
                .then(x => setCreditInfo(x))
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent('Error: Could not get payment quality!', err.message));
                });

            // Only load engagement types once and if not a customer (because customers cannot edit engagement types)
            if (engagementTypes == null && companyInfo.EngagementType !== 'Customer') {
                GetEngagementType(true)
                    .then(x => setEngagementTypes(x))
                    .catch(err => {
                        console.error(err);
                        setToast(GetErrorToastComponent('Error: Could not get prospect types!', err.message));
                    });
            }
        }
    }, [companyInfo]);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top-end' />

        {companyInfo && <>
            {/* Confirm modal for converting account back to lead */}
            <ConfirmModal
                confirmFunction={ConvertBackToLead}
                cancelFunction={() => setShowConvertConfirmModal(false)}
                title='Are you sure?'
                message={`Convert '${companyInfo.CompanyName}' to Leads`}
                showModal={showConvertConfirmModal}
                confirmColour='danger'
            />

            {/* Add to playlist modal */}
            {playlists &&
                <AddToPlaylistModal
                    addedFn={OnAddedToPlaylist}
                    cancelFn={() => setShowAddToPlaylistModal(false)}
                    companiesSelected={companySelected.current}
                    playlists={playlists}
                    setShowModal={setShowAddToPlaylistModal}
                    setToast={setToast}
                    showModal={showAddToPlaylistModal}
                />
            }

            {/* Flip account modal*/}
            <FlipAccountModal
                companyAccountId={id}
                accountName={companyInfo.CompanyName}
                accountOwner={companyInfo.AccountOwner}
                visible={showFlipAccountModal}
                setVisible={setShowFlipAccountModal}
                setToast={setToast}
            />

            {/* Onboard users in Customer Portal modal*/}
            <CustomerPortalOnboardingModal
                tmwCustomerId={companyInfo.TMWCustomerId}
                visible={showCustomerPortalOnboardingModal}
                setVisible={setShowCustomerPortalOnboardingModal}
                setToast={setToast}
            />
        </>}

        {/* Submit credit request modal */}
        <SubmitCreditRequestModal
            companyAccountID={id}
            successFn={GetCompanyAccount}
            visible={showSubmitCreditRequestModal}
            setVisible={setShowSubmitCreditRequestModal}
            setToast={setToast}
        />

        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>Company Profile</CHeaderText>
        </CHeader>
        <CCard>
            <CCardBody>
                <CCardTitle className='justify-content-between title-display-content'>
                    {companyInfo == null ?
                        <Placeholder width='250px' />
                        :
                        <span>
                            {companyInfo.CompanyName}
                            {companyInfo.DeletedFlag && <span className='ms-2 fw-normal fs-6'>
                                Note: This account has been deleted and can only be viewed as admin or credit user
                            </span>}
                        </span>
                    }
                    <top-button-group>
                        <ChangeEngagementTypeComponent />
                        <CDropdown style={{ width: 'auto' }}>
                            <CDropdownToggle color='secondary' disabled={loading}>Actions</CDropdownToggle>
                            <CDropdownMenu>
                                <CDropdownItem onClick={OnAddToPlaylist}>Add to Playlist</CDropdownItem>
                                <CDropdownItem onClick={() => setShowSubmitCreditRequestModal(true)}>Submit Credit Request</CDropdownItem>
                                <CDropdownItem onClick={() => setShowFlipAccountModal(true)}>Flip Account</CDropdownItem>
                                <CDropdownItem onClick={() => {
                                    if (companyInfo?.TMWCustomerId == null) {
                                        setToast(GetToastComponent('Only customers with TMW ID can be onboarded', null, 'warning'));
                                    } else {
                                        setShowCustomerPortalOnboardingModal(true);
                                    }
                                }}>
                                    Onboard User to Vision
                                </CDropdownItem>
                                {
                                    // Can convert back to lead if a prospect and company is enabled
                                    companyInfo != null
                                        && companyInfo.EnabledFlag === true
                                        && companyInfo.EngagementType !== engagementTypeName.Customer
                                        && companyInfo.EngagementType !== engagementTypeName.Unqualified
                                        ? <CDropdownItem component='button' onClick={() => setShowConvertConfirmModal(true)}>Convert Back To Lead</CDropdownItem>
                                        : null
                                }
                                <CDropdownDivider />
                                <CDropdownHeader>Links</CDropdownHeader>
                                <CDropdownItem onClick={OnContractLink}>Add Contract</CDropdownItem>
                                <CDropdownItem onClick={OnEdiLink}>Add EDI</CDropdownItem>
                            </CDropdownMenu>
                        </CDropdown>
                    </top-button-group>
                </CCardTitle>
                <hr />

                <ProfileDetails loading={loading} profileInfo={companyInfo} type='account' />
                <hr />

                {creditInfo == null ?
                    <>
                        <PaymentQualityBar Updating={true} />
                        <hr />
                    </>
                    :
                    <>
                        <PaymentQualityBar PaymentTerms={creditInfo.PaymentTerms} CompanyAging={creditInfo.CompanyAging} Updating={paymentBarUpdating} />
                        <hr />
                    </>
                }

                {/* Bottom Section with tabs */}
                <CNav variant='tabs' className='mt-4'>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Notes'} onClick={() => setActiveTab('Notes')}>Notes</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink active={activeTab === 'To-Dos For Company'} onClick={() => setActiveTab('To-Dos For Company')}>To-Dos For Company</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Meetings Booked'} onClick={() => setActiveTab('Meetings Booked')}>Meetings Booked</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Formal RFPs'} onClick={() => setActiveTab('Formal RFPs')}>Formal RFPs</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Credit Request History'} onClick={() => setActiveTab('Credit Request History')}>Credit Request History</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Collection Details'} onClick={() => setActiveTab('Collection Details')}>Collection Details</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Orders'} onClick={() => setActiveTab('Orders')}>Orders</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent style={{ minHeight: '250px' }}>
                    <CTabPane role='tabpanel' visible={activeTab === 'Notes'}>
                        <Notes
                            companyId={id}
                            disableAddFile={companyInfo == null || companyInfo.TMWCustomerId == null}
                            forceUpdateCounter={todosUpdateCounter}
                            afterAddFn={(noteContent) => {
                                setActiveTab('To-Dos For Company');
                                var note = noteContent.toLowerCase();

                                if (note.includes('call')) {
                                    setTodoTitle('Call');
                                } else if (note.includes('email')) {
                                    setTodoTitle('Email');
                                } else if (note.includes('follow')) {
                                    setTodoTitle('Follow Up');
                                } else {
                                    setTodoTitle('Others');
                                }
                            }}
                            setToast={setToast}
                        />
                    </CTabPane>
                    <CTabPane role='tabpanel' visible={activeTab === 'To-Dos For Company'}>
                        <Todo setToast={setToast} companyId={id} defaultTitle={todoDefaultTitle} fixedWidth={false} postAddTodoAction={forceUpdateMeetingsBooked} />
                    </CTabPane>
                    <CTabPane role='tabpanel' visible={activeTab === 'Meetings Booked'}>
                        <AllMeetingsBooked setToast={setToast} companyId={id} forceUpdateCounter={meetingsBookedUpdateCounter} />
                    </CTabPane>
                    <CTabPane role='tabpanel' visible={activeTab === 'Formal RFPs'}>
                        <FormalRfp setToast={setToast} companyAccountId={id} />
                    </CTabPane>
                    <CTabPane role='tabpanel' visible={activeTab === 'Credit Request History'}>
                        <CreditRequestHistory companyId={id} setToast={setToast} />
                    </CTabPane>
                    <CTabPane role='tabpanel' visible={activeTab === 'Collection Details'}>
                        {companyInfo == null || companyInfo.TMWCustomerId == null ?
                            'No Tmw Id to get collection details for'
                            :
                            <CollectionDetails
                                details={creditInfo}
                                useDetails={true}
                                setToast={setToast}
                            />
                        }
                    </CTabPane>
                    <CTabPane role='tabpanel' visible={activeTab === 'Orders'}>
                        {companyInfo == null || companyInfo.TMWCustomerId == null ?
                            'No Tmw Id to get order history for'
                            :
                            <OrderHistory tmwCustomerId={companyInfo.TMWCustomerId} setToast={setToast} />
                        }
                    </CTabPane>
                </CTabContent>
            </CCardBody>
        </CCard>
    </>;
}