import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { cookieNames, userRoles } from '../../constants';
import { 
    GetCookie,
    GetBearer, 
    GetErrorToastComponent, 
    GetToastComponentFromCookiesValues, 
    HandleDataReturn,
    HandleResponseReturn, 
    SetToastComponentValuesInCookie 
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CHeader,
    CHeaderText,
    CRow,
    CToaster
} from '@coreui/react';

const TinyMCE = React.lazy(() => import('../../components/tinymce/tinymce'));

export default function CampaignTemplateEdit() {

    //#region Variables

    const [template, setTemplate] = useState(null);
    const body = useRef(); // Ref used to get body editor content

    const isAdmin = useRef(JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin);
    const { templateId } = useParams(); 
    const navigate = useNavigate();
    const [toast, setToast] = useState(null);

    //#endregion Variables

    //#region Functions

    // Save the updated template information
    function OnUpdateTemplate(e) {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);

        // Add email body
        formData.append('EmailTemplateID', templateId);
        formData.append('EmailTemplateHtmlBody', body.current.getContent());
        formData.append('EmailTemplatePlainBody', template.EmailTemplatePlainBody);

        fetch('/api/campaign/update-campaign-template', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: form.method,
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    navigate('/campaigns/templates/' + templateId + '/profile');
                } else {
                    throw new Error(data);
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not update template!', err.message));
            });
    }

    //#endregion Functions

    //#region Use Effect

    // Get template info, get user info, and check for toast items to show on first load
    useEffect(() => {
        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);

        // Get template
        fetch('/api/campaign/get-campaign-template?templateId=' + templateId, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setTemplate(data);
            })
            .catch(err => {
                console.error(err);
                SetToastComponentValuesInCookie('Error: Could not get template information!', err.message, 'danger', false);
                navigate('/campaigns/templates');
            });
    }, [navigate, templateId]);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Campaign Template Edit</CHeaderText>
            </CHeader>
            <CCard>
                {template == null ? null :
                    <CCardBody>
                        <CForm onSubmit={OnUpdateTemplate} method='POST'>
                            {/* Template Elements */}
                            <CRow>
                                <CCol sm={12} md={4} className='mb-2'>
                                    <CFormLabel className='required-label'>Name</CFormLabel>
                                    <CFormInput
                                        defaultValue={template.EmailTemplateName}
                                        name='EmailTemplateName'
                                        maxLength='50'
                                        required />
                                </CCol>
                                <CCol sm={12} md={4}>
                                    <CFormLabel className='required-label'>Permission</CFormLabel>
                                    <CFormSelect defaultValue='Only Me' name='Permission' feedbackInvalid='Required field' required>
                                        <option value='Only Me'>Only Me</option>
                                        {/* Only Admins have all the options */}
                                        {isAdmin.current === true ? <>
                                            <option value='Everyone'>Everyone</option>
                                            <option value='Superuser'>Superuser</option>
                                            <option value='System'>System</option>
                                        </> : null}
                                    </CFormSelect>
                                </CCol>
                                <CCol sm={12} md={4}>
                                    <CFormLabel className='required-label'>Subject</CFormLabel>
                                    <CFormInput
                                        defaultValue={template.EmailTemplateSubject}
                                        name='EmailTemplateSubject'
                                        maxLength='300'
                                        required />
                                </CCol>
                            </CRow>

                            {/* Template Body */}
                            <CRow className='mb-5'>
                                <CCol className='mt-3'>
                                    <CFormLabel className='required-label'>Body</CFormLabel>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <TinyMCE
                                            initialEditorValue={template.EmailTemplateHtmlBody}
                                            bodyRef={body}
                                        />
                                    </Suspense>
                                </CCol>
                            </CRow>

                            {/* Submit */}
                            <CRow className='mb-3'>
                                <CCol className='' style={{ textAlign: 'end' }}>
                                    <CButton onClick={() => navigate(`/campaigns/templates/${templateId}/profile`)} color='secondary' style={{ width: '100px' }}>Cancel</CButton>
                                </CCol>
                                <CCol>
                                    <CButton type='submit' style={{ backgroundColor: 'rgb(51, 122, 183)', width: '100px' }}>Save</CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    </CCardBody>
                }
            </CCard>
        </>
    );       
}