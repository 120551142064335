import React, { useEffect, useRef, useState } from 'react';
import {
    GetErrorToastComponent,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
} from '../../functions';

import {
    CCol,
    CFormInput,
    CFormLabel,
    CRow,
} from '@coreui/react';

import Table from '../../components/table/table';

// Displays the collection details for a customer. Can pass tmwCustomerId to fetch details or pass the
// details directly from parent
// tmwCustomerId (string): TMW Id for company. Can be null if useDetails = true
// details (object): The response from getting payment quality. Can be null if useDetails = false
// useDetails (bool): Tells component to wait for details object or to fetch from tmwCustomerId
// setToast (useState): toast for if error occurs passed from parent
export default function CollectionDetails({ tmwCustomerId, details, setToast, useDetails }) {

    //#region Variables
    const [companyAging, setCompanyAging] = useState();
    const [lastItems, setLastItems] = useState(); // Last Invoice/Payment
    const [avgDaysToPay, setAvgDaysToPay] = useState();
    const [collectionActivity, setCollectionActivity] = useState();
    const [loading, setLoading] = useState(true);

    const paymentTerms = useRef();
    const collectorName = useRef();
    const collectorEmail = useRef();

    // Headers for all the tables in this section
    const headers = {
        'Company Aging': [
            {
                name: 'Invoice Age',
                headerClassName: 'invoice-age'
            },
            {
                name: 'Amount Due',
                headerClassName: 'amount-due'
            },
            {
                name: '# Open Invoices',
                headerClassName: '#-open-invoices'
            }
        ],
        'Last Items': [
            {
                name: 'Title',
                headerClassName: 'title text-white nav-buttons',
            },
            {
                name: 'Date',
                headerClassName: 'date'
            },
            {
                name: 'Amount',
                headerClassName: 'amount'
            }
        ],
        'Average Days To Pay': [
            {
                name: 'Title',
                headerClassName: 'title text-white nav-buttons',
            },
            {
                name: 'Average Days To Pay',
                headerClassName: 'average-days-to-pay'
            }
        ],
        'Collection Activity': [
            {
                name: 'Collector Name',
                headerClassName: 'collector-name'
            },
            {
                name: 'Collector Action',
                headerClassName: 'collector-action'
            },
            {
                name: 'Collector Notes',
                headerClassName: 'collector-notes'
            },
            {
                name: 'Contact Will Take Action',
                headerClassName: 'contact-will-take-action'
            },
            {
                name: 'Contact Pay Amount',
                headerClassName: 'contact-pay-amount'
            },
            {
                name: 'Collector Contacted The Person',
                headerClassName: 'collector-contacted-the-person'
            },
            {
                name: 'Collector Called At',
                headerClassName: 'collector-called-at'
            }
        ]
    };

    const formatter = new Intl.NumberFormat('en-CA', { // Currency formatter used in Annual Revenue field
        style: 'currency',
        currency: 'CAD',
    });

    //#endregion Variables

    //#region Use Effect

    // Get Collection Details
    useEffect(() => {
        const ProcessAndSetData = (data) => {
            // If error occured while fetching and nothing returned
            if (data == null) return;

            // Set Ageing table values
            if (data.CompanyAging != null) {
                let aging = data.CompanyAging;
                let companyAging = [
                    {
                        'Invoice Age': '0 - 30 Days',
                        'Amount Due': formatter.format(aging.AgingCurrent),
                        '# Open Invoices': aging.CustomerAgingCounts[0].NumOpenInvoices
                    },
                    {
                        'Invoice Age': '31 - 60 Days',
                        'Amount Due': formatter.format(aging.Aging31To60),
                        '# Open Invoices': aging.CustomerAgingCounts[1].NumOpenInvoices
                    },
                    {
                        'Invoice Age': '61 - 90 Days',
                        'Amount Due': formatter.format(aging.Aging61To90),
                        '# Open Invoices': aging.CustomerAgingCounts[2].NumOpenInvoices
                    },
                    {
                        'Invoice Age': '91 - 120 Days',
                        'Amount Due': formatter.format(aging.Aging91To120),
                        '# Open Invoices': aging.CustomerAgingCounts[3].NumOpenInvoices
                    },
                    {
                        'Invoice Age': '121 - 150 Days',
                        'Amount Due': formatter.format(aging.Aging121To150),
                        '# Open Invoices': aging.CustomerAgingCounts[4].NumOpenInvoices
                    },
                    {
                        'Invoice Age': '150 and Over',
                        'Amount Due': formatter.format(aging.AgingOver150),
                        '# Open Invoices': aging.CustomerAgingCounts[5].NumOpenInvoices
                    },
                    {
                        'Invoice Age': 'Total Balance',
                        'Amount Due': formatter.format(aging.AgingTotal),
                        '# Open Invoices': aging.CustomerAgingCounts[6].NumOpenInvoices
                    }
                ];

                setCompanyAging(companyAging);
            }

            // Set Last Invoice/Payment values
            if (data.LastInvoice != null && data.LastPayment != null) {
                let lastInvoiceDate = new Date(data.LastInvoice.DOCDATE);
                let lastPaymentDate = new Date(data.LastPayment.LASTPYDT);

                let defaultDate = new Date(1900, 0, 1).getTime();
                let noLastInvoice = defaultDate === lastInvoiceDate.getTime();
                let noLastPayment = defaultDate === lastPaymentDate.getTime();

                let lastItems = [
                    {
                        'Title': 'Last Invoice',
                        'Date': noLastInvoice ? 'N/A' : `${lastInvoiceDate.getFullYear()}/${lastInvoiceDate.getMonth() + 1}/${lastInvoiceDate.getDate()}`,
                        'Amount': noLastInvoice ? 'N/A' : formatter.format(data.LastInvoice.ORTRXAMT)
                    },
                    {
                        'Title': 'Last Payment',
                        'Date': noLastPayment ? 'N/A' : `${lastPaymentDate.getFullYear()}/${lastPaymentDate.getMonth() + 1}/${lastPaymentDate.getDate()}`,
                        'Amount': noLastPayment ? 'N/A' : formatter.format(data.LastPayment.LPYMTAMT)
                    }
                ];
                setLastItems(lastItems);
            }

            // Set Average Days To Pay values
            if (data.AverageDaysToPayPastYear != null && data.AverageDaysToPayAllTime != null) {
                let avgDaysToPay = [
                    {
                        'Title': 'Year To Date',
                        'Average Days To Pay': data.AverageDaysToPayPastYear
                    },
                    {
                        'Title': 'Life To Date',
                        'Average Days To Pay': data.AverageDaysToPayAllTime
                    }
                ]
                setAvgDaysToPay(avgDaysToPay);
            }

            // Set Collection Activity values
            if (data.CollectorActivityResponse != null) {
                var collectionActivity = data.CollectorActivityResponse;
                collectionActivity.forEach(row => {
                    row['Collector Name'] = row.CollectorName ? row.CollectorName.trim() : '';
                    row.CollectorName = undefined;

                    row['Collector Action'] = row.CollectorActivityType ? row.CollectorActivityType.trim() : '';
                    row.CollectorActivityType = undefined;

                    row['Collector Notes'] = row.CollectorActivityNoteText ? row.CollectorActivityNoteText.trim() : '';
                    row.CollectorActivityNoteText = undefined;

                    row['Contact Will Take Action'] = row.CollectorActivityActionPromised ? row.CollectorActivityActionPromised.trim() : '';
                    row.CollectorActivityActionPromised = undefined;

                    row['Contact Pay Amount'] = formatter.format(row.CollectorActivityAmountPromised);
                    row.CollectorActivityAmountPromised = undefined;

                    row['Collector Contacted The Person'] = row.CollectorActivityContactPerson ? row.CollectorActivityContactPerson.trim() : '';
                    row.CollectorActivityContactPerson = undefined;

                    let callDate = new Date(row.CollectorActivityContactDate);
                    row['Collector Called At'] = `${callDate.getFullYear()}/${callDate.getMonth() + 1}/${callDate.getDate()}`;
                    row.CollectorActivityContactDate = undefined;
                });
                setCollectionActivity(collectionActivity);
            }

            // Set other input values
            if (data.PaymentTerms != null)
                paymentTerms.current.value = data.PaymentTerms ? data.PaymentTerms.trim() : '';

            if (data.CollectorResponse != null) {
                collectorName.current.value =
                    data.CollectorResponse.CollectorName ? data.CollectorResponse.CollectorName.trim() : '';
                collectorEmail.current.value =
                    data.CollectorResponse.CollectorEmailAddress ? data.CollectorResponse.CollectorEmailAddress.trim() : '';
            }
        }

        // Only process collection details if not already done
        if (!loading) return;

        // If details is populated, use that instead of fetching
        if (useDetails === true && details != null) {
            ProcessAndSetData(details);
            setLoading(false);

        } else if (useDetails === false && tmwCustomerId != null) {
            fetch('/api/credit/get-payment-quality?TMWCustomerId=' + tmwCustomerId, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    ProcessAndSetData(data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent('Error: Could not get payment quality!', err.message));
                });
        }
    }, [tmwCustomerId, details]);

    //#endregion Use Effect

    return (
        <>
            <CRow className='mt-2'>
                <CCol md={12} lg={4}><CFormInput ref={paymentTerms} className='my-2' floatingLabel='Payment Terms' disabled /></CCol>
                <CCol md={12} lg={4}><CFormInput ref={collectorName} className='my-2' floatingLabel='Collector Name' disabled /></CCol>
                <CCol md={12} lg={4}><CFormInput ref={collectorEmail} className='my-2' floatingLabel='Collector Email' disabled /></CCol>
            </CRow>
            <CRow>
                <CCol md={12} lg={6}>
                    {/* Average Invoice Age */}
                    <Table
                        headers={headers['Company Aging']}
                        body={companyAging}
                        hover={true}
                        tableClass='align-middle'
                        isLoading={loading}
                        loadingNumRows={7}
                    />
                </CCol>
                <CCol md={12} lg={6}>
                    <Table
                        headers={headers['Average Days To Pay']}
                        body={avgDaysToPay}
                        hover={true}
                        tableClass='align-middle'
                        isLoading={loading}
                        loadingNumRows={2}
                    />

                    {/* Last Invoice/Payment */}
                    <Table
                        headers={headers['Last Items']}
                        body={lastItems}
                        hover={true}
                        tableClass='align-middle'
                        isLoading={loading}
                        loadingNumRows={2}
                    />
                </CCol>
            </CRow>
            <CRow>
                <CFormLabel>Collector Activity:</CFormLabel>
                {/* Collector Activity */}
                <Table
                    headers={headers['Collection Activity']}
                    body={collectionActivity}
                    hover={true}
                    tableClass='align-middle'
                    isLoading={loading}
                />
            </CRow>
        </>
    );
}