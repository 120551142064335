import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
    GetCountries,
    GetStateProvinces,
    GetErrorToastComponent,
    GetBearer,
    SetToastComponentValuesInCookie,
    HandleDataReturn,
    HandleResponseReturn
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCol, 
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CHeader,
    CHeaderText,
    CInputGroup,
    CInputGroupText,
    CRow,
    CToaster,
} from '@coreui/react';

import { GetErrorMessages, infoModalText } from '../../constants';

import InfoModal from '../../components/miscellaneous/infoModal';

import '../../style/edit.css';
import '../../custom.css';

export default function ContactEdit({ searchProfile }) {

    //#region Variables

    const [contactInfo, setContactInfo] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [stateProvinceList, setStateProvinceList] = useState(null);
    const [currentCountry, setCurrentCountry] = useState(null);
    const [validated, setValidated] = useState(false);
    const contactUserId = useRef(null);
    const { contactId } = useParams();
    const navigate = useNavigate();

    // Toast
    const [toast, setToast] = useState(null);

    //#endregion Variables

    //#region Functions

    function UpdateContact(e) {
        const form = e.target;
        e.preventDefault();

        // If submit is not triggered by button, don't submit
        if (document.activeElement.id !== 'contact-edit-submit') return;

        //checks if all required fields are filled
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true); // show validation

            // Get the first invalid element
            var firstInvalidElem = document.querySelector('input:invalid, select:invalid');

            // Scroll to element
            if (firstInvalidElem != null) {
                firstInvalidElem.focus();
            }
        } else {
            const formData = new FormData(form);

            formData.append('ContactID', contactUserId.current);
            formData.append('CompanyAccountID', contactInfo.CompanyAccountID);

            fetch('/api/contacts/update-contact', {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: form.method,
                body: formData
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    if (data === 'Update Contact Successful') {
                        SetToastComponentValuesInCookie('Contact update successful');
                        navigate('/contacts/' + contactUserId.current + '/profile');
                    } else {
                        throw new Error('An error has occurred when updating!');
                    }
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent(err.message));
                });
        }
    }

    //#endregion Functions

    //#region Use Effect

    useEffect(() => {
        const getContactInfo = async () => {
            contactUserId.current = contactId == null ? searchProfile : contactId;

            const response = await fetch('/api/contacts/get-contact?id=' + contactUserId.current, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            })

            const data = await HandleResponseReturn(response);
            return HandleDataReturn(data);
        }

        getContactInfo()
            .then(x => {
                setContactInfo(x);

                // Set current country 
                x.CountryID != null ? setCurrentCountry(x.CountryID.toString()) : setCurrentCountry('');
            })
            .catch(err => {
                console.error(err);
                SetToastComponentValuesInCookie('Error: Could not get contact information!', err.message, 'danger', false);

                navigate('/contacts');
            });
    }, [searchProfile]);

    // Get all the data fields for dropdown
    useEffect(() => {
        GetCountries()
            .then(x => setCountryList(x))
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(GetErrorMessages.Countries, err.message));
            });
        GetStateProvinces()
            .then(x => setStateProvinceList(x))
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(GetErrorMessages.StateProvince, err.message));
            });
    }, []);

    // Enables or disables the province/state dropdown depending on whether there is a country selected
    useEffect(() => {
        const countryDropdown = document.getElementById('country-select');
        const stateProvinceDropdown = document.getElementById('state-province-select');

        if (countryDropdown == null || stateProvinceDropdown == null) return;

        // If no value is selected for country
        if (countryDropdown.value === '') {
            stateProvinceDropdown.disabled = true;
        } else {
            stateProvinceDropdown.disabled = false;
        }
    }, [currentCountry, stateProvinceList, countryList]);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Contact Edit</CHeaderText> 
            </CHeader>
            <CCard>
                <CCardBody className='px-5'>
                    {contactInfo == null ? null : (
                        <CForm
                            className='mt-3'
                            onSubmit={UpdateContact}
                            method='POST'
                            noValidate
                            validated={validated}
                        >
                            <CRow className='mb-5'>
                                <CCol xs={12} sm={6}>
                                    <CRow>
                                        <CCol sm={12} md={6}>
                                            <CFormLabel className='required-label'>First Name</CFormLabel>
                                            <CFormInput
                                                type='text'
                                                name='FirstName'
                                                defaultValue={contactInfo.FirstName}
                                                maxLength='75'
                                                feedbackInvalid='Required field'
                                                required
                                                />
                                        </CCol>
                                        <CCol sm={12} md={6} className=''>
                                            <CFormLabel className='mt-3 mt-md-0 required-label'>Last Name</CFormLabel>
                                            <CFormInput
                                                type='text'
                                                name='LastName'
                                                defaultValue={contactInfo.LastName}
                                                maxLength='75'
                                                feedbackInvalid='Required field'
                                                required
                                            />
                                        </CCol>
                                    </CRow>
                                    <CFormLabel className='mt-3'>Title</CFormLabel>
                                    <CFormInput type='text' name='Title' defaultValue={contactInfo.Title} maxLength='75' />
                                    <CFormLabel className='mt-3'>Email Address</CFormLabel>
                                    <CFormInput type='text' name='EmailAddress' defaultValue={contactInfo.EmailAddress} maxLength='150' />
                                    <CFormLabel className='mt-3'>Phone Number</CFormLabel>
                                    <CInputGroup>
                                        <CFormInput type='text' name='PhoneNumber' defaultValue={contactInfo.PhoneNumber} maxLength='16' />
                                        <div className='mx-3'></div>
                                        <CInputGroupText>ext</CInputGroupText>
                                        <CFormInput type='text' name='PhoneNumberExtension' defaultValue={contactInfo.PhoneNumberExtension} maxLength='6' style={{ maxWidth: '30%'}} />
                                    </CInputGroup>
                                    <CFormLabel className='mt-3 required-label'>
                                        Contact Type
                                        <InfoModal modalTitle='Warning' modalContent={infoModalText.ContactEditType} />
                                    </CFormLabel>
                                    <CFormSelect name='Type' defaultValue={contactInfo.Type} required feedbackInvalid='Required field'>
                                        <option value='Other'>Other</option>
                                        <option value='Primary'>Primary</option>
                                        <option value='Credit'>Credit</option>
                                    </CFormSelect>
                                    <CFormLabel className='mt-3'>Mobile Number</CFormLabel>
                                    <CFormInput type='text' name='MobilePhoneNumber' defaultValue={contactInfo.MobilePhoneNumber} maxLength='16' />
                                    <CFormLabel className='mt-3'>Follow Up Frequency</CFormLabel>
                                    <CFormSelect name='AutomatedEmailFrequency' defaultValue={contactInfo.AutomatedEmailFrequency} >
                                        <option></option>
                                        <option value='Never'>Never</option>
                                        <option value='Weekly'>Weekly</option>
                                        <option value='Bi-Weekly'>Bi-Weekly</option>
                                        <option value='Monthly'>Monthly</option>
                                        <option value='Bi-Monthly'>Bi-Monthly</option>
                                        <option value='Quarterly'>Quarterly</option>
                                        <option value='Semi-Annually'>Semi-Annually</option>
                                        <option value='Annually'>Annually</option>
                                    </CFormSelect>
                                </CCol>
                                <CCol xs={12} sm={6} className='mt-3 mt-sm-0'>
                                    {
                                        (countryList == null || stateProvinceList == null || currentCountry == null) ? null : 
                                            <>
                                                <CFormLabel>Address</CFormLabel>
                                                <CFormInput type='text' name='AddressLine1' className='mb-3' defaultValue={contactInfo.AddressLine1} maxLength='75' />
                                                <CFormInput type='text' name='AddressLine2' defaultValue={contactInfo.AddressLine2} maxLength='75' />
                                                <CRow>
                                                    <CCol>
                                                        <CFormLabel className='mt-3'>City</CFormLabel>
                                                        <CFormInput type='text' name='City' defaultValue={contactInfo.City} maxLength='75' />
                                                        <CFormLabel className='mt-3'>Province/State</CFormLabel>
                                                        <CFormSelect
                                                            id='state-province-select'
                                                            defaultValue={contactInfo.StateProvinceID}
                                                            name='StateProvinceID'
                                                        >
                                                            {
                                                                currentCountry === '' ? null : 
                                                                    stateProvinceList.map((stateProvince) => {
                                                                        // Only map if country doesn't exist or the country matches
                                                                        if (stateProvince.CountryID.toString() === currentCountry) {
                                                                            return (<option key={stateProvince.StateProvinceID} value={stateProvince.StateProvinceID}>{stateProvince.StateProvinceName}</option>)
                                                                        }
                                                                        return null;
                                                                    })
                                                            }
                                                        </CFormSelect>
                                                    </CCol>
                                                    <CCol>
                                                        <CFormLabel className='mt-3'>Country</CFormLabel>
                                                        <CFormSelect
                                                            id='country-select'
                                                            defaultValue={currentCountry}
                                                            name='CountryID'
                                                            onChange={(e) => setCurrentCountry(e.target.value)}
                                                        >
                                                            <option />
                                                            {
                                                                countryList.map((country) => {
                                                                    return (<option key={country.CountryID} value={country.CountryID}>{country.CountryName}</option>)
                                                                })
                                                            }
                                                        </CFormSelect>
                                                        <CFormLabel className='mt-3'>Postal/Zip Code</CFormLabel>
                                                        <CFormInput type='text' name='ZipPostalCode' defaultValue={contactInfo.ZipPostalCode} maxLength='15' />
                                                    </CCol>
                                                </CRow>
                                            </>
                                    }
                                </CCol>
                            </CRow>
                            <CRow className='bottom-button-group'>
                                <CCol style={{ textAlign: 'end' }}>
                                    <CButton onClick={() => navigate(`/contacts/${contactUserId.current}/profile`)} color='secondary'>Cancel</CButton>
                                </CCol>
                                <CCol>
                                    <CButton id='contact-edit-submit' type='submit' style={{ backgroundColor: '#337ab7' }}>Save</CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    )}
                </CCardBody>
            </CCard>
        </>
    );
}