import React, { useEffect, useRef, useState } from 'react';

import '../../style/placeholder.css';

/**
 * Creates a placeholder with custom parameters
 * @param {string} width Defaults '150px'. Maximum width
 * @param {string} height Default '100%'. Maximum height
 * @param {number} widthRandomness Default 0.25. Larger number means more will be taken away from initial width
 * @param {number} heightRandomness Default 0. Larger number means more will be taken away from initial height 
 * @param {string} borderRadius Default '8px'. CSS radius of corners 
 * @param {string} color Default '#DEDEDE'. Color of placeholder
 * @param {int} count Default 1. Number of elements per placeholder span 
 * @param {bool} useBreak Default false. Only matters if count > 1 and you want the placeholders to display on new line
 * @param {bool} animated Default true. Animate the placeholder or not
 * @param {...} otherProps Optional other props for span holding placeholder
 */
export default function Placeholder({ width, height, widthRandomness, heightRandomness, borderRadius, color, count, useBreak, animated, ...otherProps }) {
    const [items, setItems] = useState([]);
    const widthNum = useRef(parseFloat(width ?? '150px'));
    const heightNum = useRef(parseFloat(height ?? '100%'));
    const widthUnit = useRef((width ?? '150px').toString().replace(/\d+/g, ''));
    const heightUnit = useRef((height ?? '100%').toString().replace(/\d+/g, '')); 
    const counter = useRef(count ?? 1);

    useEffect(() => {
        var elements = [];
        for (let i = 0; i < counter.current; i++) {
            const w = `${widthNum.current - (Math.random() * widthNum.current * (widthRandomness ?? 0.25))}${widthUnit.current}`;
            const h = `${heightNum.current - (Math.random() * heightNum.current * (heightRandomness ?? 0))}${heightUnit.current}`;

            elements.push(
                <span
                    className={`placeholder ${animated === false ? '' : 'animated'}`}
                    key={i}
                    style={{
                        width: w,
                        height: h,
                        borderRadius: borderRadius ?? '8px',
                        backgroundColor: color ?? '#DEDEDE'
                    }}
                >
                    &zwnj;
                </span>
            );

            // Add break if not the last item
            if (i !== counter.current - 1) {
                if (useBreak === true) {
                    elements.push(<br key={`br-${i}`} />);
                } else {
                    elements.push(<span key={`span-${i}`} className='mx-2' />);
                }                
            }
        }

        setItems(elements);
    }, []);

    return (
        <span {...otherProps}>
            {items.map(x => x)}
        </span>
    );

} 