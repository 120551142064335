import React, { useEffect, useState } from 'react';
import { GetBearer, HandleDataReturn, HandleResponseReturn } from '../../../functions';

import {
    CCard,
    CCardBody,
    CHeader,
    CHeaderDivider,
    CHeaderText,
} from '@coreui/react';

import Table from '../../../components/table/table'

export default function Users() {

    /*************** VARIABLES ***************/
    const [usersData, setUsers] = useState(null);
    const headers = [
        {
            name: 'Display Name',
        },
        {
            name: 'Name',
        },
        {
            name: 'Username',
        },
        {
            name: 'Division',
        }
    ];

    // Get the table values and format
    useEffect(() => {
        fetch('/api/administration/get-all-users', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setUsers(data);
            })
            .catch(console.error);
    }, []);

    return <>
        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>Users</CHeaderText>
        </CHeader>
        <CHeaderDivider />
        <CCard>
            <CCardBody>
                <Table headers={headers} body={usersData} bordered={true} striped={true} color={'light'} />
            </CCardBody>
        </CCard>
    </>;
}