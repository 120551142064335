import React from 'react';

import {
    CToast,
    CToastBody,
    CToastHeader,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faDumpsterFire, faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { cookieNames, localStoreNames } from './constants';

// Functions
//#region Functions

// Cookies
//#region Cookies

/**
 * Gets cookie value if exist and option to remove it as well, returns null if not found
 * @param {string} name The key of the cookie to get
 * @param {bool?} removeAlso Default false. Should the cookie be removed from storage or kept (until expiry)
 * @returns Cookie value or null if it does not exist
 */
function GetCookie(name, removeAlso) {
    let cName = name + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let cookies = decodedCookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();

        if (cookie.indexOf(cName) === 0) {
            let cookieValue = cookie.substring(cName.length, cookie.length);
            if (removeAlso) {
                // Remove by setting expiry to now
                SetCookie(name, cookieValue, 0);
            }

            return cookieValue;
        }
    }

    return null;
}

// Sets a cookie name and value which expires in set number of hours
// If no path is provided, generic path is set
// name (string) = key of the cookie (aka name of the item to store)
// value = the data that is to be retrieved later
// expireHours = how many hours from now before cookie expires
// path (optional) = specify the path of cookie in the event there are multiple with the same name
/**
 * Sets a cookie name and value which expires in set number of hours
 * If no path is provided, generic path is set
 * @param {string} name Key of the cookie (aka name of the item to store)
 * @param {any} value The data that is to be retrieved later
 * @param {int} expireHours Number of hours from now before cookie expires
 * @param {bool} secureOnly Optional cookie secure tag to add to cookie. Default false.  
 * @param {string} path Optional cookie path for storage. Useful if you have multiple cookies of the same name
 */
function SetCookie(name, value, expireHours, secureOnly, path) {
    if (name == null || value == null || expireHours == null) throw Error('name, value, or expireHours parameter is null and shouldb be set');

    let date = new Date();
    date.setTime(date.getTime() + expireHours * 1000 * 60 * 60);
    let secure = secureOnly === true ? 'secure;' : '';
    let cookie = `${name}=${value};expires=${date.toUTCString()};${secure}path=`;

    if (path == null) cookie += '/';
    else cookie += path;

    document.cookie = cookie;
}

//#endregion Cookies

// Get Functions
//#region Get Functions

// Note: all of the Get functions will throw error when something wrong and errors will need to be handled

/**
 * Gets the latest change log version and sets toast message if there is a difference indicating new updates
 * @param {setState} setToast Used to show toast
 */
async function GetLastestChangeLogVersion(setToast) {
    let currentVersion = localStorage.getItem(localStoreNames.changeLogMostRecentVersion);

    // Update cookie with most recent change log version
    // Note: this is also done on main App page load
    const response = await fetch('/api/setting/get-change-log-version', {
        method: 'GET'
    });
    const data = await response.text()

    localStorage.setItem(localStoreNames.changeLogMostRecentVersion, data);

    // If there is a new version of Skynet
    if (currentVersion !== data && currentVersion != null)
        setToast(GetToastComponent('There has been a new version of Skynet deployed. Please refresh the page by pressing Ctrl + F5', null, null, true, 10));
}

async function GetCompanyOwners() {
    const response = await fetch('/api/companyaccounts/get-owners', {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

/**
 * Gets all the contacts for a company
 * @param {int} companyId Id of company to get all contacts of
 * @param {fn} callbackFunction Function to run after service worker return fetch.
 * This is the function you would run in the then clause. This is for service worker 
 * update. Will be passed data returned. Uses UpdateSWFetch()
 * @param {int} contactIdToShowFirst Optional id in company ids to show first
 * @returns
 */
async function GetCompanyContacts(companyId, callbackFunction, contactIdToShowFirst) {
    let url = '/api/contacts/get-company-contacts?companyId=' + companyId;

    if (contactIdToShowFirst != null) url += '&primaryContactId=' + contactIdToShowFirst;

    if (callbackFunction != null) UpdateSWFetch(url, (x) => callbackFunction(x));

    const response = await fetch(url, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

async function GetContactsByIds(ids) {
    const response = await fetch('/api/contacts/get-contacts-by-ids?ids=' + ids, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

async function GetCountries() {
    const response = await fetch('/api/location/get-countries', {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    })
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

async function GetCountryById(id) {
    const response = await fetch('/api/location/get-country?id=' + id, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    if (typeof (data) === 'string' && data.includes('Error:')) throw new Error('Country could not be found given id ' + id);

    return data;
}

/**
 * Gets the engagement types. Option to return only prospect type
 * @param {bool} prospectOnly Unset defaults to false. When set true, will return only prospects. 
 * @returns Engagement types
 */
async function GetEngagementType(prospectOnly) {
    var url = '/api/companyaccounts/get-company-engagement-types';

    if (prospectOnly !== undefined) url += '?prospectOnly=' + prospectOnly;

    const response = await fetch(url, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

async function GetGrades() {
    const response = await fetch('/api/companyaccounts/get-company-grades', {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

async function GetIndustries() {
    const response = await fetch('/api/industry/get-industries', {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

/**
 * Returns all the companies that are managed by ownerId excluding current account
 * Note: accountOwner cannot be null if user is not an admin (will throw error)
 * @param {int?} currentCompany Id of company to exclude from results
 * @param {int?} accountOwner User idto limit accounts returned to those owned by user
 * @returns List of companies belonging to the account owner excluding the current company
 */
async function GetParentCompanies(currentCompany, accountOwner) {
    var url = '/api/companyaccounts/get-parents';

    if (currentCompany != null) {
        url += '?companyId=' + currentCompany;
    }

    if (accountOwner != null) {
        if (currentCompany != null) {
            url += '&ownerId=' + accountOwner;
        } else {
            url += '?ownerId=' + accountOwner;
        }
    }

    const response = await fetch(url, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

/**
 * Returns all playlists belonging to current user
 * @returns All playlists belonging to current active user
 */
async function GetPlaylists() {
    const response = await fetch('/api/playlist/get', {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);
    return HandleDataReturn(data);
}

async function GetQuoteType() {
    const response = await fetch('/api/companyaccounts/get-quote-types', {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);
    return HandleDataReturn(data);
}

async function GetStateProvinceById(id) {
    const response = await fetch('/api/location/get-state-province?id=' + id, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    if (typeof (data) === 'string' && data.includes('Error:')) throw new Error('StateProvince could not be found given id ' + id);

    return data;
}

async function GetStateProvinces() {
    const response = await fetch('/api/location/get-states-and-provinces', {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

/**
 * Gets sources, has option to include deleted items
 * @param {int} sourceId Get source name given id
 * @returns Source name
 */
async function GetSourceNameById(sourceId) {
    const response = await fetch(`/api/source/get-source-name-by-id?sourceId=${sourceId}`, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

/**
 * Gets sources, has option to include deleted items
 * @param {bool} includeDeleted Include deleted sources. Default false
 * @returns Sources
 */
async function GetSources(includeDeleted) {
    let url = `/api/source/get-sources?includeDeleted=${includeDeleted === true ? 'true' : 'false'}`;
    const response = await fetch(url, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

//#endregion Get Functions

// Component Functions
//#region Component Functions

/**
 * Gets a toast component formatted as set
 * @param {string} title Title of toast
 * @param {string} body Optional body of toast
 * @param {string} color Optional color of toast ('success', 'danger', etc.). Default is whiteish
 * @param {bool} autohide Optional whether to autohide toast after some time
 * @param {int} hideDelaySeconds Optional delay can be set in seconds. Default is 5
 * @param {bool} bodyNotText Optional specifying body is not text and should display as passed and not converted toString
 * @returns Toast component
 */
function GetToastComponent(title, body, color, autohide, hideDelaySeconds, bodyNotText) {
    if (hideDelaySeconds == null) hideDelaySeconds = 5;
    if (autohide == null) autohide = true;
    hideDelaySeconds *= 1000;

    var icon = null;
    var iconColour = null;

    switch (color) {
        case 'success':
            icon = faCheckCircle;
            iconColour = '#2eb85c';
            break;
        case 'danger':
            icon = faDumpsterFire;
            iconColour = '#e55353';
            break;
        case 'warning':
            icon = faExclamationTriangle;
            iconColour = '#f9b115';
            break;
        default: // Default info icon
            icon = faInfoCircle;
            iconColour = '#0d6efd';
            break;
    }

    return <CToast autohide={autohide} delay={hideDelaySeconds}>
        <CToastHeader closeButton>
            <FontAwesomeIcon icon={icon} size='lg' className='me-3' style={{ color: iconColour }} />
            <div className="fw-bold me-auto">{title.toString()}</div>
        </CToastHeader>
        {body == null ?
            null
            : 
            <CToastBody>
                {bodyNotText === true ? body : body.toString()}
            </CToastBody>
        }
    </CToast>;
}

/**
 * Same as GetToastComponent but sets colour to 'danger' and does not autohide
 * @param {string} title Title of toast
 * @param {string} body Optional body of toast
 * @returns Toast Component
 */
function GetErrorToastComponent(title, body) {
    return GetToastComponent(title, body, 'danger', false);
}

//#endregion Component Functions

// Form
//#region Form

/**
 * Loads form elements from local storage to resume editing
 * @param {FormData} formData FormData object to load into. Will populate the formdata with values loaded form localStorage
 * @param {string} formIdentifier Unique identifier to fetch form
 * @param {bool} removeAlsoDefault to false, removes from storage as well
 * @returns Whether there was anything found in local storage. If formData or formIdentifier is null, will also return false
 */
function LoadFormFromLocalStorage(formData, formIdentifier, removeAlso) {
    let savedData = JSON.parse(localStorage.getItem(formIdentifier));

    if (savedData == null || formData === null || formIdentifier == null) return false;

    // Get all saved elements into formData
    for (const element of formData) {
        if (element[0] in savedData) {
            formData.set(element[0], savedData[element[0]]);
        }
    }

    if (removeAlso) localStorage.removeItem(formIdentifier);

    return true;
}

/**
 * Saves the current form data to local storage for editing later
 * @param {FormData} formData Items to save in data form
 * @param {any} formIdentifier Unique identifier to save from under. This is needed to get form later
 */
function SaveFormToLocalStorage(formData, formIdentifier) {
    let data = {};

    for (const element of formData) {
        if (element[1].length > 0) {
            data[element[0]] = element[1];
        }
    }

    localStorage.setItem(formIdentifier, JSON.stringify(data));
}

//#endregion Form

// Settings
//#region Settings

/**
 * Gets the setting value for current user. Returns null if not exist
 * @param {string[]} settings All the settings to get for current user
 * @returns An string[]? with the setting values in the order it was passed
 */
async function GetSetting(settings) {
    var parameters = '';

    // Format the parameters to send
    settings.forEach(x => {
        parameters += 'setting=' + x + '&';
    });

    const response = await fetch('/api/setting/get-user-setting?' + parameters, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'GET'
    });
    const data = await HandleResponseReturn(response);

    return HandleDataReturn(data);
}

/**
 * Sets setting value for current user to be a new value or null
 * Does not return anything if error. Only console logs errors
 * @param {obj[]} settings  Array with each element containing the following parameters { Setting: string, Value: string }
 */
async function SetSetting(settings) {
    const response = await fetch('/api/setting/set-user-setting', {
        headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(settings)
    });
    const data = await HandleResponseReturn(response);
    const results = HandleDataReturn(data);

    // Only console log issues
    results.forEach(x => {
        if (!x.Updated) console.log('The setting ' + x.Name + ' was not updated');
    });
}

//#endregion Settings

// Fetch Middleware
//#region Fetch Middleware

//Note: not actually middleware but behaves like it and should be added to every fetch call

/**
 * Handles the data that is returned by get function
 * Throws error if error is returned from controller or data is null
 * @param {any} data Data that is returned from fetch
 * @returns the data passed as is if everything is ok
 */
function HandleDataReturn(data) {
    if (data == null) throw new Error('Expected value but was null');

    if (typeof (data) === 'string' && data.includes('Error:')) {
        throw new Error(data.slice(6)); // Remove old 'Error:' before throwing error
    }

    // If there are data validation errors
    // Note: this is mostly for development. They should not occur in production and should be fixed
    // Data should be checked before POST to controller
    if (typeof (data) === 'object' && data.errors != null) {
        throw new Error(JSON.stringify(data.errors));
    }

    return data;
}

/**
 * Handles responses and checks if there is a new token that was refreshed.
 * If there is, replace token in session. Will also handle 400 and 500 status codes.
 * @param {obj} response The response from request
 * @param {bool} returnAsIs Default false, returns the response as is and does not convert to json
 * @returns response.json() if returnAsIs = false and response if returnAsIs = true
 * @throws {exception} 400 and 500 status code
 */
function HandleResponseReturn(response, returnAsIs = false) {
    // Session expired means intended body is not returned and so should not continue and parse response
    // since it won't be json format. Error thrown to go directly to catch block in fetch instead of 
    // potentially risking incorrect parsing in then clause
    if (response.status === 401) throw new Error("Session has expired. Please refresh the page to login.");

    const headers = response.headers;

    // Set new token if exist
    const bearer = headers.get('Authorization');

    if (bearer != null) {
        const token = bearer.substring(7, bearer.length);
        SetCookie(cookieNames.accessToken, JSON.stringify(token), 1, true);
    }

    // Returns response as is without converting to json
    if (returnAsIs === true) return response;

    return response.json();
}

//#endregion Fetch Middleware

// Sorting
//#region Sorting

/**
    * Compares two items to see which is larger.
    * Note: based on function in Table component
    * Note: null values will be set to empty string
    * @param {string} a First item
    * @param {string} b Second item
    * @param {string} orderBy asc or dsc
    * @returns int saying which is greater
    */
function DescendingComparator(a, b) {
    try {
        let itemA = typeof a === 'string' ? a.toLowerCase() : a;
        let itemB = typeof b === 'string' ? b.toLowerCase() : b;

        if (a == null) itemA = '';
        if (b == null) itemB = '';

        if (itemB < itemA) {
            return -1;
        } else if (itemB > itemA) {
            return 1;
        }

        return 0;

    } catch {
        // If a value is null, will throw error due to toLowerCase()

        // Both null means equal
        if (b == null && a == null) {
            return 0;
        } else if (b == null) { // second value smaller if only second value null
            return -1;
        }

        // First value smaller
        return 1;
    }
}

/**
 * Comparator that compares two objects at property name key
 * @param {string} key Property name to compare by in both objects
 * @param {bool} sortAsc Sort ascending with true, descending with false
 * @returns -1, 0, 1 denoting greater, less, or equal
 */
function GetComparator(key, sortAsc) {
    return sortAsc
        ? (a, b) => -DescendingComparator(a[key], b[key])
        : (a, b) => DescendingComparator(a[key], b[key]);
}

//#endregion Sorting

// Utilities
//#region Utilities

/**
 * Converts Base 64 string into a blob of pdf type
 * @param {string} base64String Valid base64 string
 * @param {string} fileType File type for blob
 * @returns Blob object 
 */
function Base64ToBlob(base64String, fileType) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: fileType });
};

/**
 * Gets all the values from cookie if exists and passes it to GetToastComponent() and returns the result of that
 * @param {bool} removeAlso Should it also remove cookie value (same as GetCookie() removeAlso)
 * @returns Formatted toast component that can be passed to setToast directly
 */
function GetToastComponentFromCookiesValues(removeAlso) {
    // If showToast is not set, don't need to check the rest
    if (GetCookie('showToast', removeAlso) !== '1') return;

    let toastTitle = GetCookie('toastTitle', removeAlso);
    let toastBody = GetCookie('toastBody', removeAlso);
    let toastColour = GetCookie('toastColour', removeAlso);
    let toastAutohide = GetCookie('toastAutohide', removeAlso);
    let toastDelay = GetCookie('toastDelay', removeAlso);

    // Convert to bool
    if (toastAutohide === 'false') toastAutohide = false;
    else toastAutohide = true;

    return GetToastComponent(toastTitle, toastBody, toastColour, toastAutohide, toastDelay);
}

/**
 * Takes the values to set to cookie for toast, pass in null if value is not needed
 * Values are the same as values taken in GetToastComponent()
 * Can be retrieved manually from cookies or using utility function GetToastComponentFromCookiesValues()
 * @param {string} title
 * @param {string} body
 * @param {string} colour
 * @param {bool} autohide
 * @param {int} delaySeconds
 */
function SetToastComponentValuesInCookie(title, body, colour, autohide, delaySeconds) {
    if (title == null) {
        throw Error('title should not be empty for SetToastComponentValuesInCookie()');
    } else {
        SetCookie('showToast', '1', 1);
        SetCookie('toastTitle', title, 1);
    }
    if (body != null) SetCookie('toastBody', body, 1);
    if (colour != null) SetCookie('toastColour', colour, 1);
    if (autohide != null) SetCookie('toastAutohide', autohide, 1);
    if (delaySeconds != null) SetCookie('toastDelay', delaySeconds, 1);
}

/**
 * Gets the bearer token from session storage and returns it
 * @returns
 */
function GetBearer() {
    return JSON.parse(GetCookie(cookieNames.accessToken)) ?? '';
}

/**
 * Checks when the bearer is valid until
 * @returns Date object of expiry time
 */
function GetBearerValidUntil() {
    let token = GetBearer();

    if (token === '') return null;

    let tokenExpiry = ParseJwt(token).exp;
    return new Date(tokenExpiry * 1000);
}

/**
 * Takes a date string and formats it as yyyy/mm/dd. No empty spots for single digits
 * @param {string} date String format that can be formatted by Date()
 * @param {string?} separator optional separator for the date. Defaults to '/'
 */
function FormatDate(date, separator) {
    let d = new Date(date);

    if (separator == null) separator = '/';

    return `${d.getFullYear()}${separator}${('0' + (d.getMonth() + 1)).slice(-2)}${separator}${('0' + d.getDate()).slice(-2)}`;
}

/**
 * Regular navigation or ctrl + click for open in new tab
 * @param {event} e Route to navigate to
 * @param {string} route Route to navigate to
 * @param {useNavigate} nav Object for navigation from react-router-dom
 */
function NavWithNewTab(e, nav, route, ) {
    if (e.ctrlKey) {
        window.open(route, '_blank');
    } else {
        nav(route);
    }
}

/**
 * Parses the bearer token into an object
 * @param {string} token 
 * @returns token
 */
function ParseJwt(token) {
    if (token == null || token === '') return;

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

//#endregion Utilities

// Workbox
//#region Workbox

const functionHandlers = [];
const functionHandlerLock = 'function_handler_lock';

/**
 * Updates existing page with new data. Runs update function
 * @param {string} url URL of fetch must match in order for it to run update
 * @param {fn} updateFunction Function that takes the new data as a parameter  
 * and updates the useState that stores the data
 */
function UpdateSWFetch(url, updateFunction) {
    if (url == null || updateFunction == null) {
        console.error('url or updateFunction parameter was not provided');
        return;
    }

    // Add new url to check
    let handler = {
        url,
        updateFunction
    };
    // Check if there exists a handler under that url already
    navigator.locks.request(functionHandlerLock, async () => {
        let existingIndex = functionHandlers.findIndex(x => x.url === handler.url);
        if (existingIndex === -1) {
            functionHandlers.push(handler);
        } else {
            functionHandlers[existingIndex] = handler;
        }
    });
}

/**
 * This is called to run callback function associated.
 * NOTE: this is used by service worker. Do not use this for fetch. Use UpdateSWFetch()
 * @param {obj} message returned data with two parameters; url and newData
 */
function UpdateSWCallback(message) {
    navigator.locks.request(functionHandlerLock, async () => {
        if (functionHandlers.length === 0) return;
        functionHandlers.forEach((handler) => {
            if (message.url === handler.url) {
                handler.updateFunction(message.newData);
                return;
            }
        });
    });
}

//#endregion Workbox

//#endregion Functions

export {
    GetCookie, // Cookies
    SetCookie, // Cookies End
    GetLastestChangeLogVersion, // Get Functions
    GetCompanyOwners, 
    GetCompanyContacts,
    GetContactsByIds,
    GetCountries,
    GetCountryById,
    GetEngagementType,
    GetGrades,
    GetIndustries,
    GetParentCompanies,
    GetPlaylists,
    GetQuoteType,
    GetStateProvinceById,
    GetStateProvinces,
    GetSourceNameById,
    GetSources, // Get Functions End
    GetToastComponent, // Component Functions
    GetErrorToastComponent, // Component Functions End
    LoadFormFromLocalStorage, // Form 
    SaveFormToLocalStorage, // Form End
    GetSetting, // Setting
    SetSetting, // Setting End
    HandleDataReturn, // Fetch Middleware
    HandleResponseReturn, // Fetch Middleware End
    DescendingComparator, // Sorting
    GetComparator, // Sorting End
    Base64ToBlob, // Utilities
    GetToastComponentFromCookiesValues, 
    SetToastComponentValuesInCookie,
    GetBearer,
    GetBearerValidUntil,
    FormatDate,
    NavWithNewTab,
    ParseJwt, // Utilities End
    UpdateSWFetch, // Workbox
    UpdateSWCallback,// Workbox End
}