import React from 'react';
import CIcon from '@coreui/icons-react';
import {
    cilGroup,
    cilGraph,
    cilStar,
    cilChartLine,
    cilTask,
    cilTruck,
    cilCash,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilNotes,
    cilChart,
    cilContact,
    cilCreditCard,
    cilClipboard,
} from '@coreui/icons';
import { CNavGroup, CNavItem } from '@coreui/react';

import { userRoles as r } from '../../constants';

// defining the side bar options 
const _nav = [
    {
        component: CNavItem,
        name: 'Users',
        to: '/admin-users',
        icon: <CIcon icon={cilGroup} className="nav-icon" />,
        roles: [r.Admin],
    },
    {
        component: CNavItem,
        name: 'Scoreboard',
        to: '/scoreboard',
        icon: <CIcon icon={cilGraph} className="nav-icon" />,
        roles: [r.Admin, r.Sales],
    },
    {
        component: CNavItem,
        name: 'Megaboard',
        to: '/megaboard',
        icon: <CIcon icon={cilStar} className="nav-icon" />,
        roles: [r.Admin, r.Sales],
    },
    {
        component: CNavItem,
        name: 'New Business YTD',
        to: '/new-business',
        icon: <CIcon icon={cilChartLine} className="nav-icon" />,
        roles: [r.Admin, r.Sales],
    },
    {
        component: CNavItem,
        name: 'Sales Dashboard',
        to: '/salesDashboard',
        icon: <CIcon icon={cilChart} className="nav-icon" />,
        roles: [r.Admin, r.Sales],
    },
    {
        component: CNavItem,
        name: 'SSRS Reports',
        to: 'http://bo-ssrs-01/ReportServer/Pages/ReportViewer.aspx?%2fSales%2fSales+Menu&rs:Command=Render',
        icon: <CIcon icon={cilClipboard} className="nav-icon" />,
        roles: [r.Admin, r.Sales],
    },
    {
        component: CNavItem,
        name: 'To-Dos',
        to: '/todo',
        icon: <CIcon icon={cilTask} className="nav-icon" />,
        roles: [r.Admin, r.Sales, r.Credit],
    },
    {
        component: CNavItem,
        name: 'Leads',
        to: '/company-leads',
        icon: <CIcon icon={cilTruck} className="nav-icon" />,
        roles: [r.Admin, r.Sales],
    },
    {
        component: CNavItem,
        name: 'Accounts',
        to: '/company-accounts',
        icon: <CIcon icon={cilCash} className="nav-icon" />,
        roles: [r.Admin, r.Sales],
    },
    {
        component: CNavGroup,
        name: 'Campaigns',
        icon: <CIcon icon={cilEnvelopeClosed} className="nav-icon" />,
        roles: [r.Admin],
        items: [
            {
                component: CNavItem,
                name: 'Summary',
                to: '/campaigns',
                icon: <CIcon icon={cilEnvelopeOpen} className="nav-icon" />,
                roles: [r.Admin],
            },
            {
                component: CNavItem,
                name: 'Templates',
                to: '/campaigns/templates',
                icon: <CIcon icon={cilNotes} className="nav-icon" />,
                roles: [r.Admin],
            }
        ],
    },
    {
        component: CNavItem,
        name: 'Contacts',
        to: '/contacts',
        icon: <CIcon icon={cilContact} className="nav-icon" />,
        roles: [r.Admin, r.Sales],
    },
    {
        component: CNavItem,
        name: 'Credit',
        to: '/credit',
        icon: <CIcon icon={cilCreditCard} className="nav-icon" />,
        roles: [r.Admin, r.Credit],
    },
]

export default _nav;