import { useEffect, useState } from 'react';
import {
    GetBearer,
    GetCookie,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn,
} from '../../functions';

import {
    CButton,
    CFormLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { cookieNames, userRoles } from '../../constants';

export default function FlipAccountModal({ companyAccountId, accountName, accountOwner, visible, setVisible, setToast }) {

    //#region Fields

    const [sales, setSales] = useState(null); // List of sales reps
    const [selectedSales, setSelectedSales] = useState([]); // Sales selected to flip to

    //#endregion Fields

    //#region Functions

    function FlipAccount() {
        // If no rep selected
        if (selectedSales.length === 0) {
            setToast(GetToastComponent('Choose a rep to flip account to', null, 'warning'));
            return;
        }

        var request = {
            CompanyIdsToUpdate: [companyAccountId],
            NewOwner: selectedSales[0].UserID
        };

        fetch('/api/companyaccounts/change-account-owner', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(request)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    // Also show warning that user cannot see account anymore
                    let userId = parseInt(JSON.parse(GetCookie(cookieNames.userID)));
                    let role = JSON.parse(GetCookie(cookieNames.userRole));
                    let noteBody = null;
                    if (selectedSales[0].UserID !== userId && role === userRoles.Sales) {
                        noteBody = 'Note: You will not be able to view page after refreshing/navigating away because account has been flipped to someone else';
                    }

                    // Show toast won't autohide if body exists
                    setToast(
                        GetToastComponent(
                            'Flipped account to ' + selectedSales[0].DisplayName,
                            noteBody,
                            'success',
                            noteBody == null ? true : false
                        )
                    );
                    setVisible(false);
                } else {
                    throw new Error('An error has occurred while flipping');
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(err.message));
            });
    }

    //#endregion Functions

    //#region UseEffect

    // Get sales users after visible on first load
    useEffect(() => {
        if (!visible || sales != null) return;

        fetch('/api/sales/get-all-salespeople', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);
                setSales(data);
            })
            .catch(err => {
                setToast(GetErrorToastComponent('Error: Could not get salespeople!', err));
            });
    }, [visible, sales]);

    //#endregion UseEffect

    return <>
        <CModal alignment='center' visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader closeButton={false}>
                <CModalTitle>Flip Account "{accountName}" To Another Rep</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CFormLabel>Flip account from "{accountOwner}" to:</CFormLabel>
                <Typeahead
                    id='flip-sales-typeahead'
                    options={sales}
                    onChange={(value) => setSelectedSales(value)}
                    selected={selectedSales}
                    labelKey={option => `${option.DisplayName}`}
                    isLoading={sales == null}
                    emptyLabel='No sales found. You can only flip to reps found in dropdown'
                    inputProps={{ required: true }}
                />
            </CModalBody>
            <CModalFooter>
                <CButton
                    color='secondary'
                    variant='outline'
                    style={{ width: '90px' }}
                    onClick={() => setVisible(false)}
                >
                    Cancel
                </CButton>
                <CButton
                    color='success'
                    style={{ width: '90px' }}
                    onClick={FlipAccount}
                >
                    Flip
                </CButton>
            </CModalFooter>
        </CModal>
    </>;
}