import React from 'react';

import {
    CButton,
    CModal, 
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

// Confirmation modal before deleting items
// confirmFunction (fn) = runs this after confirming action
// confirmColour (string) = CoreUi colour
// cancelFunction (fn) = runs this when 'Cancel' is clicked to cancel action
// title (string) =  title to show in modal
// message (string) = optional message to put in modal body
// showModal (bool) = show or don't show modal
// Note: Handle variable passed to showModal in parent component done in Confirm/Cancel Function
export default function ConfirmModal({ confirmFunction, confirmColour, cancelFunction, title, message, showModal}) {
    return (
        <>
            <CModal
                alignment='center'
                scrollable={true}
                visible={showModal}
                onClose={cancelFunction}
            >
                <CModalHeader closeButton={false}>
                    <CModalTitle>{title}</CModalTitle>
                </CModalHeader>
                {message == null ? null : 
                    <CModalBody>
                        {message}
                    </CModalBody>
                }
                <CModalFooter>
                    <CButton color='secondary' variant='outline' style={{ width: '90px' }} onClick={cancelFunction}>Cancel</CButton>
                    <CButton color={confirmColour} style={{ width: '90px' }} onClick={confirmFunction}>Yes</CButton>
                </CModalFooter>
            </CModal>
        </>
    );
}