import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { cookieNames, userRoles } from '../../constants';
import {
    GetCookie,
    GetErrorToastComponent,
    GetBearer,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    NavWithNewTab,
    UpdateSWFetch,
} from '../../functions';

import {
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardTitle,
    CCol,
    CFormCheck,
    CHeader,
    CHeaderText,
    CRow,
    CToaster,
    CTooltip
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faPen, faPhoneAlt, faEnvelope, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

import Table from '../../components/table/table';

export default function Contact() {

    //#region Variables

    const [contacts, setContacts] = useState();
    const [showAllContacts, setShowAllContacts] = useState(false);
    const myContactData = useRef();
    const allContactData = useRef();
    const isAdmin = useRef(false);
    const [loading, setLoading] = useState(false);

    const [toast, setToast] = useState();
    const navigate = useNavigate();
    const headers = [
        {
            name: 'Title',
            headerClassName: 'title',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Name',
            headerClassName: 'name',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Company',
            headerClassName: 'company',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Contact Information',
            headerClassName: 'contact-information',
            colSort: false,
            moreThanJustText: true,
            hasSearch: true,
        },
        {
            name: 'Button',
            headerClassName: 'text-light nav-buttons',
            moreThanJustText: true,
            hasSearch: false,
        },
    ];

    //#endregion Variables

    //#region Use Effect

    // Get Contact data
    useEffect(() => {
        const processData = (data) => {
            if (data == null) return;

            data.forEach(row => {
                var valueWithElements = {};

                // Append the names together and remove old fields
                row['Name'] = row['FirstName'] + ' ' + row['LastName'];
                row['FirstName'] = undefined;
                row['LastName'] = undefined;

                // Format CompanyName as Company to match title
                row['Company'] = row['CompanyName'];
                row['CompanyName'] = undefined;

                // Format the contact information
                var phone, mobile, email;

                if (row['PhoneNumber'] != null && row['PhoneNumber'].trim() !== '') {
                    let phoneText = row['PhoneNumber'];

                    if (row['PhoneNumberExtension'] != null) phoneText += ' x ' + row['PhoneNumberExtension'];

                    phone = <><FontAwesomeIcon className='me-3 fa-xs' icon={faPhoneAlt} />{phoneText}<br /></>
                }

                if (row['MobilePhoneNumber'] != null && row['MobilePhoneNumber'].trim() !== '') {
                    mobile = <><FontAwesomeIcon className='me-3 fa-xs' icon={faMobileAlt} />{row['MobilePhoneNumber']}<br /></>
                }

                if (row['EmailAddress'] != null && row['EmailAddress'].trim() !== '') {
                    email = <><FontAwesomeIcon className='me-3 fa-xs' icon={faEnvelope} />{row['EmailAddress']}<br /></>
                }

                // Set the text values as well so it is searchable
                row['Contact Information'] = row['PhoneNumber'] + ' x ' + row['PhoneNumberExtension'] + ' ' + row['MobilePhoneNumber'] + ' ' + row['EmailAddress'];

                // Set the formatted display of Contact Information cell
                valueWithElements['Contact Information'] = <>
                    {phone}
                    {mobile}
                    {email}
                </>;

                // Add the Profile Button
                valueWithElements['Button'] =
                    <CRow>
                        <CCol>
                            <CTooltip content='Profile'>
                                <CButton onClick={(e) => NavWithNewTab(e, navigate, `/contacts/${row['ContactID']}/profile`)} className='m-1'>
                                    <FontAwesomeIcon icon={faAddressCard} />
                                </CButton>
                            </CTooltip>
                        </CCol>
                        <CCol>
                            <CTooltip content='Edit'>
                                <CButton onClick={(e) => NavWithNewTab(e, navigate, `/contacts/${row['ContactID']}/edit`)} className='m-1' color='warning'>
                                    <FontAwesomeIcon icon={faPen} style={{ color: 'white' }} />
                                </CButton>
                            </CTooltip>
                        </CCol>
                    </CRow>;

                row['ValueWithElements'] = valueWithElements;
            })

            return data;
        }

        /**
         * Sets contacts for my/all contacts
         * @param {obj[]} contacts
         * @param {bool} setMine true will set to myContactData, false will set to allContact
         */
        const setContactsFromFetch = (contacts, setMine) => {
            if (setMine) {
                myContactData.current = contacts;
            } else {
                allContactData.current = contacts;
            }
            
            setContacts(contacts);
        }
        const getContacts = async (abortController) => {
            setLoading(true);
            const response = await fetch('/api/contacts/get-contacts?allContacts=' + showAllContacts, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET',
                signal: abortController.signal
            });

            var data = await HandleResponseReturn(response);
            data = HandleDataReturn(data);

            const result = processData(data);
            setLoading(false);

            return result;
        }

        const abortController = new AbortController(); // Used to abort fetches

        // Get info only if needed and set to variable so that fetch doesn't happen again until page refresh
        if (showAllContacts) {
            if (allContactData.current == null) {
                UpdateSWFetch('/api/contacts/get-contacts?allContacts=true', (x) => {
                    // Run the process data that runs inside getContacts
                    x = processData(x);

                    setContactsFromFetch(x, false);
                });

                getContacts(abortController)
                    .then(data => setContactsFromFetch(data, false))
                    .catch(err => {
                        console.error(err);
                        setToast(GetErrorToastComponent(err.message));
                    });
            } else {
                setContacts(allContactData.current);
            }
        } else {
            if (myContactData.current == null) {
                UpdateSWFetch('/api/contacts/get-contacts?allContacts=false', (x) => {
                    // Run the process data that runs inside getContacts
                    x = processData(x);

                    setContactsFromFetch(x, true);
                });
                getContacts(abortController)
                    .then(data => setContactsFromFetch(data, true))
                    .catch(err => {
                        console.error(err);
                        setToast(GetErrorToastComponent(err.message));
                    });
            } else {
                setContacts(myContactData.current);
            }
        }

        return () => abortController.abort(); // Abort on navigate away
    }, [showAllContacts]);

    // Show toast if applicable and set if admin
    useEffect(() => {
        isAdmin.current = JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin;

        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);
    }, []);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Contacts</CHeaderText>
            </CHeader>
            <CCard>
                <CCardBody>
                    <CCardTitle className='justify-content-end title-display-content'>
                        {
                            isAdmin.current ? 
                                <CButtonGroup style={{ whiteSpace: 'nowrap' }}>
                                    <CFormCheck
                                        label='All Contacts'
                                        id='all-contacts-button'
                                        button={{ color: 'secondary', variant: 'outline' }}
                                        type='radio'
                                        name='btnradio contact-type-to-show'
                                        onChange={() => { if (!loading) setShowAllContacts(true); }}
                                        readOnly
                                        checked={showAllContacts}
                                    />
                                    <CFormCheck
                                        label='My Contacts'
                                        id='my-contacts-button'
                                        button={{ color: 'secondary', variant: 'outline' }}
                                        type='radio'
                                        name='btnradio contact-type-to-show'
                                        onChange={() => { if (!loading) setShowAllContacts(false); }}
                                        readOnly
                                        checked={showAllContacts === false}
                                    />
                                </CButtonGroup>
                            : null
                        }
                    </CCardTitle>
                    <hr />
                    <Table
                        headers={headers}
                        body={contacts}
                        hover={true}
                        color={'light'}
                        striped={true}
                        hasColSort={true}
                        hasPages={true}
                        hasSearchRow={true}
                        tableClass='align-middle'
                        isLoading={loading}
                    />
                </CCardBody>
            </CCard>
        </>
    );
}