import { Navigate } from 'react-router-dom';

import Account from './pages/account/account';
import AccountEdit from './pages/editInfo/accountEdit';
import AccountProfile from './pages/profile/accountProfile';
import AccountNew from './pages/account/accountNew';
import Campaign from './pages/campaign/campaign';
import CampaignTemplate from './pages/campaignTemplate/campaignTemplate';
import CampaignTemplateAdd from './pages/campaignTemplate/campaignTemplateAdd';
import CampaignTemplateEdit from './pages/campaignTemplate/campaignTemplateEdit';
import CampaignTemplateProfile from './pages/campaignTemplate/campaignTemplateProfile';
import Contact from './pages/contact/contact';
import ContactEdit from './pages/editInfo/contactEdit';
import ContactProfile from './pages/profile/contactProfile';
import Credit from './pages/credit/credit';
import CreditRequestEdit from './pages/credit/creditRequestEdit';
import Lead from './pages/lead/lead';
import LeadEdit from './pages/editInfo/leadEdit';
import LeadNew from './pages/lead/leadNew';
import LeadProfile from './pages/profile/leadProfile';
import Login from './pages/login/login';
import Megaboard from './pages/megaboard/megaboard';
import NewBusinessYtd from './pages/newBusiness/newBusinessYtd';
import SalesDashboard from './pages/salesDashboard/salesDashboard';
import SearchList from './pages/search/searchList';
import Scoreboard from './pages/scoreboard/scoreboard';
import TaskManager from './pages/taskManager/taskManager';
import Users from './pages/administration/users/users';

/*
 *  App Routes 
 *  name: the name of the route (not used in code)
 *  path: the path of the route to take
 *  requireAuth: does the user need to be logged in to go to this route
 *  userRoles: what kind of users can access this route; null indicates user that is not logged in
 *  element: the page that should be loaded
 */

const AppRoutes = [
    {
        name: 'Wildcard',
        path: '/*',
        requireAuth: false,
        userRoles: [null],
        element: <Navigate to='/login' />
    },
    {
        name: 'Wildcard Default',
        path: '/*',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <Navigate to='/company-accounts' />
    },
    {
        name: 'Wildcard Credit',
        path: '/*',
        requireAuth: true,
        userRoles: ['Credit'],
        element: <Navigate to='/credit' />
    },
    {
        name: 'Login',
        path: '/login',
        requireAuth: false,
        userRoles: [null],
        element: <Login />
    },
    {
        name: 'Search List',
        path: '/search-list',
        requireAuth: true,
        userRoles: ['Admin', 'Sales', 'Credit'],
        element: <SearchList />
    },
    {
        name: 'Users',
        path: '/admin-users',
        requireAuth: true,
        userRoles: ['Admin'],
        element: <Users />
    },
    {
        name: 'Scoreboard',
        path: '/scoreboard',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <Scoreboard />
    },
    {
        name: 'Megaboard',
        path: '/megaboard',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <Megaboard />
    },
    {
        name: 'Account Summary Page',
        path: '/company-accounts',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <Account />
    },
    {
        name: 'Company Account Profile',
        path: '/company-accounts/:companyId/profile',
        requireAuth: true,
        userRoles: ['Admin', 'Sales', 'Credit'],
        element: <AccountProfile />
    },
    {
        name: 'Company Account Edit',
        path: '/company-accounts/:companyId/edit',
        requireAuth: true,
        userRoles: ['Admin', 'Sales', 'Credit'],
        element: <AccountEdit />
    },
    {
        name: 'Company Account Add',
        path: '/company-accounts/new',
        requireAuth: true,
        userRoles: ['Admin', 'Sales', 'Credit'],
        element: <AccountNew />
    },
    {
        name: 'Contact',
        path: '/contacts',
        requireAuth: true,
        userRoles: ['Admin', 'Sales', 'Credit'],
        element: <Contact />
    },
    {
        name: 'Contact Profile',
        path: '/contacts/:contactId/profile',
        requireAuth: true,
        userRoles: ['Admin', 'Sales', 'Credit'],
        element: <ContactProfile />
    },
    {
        name: 'Contact Edit',
        path: '/contacts/:contactId/edit',
        requireAuth: true,
        userRoles: ['Admin', 'Sales', 'Credit'],
        element: <ContactEdit />
    },
    {
        name: 'Company Lead',
        path: '/company-leads',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <Lead />
    },
    {
        name: 'Company Lead Profile',
        path: '/company-leads/:companyId/profile',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <LeadProfile />
    },
    {
        name: 'Company Lead Edit',
        path: '/company-leads/:companyId/edit',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <LeadEdit />
    },
    {
        name: 'Company Lead New',
        path: '/company-leads/new',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <LeadNew />
    },
    {
        name: 'Campaign',
        path: '/campaigns',
        requireAuth: true,
        userRoles: ['Admin'],
        element: <Campaign />
    },
    {
        name: 'Campaign Templates',
        path: '/campaigns/templates',
        requireAuth: true,
        userRoles: ['Admin'],
        element: <CampaignTemplate />
    },
    {
        name: 'Campaign Template Add',
        path: '/campaigns/templates/add',
        requireAuth: true,
        userRoles: ['Admin'],
        element: <CampaignTemplateAdd />
    },
    {
        name: 'Campaign Template Profile',
        path: '/campaigns/templates/:templateId/profile',
        requireAuth: true,
        userRoles: ['Admin'],
        element: <CampaignTemplateProfile />
    },
    {
        name: 'Campaign Template Edit',
        path: '/campaigns/templates/:templateId/edit',
        requireAuth: true,
        userRoles: ['Admin'],
        element: <CampaignTemplateEdit />
    },
    {
        name: 'Credit',
        path: '/credit',
        requireAuth: true,
        userRoles: ['Admin', 'Credit'],
        element: <Credit />
    },
    {
        name: 'Credit Request Edit',
        path: '/credit/:companyApprovalId',
        requireAuth: true,
        userRoles: ['Admin', 'Credit'],
        element: <CreditRequestEdit />
    },
    {
        name: 'Todo',
        path: '/todo',
        requireAuth: true,
        userRoles: ['Admin', 'Sales', 'Credit'],
        element: <TaskManager />
    },
    {
        name: 'New Business YTD',
        path: '/new-business',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <NewBusinessYtd />
    },
    {
        name: 'Sales Dashboard',
        path: '/salesDashboard',
        requireAuth: true,
        userRoles: ['Admin', 'Sales'],
        element: <SalesDashboard />
    },
];

export default AppRoutes;
