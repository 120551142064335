import React, { useRef, useState } from 'react';

import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Tooltip component that shows info icon and lets you display a message
// Useful for providing guidance in a page
// content = the tips and tricks in the body
// title = what to display as title in modal. Defaults to 'Tips'
export default function InfoModal({ modalContent, modalTitle }) {

    const [visible, setVisible] = useState(false); // Whether to show contact information modal
    const title = useRef(modalTitle ?? 'Tips')

    return <>
        <CButton
            color='secondary'
            shape='rounded-circle'
            variant='ghost'
            onClick={() => setVisible(true)}
        >
            <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#0d6efd' }} />
        </CButton>
        <CModal
            alignment='center'
            scrollable={true}
            visible={visible}
            onClose={() => setVisible(false)}
        >
            <CModalHeader closeButton={false}>
                <CModalTitle>{title.current}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                {modalContent}
            </CModalBody>
            <CModalFooter>
                <CButton style={{ width: '90px' }} onClick={() => setVisible(false)}>Close</CButton>
            </CModalFooter>
        </CModal>
    </>;
}