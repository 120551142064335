import React, { useEffect, useState } from 'react';
import {
    FormatDate,
    GetErrorToastComponent,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    GetToastComponent,
} from '../../functions';

import {
    CButton
} from '@coreui/react';

import Table from '../../components/table/table';

/**
 * Displays the order history for a customer
 * @param {string} tmwCustomerId TMW Id for company
 * @param {useState} setToast used to set error toasts
 * @returns Order history table component
 */
export default function OrderHistory({ tmwCustomerId, setToast }) {

    //#region Variables
    const [orderHistories, setOrderHistories] = useState([]);
    const [loading, setLoading] = useState(false);

    const formatter = new Intl.NumberFormat('en-CA', { // Currency formatter used in Annual Revenue field
        style: 'currency',
        currency: 'CAD',
    });

    // Headers for all the tables in this section
    const headers = [
        {
            name: 'Status',
            colSort: true,
            hasSearch: true,
            headerClassName: '',
        },
        {
            name: 'Load #',
            colSort: true,
            hasSearch: true,
            headerClassName: '',
        },
        {
            name: 'Booked Date',
            colSort: true,
            hasSearch: true,
            headerClassName: '',
        },
        {
            name: 'From',
            colSort: true,
            hasSearch: true,
            headerClassName: '',
        },
        {
            name: 'To',
            colSort: true,
            hasSearch: true,
            headerClassName: '',
        },
        {
            name: 'Revenue',
            colSort: true,
            hasSearch: true,
            headerClassName: '',
        },
        {
            name: 'Pay',
            colSort: true,
            hasSearch: true,
            headerClassName: '',
        },
        {
            name: 'Carrier',
            colSort: true,
            hasSearch: true,
            headerClassName: '',
        }
    ];

    //#endregion Variables

    //#region Functions

    /**
     * Gets order history
     * @param {int?} count How many results to return
     */
    function GetOrderHistories(count) {
        const ProcessData = (data) => {
            if (data == null) return;
            data.forEach(row => {
                row['Status'] = row.ord_status;
                row.ord_status = undefined;

                row['Load #'] = row.ord_hdrnumber;
                row.ord_hdrnumber = undefined;

                row['Booked Date'] = FormatDate(row.ord_bookdate)
                row.ord_bookdate = undefined;

                row['From'] = `${row.OriginCityName}, ${row.ord_originstate}`;
                row.OriginCityName = undefined;
                row.ord_originstate = undefined;

                row['To'] = `${row.DestCityName}, ${row.ord_deststate}`;
                row.DestCityName = undefined;
                row.ord_deststate = undefined;

                row['Revenue'] = `${row.ord_currency.includes('US') ? 'USD' : 'CAD'} ${formatter.format(row.ord_totalcharge)}${row.IsRevenueAfterTax ? ' *' : ''}`;
                row.IsRevenueAfterTax = undefined;
                row.ord_currency = undefined;
                row.ord_totalcharge = undefined;

                row['Pay'] = `${row.PayCurrency} ${formatter.format(row.Pay)}`;
                row.PayCurrency = undefined;

                row['Carrier'] = row.ord_carrier;
                row.ord_carrier = undefined;
            });

            return data;
        }

        // If already loading
        if (loading) {
            setToast(GetToastComponent('Already loading order history'));
            return;
        }

        setLoading(true);

        let url = `/api/order/get-recent-orders?tmwId=${tmwCustomerId}`;

        if (count != null) url += `&count=${count}`;

        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setOrderHistories(ProcessData(data));
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not get order history!', err.message));
            });
    }

    //#endregion Functions

    //#region Use Effect

    // Get Collection Details
    useEffect(() => {
        GetOrderHistories(10);
    }, [tmwCustomerId]);

    //#endregion Use Effect

    return <>
        <div className='d-flex justify-content-end mt-3'>
            <CButton color='secondary' onClick={() => GetOrderHistories()}>
                Show Full History
            </CButton>
        </div>

        <Table
            headers={headers}
            body={orderHistories}
            hasColSort={true}
            hasSearchRow={true}
            striped={false}
            hover={true}
            tableClass='align-middle'
            isLoading={loading}
        />

        <span className='ms-3'>*after tax</span>
    </>;
}