import React from 'react';

import {
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons';

import Todo from './todo';

/**
 * Opens a modal to add todo. Uses stripped down version of <Todo />
 * @param {bool} visible Show or hide modal
 * @param {useState} setVisible Sets the state of visible. Used for closing and after adding todo
 * @param {set useState} setToast useState from parent to show toast after todo is added
 * @param {int} companyId Optional CompanyID associated with todo
 * @param {string} companyName Optional company name to display on modal header
 * @returns Component
 */
export default function TodoModal({ visible, setVisible, setToast, companyId, companyName }) {

    return (
        <CModal
            alignment='center'
            visible={visible}
            size='lg'
            onClose={() => setVisible(false)}
        >
            <CModalHeader closeButton={false}>
                {companyName == null ?  
                    <CModalTitle>Add To-do For Company</CModalTitle>
                    :
                    <CModalTitle>Add To-do For '{companyName}'</CModalTitle>
                }
                <CIcon icon={cilX} size='lg' onClick={() => setVisible(false)} />
            </CModalHeader>
            <CModalBody>
                <Todo
                    setToast={setToast}
                    companyId={parseInt(companyId)}
                    hideTodoList={true}
                    postAddTodoAction={() => setVisible(false)}
                />
            </CModalBody>
        </CModal>
    );
}