import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
    GetErrorToastComponent,
    GetToastComponent,
    GetBearer,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    SetToastComponentValuesInCookie,
    GetSourceNameById
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CHeader,
    CHeaderText,
    CToaster,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import ConfirmModal from '../../components/miscellaneous/confirmModal';
import ProfileDetails from '../../components/profiles/profileDetails';

import '../../style/profile.css';

export default function LeadProfile(props) {

    //#region Variables

    const [companyInfo, setCompanyInfo] = useState(null);
    const leadId = useRef(null); 
    const [leadExistsInCompany, setLeadExists] = useState(null);
    const { companyId } = useParams(); // lead id taken from url
    const navigate = useNavigate();
    const [toast, setToast] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    //#endregion Variables

    //#region Functions

    // Sends a request to try converting current lead into a prospect
    function ConvertToProspect() {
        const convert = async () => {
            const response = await fetch('/api/companyleads/convert-lead-to-prospect?id=' + leadId.current, {
                headers: {
                    'Authorization': 'Bearer ' + GetBearer(),
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            });
            var data = await HandleResponseReturn(response);
            data = HandleDataReturn(data);

            if (typeof (data) !== 'object') throw new Error('Wrong type returned: ' + data);

            if (data.Message.includes('Converted to Prospect') || data.Message.includes('Converted back to Prospect')) {
                // Converted successfully
                SetToastComponentValuesInCookie(data.Message)
                navigate('/company-accounts/' + data.CompanyAccountID + '/profile');
            } else if (data.Message === 'Cannot convert because it exists already in CompanyAccounts') {
                // Display toast showing account exists already
                setToast(GetToastComponent(data.Message, null, 'danger', true, 10))
            }
        };

        convert().catch(console.error);
    }

    //#endregion Functions

    //#region Use Effect

    // Set the lead id initially
    useEffect(() => {
        leadId.current = companyId == null ? props.searchProfile : companyId;
    }, [props.searchProfile]);

    // Run after the lead Id has been set 
    useEffect(() => {
        const getCompanyLead = async () => {
            const response = await fetch('/api/companyleads/get-lead-by-id?id=' + leadId.current, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            });
            const data = await HandleResponseReturn(response);
            return HandleDataReturn(data);
        }

        const getIfLeadExists = async () => {
            const response = await fetch('/api/companyaccounts/get-lead-exists-and-enabled-company-account?leadId=' + leadId.current, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            });

            const data = await HandleResponseReturn(response);
            return HandleDataReturn(data);
        };

        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);

        getCompanyLead()
            .then(x => {
                // Add open staus to lead
                x.EngagementType = 'Open';

                setCompanyInfo(x);

                // Get source that matches
                if (x.SourceID != null) {
                    GetSourceNameById(x.SourceID)
                        .then(sourceName =>
                            setCompanyInfo(prev => ({
                                ...prev,
                                'Source': sourceName
                            }))
                        )
                        .catch(console.error);
                }
            })
            .catch(err => {
                console.error(err);
                SetToastComponentValuesInCookie('Error: Could not get lead information!', err.message, 'danger', false);
                navigate('/company-leads');   
            });

        getIfLeadExists()
            .then(x => setLeadExists(x))
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not check if lead exists in company account table', err.message));
            });
    }, [leadId]);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            {companyInfo == null ? null :
                <ConfirmModal
                    confirmFunction={() => ConvertToProspect()}
                    cancelFunction={() => setShowConfirmModal(false)}
                    title='Are you sure?'
                    message={`Convert '${companyInfo.Name}' to prospect`}
                    showModal={showConfirmModal}
                    confirmColour='success'
                />
            }
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Lead Profile</CHeaderText> 
                {
                    leadExistsInCompany === true ? null : 
                    <CButton color='success' onClick={() => setShowConfirmModal(true)}>
                        <FontAwesomeIcon icon={faEdit} className='me-1' />
                        Convert To Prospect
                    </CButton>
                }
            </CHeader>
            <CCard>
                <CCardBody>
                    <CCardTitle className='justify-content-between title-display-content'>
                        {companyInfo != null ? companyInfo.Name : null}
                    </CCardTitle>
                    <hr/>
                    <ProfileDetails profileInfo={companyInfo} type='lead' />
                </CCardBody>
            </CCard>
        </>
    );
}