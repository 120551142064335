import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
    GetBearer,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    NavWithNewTab,
    SetToastComponentValuesInCookie,
    UpdateSWFetch
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CHeader,
    CHeaderText,
    CToaster
} from '@coreui/react';

import ProfileDetails from '../../components/profiles/profileDetails';

export default function ContactProfile({ modalMode, searchProfile }) {

    //#region Variables

    const [contact, setContact] = useState(null);
    const { contactId } = useParams();
    const navigate = useNavigate();
    const [toast, setToast] = useState(null);

    //#endregion Variables

    //#region Use Effect

    useEffect(() => {
        let id = contactId == null ? searchProfile : contactId;
        let url = '/api/contacts/get-contact?id=' + id;

        UpdateSWFetch(url, (x) => setContact(x));

        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);
                // Check for toast item to show
                let toastItem = GetToastComponentFromCookiesValues(true);
                if (toastItem != null) setToast(toastItem);

                setContact(data);
            })
            .catch(err => {
                console.error(err);
                SetToastComponentValuesInCookie('Error: Could not get contact information!', err.message);
                navigate('/contacts');
            });
    }, [contactId, navigate, searchProfile]);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top-end' />
        {modalMode ? null : 
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Contact</CHeaderText>
            </CHeader>
        }
        <CCard>
            <CCardBody>
                <CCardTitle className='justify-content-between title-display-content'>
                    {contact && <>
                        {[contact.FirstName, contact.LastName].join(' ')}
                        <CButton
                            style={{ backgroundColor: '#337ab7' }}
                            onClick={(e) => NavWithNewTab(e, navigate, `/company-accounts/${contact.CompanyAccountID}/profile`)}
                        >
                            Company Account
                        </CButton>
                    </>}
                </CCardTitle>
                <hr />
                <ProfileDetails profileInfo={contact} type={'contact'} hideButtons={modalMode} />
            </CCardBody>
        </CCard>
    </>;
}