import { useState } from 'react';
import {
    Base64ToBlob,
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn,
} from '../../functions';

import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
    CTooltip,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

export default function CustomerPortalOnboardingModal({ tmwCustomerId, visible, setVisible, setToast }) {

    //#region Fields

    const [validated, setValidated] = useState(false);

    //#endregion Fields

    //#region Functions

    function Download() {
        fetch('/api/file/vision-getting-started', {
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                var pdfBlob = Base64ToBlob(data, 'application/pdf');
                const url = URL.createObjectURL(pdfBlob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Titanium-Vision-Customer-Portal-Instructions.pdf';
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not get tutorial file', err));
            });
    }

    function OnboardUser(e) {
        const form = e.target;
        e.preventDefault();

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            const formData = new FormData(form);

            formData.append('TMWCustomerID', tmwCustomerId);

            fetch('/api/companyaccounts/onboard-customer-portal-user', {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: form.method,
                body: formData
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    if (data === true) {
                        setToast(GetToastComponent('User onboarded!', 'Please reach out to user regarding access', 'success'));
                    } else {
                        throw new Error('An error has occurred while onboarding');
                    }
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent(err.message));
                });
        }
    }

    //#endregion Functions

    //#region UseEffect

    //#endregion UseEffect

    return <CModal size='lg' alignment='center' visible={visible} onClose={() => setVisible(false)}>
        <CForm onSubmit={OnboardUser} method='POST' validated={validated}>
            <CModalHeader closeButton={false}>
                <CModalTitle>Onboard User to Vision</CModalTitle>
                <CTooltip content='Download Vision Portal Instruction'>
                    <CButton color='secondary' onClick={Download}>
                        <FontAwesomeIcon icon={faFileDownload} />
                    </CButton>
                </CTooltip>
            </CModalHeader>
            <CModalBody>
                <CRow>
                    <CCol>
                        TMW Customer ID: {tmwCustomerId}
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CFormLabel className='required-label mt-2'>First Name</CFormLabel>
                        <CFormInput type='text' name='FirstName' maxLength='50' required />
                        <CFormLabel className='required-label mt-2'>Last Name</CFormLabel>
                        <CFormInput type='text' name='LastName' maxLength='50' required />
                    </CCol>
                    <CCol>
                        <CFormLabel className='required-label mt-2'>Phone Number</CFormLabel>
                        <CFormInput type='text' name='Phone' maxLength='20' required />
                        <CFormLabel className='required-label mt-2'>Type</CFormLabel>
                        <CFormSelect name='Type' required>
                            <option />
                            <option>Accounting</option>
                            <option>Admin</option>
                            <option>Operations</option>
                        </CFormSelect>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol>
                        <CFormLabel className='required-label mt-2'>Email</CFormLabel>
                        <CFormInput type='email' name='Email' maxLength='255' required />
                    </CCol>
                </CRow>
                <CRow className='mt-2'>
                    <CCol>
                        <b style={{ backgroundColor: 'yellow' }}>NOTE:</b> An email will automatically be sent to the customer with the 'Vision Customer Portal Instructions' PDF and you will be CC'd
                    </CCol>
                </CRow>
            </CModalBody>
            <CModalFooter>
                <CButton
                    color='secondary'
                    variant='outline'
                    style={{ width: '90px' }}
                    onClick={() => setVisible(false)}
                >
                    Cancel
                </CButton>
                <CButton
                    type='submit'
                    color='success'
                    style={{ width: '90px' }}
                >
                    Onboard
                </CButton>
            </CModalFooter>
        </CForm>
    </CModal>;
}