import React from 'react';
import Nav from './nav';
import '../../style/sidebar.css';
import { Link } from "react-router-dom";

import {
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';

import { logoNegative } from '../../assets/icons/logo-negative';

import { AppSidebarNav } from './appSidebarNav';

export default function Sidebar({ show, userRole}) {

    return (
        <CSidebar visible={show} >
            {/*the side bar brand with two icons, collapsed and full*/}
            <CSidebarBrand to="/">
                <Link to={userRole === 'Credit' ? '/credit' : '/company-accounts'}>
                    <CIcon icon={logoNegative} height={35} />
                </Link>
            </CSidebarBrand>

            {/*side bar nav using the create element and passing in the nav*/}
            <CSidebarNav>
                <AppSidebarNav items={Nav} role={userRole} />
            </CSidebarNav>
        </CSidebar>
    );
}