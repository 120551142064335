import React from 'react'
import {
    CFooter,
    CLink
} from '@coreui/react'

export default function Footer() {
    return <CFooter>
        <CLink href="https://ttgi.com" target="_blank" rel="noopener noreferrer">Titanium Transportation Group Inc. &copy; 2024</CLink>
    </CFooter>;
}