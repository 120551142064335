import React, { useRef, useState } from 'react';
import {
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn
} from '../../functions';

import {
    CButton,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CTooltip,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFileDownload } from '@fortawesome/free-solid-svg-icons';

/**
 * Opens a modal to import from excel
 * @param {bool} visible Show or hide modal
 * @param {set useState} setVisible Sets the state of visible
 * @param {set useState} setToast
 * @returns Import from excel modal
 */
export default function ImportFromExcelModal({ visible, setVisible, setToast }) {

    //#region Fields

    const [selectedSource, setSelectedSource] = useState("Skynet Template");

    // Refs
    const importInput = useRef();

    //#endregion Fields

    //#region Functions

    // Import the excel file to add to leads
    function OnImport() {
        // Check there is a file to pass
        if (importInput.current.value === '') {
            setToast(GetToastComponent('Select a file to import first'));
            return;
        }

        const formData = new FormData();

        formData.append('FileImport', importInput.current.files[0]);
        formData.append('Source', selectedSource);

        // First process the leads file
        fetch('/api/companyaccounts/import-prospects', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST',
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                var title = '';
                var body = '';
                var color = null;
                var autohide = true;
                var bodyNotText = false;

                // If all rows added successfully to account and playlist
                if (data.RowsAddedToAccount === data.TotalRows && data.ErrorMessages.length === 0) {
                    title = 'All ' + data.TotalRows + ' rows added successfully';
                    body = `View newly added accounts in playlist "` + data.NewPlaylistName + `"`;
                    setVisible(false);
                } else if (data.RowsAddedToAccount !== data.TotalRows || data.ErrorMessages.length > 0) { // If some accounts could not be added
                    title = 'Added ' + data.RowsAddedToAccount + ' of ' + data.TotalRows + ' rows';
                    color = 'warning';
                    autohide = false;
                    bodyNotText = true;
                    body = [];

                    body.push(<>Error Messages: <br /></>);

                    // Add the error messages
                    data.ErrorMessages.forEach(x => {
                        body.push(<>
                            <FontAwesomeIcon icon={faArrowRight} className='me-2' />
                            {x}
                            <br />
                        </>);
                    });
                }

                if (title !== '') setToast(GetToastComponent(title, body, color, autohide, 15, bodyNotText));
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Could not parse file', err));
            });
    }

    //#endregion Functions

    return (<CModal
        alignment='center'
        visible={visible}
        onClose={() => setVisible(false)}
    >
        <CModalHeader closeButton={false}>
            <CModalTitle>
                Import Leads From Excel
            </CModalTitle>
            <CTooltip content='Download Template'>
                <a className='btn btn-secondary' href={'/api/file/import-prospects-template'} download='ProspectsUploadTemplate.csv'>
                    <FontAwesomeIcon icon={faFileDownload} />                  
                </a>
            </CTooltip>
        </CModalHeader>
        <CModalBody>
            <strong>Note: </strong> The current excel formats accepted are from the <i>template</i> provided or the one exported from <i>ZoomInfo</i>
            <br /><br />
            <CFormLabel className='me-3' style={{ width: 'auto' }}>Upload Source:</CFormLabel>
            <CDropdown className='mb-2'>
                <CDropdownToggle color='secondary'>{selectedSource}</CDropdownToggle>
                <CDropdownMenu>
                    <CDropdownItem onClick={() => setSelectedSource("Skynet Template")}>Skynet Template</CDropdownItem>
                    <CDropdownItem onClick={() => setSelectedSource("ZoomInfo")}>ZoomInfo</CDropdownItem>
                </CDropdownMenu>
            </CDropdown>
            <CFormInput ref={importInput} type='file' name='FileImport' label='Excel file to import:' accept='.csv' />
        </CModalBody>
        <CModalFooter>
            <CButton color='secondary' variant='outline' style={{ width: '90px' }} onClick={() => setVisible(false)}>Cancel</CButton>
            <CButton type='submit' style={{ width: '90px' }} onClick={OnImport}>Import</CButton>
        </CModalFooter>
    </CModal>);
}