import React from 'react';
import {
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

export default function EmailBodyPreviewModal({modalBody, modalTitle, showModal, setShowModal }) {
    return (
        <CModal
            alignment='center'
            scrollable={true}
            visible={showModal}
            onClose={() => setShowModal(false)}
            size='lg'
        >
            <CModalHeader>
                <CModalTitle>{modalTitle}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <iframe
                    title='Template Body'
                    srcDoc={modalBody}
                    width='100%'
                    style={{ minHeight: '500px', resize: 'vertical', overflow: 'auto' }}
                />
            </CModalBody>
        </CModal>
    );
}