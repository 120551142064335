import React, { useEffect, useState } from 'react';
import {
    GetBearer,
    GetErrorToastComponent,
    HandleDataReturn,
    HandleResponseReturn,
} from '../../functions';
import Table from '../table/table';

const headers = [
    {
        name: 'Meeting Date',
        headerClassName: 'meeting-date',
        colSort: true,
    },
    {
        name: 'Booked By',
        headerClassName: 'booked-with',
        colSort: true,
    },
    {
        name: 'Booked With',
        headerClassName: 'booked-with',
        colSort: true,
    },
    {
        name: 'Entered On',
        headerClassName: 'entered-on',
        colSort: true,
    },
];

/**
 * Todo Component
 * @param {set useState} setToast Set toast from parent to used to display toasts
 * @param {int} companyId CompanyAccountID from CompanyAccount table get meetings booked for that company
 * @param {int} forceUpdateCounter Optional state to set to force update. Can use reducer to update
 * @returns Component
 */
export default function AllMeetingsBooked({ setToast, companyId, forceUpdateCounter }) {

    //#region Fields

    const [meetingsBooked, setMeeingsBooked] = useState([]);
    const [loading, setLoading] = useState(true);

    //#endregion Fields

    //#region Functions

    /**
     * Fetches all meetings booked to-dos for company
     */
    function GetMeetingsBookedTodos() {
        setLoading(true);

        fetch(`/api/todo/get-meetings-booked-to-dos?companyId=${companyId}`, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                data.forEach(row => {
                    let meetingDate = new Date(row.MeetingDate);
                    row['Meeting Date'] = `${meetingDate.getFullYear()}/${meetingDate.getMonth() + 1}/${meetingDate.getDate()}`;
                    row.MeetingDate = undefined;

                    row['Booked With'] = row.BookedWith;
                    row.BookedWith = undefined;

                    row['Booked By'] = row.BookedBy;
                    row.BookedBy = undefined;                 

                    let enteredOnDate = new Date(row.EnteredOn);
                    row['Entered On'] = `${enteredOnDate.getFullYear()}/${enteredOnDate.getMonth() + 1}/${enteredOnDate.getDate()}`;
                    row.EnteredOn = undefined;
                });
                setMeeingsBooked([...data]);
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not get meetings booked', err.message));
            })
            .finally(() => setLoading(false));
    }

    //#endregion Functions

    //#region Use Effect

    // Initial load get meetings booked
    useEffect(() => { GetMeetingsBookedTodos() }, [companyId]);

    // Updates whenever update counter is set
    useEffect(() => {
        if (forceUpdateCounter == null || forceUpdateCounter === 0) return;

        GetMeetingsBookedTodos();
    }, [forceUpdateCounter]);

    //#endregion Use Effect

    return <Table
        headers={headers}
        body={meetingsBooked}
        hover={true}
        hasColSort={true}
        tableClass='align-middle'
        isLoading={loading}
    />;
}