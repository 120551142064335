import React, { useEffect, useState } from 'react';
import Placeholder from '../miscellaneous/placeholder'; // Update this if using in another project
import Table from './table';

/**
 * Table to show when loading
 * @param {int} numCols Default 5. Number of table columns
 * @param {int} numRows Default 10. Number of table rows
 * @param {string} tableColor Color of CTable
 * @param {bool} tableStriped Default false. Should table be striped 
 * @param {props} placeholderProps Props for Placeholder component which will fill the table cells. Refer to Placeholder for prop usage
 */
export default function SkeletonTable({ numCols, numRows, tableColor, tableStriped, placeholderProps }) {

    const [headers, setHeaders] = useState([]);
    const [body, setBody] = useState();

    // Sets specified header and rows for table
    useEffect(() => {
        let numberOfColumns = numCols ?? 5;
        let numberOfRows = numRows ?? 10;
        var headersItems = [];
        var bodyItems = [];
        var rowElement = {};
        rowElement['ValueWithElements'] = {};

        // Add the headers and setup row
        for (let i = 0; i < numberOfColumns; i++) {
            headersItems.push({
                name: 'col-' + i.toString(),
                headerClassName: 'text-light',
                moreThanJustText: true,
                colSort: false,
                hasSearch: false
            });

            rowElement['col-' + i.toString()] = '';
            rowElement['ValueWithElements']['col-' + i.toString()] = <Placeholder {...placeholderProps} />;
        }

        // Append the number of rows needed
        for (let i = 0; i < numberOfRows; i++) {
            bodyItems.push(rowElement);
        }

        setHeaders(headersItems);
        setBody(bodyItems);
    }, []);

    return (
        <Table
            headers={headers}
            body={body}
            color={tableColor}
            striped={tableStriped}
            tableClass='align-middle'
        />
    );
}