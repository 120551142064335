import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

import {
    CButton,
    CButtonGroup,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';

import {
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn,
} from '../../functions';

import '../../custom.css';

/**
 * Submit credit request modal. All fields are mandatory
 * @param {int} companyAccountID Id of company to be submitting credit request for
 * @param {function} successFn Runs on successful credit request submission
 * @param {bool} visible Show or hide modal
 * @param {useState} setVisible Component manages hiding component so need setVisible to do that
 * @param {useState} setToast Passed from parent component to show toast
 */
export default function SubmitCreditRequestModal({ companyAccountID, successFn, visible, setVisible, setToast }) {

    //#region Fields

    const [companyData, setCompanyData] = useState(); // Data relating to credit request
    const [isPrepaid, setIsPrepaid] = useState(false); // Prepaid or credit request

    const requestCredit = useRef();

    const formatter = new Intl.NumberFormat('en-CA', { // Currency formatter used in Annual Revenue field
        style: 'currency',
        currency: 'CAD',
    });
    const navigate = useNavigate();

    //#endregion Fields

    //#region Functions

    /**
     * Toggles the fields showing depending on which is selected: 'Credit' or 'Prepaid'. 
     * Also clears the credit amount field if switching to prepaid
     * @param {string} toggle 'Credit' or 'Prepaid'
     */
    function OnCreditPrepaidToggle(toggle) {
        setIsPrepaid(toggle === 'Prepaid');
        if (toggle === 'Prepaid') requestCredit.current.value = null;
    }

    function OnSubmitCreditRequest(e) {
        const formData = new FormData(e.target);
        e.preventDefault();

        let type = requestCredit.current.classList.contains('d-none') ? 'Prepaid' : 'Credit';

        formData.append('CompanyAccountID', companyAccountID);
        formData.append('Type', type);
        formData.append('CompanyApprovalType', 'Initial Credit');

        fetch('/api/credit/submit-credit-application', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST',
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                // If completed successfully
                if (data.Errors != null && data.Errors.length === 0) {
                    successFn();
                    setToast(GetToastComponent('Submitted credit request successfully!', `Request ID: ${data.CompanyApprovalProcessId}`, 'success', true, 10));
                    setVisible(false);

                    // Else some kind of errors
                } else {
                    var formattedErrors = [];

                    // If execution error
                    if (data.Errors != null && data.Errors.length > 0) {
                        data.Errors.forEach(err => formattedErrors.push(<>{err}<br /></>));

                        // If data validation error returned
                    } else if (data.errors != null) {
                        for (var err in data.errors) {
                            formattedErrors.push(<>{data.errors[err][0]}<br /></>)
                        }
                    }

                    // Set formatted error toast
                    setToast(GetToastComponent('Error: Could not submit credit application!', formattedErrors, 'danger', false, null, true));
                }
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not submit credit application!', err.message));
            });
    }

    function CreditInfoComponent() {
        var creditAmountApproved = companyData == null || companyData.CreditAmountApproved == null ?
            'N/A' : formatter.format(companyData.CreditAmountApproved);
        var availableCredit = companyData == null || companyData.AvailableCredit == null ?
            'N/A' : formatter.format(companyData.AvailableCredit);

        return <CRow className='mb-2'>
            <CCol>
                <span className='fw-medium'>Credit Amount Approved:</span>
                <span className='ms-1 fw-light'>{creditAmountApproved}</span>
            </CCol>
            <CCol>
                <span className='fw-medium'>Available Credit:</span>
                <span className='ms-1 fw-light'>{availableCredit}</span>
            </CCol>
        </CRow>
    }

    //#endregion Functions 

    // Initial credit data fetch when showing modal
    useEffect(() => {
        if (!visible || companyData != null) return;

        // Check if there is a existing credit request
        fetch(`/api/credit/get-can-submit-credit-application-exist?companyId=${companyAccountID}`, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data.HasExistingCreditApp) {
                    setToast(GetToastComponent('Cannot submit another credit request, there already exists one for this company', null, null, true, 15));
                    setVisible(false);
                    return;
                }

                setCompanyData(data);
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not get credit application!', err.message));
            });
    }, [visible]);

    return <CModal alignment='center' visible={visible} size='lg' onClose={() => setVisible(false)}>
        {companyData != null && companyData.MandatoryFieldsFilled === false ?
            <>
                <CModalHeader className='justify-content-between' closeButton={false}>
                    <CModalTitle>Mandatory fields not filled. Please fill them before submitting credit request</CModalTitle>
                </CModalHeader>
                <CModalFooter>
                    <CButton
                        color='success'
                        onClick={() => {
                            setVisible(false);
                            navigate(`/company-accounts/${companyAccountID}/edit`);
                        }}
                    >
                        Go To Edit
                    </CButton>
                </CModalFooter>
            </>
            :
            <CForm onSubmit={OnSubmitCreditRequest} method='POST'>
                <CModalHeader className='justify-content-between' closeButton={false}>
                    <CModalTitle>Submit Credit Request</CModalTitle>

                    <CButtonGroup>
                        <CFormCheck
                            id='credit-toggle'
                            label='Credit'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='radio'
                            name='credit-option'
                            onChange={() => OnCreditPrepaidToggle('Credit')}
                            readOnly
                            checked={!isPrepaid}
                            disabled={companyData == null || companyData.CreditAmountApproved != null}
                        />
                        <CFormCheck
                            id='prepaid-toggle'
                            label='Prepaid'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='radio'
                            name='credit-option'
                            onChange={() => OnCreditPrepaidToggle('Prepaid')}
                            readOnly
                            checked={isPrepaid}
                            disabled={companyData == null || companyData.CreditAmountApproved != null}
                        />
                    </CButtonGroup>
                </CModalHeader>
                <CModalBody>
                    {CreditInfoComponent()}

                    {/* Only show credit contact field if there is not an existing credit contact */}
                    {companyData != null && companyData.HasCreditContact ? null : <>
                        <CRow className='mb-1'><CCol className='fs-5 fw-semibold'>AP Credit Contact</CCol></CRow>
                        <CRow className='mb-3'>
                            <CCol>
                                <CFormLabel className='required-label'>First Name</CFormLabel>
                                <CFormInput name='CreditContactFirstName' required />
                            </CCol>
                            <CCol>
                                <CFormLabel className='required-label'>Last Name</CFormLabel>
                                <CFormInput name='CreditContactLastName' required />
                            </CCol>
                            <CCol>
                                <CFormLabel className='required-label'>Email Address</CFormLabel>
                                <CFormInput name='CreditContactEmail' type='email' required />
                            </CCol>
                        </CRow>
                    </>}
                    <CRow className='mb-3'>
                        <CCol>
                            <CFormLabel className={isPrepaid ? null : 'required-label'}>Requested Credit Amount</CFormLabel>
                            <CFormInput ref={requestCredit} className={isPrepaid ? 'd-none' : null} type='number' name='RequestedCreditAmount' required={!isPrepaid} />
                            <CFormInput className={isPrepaid ? null : 'd-none'} value='No credit needed for prepaid' disabled />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol><CFormTextarea label='Note To Credit' name='CreditNote' /></CCol>
                    </CRow>
                </CModalBody>
                <CModalFooter>
                    <CButton color='secondary' variant='outline' style={{ width: '90px' }} onClick={() => setVisible(false)}>Cancel</CButton>
                    <CButton color='success' style={{ width: '90px' }} type='submit'>Submit</CButton>
                </CModalFooter>
            </CForm>
        }
    </CModal>;
}