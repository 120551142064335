import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
    GetCookie,
    GetCountries,
    GetIndustries,
    GetStateProvinces,
    GetSources,
    GetBearer,
    GetErrorToastComponent,
    SetToastComponentValuesInCookie,
    HandleDataReturn,
    HandleResponseReturn
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CCol, 
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CHeader,
    CHeaderText,
    CInputGroup,
    CInputGroupText,
    CRow,
    CToaster,
} from '@coreui/react';

import AddressEdit from '../../components/edit/addressEdit';

import '../../style/edit.css';
import '../../style/typeahead.css';
import '../../custom.css';
import { cookieNames, GetErrorMessages, userRoles } from '../../constants';

export default function LeadEdit(props) {

    //#region Variables

    const [leadInfo, setLeadInfo] = useState(null);
    const [countryList, setCountryList] = useState([]);
    const [stateProvinceList, setStateProvinceList] = useState([]);
    const [sources, setSources] = useState([]);
    const [industries, setIndustries] = useState([]);

    const [validated, setValidated] = useState(false);
    const [toast, setToast] = useState(0);
    const leadId = useRef(null);
    const { companyId } = useParams();
    const navigate = useNavigate();
    const isAdmin = useRef(JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin);

    // Selected option for dropdown menu
    const [selectedIndustry, setSelectedIndustry] = useState([]); 

    //#endregion Variables

    //#region Functions

    function UpdateLead(e) {
        const form = e.target;
        e.preventDefault();

        // If submit is not triggered by button, don't submit
        if (document.activeElement.id !== 'lead-edit-submit') return;

        //checks if all required fields are filled
        if (form.checkValidity() === false) {
            e.stopPropagation()
            setValidated(true); // show validation

            // Get the first invalid element
            var firstInvalidElem = document.querySelector('input:invalid, select:invalid');

            // Scroll to element
            if (firstInvalidElem != null) {
                firstInvalidElem.focus();
            }
        } else {
            const formData = new FormData(form);

            // Add company leadId
            formData.append('CompanyLeadID', leadId.current);

            // Append Industry if selected
            if (selectedIndustry.length === 1) {
                let industryInput = document.querySelector('#industry-typeahead .rbt input.rbt-input-main.rbt-input');
                // Append value if input box is not empty (which indicates no value selected)
                if (industryInput != null && industryInput.value.trim().length !== 0)
                    formData.append('IndustryID', selectedIndustry[0].IndustryID);
            }

            fetch('/api/companyleads/update-lead', {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: form.method,
                body: formData
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);
                    if (data === 'Update Lead Successful') {
                        SetToastComponentValuesInCookie('Lead account update successful');
                        navigate(`/company-leads/${leadId.current}/profile`);
                    } else {
                        throw new Error('An error has occurred when updating!');
                    }
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent(err.message));
                });
        }
    }

    //#endregion Functions

    //#region Use Effect

    useEffect(() => {
        const getCompanyLeadInfo = async () => {
            leadId.current = companyId == null ? props.searchProfile : companyId;

            const response = await fetch('/api/companyleads/get-lead-by-id?id=' + leadId.current, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            })

            var data = await HandleResponseReturn(response);
            data = HandleDataReturn(data);
            
            setLeadInfo(data);
        }

        getCompanyLeadInfo()
            .catch(err => {
                console.error(err);
                SetToastComponentValuesInCookie('Error: Could not get lead information!', err.message, 'danger', false);
                navigate('/company-leads');
            });
    }, [props.searchProfile]);

    // Get all the data fields for dropdown
    useEffect(() => {
        GetCountries()
            .then(x => setCountryList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Countries, err)));

        GetStateProvinces()
            .then(x => setStateProvinceList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.StateProvince, err)));

        GetSources()
            .then(x => setSources(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Sources, err)));
    }, []);

    // Get Industries and default value if exist
    useEffect(() => {
        if (leadInfo == null) return;

        GetIndustries()
            .then(x => {
                setIndustries(x);

                // Find if value already exist
                if (leadInfo.IndustryID != null) {
                    let defaultIndustry = x.find(i => i.IndustryID === leadInfo.IndustryID);

                    // Set default value if exist
                    if (defaultIndustry != null) {
                        setSelectedIndustry([defaultIndustry]);
                    }
                }
            })
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Industries, err)));
    }, [leadInfo])

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Lead Edit</CHeaderText> 
            </CHeader>
            <CCard>
                <CCardBody className='px-5'>
                    {leadInfo == null ? null : (
                        <CForm
                            className='mt-3'
                            onSubmit={UpdateLead}
                            method='POST'
                            noValidate
                            validated={validated}
                        >
                            <CCardTitle className='justify-content-between title-display-content'>
                                Lead Information
                            </CCardTitle>
                            <hr />
                            <CRow>
                                <CCol xs={12} sm={6}>
                                    <CFormLabel htmlFor='Name' className='mt-3 required-label'>Lead Name</CFormLabel>
                                    <CFormInput
                                        type='text'
                                        id='Name'
                                        name='Name'
                                        defaultValue={leadInfo['Name']}
                                        feedbackInvalid='Required field'
                                        required
                                        maxLength='150'
                                    />
                                    <CFormLabel className='mt-3'>Industry</CFormLabel>
                                    {
                                        industries == null ? null :
                                            <div id='industry-typeahead'>
                                                <Typeahead
                                                    options={industries}
                                                    labelKey={option => `${option.Industry}`}
                                                    onChange={setSelectedIndustry}
                                                    defaultInputValue={
                                                        selectedIndustry.length === 1
                                                            ? `${selectedIndustry[0].Industry}`
                                                            : ''
                                                    }
                                                    id='industry'
                                                    clearButton={true}
                                                    emptyLabel='No matches found, only options chosen from dropdown will be saved'
                                                />
                                            </div >
                                    }
                                    <CFormLabel className='mt-3'>Lead Source</CFormLabel>
                                    <CFormSelect defaultValue={leadInfo['SourceID']} name='SourceID' disabled={!isAdmin.current}>
                                        <option />
                                        {sources.map((source) => {
                                            return (<option key={source.SourceID} value={source.SourceID}>{source.Name}</option>)
                                        })}
                                    </CFormSelect>
                                    <CFormLabel className='mt-3'>Annual Revenue</CFormLabel>
                                    <CInputGroup>
                                        <CInputGroupText>$</CInputGroupText>
                                        <CFormInput type='number' name='AnnualRevenue' defaultValue={leadInfo['AnnualRevenue'] ?? 0} />
                                    </CInputGroup>
                                </CCol>
                                <CCol xs={12} sm={6}>
                                    <CFormLabel className='mt-3'>Website</CFormLabel>
                                    <CInputGroup>
                                        <CInputGroupText>https://</CInputGroupText>
                                        <CFormInput type='text' name='WebsiteURL' defaultValue={leadInfo['WebsiteURL']} maxLength='255' />
                                    </CInputGroup>
                                    <CFormLabel className='mt-3'>Phone Number</CFormLabel>
                                    <CFormInput type='text' name='PhoneNumber' defaultValue={leadInfo['PhoneNumber']} maxLength='20' />    
                                    <CFormLabel className='mt-3'>Fax Number</CFormLabel>
                                    <CFormInput type='text' name='FaxNumber' defaultValue={leadInfo['FaxNumber']} maxLength='16' />
                                </CCol>
                            </CRow>
                            <CRow className='mb-4 mt-3'>
                                <CCol>
                                    <CFormLabel>Description</CFormLabel>
                                    <CFormTextarea type='text' name='Description' defaultValue={leadInfo['Description']} />
                                </CCol>
                            </CRow>
                            {countryList.length === 0 || stateProvinceList.length  === 0 || leadInfo == null ? null :
                                <AddressEdit
                                    addressLine1={leadInfo['AddressLine1']}
                                    addressLine2={leadInfo['AddressLine2']}
                                    city={leadInfo['City']}
                                    stateProvinceId={leadInfo['StateProvinceID']}
                                    zipPostalCode={leadInfo['ZipPostalCode']}
                                    countryList={countryList}
                                    stateProvinceList={stateProvinceList}
                                />
                            }
                            <CRow className='bottom-button-group'>
                                <CCol style={{ textAlign: 'end' }}>
                                    <CButton onClick={() => navigate(`/company-leads/${leadId.current}/profile`)} color='secondary'>Cancel</CButton>
                                </CCol>
                                <CCol>
                                    <CButton id='lead-edit-submit' type='submit' style={{ backgroundColor: '#337ab7' }}>Save</CButton>
                                </CCol>
                            </CRow>
                        </CForm>
                    )}
                </CCardBody>
            </CCard>
        </>
    );
}