import React, { useEffect, useState } from 'react';

import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

import ContactProfile from '../../pages/profile/contactProfile';

export default function ShowMoreInfoContactModal({ closeModal, contactId, contactType, navigateToEditContact, showModal}) {

    //#region Variables

    const [visible, setVisible] = useState(showModal);

    //#endregion Variables

    //#region Functions

    /**
     * Actions to run when closing the modal
     */
    function CloseInfoModal() {
        setVisible(false);
        closeModal();
    }

    //#endregion Functions

    //#region Use Effect

    // Update the visibility of modal
    useEffect(() => {
        setVisible(showModal);
    }, [showModal]);

    //#endregion

    return <CModal
        alignment='center'
        scrollable={true}
        visible={visible && contactId != null}
        onClose={closeModal}
        unmountOnClose={false}
    >
        <CModalHeader closeButton={false}>
            <CModalTitle>{contactType} Contact Additional Information</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <ContactProfile searchProfile={contactId} modalMode={true} />
        </CModalBody>
        <CModalFooter>
            <CButton className='me-3' style={{ width: '90px' }} onClick={() => navigateToEditContact(contactId)}>Edit</CButton>
            <CButton color='danger' style={{ width: '90px' }} onClick={() => CloseInfoModal()}>Close</CButton>
        </CModalFooter>
    </CModal>;
}