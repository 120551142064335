import { useEffect, useState } from 'react';
import { cookieNames, userRoles } from '../../constants';
import { GetCookie } from '../../functions';

import {
    CCard,
    CCardBody,
    CHeader,
    CHeaderText
} from '@coreui/react';

/** Shows megaboard */
export default function Megaboard() {
    const [urlParams, setUrlParams] = useState(null);

    // Set appropriate url params
    useEffect(() => {
        let userRole = JSON.parse(GetCookie(cookieNames.userRole, false));
        let param = '?NoCeli';

        if (userRole === userRoles.Sales) {
            // Check which division of sales
            // Only TTSI has a params, all others have no additional params
            if (JSON.parse(GetCookie(cookieNames.userRoleSubdivision, false)) === userRoles.TTSI)
                param += '&TTSI';
        } else if (userRole === userRoles.Admin) {
            param += '&clairvoyance'; // This allows admin to see all
        }

        setUrlParams(param);
    });

    return <>
        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>Megaboard</CHeaderText>
        </CHeader>
        <CCard>
            <CCardBody>
                <div style={{ height: '1250px', width: '2000px' }}>
                    {urlParams == null ? 'Loading...' :
                        <iframe src={'https://megaboard.ttgi.com/' + urlParams} title='megaboard'
                            style={{
                                height: '100%',
                                width: '100%'
                            }}
                        />
                    }
                </div>
            </CCardBody>
        </CCard>
    </>;
}