import React, { useState } from 'react';
import { GetToastComponent } from '../../functions';

import {
    CButton,
    CDropdown,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

// Modal to remove searchFilter selected in dropdown
// showModal (bool) = show or don't show modal
// setShowModal (useState) = set state for showModal
// searchFilters (useState) = filters to remove from
// confirmFunction (fn) = runs this after confirming action
// setToast (useState) =  used to set error toasts
export default function RemoveSearchFilterModal({ showModal, setShowModal, searchFilters, confirmFunction, setToast }) {

    const [selectedName, setSelectedName] = useState();

    // Confirming name selected
    function OnRemoveClicked() {
        // Should not be empty string
        if (selectedName == null) {
            setToast(GetToastComponent('Select item to remove', null, 'warning'));
            return;
        }

        setShowModal(false);

        let itemToRemove = selectedName;
        setSelectedName(null); // Reset
        confirmFunction(itemToRemove); // Pass the name back to parent function
    }

    return (
        <CModal
            alignment='center'
            visible={showModal}
            onClose={() => setShowModal(false)}
        >
            <CModalHeader closeButton={false}>
                <CModalTitle>Choose a Filter to Remove</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CDropdown className='d-flex justify-content-center m-2'>
                    <CDropdownToggle color='secondary'>
                        {selectedName == null ? 'Select Item' : selectedName}
                    </CDropdownToggle>
                    <CDropdownMenu>
                        {searchFilters == null ? <CDropdownHeader>No Filters to Show</CDropdownHeader> :
                            searchFilters.map((filter, i) => <CDropdownItem key={i} onClick={() => setSelectedName(filter.name)}>{filter.name}</CDropdownItem>)
                        }
                    </CDropdownMenu>
                </CDropdown>
            </CModalBody>
            <CModalFooter>
                <CButton color='secondary' variant='outline' style={{ width: '90px' }} onClick={() => setShowModal(false)}>Cancel</CButton>
                <CButton color='danger' style={{ width: '90px' }} onClick={OnRemoveClicked}>Remove</CButton>
            </CModalFooter>
        </CModal>);
}