import React, { Suspense, useEffect, useRef, useState } from 'react';
import {
    Base64ToBlob,
    GetCookie,
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn,
    UpdateSWFetch,
} from '../../functions';
import { infoModalText, cookieNames } from '../../constants';

import {
    CButton,
    CCardBody,
    CCol,
    CCollapse,
    CForm,
    CFormInput,
    CFormTextarea,
    CRow,
    CTooltip,
} from '@coreui/react';

import Placeholder from '../miscellaneous/placeholder';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

import InfoModal from '../miscellaneous/infoModal';

import '../../style/note.css';

const File = React.lazy(() => import('../miscellaneous/file'));

const userId = parseInt(JSON.parse(GetCookie(cookieNames.userID)));

/**
 * Show and add company note
 * @param {int} companyId Id of company to add note for
 * @param {bool} disableAddFile Defaults true, companies with no tmw id should have add file disabled
 * @param {int} forceUpdateCounter Optional state to set to force update. Can use reducer to update
 * @param {fn} afterAddFn Optional function to run after adding note. Can accept parameter that contains note
 * @param {useState} setToast Toast component in the parent component
 * @returns Component
 */
export default function Notes({ companyId, disableAddFile, forceUpdateCounter, afterAddFn, setToast }) {

    //#region Fields

    const [notes, setNotes] = useState([]);
    const [noteEditId, setNoteEditId] = useState(null); // Id of note being edited. Null if no note being edited
    const [loading, setLoading] = useState(true);
    const dateTime30MinAgo = useRef(null);

    // Element reference
    const formElem = useRef();
    const noteInput = useRef();

    //#endregion Fields

    //#region Functions

    /**
     * Add a new note
     * @param {event} e
     */
    function AddNote(e) {
        var formData;
        e.preventDefault();

        try {
            formData = new FormData(formElem.current);

            // Check note is not empty
            if (formData.get('NoteText') === '') {
                setToast(GetToastComponent('Note Cannot be empty', null, 'warning'));
                return;
            }

            formData.append('CompanyAccountID', companyId);
            formData.append('NoteCreatedByUserID', JSON.parse(GetCookie(cookieNames.userID)));
        } catch (err) {
            setToast(GetErrorToastComponent(err));
            console.log(err);
        }

        fetch('/api/companyaccounts/add-note', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: formElem.current.method,
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    GetNotes();
                    setToast(GetToastComponent('Note Added', null, 'success'));
                    noteInput.current.value = null; // Reset note input

                    if (afterAddFn != null) afterAddFn(formData.get('NoteText'));
                } else {
                    throw Error('Could not add note!');
                }
            })
            .catch(err => {
                setToast(GetErrorToastComponent(err));
                console.log(err);
            });
    }

    /**
     * Download file attached to notes
     * @param {int} id Id of note to download file for
     */
    function DownloadFile(id) {
        // Get index index of id in notes
        var note = notes.find(x => x.Note.CompanyAccountNoteID === id);

        // If note not found
        if (note == null) setToast(GetErrorToastComponent('Error: Could not download file!'));

        var pdfBlob = Base64ToBlob(note.FileBase64, 'application/pdf');

        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = note.FileName;
        a.click();
        URL.revokeObjectURL(url);
    }

    /**
     * Gets notes for account
     */
    function GetNotes() {
        let url = '/api/companyaccounts/get-notes?companyId=' + companyId;

        UpdateSWFetch(url, (x) => setNotes([...x]));

        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);
                setNotes(data);
                setLoading(false); // Will only have effect first time loading
            })
            .catch(console.error);
    }

    /**
    * Another way to add notes
    * @param {event} e 
    */
    function HandleKeyDown(e) {
        if (e.ctrlKey && e.key === 'Enter') {
            AddNote(e);
        }
    }

    /**
     * Confirm edit changes using id stored in noteEditId
     */
    function ConfirmNoteEdit() {
        let textAreaElem = document.getElementById(`${noteEditId}-note-edit`);

        // If element not found
        if (textAreaElem == null) {
            setToast(GetErrorToastComponent('Error: could not update toast'));
            return;
        }

        let request = {
            NoteId: noteEditId,
            UpdatedNote: textAreaElem.value,
            UserId: parseInt(JSON.parse(GetCookie(cookieNames.userID)))
        };

        // Do fetch call to update
        fetch('/api/companyaccounts/update-note', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(request)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                if (data === true) {
                    setToast(GetToastComponent('Updated Note', null, 'success'))

                    GetNotes();
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: could not update note'));
            })
            .finally(() => setNoteEditId(null));
    }

    /**
     * Show/hide file attached to note
     * @param {int} id Id of note to show/hide file for
     * @returns
     */
    function ToggleCollapse(id) {
        var collapse = document.getElementById(id + '-note-file-collapse');
        var toggleButton = document.getElementById(id + '-note-file-toggle');

        if (collapse == null) return;

        // Toggle show or hide CCollapse component and button message
        if (collapse.classList.contains('show')) {
            collapse.classList.remove('show');
            toggleButton.innerText = 'Show file';
        } else {
            collapse.classList.add('show');
            toggleButton.innerText = 'Hide file';
        }
    }

    //#endregion Functions

    //#region useEffect

    // Get all the notes for company
    useEffect(() => {
        if (companyId == null) return;

        // Set dateTime for 30 minutes ago as cutoff for note editing
        let now = new Date();
        dateTime30MinAgo.current = now.setMinutes(now.getMinutes() - 30);

        GetNotes();
    }, [companyId]);

    // Updates whenever update counter is set
    useEffect(() => {
        if (forceUpdateCounter == null || forceUpdateCounter === 0) return;

        GetNotes();
    }, [forceUpdateCounter]);

    //#endregion useEffect

    return <CCardBody>
        <CForm ref={formElem} onSubmit={AddNote} method='POST' onKeyDown={HandleKeyDown}>
            <h5>New Note<InfoModal modalContent={infoModalText.NotesComponent} /></h5>
            <CRow className='mb-4'>
                <CCol xs={12} md={7} lg={8}>
                    <CFormTextarea ref={noteInput} name='NoteText' />
                </CCol>
                <CCol xs={12} md={3} lg={3}>
                    <CFormInput type='file' accept='.pdf' name='FileUpload' className='my-3' disabled={disableAddFile !== false} />
                </CCol>
                <CCol xs={12} md={2} lg={1}>
                    <CTooltip content={'Can also add note when inside text box by pressing CTRL + ENTER'}>
                        <CButton type='submit' className='my-3'>Add Note</CButton>
                    </CTooltip>
                </CCol>
            </CRow>
        </CForm>

        {/* Map the notes*/}
        {loading ?
            Array.from({ length: 3 }, (_, i) => <>
                <CRow>
                    {/* Note date time */}
                    <CCol key={`${i}-date-col-1`} sm={4} md={3} >
                        <div key={`${i}-date-div-1`} >
                            <Placeholder key={`${i}-date-placeholder-1`} width='200px' />
                        </div>
                        <div key={`${i}-date-div-2`} >
                            <Placeholder key={`${i}-date-placeholder-2`} />
                        </div>
                    </CCol>
                    <CCol key={`${i}-body-col-2`} sm={8} md={6}>
                        <div key={`${i}-body-div-1`} >
                            <Placeholder key={`${i}-body-placeholder-1`} count={2} width='250px' widthRandomness={0.5} useBreak={true} />
                        </div>
                    </CCol>
                </CRow>
                <hr />
            </>)
            :
            notes.map((n, i) => {
                let date = new Date(n.Note.NoteTime);

                return <div key={i + '-note'}>
                    <CRow key={i + '-row'}>
                        {/* Note date time */}
                        <CCol sm={4} md={3} key={i + '-date-time-col'}>
                            <div key={i + '-note-date'} style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{date.toLocaleDateString('en-ca', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
                            <div key={i + '-note-time'} style={{ fontSize: '0.8rem' }}><i key={i + '-italics'}>{date.toLocaleTimeString('en-ca', { hour: 'numeric', minute: 'numeric' })}</i></div>
                        </CCol>

                        {/* Note content and author */}
                        <CCol sm={8} md={6} key={i + '-note-col'}>
                            {userId !== n.Note.NoteCreatedByUserID && n.NoteAuthor}

                            {/* showEdit is not part of it by default */}
                            {noteEditId !== n.Note.CompanyAccountNoteID ?
                                <pre key={n.Note.CompanyAccountNoteID} style={{ fontSize: '1.2rem', whiteSpace: 'break-spaces' }}>{n.Note.NoteText}</pre>
                                :
                                <CFormTextarea
                                    id={`${n.Note.CompanyAccountNoteID}-note-edit`}
                                    key={n.Note.CompanyAccountNoteID}
                                    defaultValue={n.Note.NoteText}
                                    style={{ fontSize: '1.2rem', height: 'auto', whiteSpace: 'break-spaces' }}
                                />
                            }
                        </CCol>

                        {/* Note buttons */}
                        <CCol sm={12} md={3} key={i + '-toggle-col'} className='mb-2 d-flex note-item-button'>
                            {/* Show edit button only if note is added by user less than 30 minutes since page has loaded and not already editing*/}
                            {n.Note.SystemNoteFlag === false && dateTime30MinAgo.current < date.getTime() && noteEditId !== n.Note.CompanyAccountNoteID &&
                                <span key={i + '-note-edit-span'}>
                                    <CButton className='mx-2' color='warning' onClick={() => setNoteEditId(n.Note.CompanyAccountNoteID)} key={i + '-note-edit-button'}>Edit</CButton>
                                </span>
                            }

                            {/* Confirm & cancel change button that shows only when editing note */}
                            {noteEditId === n.Note.CompanyAccountNoteID && <div>
                                <CButton color='success' className='mx-2' onClick={ConfirmNoteEdit}>Confirm</CButton>
                                <CButton color='warning' className='mx-2' onClick={() => setNoteEditId(null)}>Cancel</CButton>
                            </div>}

                            {/* Show toggle only if file exists */}
                            {n.FileBase64 != null && <>
                                <CButton
                                    key={n.Note.CompanyAccountNoteID + '-note-file-toggle'}
                                    id={n.Note.CompanyAccountNoteID + '-note-file-toggle'}
                                    color='secondary'
                                    className='mx-2'
                                    onClick={() => ToggleCollapse(n.Note.CompanyAccountNoteID)}
                                >
                                    Show file
                                </CButton>
                                <CButton
                                    key={n.Note.CompanyAccountNoteID + '-note-file-download'}
                                    id={n.Note.CompanyAccountNoteID + '-note-file-download'}
                                    color='secondary'
                                    className='mx-2'
                                    onClick={() => DownloadFile(n.Note.CompanyAccountNoteID)}
                                >
                                    <FontAwesomeIcon key={n.Note.CompanyAccountNoteID + '-note-file'} icon={faDownload} />
                                </CButton>
                            </>}
                        </CCol>
                    </CRow>

                    {/* Show file if toggle is clicked */}
                    {n.FileBase64 != null &&
                        <CCollapse id={n.Note.CompanyAccountNoteID + '-note-file-collapse'} key={i + 'collapse'} className='mb-4'>
                            <Suspense fallback={<div>Loading...</div>}>
                                <File file={'data:application/pdf;base64,' + n.FileBase64} />
                            </Suspense>
                        </CCollapse>
                    }
                    <hr key={i + '-br'} />
                </div>
            })
        }
    </CCardBody>;
}