import React, { useEffect, useState } from 'react';
import { GetToastComponentFromCookiesValues } from '../../functions';

import {
    CCard,
    CHeader,
    CHeaderText,
    CToaster
} from '@coreui/react';

/**
 * Embeds New Business Power BI Report
 */
export default function NewBusinessYtd() {

    //#region Variables

    const [toast, setToast] = useState(null);

    //#endregion Variables

    //#region Use Effect

    // Get any toast elements that may exist from cookie
    useEffect(() => {
        setToast(GetToastComponentFromCookiesValues(true));
    }, []);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top-end' />
        <CCard>
            <iframe
                title='Sales Dashboard (NBB)' 
                width='100%'
                height='100%'
                src='https://app.powerbi.com/reportEmbed?reportId=509b2375-50a3-4ff0-b7bd-db45f71a6c2b&autoAuth=true&ctid=43a2ef7f-ca77-4dbb-a270-ddb807884133'
                frameBorder='0'
                allowFullScreen={true}
            />
        </CCard>
    </>;
}