import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react'

export const AppSidebarNav = ({ items, role }) => {
    const location = useLocation();

    function navLink(name, icon, badge) {
        return (
            <>
                {icon && icon}
                {name && name}
                {badge && (
                    <CBadge color={badge.color} className="ms-auto">
                        {badge.text}
                    </CBadge>
                )}
            </>
        )
    }

    function navItem(item, index) {
        const { component, name, badge, icon, roles, ...rest } = item;
        const Component = component;

        // Check if item should be mapped
        if (!roles.includes(role)) return null;

        return <Component
            {...(rest.to && !rest.items && { component: NavLink })}
            key={index}
            {...rest}
            {...(rest.to.includes('http') && { target:'_blank' })}
        >
            {navLink(name, icon, badge)}
        </Component>;
    }
    function navGroup(item, index) {
        const { component, name, icon, to, roles, ...rest } = item;
        const Component = component;

        // Check if item should be mapped
        if (!roles.includes(role)) return null;

        return (
            <Component
                idx={String(index)}
                key={index}
                toggler={navLink(name, icon)}
                visible={location.pathname.startsWith(to)}
                {...rest}
            >
                {item.items?.map((item, index) =>
                    item.items ? navGroup(item, index) : navItem(item, index),
                )}
            </Component>
        )
    }

    return (
        <React.Fragment>
            {items &&
                items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))
            }
        </React.Fragment>
    )
}

AppSidebarNav.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
}
