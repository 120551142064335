import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { cookieNames } from '../../constants';
import {
    GetBearer,
    GetComparator,
    GetCookie,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn,
} from '../../functions';

import {
    CButton,
    CCardBody,
    CCol,
    CDropdown,
    CDropdownToggle,
    CDropdownMenu,
    CDropdownItem,
    CForm,
    CFormInput,
    CFormLabel,
    CFormTextarea,
    CHeaderText,
    CListGroup,
    CListGroupItem,
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTooltip,
} from '@coreui/react';

import Placeholder from '../miscellaneous/placeholder';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCalendarDay, faCheckSquare, faPen } from '@fortawesome/free-solid-svg-icons';

// Date 
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

import '../../style/todo.css';

const dateToday = new Date(new Date().setHours(0, 0, 0, 0)); // Get today's date with time of 00:00 to match to-dos
const tabOptions = {
    company: 'Company',
    dueDate: 'Due Date',
    type: 'Type'
};

/**
 * Todo Component
 * @param {set useState} setToast Set toast from parent to used to display toasts
 * @param {int} companyId Optional CompanyAccountID from CompanyAccount table to link todo to specific company
 * @param {string} defaultTitle Optional default title to show. Options are 'Book a Meeting', 'Call', 'Email', 'Follow Up', or 'Others'. Null defaults to 'Call'
 * @param {bool} hideTodoList Default is false, optional set to true if component should hide the bottom list with to-dos (used for just adding todo)
 * @param {fn} postAddTodoAction Optional function to run after adding todo successfully
 * @returns Component
 */
export default function Todo({ setToast, companyId, defaultTitle, hideTodoList, postAddTodoAction }) {

    //#region Fields

    const [todos, setTodos] = useState([]);
    const [loading, setLoading] = useState(false);

    // Dropdown selected values
    const [title, setTitle] = useState(defaultTitle ?? 'Call'); // 'Book a Meeting' has special behaviours
    const [dueDate, setDueDate] = useState();

    // Date time selection
    const [dateTimeValue, setDateTimeValue] = useState();

    // Edit to-dos
    const [todoEditId, setTodoEditId] = useState(null); // Id of todo that is being edited
    const [todoEditDate, setTodoEditDate] = useState(); // New Due date for to-do being edited

    // Undo Mark Complete
    const completedTodoStack = useRef([]); // LIFO stack
    document.onkeydown = UndoMarkComplete; // Execute ctrl + z

    // Tab sorting
    const [activeTab, setActiveTab] = useState(tabOptions.dueDate);
    const [order, setOrder] = useState('asc'); // Sort asc or dsc

    //#endregion Fields

    //#region Functions

    /**
     * Fetches all the to-dos
     */
    function GetTodos() {
        if (hideTodoList === true) return; // Need explicit comparison because could be null

        setLoading(true);
        let url = '/api/todo/get-to-dos';

        if (companyId != null) url += '?companyId=' + companyId;

        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setTodos([...data]);
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not get to-dos', err.message));
            });
    }

    /**
     * Mark all to-dos in completedTodoIds as complete
     * @param {int[]} completedTodoIds Array of todo ids
     */
    function MarkTodosComplete(completedTodoIds) {
        fetch('/api/todo/mark-to-dos-complete', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(completedTodoIds)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    // Add it to stack so that it can be undone
                    completedTodoStack.current.push(completedTodoIds);

                    // Remove completed todos from list. Make copy first
                    const updatedTodos = [...todos].filter(x => !completedTodoIds.includes(x.TodoID));
                    setTodos(SortTodos(updatedTodos, activeTab, order === 'asc'));
                } else {
                    throw Error('Could not mark todo completed!');
                }
            })
            .catch(err => {
                console.log(err)
                setToast(GetErrorToastComponent('Could not mark todo(s) complete!'));
            });
    }

    /**
     * Add a new to-do
     * @param {event} e
     */
    function OnAddTodo(e) {
        const form = e.target;
        var formData;
        e.preventDefault();

        try {
            // If submitted form submit button
            if (form.id === 'add-todo-form') {
                formData = new FormData(form);
            } else { // Submitted from enter key press
                let formElem = document.getElementById('add-todo-form');
                formData = new FormData(formElem);
            }

            formData.append('UserID', JSON.parse(GetCookie(cookieNames.userID)));
            formData.append('Title', title);

            if (companyId != null) formData.append('CompanyAccountID', companyId);
        } catch (err) {
            console.log(err);
            setToast(GetErrorToastComponent('Could not add to-do!', err));
        }

        fetch('/api/todo/add', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: form.method,
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    setToast(GetToastComponent('New to-do added!', null, 'success'));
                    // Get to-dos again to include newly added todo
                    GetTodos();

                    // Clear input fields
                    document.getElementById('todo-text-area').value = '';
                } else {
                    throw Error('Could not add Todo!');
                }

                // Runs injected function first if exist
                if (postAddTodoAction != null) postAddTodoAction();
            })
            .catch(err => {
                console.log(err)
                setToast(GetErrorToastComponent('Could not add to-do!'));
            });
    }

    /**
     * When date is selected from the dropdown
     * @param {string} interval Today, Tomorrow, Next Week, or Next Month are options for interval
     */
    function OnDropdownDateSelect(interval) {
        var dueDate;
        setDueDate(interval);

        switch (interval) {
            case 'Today':
                dueDate = dateToday;
                break;
            case 'Tomorrow':
                dueDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() + 1);
                break;
            case 'Next Week':
                dueDate = new Date(dateToday.getFullYear(), dateToday.getMonth(), dateToday.getDate() + 7);
                break;
            case 'Next Month':
                dueDate = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, dateToday.getDate());
                break;
            default:
                break;
        }

        if (dueDate != null) setDateTimeValue(dueDate);
    }

    /**
     * Updates the to-do. Gets the body of to-do from useRef and date from useState
     * @param {int} todoId Id of to-do to update body for
     */
    function OnUpdateTodo(e, todoId) {
        const form = e.target;
        var formData = new FormData(form);
        e.preventDefault();

        formData.append('TodoID', todoId);

        // Date cannot be empty
        if (todoEditDate == null) {
            setToast(GetToastComponent('Date field must be filled', null, 'warning'));
            return;
        }

        fetch('/api/todo/update-to-dos', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: form.method,
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data.TodoID !== todoEditId)
                    throw Error('Updated TodoID did not match the TodoID that was supposed to be updated');

                // Setup with changes. Make copy first
                let newTodos = [...todos].map(x => {
                    if (x.TodoID !== data.TodoID) {
                        return x;
                    } else {
                        return {
                            ...x,
                            Body: data.UpdatedBody,
                            TodoByDate: data.UpdatedTodoByDate
                        };
                    }
                }).sort(TodoSort);

                setTodoEditId(null); // Clear updating status
                setTodos(SortTodos(newTodos, activeTab, order === 'asc'));

            })
            .catch(err => {
                setToast(GetErrorToastComponent('Could not update to-do with id ' + todoId, err.message));
            });
    }

    /**
     * Undo the last todo marked complete todo if exist
     * @param {event} e
     */
    function UndoMarkComplete(e) {
        if (e.ctrlKey && e.keyCode === 90) {

            let todoId = completedTodoStack.current.pop();

            if (isNaN(todoId)) return;

            fetch('/api/todo/undo-mark-complete?todoId=' + todoId, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'POST',
            })
                .then(response => HandleResponseReturn(response, true))
                .then(data => {
                    data = HandleDataReturn(data);

                    GetTodos();
                })
                .catch(console.error);
        }
    }

    //#region Sorting

    /**
     * When new sort by is the same as activeTab, flips sorting asc and dec. 
     * Sorts asc whenever going to new tab
     * @param {string} newSortBy The new property value based on one of the tabOptions' value
     */
    function OnTabSort(newSortBy) {
        if (loading) return;

        let sortAsc;

        if (activeTab === newSortBy) {
            let prevOrder = order;
            sortAsc = prevOrder === 'dsc';
            setOrder(prev => prev === 'asc' ? 'dsc' : 'asc');

            document
                .getElementById('tab-arrow-' + newSortBy.toLowerCase().replace(/ /g, '-'))
                .innerHTML = sortAsc ? '&#x10c39;' : '&#x10c38;';

        } else {
            sortAsc = true;
            setActiveTab(newSortBy);
            setOrder('asc');
        }

        // Make copy to sort
        setTodos(SortTodos([...todos], newSortBy, sortAsc));
    }

    /**
     * Sorts the provided items
     * @param {obj[]} todosToSort Items to sort
     * @param {string} sortBy Type, Company, or Due Date
     * @param {bool} sortAsc true = sort ascending, false = sort descending
     * @returns Sorted items
     */
    function SortTodos(todosToSort, sortBy, sortAsc) {
        let key;

        switch (sortBy) {
            case 'Type':
                key = 'Title';
                break;
            case 'Company':
                key = 'CompanyName';
                break;
            case 'Due Date':
                key = 'TodoByDate';
                break;
            default:
                return todosToSort; // Return unsorted
        }

        return todosToSort.sort(GetComparator(key, sortAsc));
    }

    //#endregion Sorting

    //#region Utilities

    /**
     * Sorts the todos by TodoByDate asc and TodoID asc. 
     * There will never be a todo that matches in both criterias
     * @param {obj} a
     * @param {obj} b
     * @returns greater or less as needed by sort function
     */
    function TodoSort(a, b) {
        let dateA = new Date(a.TodoByDate).getTime();
        let dateB = new Date(b.TodoByDate).getTime();

        // Same date, so compare ids    
        if (dateA === dateB) return a.TodoID < b.TodoID ? -1 : 1;

        return dateA < dateB ? -1 : 1;
    }

    //#endregion Utilities

    //#endregion Functions

    //#region Use Effect

    // Initial load get to-dos
    useEffect(() => { GetTodos() }, [companyId]);

    // Sets title to whatever is set on load
    useEffect(() => {
        if (defaultTitle != null) setTitle(defaultTitle);
    }, [defaultTitle]);

    //#endregion Use Effect

    return <CCardBody>
        <CForm id='add-todo-form' method='POST' onSubmit={OnAddTodo}>
            <CRow>
                <CCol lg={5}>
                    {/* Title Dropdown */}
                    <CDropdown className='mb-2'>
                        <CDropdownToggle color='secondary'>{title}</CDropdownToggle>
                        <CDropdownMenu>
                            {['Book a Meeting', 'Call', 'Email', 'Follow Up', 'Others'].map((x, i) => {
                                return <CDropdownItem key={i} onClick={() => {
                                    setTitle(x);

                                    if (x === 'Book a Meeting' && companyId == null)
                                        setToast(GetToastComponent('Book a Meeting must be done inside a company account', null, 'warning'));
                                }}>{x}</CDropdownItem>
                            })}
                        </CDropdownMenu>
                    </CDropdown>
                    <CFormTextarea
                        id='todo-text-area'
                        name='Body'
                        placeholder={title === 'Book a Meeting' ? 'Prospect Attendees' : 'Body'}
                        className='mb-3'
                        maxLength='250'
                        required={title === 'Book a Meeting'}
                    />
                </CCol>
                <CCol xs={7} sm={8} lg={5}>
                    <CFormLabel className='required-label me-2' style={{ width: 'auto' }}>
                        {title === 'Book a Meeting' ? 'Meeting Date' : 'Due Date'}
                    </CFormLabel>
                    <CDropdown className='ms-2 mb-2 '>
                        <CDropdownToggle color='secondary'>
                            <FontAwesomeIcon icon={faCalendarDay} />
                            <span className='ms-2'>{dueDate}</span>
                        </CDropdownToggle>
                        <CDropdownMenu>
                            {['Today', 'Tomorrow', 'Next Week', 'Next Month'].map((x, i) => {
                                return <CDropdownItem key={i} onClick={() => OnDropdownDateSelect(x)}>{x}</CDropdownItem>
                            })}
                        </CDropdownMenu>
                    </CDropdown>
                    <DatePicker
                        id='date-picker'
                        name='TodoByDate'
                        className='form-control mb-3'
                        value={dateTimeValue}
                        onChange={(date) => {
                            setDueDate(date == null ? null : 'Custom Date');
                            setDateTimeValue(date);
                        }}
                        locale='en-ca'
                        format='yy-MM-dd'
                        minDate={new Date()}
                        minDetail='year'
                        yearPlaceholder='yyyy'
                        monthPlaceholder='mm'
                        dayPlaceholder='dd'
                        required
                    />
                </CCol>
                <CCol xs={5} sm={4} lg={2}>
                    <CButton
                        id='submit-new-todo-button'
                        type='submit'
                        color='success'
                        style={{ marginTop: '45px', width: '100%' }}
                    >
                        Add To-Do
                    </CButton>
                </CCol>
            </CRow>
        </CForm>
        {hideTodoList === true ? null : <>
            <CNav id='todo-sort-nav' variant='underline' layout='justified' className='mb-2'>
                <CNavItem>
                    <CNavLink active={activeTab === tabOptions.type} onClick={() => OnTabSort(tabOptions.type)}>
                        {tabOptions.type}
                        <span id='tab-arrow-type' className='ms-2 arrow'>&#x10c39;</span>
                    </CNavLink>
                </CNavItem>
                {companyId == null &&
                    <CNavItem>
                        <CNavLink active={activeTab === tabOptions.company} onClick={() => OnTabSort(tabOptions.company)}>
                            {tabOptions.company}
                            <span id='tab-arrow-company' className='ms-2 arrow'>&#x10c39;</span>
                        </CNavLink>
                    </CNavItem>
                }
                <CNavItem>
                    <CNavLink active={activeTab === tabOptions.dueDate} onClick={() => OnTabSort(tabOptions.dueDate)}>
                        {tabOptions.dueDate}
                        <span id='tab-arrow-due-date' className='ms-2 arrow'>&#x10c39;</span>
                    </CNavLink>
                </CNavItem>
            </CNav>
            <CListGroup>
                {todos == null || loading ?
                    <TodoLoadingListComponent />
                    :
                    todos.map(todo => {
                        let todoByDate = new Date(todo.TodoByDate);
                        let color = null;

                        // If overdue
                        if (todoByDate.getTime() < dateToday.getTime()) {
                            color = 'danger';
                        } else if (todoByDate.getTime() === dateToday.getTime()) { // If due today
                            color = 'success';
                        }

                        return <CListGroupItem
                            id={todo.TodoID + '-todo-item'}
                            key={todo.TodoID + '-list-item'}
                            color={color}
                            onDoubleClick={() => {
                                setTodoEditId(todo.TodoID);
                                setTodoEditDate(todo.TodoByDate);
                            }}
                        >
                            <div key={todo.TodoID + '-div'} className='d-flex w-100 justify-content-between'>
                                <CHeaderText key={todo.TodoID + '-title'} className='fw-semibold'>
                                    {todo.CompanyAccountID == null ?
                                        todo.Title + (todo.CompanyName == null ? '' : ' - ' + todo.CompanyName)
                                        :
                                        <Link key={todo.TodoID + '-title-link'} to={`/company-accounts/${todo.CompanyAccountID}/profile`}>
                                            {todo.Title + (todo.CompanyName == null ? '' : ' - ' + todo.CompanyName)}
                                        </Link>
                                    }
                                </CHeaderText>
                                <span key={'span-' + todo.TodoID} style={{ whiteSpace: 'nowrap' }}>
                                    {todoEditId === todo.TodoID ?
                                        <CTooltip content='Cancel Edit'>
                                            <CButton
                                                key={'edit-cancel-button-' + todo.TodoID}
                                                color='danger'
                                                className='ms-2 px-2 py-1'
                                                onClick={() => setTodoEditId(null)}
                                            >
                                                <FontAwesomeIcon key={'edit-cancel-button-icon-' + todo.TodoID} icon={faBan} size='sm' style={{ color: 'white' }} />
                                            </CButton>
                                        </CTooltip>
                                        :
                                        <>
                                            <small key={'date-time' + todo.TodoID}>
                                                {todoByDate.toLocaleDateString('en-ca', { month: 'long', day: 'numeric', year: 'numeric' })}
                                            </small>
                                            <CTooltip content='Edit To-do'>
                                                <CButton
                                                    key={'edit-button-' + todo.TodoID}
                                                    color='warning'
                                                    className='ms-2 px-2 py-1'
                                                    onClick={() => {
                                                        let todoByDate = new Date(todo.TodoByDate);
                                                        setTodoEditId(todo.TodoID);
                                                        setTodoEditDate(todoByDate.getTime() < dateToday.getTime() ? dateToday : todo.TodoByDate);
                                                    }}
                                                >
                                                    <FontAwesomeIcon key={'edit-button-icon-' + todo.TodoID} icon={faPen} size='sm' style={{ color: 'white' }} />
                                                </CButton>
                                            </CTooltip>
                                        </>
                                    }
                                    <CTooltip content='Mark Complete'>
                                        <CButton
                                            key={'completed-button-' + todo.TodoID}
                                            color='success'
                                            className='ms-2 px-2 py-1'
                                            onClick={() => MarkTodosComplete([todo.TodoID])}
                                        >
                                            <FontAwesomeIcon key={'completed-button-icon-' + todo.TodoID} icon={faCheckSquare} size='sm' />
                                        </CButton>
                                    </CTooltip>
                                </span>
                            </div>
                            {todoEditId !== todo.TodoID ?
                                <> {/* Normal note display */}
                                    {todo.Body == null ? null :
                                        <p key={todo.TodoID + '-body'} className='mb-1'>
                                            {todo.Body}
                                        </p>
                                    }
                                </>
                                :
                                <CForm className='d-flex my-2' id={`update-todo-form-${todo.TodoID}`} method='POST' onSubmit={(e) => OnUpdateTodo(e, todo.TodoID)}>
                                    <CFormInput name='Body' defaultValue={todo.Body} placeholder='Body' className='mx-2' />
                                    <DatePicker
                                        id='todo-edit-date-picker'
                                        name='TodoByDate'
                                        className='form-control mx-2'
                                        value={todoEditDate}
                                        onChange={(date) => {
                                            setTodoEditDate(date);
                                        }}
                                        locale='en-ca'
                                        format='yy-MM-dd'
                                        minDate={new Date()}
                                        minDetail='year'
                                        yearPlaceholder='yyyy'
                                        monthPlaceholder='mm'
                                        dayPlaceholder='dd'
                                        required
                                    />
                                    <CButton color='success' type='submit' className='text-nowrap'>Confirm Edit</CButton>
                                </CForm>
                            }
                        </CListGroupItem>
                    })
                }
            </CListGroup>
        </>}
    </CCardBody>;
}

/**
 * Loading component for To-do with 4 list items
 */
function TodoLoadingListComponent() {
    return <>
        {Array.from({ length: 4 }, (_, index) => <CListGroupItem key={index}>
            <div key={`${index}-div`} className='mb-3'>
                <Placeholder key={`${index}-title`} count={2} width='300px' widthRandomness={0.6} />
            </div>
            <Placeholder key={`${index}-body`} count={4} widthRandomness={0.8} />
        </CListGroupItem>)}
    </>;
}