import React from 'react';

// Here are the functions that handle the check boxes in the tables.
// Note: for the functions to work properly, the checks must be setup in the specified way
// Prerequisites: Must be using <Table> component.

//#region Check Functions

/**
 * Remove all checks from checkedIds array and update ui
 * @param {useRef} checkedIds useRef array of ids to manipulate 
 */
function ClearChecks(checkedIds) {
    // Set all checked items currently to false
    checkedIds.current.forEach(id => {
        let checkbox = document.getElementById(id + '-check');

        // If element is found, set to true
        if (checkbox != null) checkbox.checked = false;
    });

    checkedIds.current = [];
}

/**
 * Run whenever a check is clicked
 * @param {useRef} checkedIds useRef array of ids to manipulate
 * @param {int} companyId Id of company to add or remove
 */
function OnCheckChange(checkedIds, companyId) {
    // Gets whether selected item has been checked
    var checkElem = document.getElementById(companyId + '-check');

    // add or remove form checkedIds depending on checked or not
    if (checkElem.checked) {
        checkedIds.current.push(companyId);
    } else {
        let indexToRemove = checkedIds.current.indexOf(companyId);

        if (indexToRemove !== -1) {
            checkedIds.current.splice(indexToRemove, 1);
        }
    }
}

/**
 * Refresh the checks on the page to check whichever one should be checked
 * whenever a change is made (e.g.first render and every subsequent update)
 * @param {useRef} checkedIds useRef array of ids to manipulate
 */
function UpdateChecks(checkedIds) {
    // Set selected items to be checked again
    checkedIds.current.forEach(id => {
        let checkbox = document.getElementById(id + '-check');

        // If element is found, set to true
        if (checkbox != null) checkbox.checked = true;
    });
}

//#endregion Check Functions

export {
    ClearChecks,
    OnCheckChange,
    UpdateChecks
}