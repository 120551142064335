import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { infoModalText, cookieNames, userRoles } from '../../constants';
import {
    GetCookie,
    GetErrorToastComponent,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    UpdateSWFetch,
    GetToastComponent,
} from '../../functions';

import {
    CAlert,
    CButton,
    CButtonGroup,
    CButtonToolbar,
    CCard,
    CCardBody,
    CPopover,
    CFormCheck,
    CHeader,
    CHeaderText,
    CToaster,
    CTooltip,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faShare, faTruck } from '@fortawesome/free-solid-svg-icons';

import InfoModal from '../../components/miscellaneous/infoModal';

import Table from '../../components/table/table';

import '../../style/scoreboard.scss';

const customerPortalUrl = process.env.REACT_APP_CUSTOMER_PORTAL_URL;
const dateToday = new Date();
const headers = [
    {
        name: 'Status',
        hasSearch: true,
        headerClassName: 'Status',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Load',
        hasSearch: true,
        headerClassName: 'Load',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'XIN',
        hasSearch: false,
        headerClassName: 'XIN',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'PO#',
        hasSearch: true,
        headerClassName: 'PO#',
        colSort: true,
    },
    {
        name: 'Sales',
        hasSearch: true,
        headerClassName: 'Sales',
        colSort: true,
        show: JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin,
    },
    {
        name: 'Customer',
        hasSearch: true,
        headerClassName: 'customer',
        colSort: true,
    },
    {
        name: 'Booked',
        hasSearch: true,
        headerClassName: 'booked',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Pickup',
        hasSearch: true,
        headerClassName: 'pickup',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Delivery',
        hasSearch: true,
        headerClassName: 'delivery',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'From',
        hasSearch: true,
        headerClassName: 'from',
        colSort: true,
    },
    {
        name: 'To',
        hasSearch: true,
        headerClassName: 'to',
        colSort: true,
    },
    {
        name: 'Bill CA',
        hasSearch: false,
        headerClassName: 'bill-ca',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Bill US',
        hasSearch: false,
        headerClassName: 'bill-us',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Pay CA',
        hasSearch: false,
        headerClassName: 'pay-ca',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Pay US',
        hasSearch: false,
        headerClassName: 'pay-us',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Mgn CA',
        hasSearch: false,
        headerClassName: 'mgn-ca',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Mgn US',
        hasSearch: false,
        headerClassName: 'mgn-us',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Mgn %',
        hasSearch: false,
        headerClassName: 'mgn-%',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Carrier',
        hasSearch: true,
        headerClassName: 'carrier',
        colSort: true,
    },
    {
        name: 'Remarks',
        hasSearch: true,
        headerClassName: 'remarks',
        colSort: true,
        moreThanJustText: true,
    },
    {
        name: 'Share',
        headerClassName: 'share',
        moreThanJustText: true,
        columnClass: 'share',
    }
];

export default function Scoreboard() {

    //#region Variables

    const scoreboardData = useRef(null);
    const [scoreboardFiltered, setScoreboardFiltered] = useState(null);
    const [statusFilter, setStatusFilter] = useState('');
    const pickupToday = useRef(false);
    const dropoffToday = useRef(false);

    const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
    });
    const [toast, setToast] = useState();
    const [showMissingCarrierAlert, setShowMissingCarrierAlert] = useState(false); // Used for special alert (not toast)
    const [loadsMissingCarriers, setLoadsMissingCarriers] = useState([]);

    //#endregion

    //#region Functions

    /**
     * Gets url for tracking link and copies it to clipboard
     * @param {int} trackingHash order tracking hash
     */
    function OnShareLink(loadNumber, trackingHash) {
        let url = `${customerPortalUrl}/order-details?order=${trackingHash}`;
        let toastBody = <>
            Press CTRL + V to paste link or go to tracking page&nbsp;
            <Link to={url} target='_blank' rel='noopener noreferrer'>here</Link>
        </>;

        navigator.clipboard.writeText(url);      
        setToast(
            GetToastComponent(
                `Tracking for load # ${loadNumber} has been copied to clipboard`
                , toastBody, 'success', true, 15, true)
        );
    }

    //#region Top Row Button Filter

    /**
     * Button click to filter status between Pending, Completed, and All as well as filter 'Dropoff Today and 'Pickup Today'
     * Uses the className 'd-none' to show or hide row
     * @param {string} status Toggle button statuses: pending, completed, all, pickup-today, dropoff-today
     * @param {bool} overrideSameFilterCheck Overrides the no filter if status is same. Default false. Set true to override
     */
    function onFilter(status, overrideSameFilterCheck) {
        // Filter is already set, no need to do anything new or Scoreboard null
        if (scoreboardData.current == null) return;
        if (status === statusFilter
            && (status !== 'pickup-today' && status !== 'dropoff-today')
            && overrideSameFilterCheck !== true
        )
            return;
        
        let newStatusFilter = null;

        // Set the parameters depending on filter change
        switch (status) {
            case 'pending':
            case 'completed':
            case 'all':
                // Only need to set status if they are the 'Status' toggles
                newStatusFilter = status;
                break;
            case 'pickup-today':
                pickupToday.current = !pickupToday.current;
                newStatusFilter = statusFilter; // Set to existing since it doesn't change
                break;
            case 'dropoff-today':
                dropoffToday.current = !dropoffToday.current;
                newStatusFilter = statusFilter; // Set to existing since it doesn't change
                break;
            default:
        }

        let tempData = scoreboardData.current.filter(row => {
            let showStatusFilter = true;
            let showPickupFilter = true;
            let showDeliveryFilter = true;

            // Set which filter to use for Status
            switch (newStatusFilter) {
                case 'completed':
                    // Alow items with 'Complete' status
                    showStatusFilter = row['Status'] === 'Completed';
                    break;
                case 'pending':
                    // Alow items without 'Complete' status
                    showStatusFilter = row['Status'] !== 'Completed';
                    break;
                case 'all':
                    // All items allowed
                    showStatusFilter = true;
                    break;
                default:
            }

            // Show only dates that are today for pickup if selected
            // Need to specify time or else new Date will create it in the wrong offset leading to different dates
            if (pickupToday.current && !DateIsToday(new Date(row.Pickup + 'T00:00:00'))) {
                showPickupFilter = false;
            }

            // Show only dates that are today for delivery if selected
            if (dropoffToday.current && !DateIsToday(new Date(row.Delivery + 'T00:00:00'))) {
                showDeliveryFilter = false;
            }

            return (showStatusFilter && showPickupFilter && showDeliveryFilter);
        });

        setStatusFilter(newStatusFilter);
        setScoreboardFiltered(tempData);
    }

    //#endregion Top Row Button Filter

    //#region Utilities

    /**
     * Appends additional classes to existing classes
     * @param {string} itemToAppendTo Existing classes
     * @param {string} classToAppend Class name to add
     * @returns
     */
    function AppendClassName(itemToAppendTo, classToAppend) {
        if (itemToAppendTo == null) return classToAppend;
        else if (itemToAppendTo.includes(classToAppend)) return itemToAppendTo;
        else return itemToAppendTo + ' ' + classToAppend;
    }

    /**
     * Get number of days from the given milliseconds
     * @param {int} milliseconds
     * @returns int Days
     */
    function GetInDays(milliseconds) {
        // milliseconds / (1000 * 60 * 60 * 24);
        return milliseconds / (8.64e7);
    }

    /**
     * Get number of hours from the given milliseconds
     * @param {int} milliseconds
     * @returns Hours
     */
    function GetInHours(milliseconds) {
        // milliseconds / (1000 * 60 * 60);
        return milliseconds / (3.6e6);
    }

    /**
     * Uses date declared on creation to prevent unnecessary new date declarations everytime this is used
     * @param {Date} date
     * @returns
     */
    function DateIsToday(date) {
        return dateToday.getDate() === date.getDate() &&
            dateToday.getMonth() === date.getMonth() &&
            dateToday.getYear() === date.getYear();
    }

    //#endregion Utilities

    //#endregion Functions

    //#region Effects

    // Gets Scoreboard data and processes it to be displayed
    useEffect(() => {
        // Any data processing that needs to be done before displaying is done here
        function ProcessData(data) {
            // Return if empty 
            if (data == null) return null;
            let loadsMissingCarriers = [];

            data.forEach(row => {
                let cellFormatting = {};
                let valueWithElements = {};

                // Load
                valueWithElements['Load'] = <CTooltip content={row['Office']}>
                    <span>{row['Load']}</span>
                </CTooltip>;

                // Dates
                let bookedDate = new Date(row['Booked']);
                let pickupDate = new Date(row['Pickup']);
                let deliveryDate = new Date(row['Delivery']);

                // Format the dates to crop out time so search does not include time
                row['Booked'] = row['Booked'].slice(0, 10);
                row['Pickup'] = row['Pickup'].slice(0, 10);
                row['Delivery'] = row['Delivery'].slice(0, 10);

                let daysUntilPickup = GetInDays(pickupDate - dateToday);
                let isLateForPickup = (row['Pickup'] === 'B' || row['Pickup'] === 'L') && daysUntilPickup < 0;
                let isExceedinglyLateForPickup = isLateForPickup && row['Status'] === 'L' && daysUntilPickup < -1;

                let daysUntilDelivery = GetInDays(deliveryDate - dateToday);
                let isLateForDelivery = (row['Delivery'] === 'I' || row['Delivery'] === 'T') && daysUntilDelivery < 0;
                let isExceedinglyLateForDelivery = isLateForDelivery && row['Status'] === 'I' && daysUntilDelivery < -1;

                // Alerts
                let alertText = [];

                //#region Status

                // Set text color depending 'Status' and 'Load' colour depending on status
                // Check and mark which Date columns are today (Booked, Pickup, Delivery)
                let statusText;
                let statusTextColour;

                switch (row['Status']) {
                    case 'AVL':
                    case 'B':
                        statusText = 'Booked';
                        statusTextColour = 'text-brown';
                        break;

                    case 'CMP':
                    case 'C':
                        statusText = 'Completed';
                        statusTextColour = 'text-grey'; //TODO: remove this after set row works

                        // Also set entire row grey
                        row['RowClassNames'] = AppendClassName(row['RowClassNames'], 'text-muted');
                        break;

                    case 'STD':
                    case 'I':
                        statusText = 'In Transit';
                        statusTextColour = 'text-green';
                        break;

                    case 'PLN':
                    case 'MPN':
                    case 'L': 
                        // Loads with carrier will be lined up
                        if (isLateForPickup) statusText = 'Late For Pickup';
                        else statusText = 'Lined Up';

                        statusTextColour = 'text-blue-primary';
                        break;

                    case 'DSP':
                        statusText = 'Dispatched';
                        statusTextColour = 'text-green';
                        break;

                    default:
                        statusText = row['Status'];
                        break;
                }

                row['Status'] = statusText;
                valueWithElements['Status'] = <><FontAwesomeIcon className='me-1' icon={faTruck} />{statusText}</>;

                //#endregion Status

                // Mark all XIN values in red text with yellow highlight
                if (row['XIN']) {
                    cellFormatting['XIN'] = AppendClassName(cellFormatting['XIN'], 'text-red');
                    valueWithElements['XIN'] = <mark-yellow>{row['XIN']}</mark-yellow>;
                }

                //#region Booked/Pickup/Delivery

                // Alerts only matter for orders that are not finished yet
                if (row['Status'] !== 'Completed') {
                     // If late for pickup/delivery, show alert on the date columns
                    if (isLateForPickup) {
                        cellFormatting['Pickup'] = 'text-red';
                        if (isExceedinglyLateForPickup) {
                            cellFormatting['Pickup'] = AppendClassName(cellFormatting['Pickup'], 'fw-bold');
                            alertText.push('More than 1 day late for Pickup');
                        } else {
                            alertText.push('Late for Pickup');
                        }

                    } else if (isLateForDelivery) {
                        cellFormatting['Delivery'] = 'text-red';
                        if (isExceedinglyLateForDelivery) {
                            cellFormatting['Delivery'] = AppendClassName(cellFormatting['Delivery'], 'fw-bold');
                            alertText.push('More than 1 day late for Delivery');
                        } else {
                            alertText.push('Late for Delivery');
                        }
                    }
                }

                // Highlight booked/pickup/delivery cell if the date is today
                let dateFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
                let timeFormatOptions = { hour: '2-digit', minute: '2-digit' };
                let formattedBookedDate = <CTooltip content={bookedDate.toLocaleString('en-CA', timeFormatOptions)}>
                    <span>
                        {bookedDate.toLocaleString('en-CA', dateFormatOptions)}
                    </span>
                </CTooltip>;
                if (DateIsToday(bookedDate)) {
                    valueWithElements['Booked'] = <mark-yellow>{formattedBookedDate}</mark-yellow>;
                } else {
                    valueWithElements['Booked'] = formattedBookedDate;
                }

                let formattedPickupDate = <CTooltip content={pickupDate.toLocaleString('en-CA', timeFormatOptions)}>
                    <span>
                        {pickupDate.toLocaleString('en-CA', dateFormatOptions)}
                    </span>
                </CTooltip>;
                if (DateIsToday(pickupDate)) {
                    let difference = GetInHours(pickupDate - dateToday);

                    // If pickup is happening soon and there is no carrier, mark with red highlight
                    if (row['Carrier'] === 'UNKNOWN' && difference < 3 && row['Status'] === 'Booked') {
                        valueWithElements['Pickup'] = <mark-red>{formattedPickupDate}</mark-red>;
                        alertText.push('Order to be picked up soon but no carrier assigned');
                        cellFormatting['Carrier'] = 'text-red';

                        // Will show message afterward that indicates load has no carrier
                        loadsMissingCarriers.push(row.Load); 
                    } else {
                        valueWithElements['Pickup'] = <mark-yellow>{formattedPickupDate}</mark-yellow>;
                    }
                } else {
                    valueWithElements['Pickup'] = formattedPickupDate;
                }

                let formattedDeliveryDate = <CTooltip content={deliveryDate.toLocaleString('en-CA', timeFormatOptions)}>
                    <span>
                        {deliveryDate.toLocaleString('en-CA', dateFormatOptions)}
                    </span>
                </CTooltip>;
                if (DateIsToday(deliveryDate)) {
                    valueWithElements['Delivery'] = <mark-yellow>{formattedDeliveryDate}</mark-yellow>;
                } else {
                    valueWithElements['Delivery'] = formattedDeliveryDate;
                }

                //#endregion Booked/Pickup/Delivery

                // Override Status column if there are errorsAdd icons to 'Status' column
                if (alertText.length > 0) {
                    valueWithElements['Status'] =
                        <CPopover className='show-hover-message' trigger='hover' content={alertText.join(', ')} placement='top'>
                            <span>
                                <FontAwesomeIcon className='me-1' icon={faExclamationTriangle} />
                                {statusText}
                            </span>
                        </CPopover>;
                }

                //#region Bill, Pay, Margin, and Margin % for CAD and USD

                // Set null values to '' for table sort to function properly and put null values as smallest
                if (row['Bill CA'] != null) {
                    valueWithElements['Bill CA'] = formatter.format(row['Bill CA']);
                    valueWithElements['Bill US'] = formatter.format(row['Bill US']);
                } else {
                    row['Bill CA'] = 'N/A';
                    row['Bill US'] = 'N/A';
                }

                if (row['Pay CA'] != null) {
                    valueWithElements['Pay CA'] = formatter.format(row['Pay CA']);
                    valueWithElements['Pay US'] = formatter.format(row['Pay US']);
                } else {
                    row['Pay CA'] = 'N/A';
                    row['Pay US'] = 'N/A';
                }

                if (row['Mgn CA'] != null) {
                    valueWithElements['Mgn CA'] = formatter.format(row['Mgn CA']);
                    valueWithElements['Mgn US'] = formatter.format(row['Mgn US']);
                } else {
                    row['Mgn CA'] = 'N/A';
                    row['Mgn US'] = 'N/A';
                }

                // Margin % CAD and USD is not always matching like above items
                // Note: this is a flaw and it should match
                if (row['Mgn %'] != null) valueWithElements['Mgn %'] = row['Mgn %'] + '%';
                else row['Mgn %'] = 'N/A';

                // Margin Warnings
                // isDeficit
                if (row['Mgn CA'] < 0) {
                    row['RowClassNames'] = AppendClassName(row['RowClassNames'], 'margin-deficit');
                }

                // isCommisionDeficit
                if ((row['Mgn CA'] > 0 && row['Mgn CA'] < 51) || (row['Mgn %'] > 0 && row['Mgn %'] <= 4)) {
                    row['RowClassNames'] = AppendClassName(row['RowClassNames'], 'commission-deficit');
                }

                //#endregion Bill, Pay, Margin, and Margin % for CAD and USD

                // Remarks
                // Show only first 10 characters
                valueWithElements['Remarks'] = row.Remarks == null || row.Remarks === '' || row.Remarks.length <= 10 ?
                    row.Remarks
                    : <CTooltip content={row.Remarks}><span>{row.Remarks.substring(0, 7)}...</span></CTooltip>;

                // Add cell formatting classes to row data
                if (statusTextColour != null) {
                    cellFormatting['Status'] = statusTextColour;
                    cellFormatting['Load'] = statusTextColour;
                }

                // Share
                valueWithElements['Share'] = <CTooltip content='Share Tracking Link'>
                    <CButton
                        color='secondary'
                        size='sm'
                        disabled={row['TrackingHash'] == null}
                        onClick={() => OnShareLink(row['Load'], row['TrackingHash'])}
                    >
                        <FontAwesomeIcon icon={faShare} size='sm' />
                    </CButton>
                </CTooltip>;

                // Add specific cell formatting for that row
                row['CellClassNames'] = cellFormatting;

                // Add specific formatting for display 
                row['ValueWithElements'] = valueWithElements;
            });

            // Special alert shown if load is to be picked up soon but still no carrier
            // Someone fucked up
            if (loadsMissingCarriers.length > 0) {
                setShowMissingCarrierAlert(true);
                setLoadsMissingCarriers(loadsMissingCarriers);
            }

            return data;
        }

        const controller = new AbortController();
        let url = '/api/sales/get-scoreboard';
        UpdateSWFetch(url, (x) => {
            let processedData = ProcessData(x);
            scoreboardData.current = processedData;
            setScoreboardFiltered(processedData);

            onFilter('pending', true); 
        });

        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET',
            signal: controller.signal
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                let processedData = ProcessData(data);
                scoreboardData.current = processedData;
                setScoreboardFiltered(processedData);

                // Set filter to pending at the start
                onFilter('pending');
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not get scoreboard data!', err.message));
            });

        return () => controller.abort(); // Abort on navigate away
    }, []);

    //#endregion Effect

    //#region Return

    return <>
        <CToaster push={toast} placement='top-end' />
        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>Scoreboard</CHeaderText>
            <InfoModal modalTitle='Legend' modalContent={infoModalText.ScoreboardPage} />
        </CHeader>
        <CCard>
            {/* Top button row */}
            <CCardBody id='top-button-row'>
                <CAlert
                    color='warning'
                    visible={showMissingCarrierAlert}
                    onClose={() => setShowMissingCarrierAlert(false)}
                    dismissible
                    style={{ whiteSpace: 'normal' }}
                >
                    <b>The following load(s) should be picked up already or in less than 3 hours but don't have a carrier assigned!</b>
                    <br />
                    {loadsMissingCarriers.join(' , ')}
                </CAlert>
                <CButtonToolbar>
                    <CButtonGroup id='order-type' className='me-4'>
                        <CFormCheck
                            label='Pending'
                            id='pending-button'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='radio'
                            name='btnradio orderstatus'
                            onChange={() => onFilter('pending')}
                            readOnly
                            checked={statusFilter === 'pending'}
                        />
                        <CFormCheck
                            label='Completed'
                            id='completed-button'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='radio'
                            name='btnradio orderstatus'
                            onChange={() => onFilter('completed')}
                            readOnly
                            checked={statusFilter === 'completed'}
                        />
                        <CFormCheck
                            label='All'
                            id='all-button'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='radio'
                            name='btnradio order-status'
                            onChange={() => onFilter('all')}
                            readOnly
                            checked={statusFilter === 'all'}
                        />
                    </CButtonGroup>
                    <CButtonGroup id='pickup-dropoff' className='me-4'>
                        <CFormCheck
                            label='Pickup Today'
                            id='pickup-today-button'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='checkbox'
                            onClick={() => onFilter('pickup-today')}
                            readOnly
                            checked={pickupToday.current}
                        />
                        <CFormCheck
                            label='Dropoff Today'
                            id='dropoff-today-button'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='checkbox'
                            onClick={() => onFilter('dropoff-today')}
                            readOnly
                            checked={dropoffToday.current}
                        />
                    </CButtonGroup>
                </CButtonToolbar>
            </CCardBody>

            {/* Table */}
            <Table
                headers={headers}
                body={scoreboardFiltered}
                hasSearchRow={true}
                hasColSort={true}
                hasPages={true}
                striped={false}
                tableClass={'scoreboard-table'}
                headerWrap={false}
                hover={true}
                bodyFontSize={'0.9rem'}
                isLoading={scoreboardFiltered == null}
                placeholderProps={{ width: '75px', widthRandomness: 0.5 }}
            />
        </CCard>
    </>;

    //#endregion
}