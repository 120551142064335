import React, { Suspense, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { cookieNames, userRoles } from '../../constants';
import { 
    GetCookie,
    GetBearer, 
    GetErrorToastComponent, 
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn, 
    SetToastComponentValuesInCookie 
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CHeader,
    CHeaderText,
    CRow,
    CToaster
} from '@coreui/react';

const TinyMCE = React.lazy(() => import('../../components/tinymce/tinymce'));

export default function CampaignTemplateAdd() {

    //#region Variables

    const body = useRef(); // Ref used to get body editor content
    const isAdmin = useRef(JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin);
    const navigate = useNavigate();
    const [toast, setToast] = useState(null);

    //#endregion Variables

    //#region Functions

    // Save the updated template information
    function OnAddTemplate(e) {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);
        const htmlBody = body.current.getContent().trim();

        // Cannot pass empty string, must have content
        if (htmlBody === '') {
            setToast(GetToastComponent('Body must not be empty'));
            return;
        }

        // Add email body
        formData.append('EmailTemplateHtmlBody', htmlBody);
        formData.append('OwnerUserID', JSON.parse(GetCookie(cookieNames.userID)));

        fetch('/api/campaign/add-campaign-template', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: form.method,
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);
            
                let newTemplateId = parseInt(data);
                SetToastComponentValuesInCookie('New email template added successfully');
                navigate(`/campaigns/templates/${newTemplateId}/profile`);
                
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not add template!', err.message));
            });
    }

    //#endregion Functions

    //#region Use Effect

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Campaign Template Add</CHeaderText>
            </CHeader>
            <CCard>
                <CCardBody>
                    <CForm onSubmit={OnAddTemplate} method='POST'>
                        {/* Template Elements */}
                        <CRow className='mb-2'>
                            <CCol sm={12} md={4}>
                                <CFormLabel className='required-label'>Name</CFormLabel>
                                <CFormInput name='EmailTemplateName' maxLength='50' required />
                            </CCol>
                            <CCol sm={12} md={4}>
                                <CFormLabel className='required-label'>Permission</CFormLabel>
                                <CFormSelect defaultValue='Only Me' name='Permission' feedbackInvalid='Required field' required>
                                    <option value='Only Me'>Only Me</option>
                                    {/* Only Admins have all the options */}
                                    {isAdmin.current === true ? <>
                                        <option value='Everyone'>Everyone</option>
                                        <option value='Superuser'>Superuser</option>
                                        <option value='System'>System</option>
                                    </> : null}
                                </CFormSelect>
                            </CCol>
                            <CCol sm={12} md={4}>
                                <CFormLabel className='required-label'>Subject</CFormLabel>
                                <CFormInput name='EmailTemplateSubject' maxLength='300' required />
                            </CCol>
                        </CRow>

                        {/* Template Body */}
                        <CRow className='mb-5'>
                            <CCol className='mt-3'>
                                <CFormLabel className='required-label'>Body</CFormLabel>
                                <Suspense fallback={<div>Loading...</div>}>
                                    <TinyMCE
                                        bodyRef={body}
                                    />
                                </Suspense>
                            </CCol>
                        </CRow>

                        {/* Submit */}
                        <CRow className='mb-3'>
                            <CCol className='' style={{ textAlign: 'end' }}>
                                <CButton onClick={() => navigate('/campaigns/templates/')} color='secondary' style={{ width: '100px' }}>Cancel</CButton>
                            </CCol>
                            <CCol>
                                <CButton type='submit' style={{ backgroundColor: 'rgb(51, 122, 183)', width: '100px' }}>Add</CButton>
                            </CCol>
                        </CRow>
                    </CForm>
                </CCardBody>
            </CCard>
        </>
    );       
}