import React, { useEffect, useRef, useState } from 'react';
import {
    CAlert,
    CButton,
    CButtonGroup,
    CButtonToolbar,
    CCardBody,
    CFormCheck,
    CFormInput,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from '@coreui/react';

import CIcon from '@coreui/icons-react';
import { cilArrowThickTop, cilWarning } from '@coreui/icons';

import '../../style/table.css'
import Placeholder from '../miscellaneous/placeholder';

// Takes the properties headers, body, tableClass, hasSearchRow, hasColSort, hasPages, onChange
// Reference README.md for more info
// It will also take the table formatting properties 'bordered', 'striped', 'color'
// Creates a table given the headers and body (rows data)
// Mandatory properties: headers, body
// Optional styling and table options: bordered, hasColSort, hasPages, hasSearchRow, hover, striped, tableClass, tableBodyClass, bodyFontSize, color, debounceDelay
// Optional function to run  when table changes: onChange, onRowClick
// Optional return filtered items: hasReturnFilteredItems, returnPropertyName, filteredItems
// Optional skeleton loading properties: isLoading, loadingNumRows, placeholderProps
export default function Table({ body, headers, bordered, hasColSort, hasPages, hasSearchRow, hover, striped, tableClass,
    tableBodyClass, bodyFontSize, color, debounceDelay, onChange, onRowClick, hasReturnFilteredItems, returnPropertyName,
    filteredItems, isLoading, loadingNumRows, placeholderProps }) {

    //#region Variables

    const tableBodyUnmodified = useRef(null);
    const tableBody = useRef(null);

    // Sorting
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('');
    const lastSelectedHeaderId = useRef('');
    const activeTableClass = 'table-active';

    // Search 
    const searchFilter = useRef([]);
    const [tableBodyFiltered, setTableBodyFiltered] = useState(null);

    // Pages
    const page = useRef(1)
    const rowsPerPage = useRef(10);
    const numberOfPages = useRef(1);
    const [splitBody, setSplitBody] = useState(null);

    // Error Handling
    const [alertVisible, setAlertVisible] = useState(false);
    const alertMessage = useRef('An error occurred. Please refresh! :(');

    // Timeout
    const timeoutId = useRef();
    const delay = useRef(debounceDelay ?? 0);

    //#endregion Variables

    //#region Functions

    //#region Search Functions

    /**
     * Sets the search parameters for the search. Can be null if you want to rerun
     * search with existing values. Takes the id and value of the column
     * @param {string} inputId Id of input element to add search for
     * @param {string} value Value to filter by
     * @param {bool} clickTrigger Trigger by click should not have timeout, trigger not by click (by change) will have timeout
     */
    function onSearchItem(inputId, value, clickTrigger) {
        const runSearch = () => {
            let className, index, trimmedValue;

            // add to searchFilter if both values populated
            if (inputId != null && value != null) {
                trimmedValue = value.trim();
                className = inputId.replace('-Search-Input', '').replace(/-/g, ' ');
                index = searchFilter.current.length === 0
                    ? -1
                    : searchFilter.current.findIndex(item => item['className'] === className)

                // No item found
                 if (index === -1) {
                    if (trimmedValue !== '') {
                        searchFilter.current.push({
                            'className': className,
                            'value': trimmedValue
                        });
                    }
                } else { // Found item
                    // Remove item if search is removed
                     if (trimmedValue === '') {
                        searchFilter.current.splice(index, 1);
                    } else { // Update search item
                        searchFilter.current[index]['value'] = trimmedValue;
                    }
                }
            }

            if (searchFilter != null) { // If either values null and search is empty, then return since nothing to search
                // Search and set filtered list
                tableBody.current = Search(tableBodyUnmodified.current, searchFilter.current);

                PopulateFilteredItems(); // Populate filteredItems if applicable
            }

            if (hasColSort)
                tableBody.current = Sort(tableBody.current);

            // Use splitData set in SetDisplayPages if using pages, otherwise, set tableBodyFiltered
            if (hasPages) {
                SetDisplayPages();
            } else {
                setTableBodyFiltered(tableBody.current);
            }
        }

        // Stop search if filter is null
        if (searchFilter.current == null) return;

        // Clear the previous timeout and create a new one
        clearTimeout(timeoutId.current);

        // Set timeout only if triggered by OnChange
        if (clickTrigger) {
            runSearch();
        } else {
            timeoutId.current = setTimeout(() => {
                runSearch();
            }, delay.current);
        }
    }

    /**
     * Searches for the items in searchFilter. Will ensure all columns match
     * @param {obj[]} bodyData The body to search through
     * @param {obj[]} filter The array of all the headers with search values. Format: 
     * [{'className': headerName, 'value': searchTermValue }, ...]
     * @returns
     */
    function Search(bodyData, filter) {
        if (bodyData === undefined || bodyData == null) return;
        if (filter == null || filter.length === 0) return bodyData; // No search neeeded

        // Filter all the items in column that do not match search critera
        bodyData = bodyData.filter(row => {
            var result = true;
            for (let i = 0; i < filter.length; i++) {
                // Nothing in filter means show everything
                if (filter[i].value === '') result &&= true;

                // If row element is null and there is a filter, 
                // can skip checking below becuase it is empty
                if (row[filter[i].className] == null) {
                    result &&= false;
                    continue;
                }

                result &&= row[filter[i].className]
                    .toString()
                    .toLowerCase()
                    .includes(filter[i].value.toString().toLowerCase());
            }
            return result;
        });

        return bodyData;
    }

    //#endregion Search Functions

    //#region Sort By Functions

    /**
     * When the filter button is clicked set the filters.
     * Element could be the span element or the th element depending where it is clicked
     * @param {obj} element Item clicked
     */
    function onFilterClick(element) {
        if (element == null) return;

        try {
            var parsedId = element.id.replace('-arrow', '');
            var parsedIdNoDash = parsedId.replace(/-/g, ' ');

            // If clicking the same header, swap asc/desc
            if (orderBy === parsedIdNoDash) {
                // Note: orderBy is the format as the json key
                let prevOrder = order;
                setOrder((current) => current === 'asc' ? 'desc' : 'asc');
                document.getElementById(parsedId + '-arrow').innerHTML = prevOrder === 'desc' ? '&#x10c39;' : '&#x10c38;';
            } else { // Sort by new item
                setOrderBy(() => parsedIdNoDash);
                setOrder(() => 'desc');

                // Hightlight table row selected and remove previous hightlight and
                // set old element back to descending arrow if not the first load
                var prevHeader = document.getElementById(lastSelectedHeaderId.current);
                if (prevHeader != null) {
                    prevHeader.classList.remove(activeTableClass);
                    document.getElementById(lastSelectedHeaderId.current + '-arrow').innerHTML = '&#x10c38;';
                }

                // Set the clicked item active
                document.getElementById(parsedId).classList.add(activeTableClass);
                lastSelectedHeaderId.current = parsedId;
            }
        } catch (ex) {
            // Show error alert
            setAlertVisible(true);
            console.log('lastheaderId: ' + lastSelectedHeaderId.current + 'parsedId: ' + parsedId + 'parsedIdnodash: ' + parsedIdNoDash);
            // TODO: log exception instead
            console.log(ex);
        }
    }

    /**
     * The function sorts by desc or asc depending on what is currently set for the column
     * @param {any} bodyData
     * @returns Sorted array or empty array if parameter is null or undefined
     */
    function Sort(bodyData) {
        if (bodyData === undefined || bodyData == null || bodyData.length === 0 || orderBy === '') return bodyData;

        // Get all the elements in that column
        bodyData.sort(getComparator(order, orderBy))
        return bodyData;
    }

    /**
     * Compares two items to see which is larger
     * @param {string} a First item
     * @param {string} b Second item
     * @param {string} orderBy asc or dsc
     * @returns int saying which is greater
     */
    function descendingComparator(a, b, orderBy) {
        try {
            let itemA = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
            let itemB = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];

            if (itemB < itemA) {
                return -1;
            } else if (itemB > itemA) {
                return 1;
            }

            return 0;

        } catch {
            // If a value is null, will throw error due to toLowerCase()

            // Both null means equal
            if (b[orderBy] == null && a[orderBy] == null) {
                return 0;
            } else if (b[orderBy] == null) { // second value smaller if only second value null
                return -1;
            }

            // First value smaller
            return 1;
        }
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    //#endregion Sort By Functions

    //#region Page Filter

    /**
     * Takes tableBodyFiltered and takes the section needed
     */
    function SetDisplayPages() {
        if (tableBody.current == null) return;

        // Sets how mnay pages there are currently
        numberOfPages.current = Math.ceil(tableBody.current.length / rowsPerPage.current);

        // Check if current page number exceeds the number of pages there are
        if (page.current > numberOfPages.current && numberOfPages.current !== 0) {
            // Update current page to be last page
            page.current = numberOfPages.current;
        }

        var startIndex = (page.current - 1) * rowsPerPage.current;
        var endIndex = page.current * rowsPerPage.current;

        // Set the page body rows
        setSplitBody(tableBody.current.slice(startIndex, endIndex));
    }

    /**
     * Change the number of rows that are displayed on the page
     * @param {int} rows Number of rows to show on the page
     */
    function onDisplayRowNumberChange(rows) {
        // No change needed, same rows showing already
        if (rows === rowsPerPage) return;

        rowsPerPage.current = rows;
        page.current = 1;

        SetDisplayPages();
    }

    /**
     * Change the page
     * @param {int} setToPage Page to go to
     */
    function onPageChange(setToPage) {
        setToPage = parseInt(setToPage);

        // No change needed, page showing already
        if (setToPage === page.current || setToPage > numberOfPages.current || setToPage < 1) return;

        page.current = setToPage;

        SetDisplayPages();
    }

    //#endregion Page Filter

    //#region Component Functions

    // Display the column headers
    function HeaderRowComponent() {
        if (hasColSort) {
            return headers.map((header) => {
                // Check if column should be shown
                if (header.show === false) return null;

                var headerId = HeaderIdGenerator(header.name);
                var headerClasses = header.headerClassName == null ? 'text-nowrap' : 'text-nowrap ' + header.headerClassName;

                return <CTableHeaderCell
                    scope='col'
                    id={headerId}
                    key={headerId}
                    className={headerClasses}
                    onClick={header.colSort === true ? (e) => onFilterClick(e.target) : null}
                >
                    {header.name}
                    {header.colSort === true ?
                        <span id={headerId + '-arrow'} key={headerId + '-arrow'} className='ms-2 text-light arrow'>&#x10c38;</span>
                        : null
                    }
                </CTableHeaderCell>;
            })
        } else {
            return headers.map((header) => {
                // Check if column should be shown
                if (header.show === false) return null;

                var headerId = HeaderIdGenerator(header.name);
                return (
                    <CTableHeaderCell
                        scope='col'
                        id={headerId}
                        key={headerId}
                        className={header.headerClassName ?? null}
                    >
                        {header.name}
                    </CTableHeaderCell>
                );
            })
        }
    }

    // Display the column search row
    function SearchRowComponent() {
        if (hasSearchRow !== true) return (null);

        return (
            <CTableHead className='table-group-divider'>
                <CTableRow>
                    {headers.map((header) => {
                        // If column is not showing, no need to show search box
                        if (header.show === false) return null;

                        var headerClasses = header.headerClassName ?? null;
                        var headerId = header.name.trim().replace(/ /g, '-');
                        var searchId = headerId + '-Search';
                        var searchInput = searchId + '-Input'

                        if (headerClasses === undefined || headerClasses == null) {
                            headerClasses = 'search';
                        } else {
                            headerClasses += ' search';
                        }
                        return (
                            <CTableHeaderCell
                                scope='col'
                                id={searchId}
                                key={searchId}
                                className={headerClasses}
                            >
                                {
                                    header.hasSearch === true ? (
                                        <CFormInput
                                            id={searchInput}
                                            key={searchInput}
                                            onChange={(e) => onSearchItem(e.target.id, e.target.value.trim(), false)}
                                            onClick={(e) => onSearchItem(e.target.id, e.target.value, true)} // Required for manually triggering search, use click()
                                        />
                                    ) : ''
                                }
                            </CTableHeaderCell>
                        );
                    })}
                </CTableRow>
            </CTableHead>
        );
    }

    // Display table rows
    function TableBodyComponent() {
        if (tableBody.current == null && tableBodyFiltered == null && splitBody == null && isLoading !== true) return null;

        return <Body
            body={hasPages === true ? splitBody : tableBodyFiltered}
            headers={headers}
            fontSize={bodyFontSize}
            /*hover={hover}*/
            onRowClick={onRowClick}
            isLoading={isLoading}
            loadingNumRows={loadingNumRows}
            placeholderProps={placeholderProps}
            tableBodyClass={tableBodyClass}
        />;
    }

    // Page Navigation options in a CButtonGroup
    function PagesComponent() {
        if (tableBody.current == null || tableBody.current.length === 0 || numberOfPages.current === 1) return (<><div /></>);

        var pageButtons = [];
        var skipAfter; // Skip pages after this index
        var skipTo; // Skip to this page number
        var singleSeparator = true; // single skip if first/last 5 pages or less than 10 total pages

        // Separator (the ...) Conditions
        if (numberOfPages.current < 10) { // If less than 10 pages in total
            // No Skipping
            skipAfter = -1;
            skipTo = -1;
        } else if (page.current <= 5) { // If first 5 pages 
            // Show the first 7 pages and the last page
            skipAfter = 7;
            skipTo = numberOfPages.current;
        } else if (page.current >= numberOfPages.current - 5) { // If last 5 pages
            // Show the last 7 pages and the first page
            skipAfter = 1;
            skipTo = numberOfPages.current - 6
        } else { // All the pages beween 6 and (last page - 5)
            singleSeparator = false;
        }

        // Iterate to display page buttons 
        for (let i = 1; i <= numberOfPages.current; i++) {
            if (singleSeparator) {
                // Show page number button
                pageButtons.push(
                    <CFormCheck
                        label={i}
                        id={'Page-' + i}
                        key={i}
                        button={{ color: 'secondary', variant: 'outline' }}
                        type='radio'
                        name='btnradio pagenumber'
                        value={i}
                        onClick={e => onPageChange(e.target.value)}
                        checked={i === page.current ? true : false}
                        readOnly
                    />
                );

                // Skip to other index 
                if (skipAfter === i) {
                    i = skipTo - 1;
                    pageButtons.push(<CButton key={i} color='secondary' variant='outline' style={{ pointerEvents: 'none' }}>...</CButton>);
                }

            } else {
                // If first/last or within the range
                if (i === 1 || i === numberOfPages.current || (i >= page.current - 2 && i <= page.current + 2)) {
                    // Show page number button
                    pageButtons.push(
                        <CFormCheck
                            label={i}
                            id={'Page-' + i}
                            key={i}
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='radio'
                            name='btnradio pagenumber'
                            value={i}
                            onClick={e => onPageChange(e.target.value)}
                            checked={i === page.current ? true : false}
                            readOnly
                        />
                    );

                } else { // add separator and change index to next one
                    pageButtons.push(<CButton key={i} color='secondary' variant='outline' style={{ pointerEvents: 'none' }}>...</CButton>);

                    // if i < page
                    if (i < page.current) {
                        // First separator added, skip to before the page
                        i = page.current - 3;
                    } else {
                        // Skip to end
                        i = numberOfPages.current - 1;
                    }
                }
            }
        }

        return <CButtonGroup>
            <CButton color='secondary' variant='outline' onClick={() => onPageChange(page.current - 1)}>&#x10c36;</CButton>
            {pageButtons}
            <CButton color='secondary' variant='outline' onClick={() => onPageChange(page.current + 1)}>&#x10c37;</CButton>
        </CButtonGroup>;
    }

    //#endregion Component Functions

    //#region Utilities

    /**
     * Pass the header name and get a id generated from it.
     * Trims header, and repaces any spaces with dashes
     * @param {string} headerName Item to get id for
     * @returns Formatted string id
     */
    function HeaderIdGenerator(headerName) {
        return headerName.trim().replace(/ /g, '-')
    }

    /**
     * Populates the filteredItems useRef using tableBody.current if hasReturnFilteredItems = true
     */
    function PopulateFilteredItems() {
        // If returning a value in the filtered items
        if (hasReturnFilteredItems === true && tableBody.current != null) {
            // Gets array of values of property type specifed in returnPropertyName
            filteredItems.current = tableBody.current.map(x => x[returnPropertyName]);
        }
    }

    //#endregion Utilities

    //#endregion Functions

    //#region Use Effect

    // Set new table data whenever a new body is loaded
    useEffect(() => {
        if (body != null) {
            tableBodyUnmodified.current = body;
            tableBody.current = body;

            // Reset page to 1 on new data passed
            if (hasPages) {
                page.current = 1;
            }

            // If there is search, use the search
            if (searchFilter.current.length > 0) {
                onSearchItem();
            } else {
                // Sort new body data
                if (hasColSort)
                    tableBody.current = Sort(tableBody.current);

                // Use splitData set in SetDisplayPages if using pages, otherwise, set tableBodyFiltered
                if (hasPages) {
                    SetDisplayPages();
                } else {
                    setTableBodyFiltered(tableBody.current);
                }
            }

            PopulateFilteredItems();
        }
    }, [body]);

    // Update table when filtered
    useEffect(() => {
        // Reapply search if there is one
        // Search function will also do everything in the else statement
        if (searchFilter.current.length > 0) {
            onSearchItem();
        } else {
            tableBody.current = Sort(tableBody.current);

            // Use splitData set in SetDisplayPages if using pages, otherwise, set tableBodyFiltered
            if (hasPages) {
                SetDisplayPages();
            } else {
                setTableBodyFiltered(tableBody.current);
            }
        }
    }, [order, orderBy]);

    // Run function if provided whenever a change has been made
    useEffect(() => {
        if (onChange != null) onChange();
    });

    //#endregion

    return (<>
        {/* Table */}
        <CCardBody>
            <CAlert id='table-alert' color='danger' dismissible className="d-flex align-items-center" visible={alertVisible} onClick={() => setAlertVisible(false)}>
                <CIcon icon={cilWarning} className='flex-shrink-0 me-2' width={24} height={24} />
                <div>{alertMessage.current}</div>
            </CAlert>

            <CTable bordered={bordered} striped={striped} color={color} className={tableClass} responsive>

                {/* header row */}
                <CTableHead>
                    <CTableRow>
                        {HeaderRowComponent()}
                    </CTableRow>
                </CTableHead>

                {/* Search row */}
                {SearchRowComponent()}

                {/* Table body */}
                {TableBodyComponent()}
            </CTable>
            {hasPages === true ?
                <CButtonToolbar className='justify-content-between'>
                    {/* Pages */}
                    {PagesComponent()}

                    <span>
                        {/* Items Per Page Buttons */}
                        <CButtonGroup role='group'>
                            <CFormCheck
                                label='10'
                                id='10-per-page'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio perpage'
                                onClick={() => onDisplayRowNumberChange(10)}
                                readOnly
                                checked={rowsPerPage.current === 10}
                            />
                            <CFormCheck
                                label='25'
                                id='25-per-page'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio perpage'
                                onClick={() => onDisplayRowNumberChange(25)}
                                readOnly
                                checked={rowsPerPage.current === 25}
                            />
                            <CFormCheck
                                label='50'
                                id='50-per-page'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio perpage'
                                onClick={() => onDisplayRowNumberChange(50)}
                                readOnly
                                checked={rowsPerPage.current === 50}
                            />
                            <CFormCheck
                                label='100'
                                id='100-per-page'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio perpage'
                                onClick={() => onDisplayRowNumberChange(100)}
                                readOnly
                                checked={rowsPerPage.current === 100}
                            />
                        </CButtonGroup>
                        <CButton color='secondary' className='ms-2' onClick={() => window.scrollTo({ top: 0 })}>
                            <CIcon icon={cilArrowThickTop} />
                        </CButton>
                    </span>
                </CButtonToolbar>
                : null
            }
        </CCardBody>
    </>);
}

/**
 * 
 * @param {obj[]} body The data content passed by the header
 * @param {string} fontSize Font size of body element
 * @param {obj[]} headers The headers passed into table 
 * @param {bool} hover Allows hovering over table. Default fals 
 * @param {function} onRowClick Function to run when row is clicked. Will be passed the index of row clicked
 * @param {bool} isLoading Show loading animation to when data is being fetched. Default false. True will show Placeholder in each cell
 * @param {int} loadingNumRows Number of rows to show for when isLoading = true
 * @param {string?} tableBodyClass Optional body specific class names
 * @param {obj} placeholderProps Props for Placeholder component
 */
function Body({ body, fontSize, headers, hover, onRowClick, isLoading, loadingNumRows, tableBodyClass, placeholderProps }) {
    let classNames = hover === true ? 'highlight-row-hover ' : '';

    if (tableBodyClass != null) classNames += tableBodyClass;

    return <>
        <CTableBody style={{ fontSize: fontSize }} className={classNames}>
            {isLoading === true && Array.from({ length: loadingNumRows ?? 10 }, (_, i) =>
                <CTableRow id={i} key={i}>
                    <Row headers={headers} rowId={i} isLoading={isLoading} placeholderProps={placeholderProps} />
                </CTableRow>)
            }

            {body != null && isLoading !== true && body.map((row, i) => {
                return (
                    <CTableRow
                        id={i}
                        key={i}
                        className={row['RowClassNames']}
                        onClick={() => {
                            if (onRowClick != null)
                                onRowClick(i);
                        }}>
                        <Row row={row} headers={headers} rowId={i} />
                    </CTableRow>
                )
            })}
        </CTableBody>
    </>;
}

/**
 * Maps the row given. The key and id for each cell is the rowId-columnName 
 * @param {obj[]} headers The headers passed into table 
 * @param {obj} row The data for this row
 * @param {int} rowId Id of row (Starts at 0)
 * @param {bool} isLoading Show loading animation to when data is being fetched. Default false. True will show Placeholder in each cell
 * @param {obj} placeholderProps Props for Placeholder component
 */
function Row({ headers, row, rowId, isLoading, placeholderProps }) {
    /**
     * Appends 'classToAppend' value to 'itemToAppendTo'
     * @param {string} itemToAppendTo string that will add the new class 
     * @param {string} classToAppend Class name to append to 
     * @returns Concacted string
     */
    function AppendClassName(itemToAppendTo, classToAppend) {
        if (itemToAppendTo == null) return classToAppend;
        else if (itemToAppendTo.includes(classToAppend)) return itemToAppendTo;
        else return itemToAppendTo + ' ' + classToAppend;
    }

    return <>
        {headers == null ? null : headers.map(heading => {
            // If column is not showing, no need to map cell
            if (heading.show === false) {
                return null;
            }

            let cellClasses = null;
            let cellValue = null;
            let headerId = rowId + '-' + heading.name.trim().replace(/ /g, '-');

            // Append column specific class name if exist
            if (heading['columnClass'] != null) {
                cellClasses = heading['columnClass'];
            }

            if (isLoading === true) {
                cellValue = <Placeholder {...placeholderProps} />;
            } else {
                // Append cell class name if exists
                if (row["CellClassNames"] != null && row["CellClassNames"][heading.name] != null) {
                    cellClasses = AppendClassName(cellClasses, row["CellClassNames"][heading.name]);
                }

                // Use custom element format instead if exists
                if (heading['moreThanJustText'] === true && row['ValueWithElements'][heading.name] != null) {
                    cellValue = row['ValueWithElements'][heading.name];
                } else {
                    cellValue = row[heading.name];
                }
            }

            return <CTableDataCell
                id={headerId}
                key={headerId}
                className={cellClasses}
                value={row != null ? row[heading.name] : null}
            >
                {cellValue}
            </CTableDataCell>;
        })}
    </>;
}

//#region Utilities

/**
 * Clears all the search items currently showing
 */
function ClearSearchFilters() {
    var searchInputs = document.querySelectorAll('th.search input');

    if (searchInputs.length === 0) return;

    // Clear all inputs
    searchInputs.forEach(input => {
        input.value = '';
        input.click();  // Trigger click activity to run filters 
    });
}

/**
 * Gets the search parameters of the items on screen. Note: if you have multiple tables with
 * the same column header name, it may not behave correctly since it uses getElementById to get the header
 * @param {useState} headers Headers to get search input for
 * @returns Object with search params for each column that is populated
 * E.g. The object will look something like the following: 
 * { 'Table Column Name': 'search value', 'Column 2': 'search value for column 2' }
 */
function GetSearchParameters(headers) {
    var searchInput = {};
    headers.forEach(heading => {
        // Each search input is in the Table component and id is table name and ends with '-Search-Input'
        let id = heading.name.replace(/ /g, '-') + '-Search-Input';
        let elem = document.getElementById(id);

        if (elem != null && elem.value.trim() !== '') searchInput[heading.name] = elem.value.trim();
    });

    return searchInput;
}

/**
 * Update search inputs in table and triggers the table filtering
 * @param {object} searchInput Object with column names as key and the value is what 
 * is in the search input. The object passed should look something like the following: 
 * E.g. { 'Table Column Name': 'search value', 'Column 2': 'search value for column 2' }
 */
function UpdateSearchInTable(searchInput) {
    // Loop through the search inputs, and populate it with values
    for (var key in searchInput) {
        let id = key.replace(/ /g, '-') + '-Search-Input';
        var inputElem = document.getElementById(id);

        // If search is found, trigger click which will trigger search for that element
        if (inputElem != null) {
            inputElem.value = searchInput[key];
            inputElem.click(); // Trigger event so Table filter will run
        }
    }
}

//#endregion Utilities

export {
    ClearSearchFilters,
    GetSearchParameters,
    UpdateSearchInTable
}