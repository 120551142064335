import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    GetCookie,
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    NavWithNewTab,
    UpdateSWFetch,
    FormatDate,
} from '../../functions';
import { userRoles, infoModalText, cookieNames, localStoreNames } from '../../constants';
import {
    ClearChecks,
    OnCheckChange,
    UpdateChecks
} from '../../checkFunctions';

import ConfirmModal from '../../components/miscellaneous/confirmModal';
import {
    CButton,
    CCard,
    CCardBody,
    CDropdown,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CCol,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CHeader,
    CHeaderText,
    CListGroup,
    CListGroupItem,
    CRow,
    CToaster,
    CTooltip,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faArrowRight, faPen, faPlus, faTrash, faWrench } from '@fortawesome/free-solid-svg-icons';

import Table, { ClearSearchFilters, GetSearchParameters, UpdateSearchInTable } from '../../components/table/table';

import InfoModal from '../../components/miscellaneous/infoModal';
import ImportFromExcelModal from '../../components/lead/ImportFromExcelModal';

export default function Lead() {

    //#region Variables

    const leadsData = useRef(); // Unfiltered leads data
    const [leads, setLeads] = useState(); // Filtered leads data
    const isAdmin = useRef(false);
    const initialFilter = useRef(false); // Runs filtering once after leads data loads

    // Min/Max Refs
    const maxRevenueInput = useRef();
    const minRevenueInput = useRef();

    // Confirm Convert To Propsect Modal
    const [showConfirmConvertModal, setShowConfirmConvertModal] = useState(false);
    const selectedCompaniesToConvert = useRef(); // Names of companies checked that will show in confirm modal body

    // Confirm Delete Modal
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const deleteModalLeadId = useRef();
    const deleteModalMessage = useRef();

    // Import From Excel Modal
    const [showImportModal, setShowImportModal] = useState(false);

    // Currency Formatter
    const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
    });

    // Checkbox
    const checkedIds = useRef([]);

    const timeoutId = useRef(); // used to store the timeout
    const [toast, setToast] = useState();
    const nav = useNavigate();
    const headers = [
        {
            name: 'Check',
            moreThanJustText: true,
            headerClassName: 'text-light nav-buttons',
            columnClass: 'button-center',
        },
        {
            name: 'Name',
            headerClassName: 'name',
            colSort: true,
            moreThanJustText: true,
            hasSearch: true,
        },
        {
            name: 'Phone #',
            headerClassName: 'phone-number',
            hasSearch: true,
        },
        {
            name: 'Address',
            headerClassName: 'address',
            moreThanJustText: true,
            hasSearch: true,
        },
        {
            name: 'Description',
            headerClassName: 'description',
            hasSearch: true,
        },
        {
            name: 'Industry',
            headerClassName: 'industry',
            colSort: true,
            moreThanJustText: true,
            hasSearch: true,
        },
        {
            name: 'Annual Revenue',
            headerClassName: 'annual-revenue',
            colSort: true,
            moreThanJustText: true,
        },
        {
            name: 'Last Modified',
            headerClassName: 'last-modified',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Button',
            headerClassName: 'text-light nav-buttons',
            moreThanJustText: true,
            columnClass: 'button-center',
        },
    ];

    //#endregion Variables

    //#region Functions

    //#region Revenue Filters

    /**
     * Filters the revenue to the range specified in input field
     * @param {bool} includeDelay Default true. Includes 300 ms debounce delay before filtering
     * @param {bool} needUpdate Default true. False will not update cookie
     */
    function OnRevenueFilterRange(includeDelay, needUpdate) {
        // Clear the previous timeout and create a new one
        clearTimeout(timeoutId.current);

        timeoutId.current = setTimeout(() => {
            try {
                let filteredLeads = null;
                let minRevInputValue = 0;
                let maxRevInputValue = 0;
                let hasFilterMin = false;
                let hasFilterMax = false;

                // Return if elements can not be found
                if (leadsData.current == null) return;

                if (minRevenueInput.current != null) minRevInputValue = Number(minRevenueInput.current.value);
                if (maxRevenueInput.current != null) maxRevInputValue = Number(maxRevenueInput.current.value);

                // Set whether there is filter or not without conversion (since '' will convert to 0)
                if (minRevenueInput.current.value !== '') hasFilterMin = true;
                if (maxRevenueInput.current.value !== '') hasFilterMax = true;

                // Min value cannot be greater than max value if both values set
                if (hasFilterMin && hasFilterMax && minRevInputValue > maxRevInputValue) {
                    var currentlyDisplayingToast = document.getElementsByClassName('toast').length !== 0;
                    // Only show toast if not already showing one
                    if (!currentlyDisplayingToast) setToast(GetToastComponent('Minimum Revenue cannot exceed Maximum Revenue'));

                    return;
                }

                // Update the revenue settings 
                if (needUpdate !== false) UpdateDefaults();

                // Filter revenue
                filteredLeads = leadsData.current.filter(lead => {
                    let result = true;

                    // Filter by min or max if only one provided or both if both provided
                    if (hasFilterMin) result &&= lead['Annual Revenue'] >= minRevInputValue;

                    if (hasFilterMax) result &&= lead['Annual Revenue'] <= maxRevInputValue;

                    return result;
                });

                setLeads(filteredLeads);
            } catch (err) {
                console.log(err);
            }
        }, includeDelay === false ? 0 : 300);
    }

    /**
     * Clear revenue filters
     */
    function ClearRevenueFilters() {
        minRevenueInput.current.value = '';
        maxRevenueInput.current.value = '';
        ClearSearchFilters();
        OnRevenueFilterRange(false); // Run filtering with empty values
    }

    //#endregion Revenue Filters

    /**
     * Gets all leads
     * @returns AbortController object used to abort fetch call
     */
    function GetLeads() {
        const runAfterFetch = (data) => {
            // Process the data
            if (data == null) return;

            data.forEach(row => {
                var valueWithElements = {};

                // Checkbox to be used to convert multiple leads into prospects
                valueWithElements['Check'] = <CFormCheck
                    id={row['CompanyLeadID'] + '-check'}
                    onChange={() => OnCheckChange(checkedIds, row['CompanyLeadID'])}
                    style={{ height: '22px', width: '22px' }}
                />;

                // Append last modified date to Name
                let date = new Date(row['LastModificationTime']);
                if (row['LastModificationTime'] != null)
                    valueWithElements['Name'] = <>
                        {row['Name'].trim()}
                        <br />
                        <p className='fw-lighter fst-italic small-text'>
                            Last Modified: {date.getFullYear()}/{date.getMonth() + 1}/{date.getDate()}
                        </p>
                    </>;

                // Set Phone # to match table header name
                row['Phone #'] = row['PhoneNumber'];
                row['PhoneNumber'] = undefined;

                // Set the Address 
                row['Address'] =
                    row['AddressLine1'] + ' ' + row['City'] + ' ' + row['StateProvinceCode'] + ' '
                    + row['StateProvinceName'] + ' ' + row['CountryName'] + ' ' + row['ZipPostalCode'];

                let addressLine1 = row['AddressLine1'] == null ?
                    null
                    : <>{row['AddressLine1']}<br /></>;
                let addressLine2 = row['City'] == null && row['StateProvinceCode'] == null ?
                    null
                    : <>{row['City']} {row['StateProvinceCode']}<br /></>;
                let addressLine3 = row['CountryName'] == null && row['ZipPostalCode'] == null ?
                    null
                    : <>{row['CountryName']} {row['ZipPostalCode']}</>;

                valueWithElements['Address'] =
                    <>
                        {addressLine1}
                        {addressLine2}
                        {addressLine3}
                    </>;

                // Set Industry to match table header name
                row['Industry'] = row['IndustryName'] ?? '';
                row['IndustryName'] = undefined;

                // Set Annual Revenue to match table header name
                let formattedRevenue = row['AnnualRevenue'] ? formatter.format(row['AnnualRevenue']) : '';
                row['Annual Revenue'] = row['AnnualRevenue']; // Adding and formatted and unformatted number for ease of search
                valueWithElements['Annual Revenue'] = formattedRevenue;
                row['AnnualRevenue'] = undefined;

                // Last Modified
                let lastModifiedDate = new Date(row.LastModificationTime);
                row['Last Modified'] = FormatDate(lastModifiedDate);
                row.LastModificationTime = undefined;

                // Add the buttons
                valueWithElements['Button'] = <>
                    <CTooltip content='Profile'>
                        <CButton onClick={(e) => NavWithNewTab(e, nav, `/company-leads/${row['CompanyLeadID']}/profile`)} className='m-1'>
                            <FontAwesomeIcon icon={faAddressCard} />
                        </CButton>
                    </CTooltip>
                    <CTooltip content='Edit'>
                        <CButton onClick={(e) => NavWithNewTab(e, nav, `/company-leads/${row['CompanyLeadID']}/edit`)} color='warning' style={{ color: 'white' }} className='m-1'>
                            <FontAwesomeIcon icon={faPen} />
                        </CButton>
                    </CTooltip>
                    <CTooltip content='Delete'>
                        <CButton color='danger' onClick={() => OnDeleteSelected(row['CompanyLeadID'], row['Name'])} className='m-1'>
                            <FontAwesomeIcon icon={faTrash} />
                        </CButton>
                    </CTooltip>
                </>;

                // Append the custom formatted elements
                row['ValueWithElements'] = valueWithElements;
            });

            leadsData.current = data;
            setLeads(data);
        }

        const controller = new AbortController();
        let url = '/api/companyleads/get-all-leads';
        UpdateSWFetch(url, (x) => {
            runAfterFetch(x);
            OnRevenueFilterRange(false, false);
        });
        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET',
            signal: controller.signal
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                runAfterFetch(data);
                OnRevenueFilterRange(); // Run revenue filtering
            })
            .catch(err => {
                setToast(GetErrorToastComponent('Error: Could not get leads!', err));
            });

        return controller;
    }

    //#region Convert To Prospect

    /**
     * Add selected ids to prospects
     */
    function ConvertCheckedToProspects() {
        setShowConfirmConvertModal(false);

        fetch('/api/companyleads/convert-multiple-leads-to-prospect', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(checkedIds.current)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);
                var message = [];

                if (data.length === 0) throw new Error();

                // Check all items are converted and note down the ones that are not
                data.forEach(x => {
                    if (!x.Converted) {
                        let company = leads.find(lead => lead.CompanyLeadID === x.LeadID);

                        if (company != null) {
                            message.push(<>
                                <FontAwesomeIcon icon={faArrowRight} className='me-2' />
                                <b>{company.Name}</b>
                                <br />
                                <span>Reason: {x.Message}</span><br />
                            </>);
                        } else { // Company may be null because already converted to lead
                            message.push(<>
                                <FontAwesomeIcon icon={faArrowRight} className='me-2' />
                                <b>Company with lead id {x.LeadID} could not be converted</b>
                                <br />
                                <span>Reason: Lead may already be converted or deleted</span><br />
                            </>);
                        }
                    }
                });

                // If there are items not converted show error message
                if (message.length !== 0) {
                    let title = message.length > 1 ? 'The following companies were not converted:' : 'The following company was not converted:';
                    setToast(GetToastComponent(title, message, 'warning', false, null, true));
                } else {
                    setToast(GetToastComponent('All selected leads converted to prospects'));
                }

                // Get updated Leads
                GetLeads();
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not convert all leads to prospects', err.message));
            });

        // Reset checkboxes
        ClearChecks(checkedIds);
    }

    /**
     * Formats data to show in confirm modal. At least one item must be selected or else a toast will be shown
     */
    function OnConvertSelected() {
        if (checkedIds.current.length === 0) {
            setToast(GetToastComponent('Select at least 1 account to convert to prospect', null, 'warning'));
            return;
        }

        // Get the names of the companies to convert
        var leadsSelected = [];
        let leadsFull = leads.filter(x => {
            return checkedIds.current.includes(x.CompanyLeadID);
        }); // Accounts with all the data

        leadsFull.forEach(x => leadsSelected.push(x.Name));

        selectedCompaniesToConvert.current = <>
            <CFormLabel className='mt-2'>Companies Selected</CFormLabel>
            <CListGroup>
                {leadsSelected == null || leadsSelected.length === 0 ? 'No Companies Selected' : leadsSelected.map((name, i) => {
                    return <CListGroupItem key={i}>{name}</CListGroupItem>
                })}
            </CListGroup>
        </>;

        setShowConfirmConvertModal(true);
    }

    //#endregion Convert To Prospect

    //#region Delete Lead

    /**
     * Call delete function after prompt confirms the delete
     * @param {int} leadId Id of company to be deleted
     */
    function DeleteLead(leadId) {
        // Hide modal regardless of whether deleted successfully or not
        setShowConfirmDeleteModal(false);

        fetch('/api/companyleads/delete-lead?id=' + leadId, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'DELETE'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === 'Lead deleted successfully') {
                    setToast(GetToastComponent(data));
                } else {
                    setToast(GetErrorToastComponent('Error: There was an issue deleting!', data));
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(err.message));
            });
    }

    /**
     * Set modal variables and show modal
     * @param {int} leadId Id of company to delete
     * @param {string} companyName Name of company to be deleted to show in confirm modal
     */
    function OnDeleteSelected(leadId, companyName) {
        deleteModalLeadId.current = leadId;
        deleteModalMessage.current = `Are you sure you want to delete ${companyName}?`;

        setShowConfirmDeleteModal(true);
    }

    //#endregion Delete Lead

    /**
     * Updates the defaults as needed. Updates Min & Max Revenue, and Search Filters
     */
    function UpdateDefaults() {
        var settings = {
            'Min': minRevenueInput.current.value.toString(),
            'Max': maxRevenueInput.current.value.toString(),
            'SearchInput': GetSearchParameters(headers)
        };

        localStorage.setItem(localStoreNames.leadPageDefault, JSON.stringify(settings));
    }

    //#endregion Functions

    //#region Use Effect

    // Show toast if applicable and set if admin or not
    useEffect(() => {
        // Get Lead data
        const controller = GetLeads();

        // Set admin Status
        isAdmin.current = JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin;

        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);

        // Prepopulate Min & Max Revenue inputs if exist
        // Note: Search is also stored here but will fetch after getting leads or else 
        // it will not show properly since the table is not yet rendered
        var settings = JSON.parse(localStorage.getItem(localStoreNames.leadPageDefault));
        if (settings != null) {
            minRevenueInput.current.value = settings.Min;
            maxRevenueInput.current.value = settings.Max;
        }

        return () => controller.abort(); // Abort on navigate away
    }, []);

    // Run revenue filter after leads load initially
    useEffect(() => {
        if (!initialFilter.current && leads != null) {
            OnRevenueFilterRange(false, false); // Run revenue filtering

            // Get default search settings from cookies and set table with saved values
            let savedSettings = JSON.parse(localStorage.getItem(localStoreNames.leadPageDefault));
            if (savedSettings != null) UpdateSearchInTable(savedSettings.SearchInput);

            initialFilter.current = true; // Set true so it doesn't run again
        }
    }, [leads]);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top-end' />

        {/* Confirm Modal for converting leads to prospect*/}
        <ConfirmModal
            confirmFunction={() => ConvertCheckedToProspects()}
            cancelFunction={() => setShowConfirmConvertModal(false)}
            showModal={showConfirmConvertModal}
            title='Convert The Following:'
            message={selectedCompaniesToConvert.current}
            confirmColour='success'
        />

        {/* Confirm Modal for deleting a lead */}
        <ConfirmModal
            confirmFunction={() => DeleteLead(deleteModalLeadId.current)}
            cancelFunction={() => setShowConfirmDeleteModal(false)}
            showModal={showConfirmDeleteModal}
            title='Delete Confirmation'
            message={deleteModalMessage.current}
            confirmColour='danger'
        />

        {/* Import leads modal */}
        <ImportFromExcelModal
            visible={showImportModal}
            setVisible={setShowImportModal}
            setToast={setToast}
        />

        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>Leads</CHeaderText>
            <span>
                <CButton className='mx-2' color='success' onClick={OnConvertSelected}>Convert Selected to Prospects</CButton>
                <CButton onClick={(e) => NavWithNewTab(e, nav, `/company-leads/new`)} role='button' className='mx-2'>
                    <FontAwesomeIcon icon={faPlus} className='me-2' />
                    Add New Lead
                </CButton>
                <CDropdown className='m-2'>
                    <CDropdownToggle color='secondary'>
                        <FontAwesomeIcon icon={faWrench} className='me-1' />
                    </CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownHeader>Options</CDropdownHeader>
                        {isAdmin.current ? <CDropdownItem onClick={() => setShowImportModal(true)}>Import Leads From Excel</CDropdownItem> : null}
                        <CTooltip content='Coming Soon' placement='left'>
                            <CDropdownItem>Save Search Parameters</CDropdownItem>
                        </CTooltip>
                    </CDropdownMenu>
                </CDropdown>
                <InfoModal modalContent={infoModalText.LeadsPage} />
            </span>
        </CHeader>
        <CCard>
            <CCardBody>
                <CRow className='p-3'>
                    <CCol xs={3}>
                        <CFormInput
                            ref={minRevenueInput}
                            type='number'
                            step={100000}
                            floatingLabel='Minimum Annual Revenue'
                            onChange={() => OnRevenueFilterRange()}
                        />
                    </CCol>
                    <CCol xs={3}>
                        <CFormInput
                            ref={maxRevenueInput}
                            type='number'
                            step={100000}
                            floatingLabel='Maximum Annual Revenue'
                            onChange={() => OnRevenueFilterRange()}
                        />
                    </CCol>
                    <CCol>
                        <CButton type='reset' onClick={ClearRevenueFilters} style={{ marginTop: '10px' }}>Clear</CButton>
                    </CCol>
                </CRow>

                <Table
                    headers={headers}
                    body={leads}
                    hover={true}
                    color={'light'}
                    striped={true}
                    hasColSort={true}
                    hasPages={true}
                    hasSearchRow={true}
                    tableClass='align-middle'
                    onChange={() => {
                        UpdateChecks(checkedIds); // Update the checked items on page

                        // If already done initial filtering, then can update search settings as they change
                        if (initialFilter.current === true) {
                            UpdateDefaults();
                        }
                    }}
                    isLoading={leads == null}
                />
            </CCardBody>
        </CCard>
    </>;
}