import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from './context/authConfig';

import {
    GetCookie,
    SetCookie,
    GetBearerValidUntil,
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn,
    GetLastestChangeLogVersion
} from './functions';

// App routes
import AppRoutes from './AppRoutes';

// UI Components
import Sidebar from './components/ui/sidebar';
import Header from './components/ui/header';
import Footer from './components/ui/footer';

// Login Component
import Login from './pages/login/login';

import { cookieNames, localStoreNames } from './constants';

import {
    CButton,
    CToaster,
} from '@coreui/react';

import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './style/app.css';

export default function App() {

    //#region Variables

    const [routes, setRoutes] = useState();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userRole, setUserRole] = useState(null);

    const [showSideBar, setShowSideBar] = useState(false);
    const [toast, setToast] = useState();
    const location = useLocation();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();  

    // Refresh and Expiry 
    const timeoutId = useRef();
    const twoMinutesInMs = 120000;

    //#endregion Variables

    //#region Functions

    /**
     * Toggles show/hide for side navigation bar
     */
    function ToggleSidebar() {
        setShowSideBar(!showSideBar);
    }

    /**
     * Handle logout
     * @param {bool?} logoutMicrosoft Set true when logging out of microsoft as well
     */
    function OnLogout(logoutMicrosoft) {
        RemoveCookiesWithUserData();

        setIsLoggedIn(false);
        setShowSideBar(false);
        setUserRole(null);

        if (logoutMicrosoft === true) instance.logoutRedirect({ ...loginRequest, account: accounts[0] });
    }

    //#region Refresh Tokens

    /**
    * Refreshes access token
    */
    async function RefreshToken() {
        var oldExpiry = GetBearerValidUntil();
        fetch('/api/login/refresh', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST'
        })
            .then(response => HandleResponseReturn(response, true))
            .then(data => {
                HandleDataReturn(data);

                // Set new timeout for when this token is close to expiry
                let newExpiry = GetBearerValidUntil();

                // Check if expiry time has changed
                if (oldExpiry.getTime() !== newExpiry.getTime()) {
                    SetRefreshTimeout(newExpiry); // Changed time means token refreshed
                } else {
                    // Expiry did not change meaning max refreshes reached
                    setToast(GetToastComponent('Max Login Time Reached', 'Will be logged out on ' + newExpiry, null, true, 15));
                }
            })
            .catch(err => {
                console.log(err)
                setToast(GetErrorToastComponent('Could not refresh access token', err))
            });
    }

    /**
     * Removes the cookies stored on login that contains user data as well as access token
     */
    function RemoveCookiesWithUserData() {
        GetCookie(cookieNames.accessToken, true);
        GetCookie(cookieNames.autoRefreshCounter, true);
        GetCookie(cookieNames.userID, true);
        GetCookie(cookieNames.isSuperUser, true);
        GetCookie(cookieNames.userName, true);
        GetCookie(cookieNames.userRole, true);
        GetCookie(cookieNames.userRoleSubdivision, true);
    }

    /**
     * Set timeout to refresh access token when it is 2 minutes from expiring
     * A counter is kept in cookies for max number of auto refreshes before showing prompt
     * Counter is reset when there has been user action (that results in fetch calls being made that refreshes token)
     * @param {Date} expiryTime Time of expiry
     */
    function SetRefreshTimeout(expiryTime) {
        const onTimeout = () => {
            var expiryToken = GetBearerValidUntil();
            var refreshCounterStr = GetCookie('autoRefreshCounter', true);

            // If current access token does not expire within 2 minutes 
            if (expiryToken - Date.now() > twoMinutesInMs) {
                SetRefreshTimeout(expiryToken); // Set new timeout

            } else { // same token as when timeout was set, needs refreshing
                var counter = 1; // Defaults to 1 if refreshCounterStr is null but will be overriden if it is set

                if (refreshCounterStr != null) counter = parseInt(refreshCounterStr);

                // Auto refresh if counter is less than 5
                if (counter < 5) {
                    counter++;
                    RefreshToken();
                } else { // Show toast to check if user wants to stay logged in if reached max auto refresh
                    counter = 1;
                    let toastBody = <CButton onClick={RefreshToken} color='secondary'>
                        Stay logged in
                    </CButton>;
                    setToast(GetToastComponent('Would you like to stay logged in?', toastBody, null, true, 25, true));
                }

                // Save the new counter value
                SetCookie('autoRefreshCounter', counter, 5);
            }
        };

        // Set a timer to refresh 2 minutes before token expiry
        let checkAfterTime = expiryTime.setMinutes(expiryTime.getMinutes() - 2) - Date.now();
        timeoutId.current = setTimeout(onTimeout, checkAfterTime);
    }

    //#endregion Refresh Tokens

    //#endregion Functions

    //#region Use Effect

    // Check the access token is not expired and set a timeout id to check token expiry and refresh as needed
    useEffect(() => {
        let tokenExpiryTime = GetBearerValidUntil();

        // If there is an expiry date time that is pass current time, then clear session
        if (tokenExpiryTime != null && tokenExpiryTime < Date.now()) {
            RemoveCookiesWithUserData();
            GetCookie('autoRefreshCounter', true); // Removes the auto refresh counter cookie

            // Show session has expired toast
            // NOTE: will still show if first navigating to site from external because will be '/' path
            if (location.pathname !== '/login') 
                setToast(GetToastComponent('Session has expired due to inactivity!', 'Refresh page to login and start new session', null, true, 20));

            return;
        }

        // If there isn't already a timeout, set a timeout to check token expiry
        if (timeoutId.current == null && tokenExpiryTime != null) SetRefreshTimeout(tokenExpiryTime);
    });

    // Initial setup on load
    useEffect(() => {
        // This is workaround for MSAL redirecting inside popup instead of outside
        if (location.hash.includes("id_token")) {
            window.close();
        }

        // Set the app routes with custom imports
        if (routes != null || AppRoutes == null) return;

        var appRoutes = AppRoutes;
        var loginIndex = appRoutes.findIndex(x => x.name === 'Login');

        appRoutes[loginIndex].element = <Login
            setisLoggedIn={setIsLoggedIn}
            setShowSideBar={setShowSideBar}
            setUserRole={setUserRole}
        />;

        setRoutes(appRoutes);

        // Set states if already logged in
        var role = JSON.parse(GetCookie(cookieNames.userRole));
        if (role !== '' && GetBearer() !== '') { // If role and token exists, user is logged in
            setUserRole(role);
            setIsLoggedIn(true);
            setShowSideBar(true);
        }

        // Add query parameter to force Chrome to do a fresh fetch
        // This is a known Chrome bug where pages are not updated on deploy
        let currentVersion = localStorage.getItem(localStoreNames.changeLogMostRecentVersion);
        navigate(`${window.location.pathname}?v=${currentVersion}`, { replace: true });

        // Update cookie with most recent change log version
        GetLastestChangeLogVersion(setToast).catch(console.error);
    }, []);

    // Save route after navigation
    useEffect(() => {
        const path = location.pathname;
        let url = sessionStorage.getItem('url') ?? '';

        // Don't update path if didnt change or no path specified
        if (path === url || path === '/') return;

        sessionStorage.setItem('prevUrl', url);
        sessionStorage.setItem('url', path);

        // Get change log version again if just logged in since 
        // user may have not reloaded the page in a while
        if (url === '/login')
            GetLastestChangeLogVersion(setToast).catch(console.error);
    }, [location]);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top' />
        <AuthenticatedTemplate>
            <div style={{ display: "flex" }}>
                <Sidebar show={showSideBar} userRole={userRole} />
                <div id='body-component'>
                    {isLoggedIn ? <Header logout={OnLogout} onCollapse={ToggleSidebar} setToast={setToast} /> : null}
                    <Routes>
                        {routes == null ? null :
                            routes.map((route) => {
                                const { element, name, path, userRoles } = route;

                                if (userRoles.includes(userRole)) {
                                    return <Route key={name} path={path} element={element} />
                                }
                                return null;
                            })
                        }
                    </Routes>
                    {isLoggedIn ? <Footer /> : null}
                </div>
            </div>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <Routes>
                <Route path='/*' element={<Navigate to='/login' />} />
                <Route path='/login' element={
                    <Login
                        setisLoggedIn={setIsLoggedIn}
                        setShowSideBar={setShowSideBar}
                        setUserRole={setUserRole}
                    />
                } />
            </Routes>
        </UnauthenticatedTemplate>
    </>;
}