import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {
    GetCountries,
    GetStateProvinces,
    GetErrorToastComponent,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    GetToastComponent,
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCol, 
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CInputGroup,
    CInputGroupText,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
} from '@coreui/react';

import { GetErrorMessages, infoModalText } from '../../constants';

import InfoModal from '../../components/miscellaneous/infoModal';

// Modal used to add new contact to specified account type
// companyAccountId (int): Id of company associated to contact
// visible (bool): show or hide modal
// setVisible (setState): sets the state of visible
// setToast (useState): used to display toast in parent
// finishFn (fn): function to run after adding contact
export default function AddContactModal({ companyAccountId, visible, setVisible, setToast, finishFn }) {

    //#region Variables

    const [currentCountry, setCurrentCountry] = useState(null); // current country selected

    // Dropdown options
    const [countryList, setCountryList] = useState(null);
    const [stateProvinceList, setStateProvinceList] = useState(null);

    const [validated, setValidated] = useState(false); // Validation

    //#endregion Variables

    //#region Functions

    function OnAddContact(e) {
        const form = e.target;
        e.preventDefault();

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true); // show validation

            // Get the first invalid element
            var firstInvalidElem = document.querySelector('input:invalid, select:invalid');

            // Scroll to element
            if (firstInvalidElem != null) {
                firstInvalidElem.focus();
            }
        } else {
            const formData = new FormData(form);

            formData.append('CompanyAccountID', companyAccountId);

            fetch('/api/contacts/add-contact', {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: form.method,
                body: formData
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);
                    let body = <>Click <Link to={`/contacts/${data}/profile`}>here</Link> to go to new contact</>

                    setToast(GetToastComponent('Contact added successfully', body, null, null, null, true));
                    setVisible(false);

                    if (finishFn != null) finishFn();
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent('An error has occurred when adding new contact!', err.message));
                });
        }
    }

    //#endregion Functions

    //#region Use Effect

    // Get all the data fields for dropdown
    useEffect(() => {
        setCurrentCountry(1); // Canada is selected by default

        GetCountries()
            .then(x => setCountryList(x))
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(GetErrorMessages.Countries, err.message));
            });
        GetStateProvinces()
            .then(x => setStateProvinceList(x))
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(GetErrorMessages.StateProvince, err.message));
            });
    }, []);

    // Enables or disables the province/state dropdown depending on whether there is a country selected
    useEffect(() => {
        const countryDropdown = document.getElementById('country-select');
        const stateProvinceDropdown = document.getElementById('state-province-select');

        if (countryDropdown == null || stateProvinceDropdown == null) return;

        // If no value is selected for country
        if (countryDropdown.value === '') {
            stateProvinceDropdown.disabled = true;
        } else {
            stateProvinceDropdown.disabled = false;
        }
    }, [currentCountry, stateProvinceList, countryList]);

    //#endregion Use Effect

    return (
        <CModal
            alignment='center'
            visible={visible}
            size='xl'
            onClose={() => setVisible(false)}
        >
            <CForm
                className='mt-3'
                onSubmit={OnAddContact}
                method='POST'
                noValidate
                validated={validated}
            >
                <CModalHeader closeButton={false}>
                    <CModalTitle>Add Contact</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CCard>
                        <CCardBody className='px-5'>
                            
                            <CRow className='mb-5'>
                                <CCol xs={12} sm={6}>
                                    <CRow>
                                        <CCol sm={12} md={6}>
                                            <CFormLabel className='required-label'>First Name</CFormLabel>
                                            <CFormInput
                                                type='text'
                                                name='FirstName'
                                                maxLength='75'
                                                feedbackInvalid='Required field'
                                                required
                                                />
                                        </CCol>
                                        <CCol sm={12} md={6} className=''>
                                            <CFormLabel className='mt-3 mt-md-0 required-label'>Last Name</CFormLabel>
                                            <CFormInput
                                                type='text'
                                                name='LastName'
                                                maxLength='75'
                                                feedbackInvalid='Required field'
                                                required
                                            />
                                        </CCol>
                                    </CRow>
                                    <CFormLabel className='mt-3'>Title</CFormLabel>
                                    <CFormInput type='text' name='Title' maxLength='75' />
                                    <CFormLabel className='mt-3'>Email Address</CFormLabel>
                                    <CFormInput type='text' name='EmailAddress' maxLength='150' />
                                    <CFormLabel className='mt-3'>Phone Number</CFormLabel>
                                    <CInputGroup>
                                        <CFormInput type='text' name='PhoneNumber' maxLength='16' />
                                        <div className='mx-3'></div>
                                        <CInputGroupText>ext</CInputGroupText>
                                        <CFormInput type='text' name='PhoneNumberExtension'maxLength='6' style={{ maxWidth: '30%'}} />
                                    </CInputGroup>
                                    <CFormLabel className='mt-3 required-label'>
                                        Contact Type
                                        <InfoModal modalTitle='Warning' modalContent={infoModalText.ContactEditType} />
                                    </CFormLabel>
                                    <CFormSelect name='Type' required feedbackInvalid='Required field'>
                                        <option value='Other'>Other</option>
                                        <option value='Primary'>Primary</option>
                                        <option value='Credit'>Credit</option>
                                    </CFormSelect>
                                    <CFormLabel className='mt-3'>Mobile Number</CFormLabel>
                                    <CFormInput type='text' name='MobilePhoneNumber' maxLength='16' />
                                    <CFormLabel className='mt-3'>Automated Email Frequency</CFormLabel>
                                    <CFormSelect name='AutomatedEmailFrequency'>
                                        <option></option>
                                        <option value='Never'>Never</option>
                                        <option value='Weekly'>Weekly</option>
                                        <option value='Bi-Weekly'>Bi-Weekly</option>
                                        <option value='Monthly'>Monthly</option>
                                        <option value='Bi-Monthly'>Bi-Monthly</option>
                                        <option value='Quarterly'>Quarterly</option>
                                        <option value='Semi-Annually'>Semi-Annually</option>
                                        <option value='Annually'>Annually</option>
                                    </CFormSelect>
                                </CCol>
                                <CCol xs={12} sm={6} className='mt-3 mt-sm-0'>
                                    {(countryList == null || stateProvinceList == null || currentCountry == null) ? null : <>
                                        <CFormLabel>Address</CFormLabel>
                                        <CFormInput type='text' name='AddressLine1' className='mb-3' maxLength='75' />
                                        <CFormInput type='text' name='AddressLine2'  maxLength='75' />
                                        <CRow>
                                            <CCol>
                                                <CFormLabel className='mt-3'>City</CFormLabel>
                                                <CFormInput type='text' name='City' maxLength='75' />
                                                <CFormLabel className='mt-3'>Province/State</CFormLabel>
                                                <CFormSelect
                                                    id='state-province-select'
                                                    name='StateProvinceID'
                                                >
                                                    {
                                                        currentCountry === '' ? null : 
                                                            stateProvinceList.map((stateProvince) => {
                                                                // Only map if country doesn't exist or the country matches
                                                                if (stateProvince.CountryID.toString() === currentCountry) {
                                                                    return (<option key={stateProvince.StateProvinceID} value={stateProvince.StateProvinceID}>{stateProvince.StateProvinceName}</option>)
                                                                }
                                                                return null;
                                                            })
                                                    }
                                                </CFormSelect>
                                            </CCol>
                                            <CCol>
                                                <CFormLabel className='mt-3'>Country</CFormLabel>
                                                <CFormSelect
                                                    id='country-select'
                                                    name='CountryID'
                                                    onChange={(e) => setCurrentCountry(e.target.value)}
                                                >
                                                    <option />
                                                    {
                                                        countryList.map((country) => {
                                                            return (<option key={country.CountryID} value={country.CountryID}>{country.CountryName}</option>)
                                                        })
                                                    }
                                                </CFormSelect>
                                                <CFormLabel className='mt-3'>Postal/Zip Code</CFormLabel>
                                                <CFormInput type='text' name='ZipPostalCode' maxLength='15' />
                                            </CCol>
                                        </CRow>
                                    </>}
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CModalBody>
                <CModalFooter>
                    <CButton color='secondary' style={{ width: '90px' }} className='me-3' onClick={() => setVisible(false)}>Cancel</CButton>
                    <CButton type='submit' color='success' style={{ width: '90px' }}>Add</CButton>
                </CModalFooter>
            </CForm>
        </CModal>
    );
}