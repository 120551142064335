import React, { useEffect, useRef, useState } from 'react';
import {
    GetCookie,
    FormatDate,
    GetErrorToastComponent,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    GetToastComponent,
    UpdateSWFetch,
} from '../../functions';
import { cookieNames, userRoles } from '../../constants';

import {
    CButton,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
    CTooltip,
} from '@coreui/react';
import Table from '../../components/table/table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalculator, faPen } from '@fortawesome/free-solid-svg-icons';

// Date 
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const headers = [
    {
        name: 'Ent At',
        colSort: true,
    },
    {
        name: '# Lanes',
        colSort: true,
    },
    {
        name: 'Tot Vol',
        colSort: true,
    },
    {
        name: '# Rounds',
        colSort: true,
    },
    {
        name: 'Sub Stat',
        colSort: true,
    },
    {
        name: 'Due Date',
        colSort: true,
    },
    {
        name: 'Outcome',
        colSort: true,
    },
    {
        name: 'Tot Won',
        colSort: true,
    },
    {
        name: 'Awd Ann Vol',
        colSort: true,
    },
    {
        name: 'Awd Date',
        colSort: true,
    },
    {
        name: 'Est Live',
        colSort: true,
    },
    {
        name: 'Notes',
        moreThanJustText: true,
    },
    {
        name: 'Button',
        headerClassName: 'text-white nav-buttons',
        moreThanJustText: true,
    }
];
const isAdmin = JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin; // checks if admin when page loads

/**
 * Displays the Formal RFPs for a company Id
 * @param {int} companyAccountId Id of company to get formal RFPs for
 * @param {useState} setToast used to set error toasts
 */
export default function FormalRfp({ companyAccountId, setToast }) {

    //#region Variables

    const [formalRFPs, setFormalRFPs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [validated, setValidated] = useState(false);

    // Dates
    const [dueDateValue, setDueDateValue] = useState();
    const [awardDateValue, setAwardDateValue] = useState();
    const [estGoLiveDateValue, setEstGoLiveDateValue] = useState();

    const [showRfpModifyModal, setShowRfpModifyModal] = useState(false);
    const [stage, setStage] = useState(1); // 1: Initial new Rfp, 2: Initial Rfp edit (only certain scenarios allow this), 3: Outcome Edit (only certain scenarios allow this)
    const [outcomeRequiredFields, setOutcomeRequiredFields] = useState({ // Used to disable or enable if field is required
        TotalLanesWon: true,
        AwardDate: true,
        EstimatedGoLiveDate: true,
        Notes: false,
    });
    const editInfo = useRef(null); // Values to fill modal input fields with when being edited in stage 2 or 3

    //#endregion Variables

    //#region Functions

    function AddOrEditInitialFormalRFP(e) {
        // The only valid options for this function
        if (stage !== 1 && stage !== 2) return;

        let formData = new FormData(e.target);
        formData.append('CompanyAccountID', companyAccountId);
        if (stage === 2) formData.append('FormalRfpID', editInfo.current.FormalRfpID);

        if (e.target.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        fetch('/api/formalrfp/add-or-edit-initial', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST',
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data.Errors.length !== 0) {
                    throw new Error(data.Errors);
                }

                setShowRfpModifyModal(false);
                GetFormalRFPs();
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(`Error: Could not ${stage === 1 ? 'add' : 'edit'} Formal RFP!`, err.message));
            });
    }

    function EditOutcomeFormalRFP(e) {
        if (stage !== 3) return;

        let formData = new FormData(e.target);
        formData.append('FormalRfpID', editInfo.current.FormalRfpID);

        if (e.target.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        fetch('/api/formalrfp/edit-outcome', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST',
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data.Errors.length !== 0) {
                    throw new Error(data.Errors);
                }

                setShowRfpModifyModal(false);
                GetFormalRFPs();
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not edit Formal RFP!', err.message));
            });
    }

    function GetFormalRFPs() {
        const ProcessData = (data) => {
            if (data == null) return;

            let todayTime = new Date().getTime();

            data.forEach(row => {
                let valueWithElements = {};

                // NOTE: Defaults should not be modified since they will be used to prefill edit pages

                // Fields for Initial
                row['Ent At'] = FormatDate(row.CreatedOn);

                row['# Lanes'] = row.NumberOfLanesInBid;

                row['Tot Vol'] = row.TotalVolume;

                row['# Rounds'] = row.NumberOfRounds;

                switch (row.RfpSubmissionStatus) {
                    case 'I':
                        row['Sub Stat'] = 'In Progress';
                        break;
                    case 'S':
                        row['Sub Stat'] = 'Submitted';
                        break;
                    default:
                        row['Sub Stat'] = row.RfpSubmissionStatus;
                }

                row['Due Date'] = FormatDate(row.DueDate);

                // Fields for Outcome
                row.OutcomeCode = row.Outcome; // Save the original to be used later
                switch (row.Outcome) {
                    case 'WL':
                        row.Outcome = 'Won Lanes';
                        break;
                    case 'NL':
                        row.Outcome = 'No Lanes Awarded';
                        break;
                    case 'NB':
                        row.Outcome = 'No Lanes Awarded, Granted Access to a Bid Board';
                        break;
                    case 'CX':
                        row.Outcome = 'Cancelled';
                        break;
                    case null:
                        row.Outcome = null;
                        break;
                    default:
                }

                row['Tot Won'] = row.TotalLanesWon;

                row['Awd Ann Vol'] = row.AwardedAnnualVolume;

                if (row.AwardDate != null)
                    row['Awd Date'] = FormatDate(row.AwardDate);
                else row['Awd Date'] = '';

                if (row.EstimatedGoLiveDate != null)
                    row['Est Live'] = FormatDate(row.EstimatedGoLiveDate);
                else row['Est Live'] = '';

                valueWithElements['Notes'] = row.Notes == null || row.Notes.length <= 10 ?
                    row.Notes : <CTooltip content={row.Notes}><span>{row.Notes.substring(0, 7)}...</span></CTooltip>;

                let createdOnTime = new Date(row.CreatedOn).getTime();
                let createdWithin7Days = (todayTime - createdOnTime) < 604800000; // Difference less than 7 days in ms
                let outcomeEnteredOnTime = new Date(row.OutcomeEnteredOn).getTime();
                let outcomeWithin7Days = row.OutcomeEnteredOn == null || (todayTime - outcomeEnteredOnTime) < 604800000;
                let editInitalDisabled = false;
                let editOutcomeDisabled = false;

                // Check if edit Initial is allowed
                if (!isAdmin) { // If admin, edit at any time permitted
                    // Initial can be edited for up to 7 days after marking Status as Submitted provided Outcome is not entered
                    if (row.Outcome != null || (row.RfpSubmissionStatus === 'S' && !createdWithin7Days)) editInitalDisabled = true;
                }

                // Check if edit Outcome is allowed
                if (!isAdmin) {
                    // Outcome can only be edited after Initial Status is marked Submitted
                    // Outcome can be edited for up to 7 days after an Outcome is entered
                    if (row.RfpSubmissionStatus !== 'S' || (row.Outcome != null && !outcomeWithin7Days)) editOutcomeDisabled = true;
                }

                valueWithElements['Button'] = <div className='d-flex'>
                    <CTooltip content='Edit Initial'>
                        <CButton
                            onClick={() => SetupModal(2, row)}
                            className='m-1'
                            disabled={editInitalDisabled}
                        >
                            <FontAwesomeIcon icon={faPen} />
                        </CButton>
                    </CTooltip>
                    <CTooltip content='Edit Outcome'>
                        <CButton
                            onClick={() => SetupModal(3, row)}
                            className='m-1'
                            color='success'
                            disabled={editOutcomeDisabled} // Only Submitted items can add Outcome
                        >
                            <FontAwesomeIcon icon={faCalculator} />
                        </CButton>
                    </CTooltip>
                </div>;

                row['ValueWithElements'] = valueWithElements;
            });

            return data;
        }

        // If already loading
        if (loading) {
            setToast(GetToastComponent('Already loading Formal RFPs'));
            return;
        }

        setLoading(true);

        let url = `/api/formalrfp/get-formal-rfps?companyId=${companyAccountId}`;

        UpdateSWFetch(url, (x) => setFormalRFPs(ProcessData(x)));

        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setFormalRFPs(ProcessData(data));
                setLoading(false);
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not get formal RFP!', err.message));
            });
    }

    /**
     * Will do necessary setups and show modal. Will work in conjunction with CModal onShow
     * since we need to make changes to input fields after modal is visible
     * @param {int} stageToChangeTo 1: Initial new Rfp, 2: Initial Rfp edit (only certain 
     * scenarios allow this), 3: Outcome Edit (only certain scenarios allow this)
     * @param {object} rfp Index of RFP to pull for. 
     * NOTE: this is only needed for stage 2 and 3 because editing and not adding new
     */
    function SetupModal(stageToChangeTo, rfp) {
        // Set info to prefill with
        editInfo.current = rfp;

        // TotalLanesWon, AwardDate, and EstimatedGoLiveDate mandatory for WL but not other statuses
        // Notes mandatory for all statuses except WL
        if (rfp != null && rfp.OutcomeCode === 'WL') {
            setOutcomeRequiredFields({
                TotalLanesWon: true,
                AwardDate: true,
                EstimatedGoLiveDate: true,
                Notes: false,
            });
        } else {
            setOutcomeRequiredFields({
                TotalLanesWon: false,
                AwardDate: false,
                EstimatedGoLiveDate: false,
                Notes: true,
            });
        }

        // Also set dates the dates
        setDueDateValue(rfp?.DueDate);
        setAwardDateValue(rfp?.AwardDate);
        setEstGoLiveDateValue(rfp?.EstimatedGoLiveDate);

        setStage(stageToChangeTo);
        setShowRfpModifyModal(true);
    }


    //#endregion Functions

    //#region Use Effect

    // Get Collection Details
    useEffect(() => {
        if (companyAccountId == null) return;

        GetFormalRFPs();
    }, [companyAccountId]);

    //#endregion Use Effect

    return <>
        <CModal alignment='center' size='xl' visible={showRfpModifyModal} onClose={() => setShowRfpModifyModal(false)}>
            <CForm
                onSubmit={(e) => {
                    e.preventDefault();

                    if (stage === 1 || stage === 2)
                        AddOrEditInitialFormalRFP(e);
                    else EditOutcomeFormalRFP(e);
                }}
                validated={validated}
            >
                <CModalHeader closeButton={false}>
                    <CModalTitle>Add RFP</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CRow>
                        <CCol lg={2} className='my-1'>
                            <CFormLabel className='required-label'># of Lanes in Bid</CFormLabel>
                            <CFormInput
                                type='number'
                                name='NumberOfLanesInBid'
                                required={stage === 1}
                                defaultValue={editInfo.current?.NumberOfLanesInBid}
                                disabled={stage === 3}
                            />
                        </CCol>
                        <CCol lg={2} className='my-1'>
                            <CFormLabel className='required-label'>Total Volume</CFormLabel>
                            <CFormInput
                                type='number'
                                name='TotalVolume'
                                required={stage === 1}
                                defaultValue={editInfo.current?.TotalVolume}
                                disabled={stage === 3}
                            />
                        </CCol>
                        <CCol lg={2} className='my-1'>
                            <CFormLabel className='required-label'># of Rounds</CFormLabel>
                            <CFormInput
                                type='number'
                                name='NumberOfRounds'
                                required={stage === 1}
                                defaultValue={editInfo.current?.NumberOfRounds}
                                disabled={stage === 3}
                                onChange={(e) => {
                                    // Show/hide tip
                                    if (parseInt(e.target.value) > 1) {
                                        document.getElementById('submission-status-tip').style.display = 'block';

                                        // Also set Submission Status to In Progress if empty
                                        let submissionInput = document.getElementById('input-rfp-submission-status');
                                        if (submissionInput.value === '') submissionInput.value = 'I';
                                    } else {
                                        document.getElementById('submission-status-tip').style.display = 'none';
                                    }
                                }}
                            />
                        </CCol>
                        <CCol lg={2} className='my-1'>
                            <CFormLabel className='required-label'>Submission Status</CFormLabel>
                            <CFormSelect
                                id='input-rfp-submission-status'
                                name='RfpSubmissionStatus'
                                required={stage === 1}
                                defaultValue={editInfo.current?.RfpSubmissionStatus}
                                disabled={stage === 3}
                            >
                                <option />
                                <option value='S'>Submitted</option>
                                <option value='I'>In Progress</option>
                            </CFormSelect>
                        </CCol>
                        <CCol lg={4} className='my-1'>
                            <CFormLabel className='required-label'>Due Date</CFormLabel>
                            <DatePicker
                                name='DueDate'
                                className='form-control'
                                value={dueDateValue}
                                onChange={setDueDateValue}
                                locale='en-ca'
                                format='yy-MM-dd'
                                maxDetail='month'
                                yearPlaceholder='yyyy'
                                monthPlaceholder='mm'
                                dayPlaceholder='dd'
                                required={stage === 1}
                                disabled={stage === 3}
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol>
                            <mark id='submission-status-tip' style={{ display: 'none' }}>
                                NOTE: <b>Submission Status</b> should be <b>In Progress</b> until all rounds are completed
                            </mark>
                        </CCol>
                    </CRow>
                    {stage === 3 && <>
                        <hr />
                        <CRow>
                            <CCol lg={9}>
                                <CRow>
                                    <CCol lg={4} className='my-1'>
                                        <CFormLabel className='required-label'>Outcome</CFormLabel>
                                        <CFormSelect
                                            name='Outcome'
                                            required
                                            defaultValue={editInfo.current?.OutcomeCode}
                                            onChange={(e) => {
                                                // TotalLanesWon, AwardDate, and EstimatedGoLiveDate mandatory for WL but not other statuses
                                                // Notes mandatory for all statuses except WL
                                                if (e.target.value === 'WL') {
                                                    setOutcomeRequiredFields({
                                                        TotalLanesWon: true,
                                                        AwardDate: true,
                                                        EstimatedGoLiveDate: true,
                                                        Notes: false,
                                                    });
                                                } else {
                                                    setOutcomeRequiredFields({
                                                        TotalLanesWon: false,
                                                        AwardDate: false,
                                                        EstimatedGoLiveDate: false,
                                                        Notes: true,
                                                    });
                                                }
                                            }}
                                        >
                                            <option />
                                            <option value='WL'>Won Lanes</option>
                                            <option value='NL'>No Lanes Awarded</option>
                                            <option value='NB'>No Lanes Awarded, Granted Access to a Bid Board</option>
                                            <option value='CX'>Cancelled</option>
                                        </CFormSelect>
                                    </CCol>
                                    <CCol lg={4} className='my-1'>
                                        <CFormLabel className={outcomeRequiredFields.TotalLanesWon ? 'required-label' : ''}>Total Lanes Won</CFormLabel>
                                        <CFormInput
                                            type='number'
                                            name='TotalLanesWon'
                                            defaultValue={editInfo.current?.TotalLanesWon}
                                            required={outcomeRequiredFields.TotalLanesWon}
                                        />
                                    </CCol>
                                    <CCol lg={4} className='my-1'>
                                        <CFormInput
                                            type='number'
                                            label='Awarded Annual Volume'
                                            name='AwardedAnnualVolume'
                                            defaultValue={editInfo.current?.AwardedAnnualVolume}
                                        />
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol lg={6} className='my-1'>
                                        <CFormLabel className={outcomeRequiredFields.AwardDate ? 'required-label' : ''}>Award Date</CFormLabel>
                                        <DatePicker
                                            name='AwardDate'
                                            className='form-control'
                                            value={awardDateValue}
                                            onChange={setAwardDateValue}
                                            locale='en-ca'
                                            format='yy-MM-dd'
                                            maxDetail='month'
                                            yearPlaceholder='yyyy'
                                            monthPlaceholder='mm'
                                            dayPlaceholder='dd'
                                            required={outcomeRequiredFields.AwardDate}
                                        />
                                    </CCol>
                                    <CCol lg={6} className='my-1'>
                                        <CFormLabel className={outcomeRequiredFields.EstimatedGoLiveDate ? 'required-label' : ''}>
                                            Est. Go Live Date
                                        </CFormLabel>
                                        <DatePicker
                                            name='EstimatedGoLiveDate'
                                            className='form-control'
                                            value={estGoLiveDateValue}
                                            onChange={setEstGoLiveDateValue}
                                            locale='en-ca'
                                            format='yy-MM-dd'
                                            maxDetail='month'
                                            yearPlaceholder='yyyy'
                                            monthPlaceholder='mm'
                                            dayPlaceholder='dd'
                                            required={outcomeRequiredFields.EstimatedGoLiveDate}
                                        />
                                    </CCol>
                                </CRow>
                            </CCol>
                            <CCol lg={3} className='my-1'>
                                <CFormLabel className={outcomeRequiredFields.Notes ? 'required-label' : ''}>Notes</CFormLabel>
                                <CFormTextarea
                                    name='Notes'
                                    defaultValue={editInfo.current?.Notes}
                                    style={{ height: '118px' }}
                                    maxLength={500}
                                    required={outcomeRequiredFields.Notes}
                                />
                            </CCol>
                        </CRow>
                    </>}
                </CModalBody>
                <CModalFooter>
                    <CButton color='secondary' variant='outline' onClick={() => setShowRfpModifyModal(false)}>Cancel</CButton>
                    <CButton color='success' type='submit' style={{ whiteSpace: 'nowrap' }}>{stage === 1 ? 'Add' : 'Edit'} RFP</CButton>
                </CModalFooter>
            </CForm>
        </CModal>
        <CButton className='mt-4 ms-3' onClick={() => SetupModal(1, null)}>Add New RFP</CButton>
        <Table
            headers={headers}
            body={formalRFPs}
            hasColSort={true}
            hasSearchRow={true}
            striped={false}
            tableClass='align-middle'
            isLoading={loading}
            placeholderProps={{ width: '50px' }}
        />
    </>;
}