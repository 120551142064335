import React, { useEffect, useState } from 'react';

import {
    CCardTitle,
    CCol,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CRow,
} from '@coreui/react';

import '../../style/edit.css';


/**
 * Address Edit Component for Contacts Edit.
 * Note: all fields must be populated before submitting passing them to AddressEdit so defaults set correctly
 * @param {any} param0
 * @returns Address edit component
 */
export default function AddressEdit({ addressLine1, addressLine2, city, stateProvinceId, zipPostalCode, countryList, stateProvinceList }) {
    const [currentCountry, setCurrentCountry] = useState(null);

    // Sets currentCountry on first load
    useEffect(() => {
        if (stateProvinceList == null || stateProvinceId == null) {
            setCurrentCountry('');
        } else {
            try {
                let countryId = stateProvinceList.find(({ StateProvinceID }) => StateProvinceID === stateProvinceId).CountryID;
                setCurrentCountry(countryId.toString());
            } catch {
                setCurrentCountry('');
            }
        }
    }, []);

    // Enables or disables the province/state dropdown depending on whether there is a country selected
    useEffect(() => {
        const countryDropdown = document.getElementById('country-select');
        const stateProvinceDropdown = document.getElementById('state-province-select');

        if (countryDropdown == null || stateProvinceDropdown == null) return;

        // If no value is selected for country
        if (countryDropdown.value === '') {
            stateProvinceDropdown.disabled = true;
        } else {
            stateProvinceDropdown.disabled = false;
        }

    }, [currentCountry]);

    return (<>
        <CCardTitle className='justify-content-between title-display-content'>
            Address
        </CCardTitle>
        <hr />

        <CRow className='mb-5'>
            <CCol>
                <CFormLabel className='mt-3 required-label'>Address</CFormLabel>
                <CFormInput type='text' name='AddressLine1' className='mb-3' defaultValue={addressLine1} maxLength='75' feedbackInvalid='Required field' required />
                <CFormInput type='text' name='AddressLine2' defaultValue={addressLine2} maxLength='75' />
                {stateProvinceList == null || countryList == null || currentCountry == null ? null :
                    <CRow>
                        <CCol>
                            <div>
                                <CFormLabel className='mt-3 required-label'>City</CFormLabel>
                                <CFormInput type='text' name='City' defaultValue={city} maxLength='75' feedbackInvalid='Required field' required />
                            </div>
                            <div>
                                <CFormLabel className='mt-3 required-label'>Province/State</CFormLabel>
                                <CFormSelect id='state-province-select' defaultValue={stateProvinceId} name='StateProvinceID' feedbackInvalid='Required field' required>
                                    {currentCountry === '' ? null :
                                        stateProvinceList.map((stateProvince) => {
                                            // Only map if the country matches
                                            if (stateProvince.CountryID.toString() === currentCountry) {
                                                return (<option key={stateProvince.StateProvinceID} value={stateProvince.StateProvinceID}>{stateProvince.StateProvinceName}</option>)
                                            }
                                            return null;
                                        })}
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol>
                            <div>
                                <CFormLabel className='mt-3 required-label'>Country</CFormLabel>
                                <CFormSelect id='country-select' defaultValue={currentCountry} name='CountryID' onChange={(e) => setCurrentCountry(e.target.value)} feedbackInvalid='Required field' required>
                                    <option />
                                    {countryList.map((country) => {
                                            return (<option key={country.CountryID} value={country.CountryID}>{country.CountryName}</option>)
                                        })
                                    }
                                </CFormSelect>
                            </div>
                            <div>
                                <CFormLabel className='mt-3 required-label'>Postal/Zip Code</CFormLabel>
                                <CFormInput type='text' name='ZipPostalCode' defaultValue={zipPostalCode} maxLength='15' feedbackInvalid='Required field' required />
                            </div>
                        </CCol>
                    </CRow>
                }
            </CCol>
        </CRow>
    </>);
}