import { GetBearer, HandleResponseReturn } from './functions';

/**
 * Assigns the current user as the assigned to user of request
 * @param {int} requestId Id of request to assign to user
 * @returns {bool} Successful assign or not
 */
async function AssignCurrentUserAsOwner(requestId) {
    if (typeof (requestId) !== 'number') throw new Error('Expected Int');

    const response = await fetch(`/api/credit/assign-to-me?requestId=${requestId}`, {
        headers: { 'Authorization': 'Bearer ' + GetBearer() },
        method: 'POST'
    });
    const data = await HandleResponseReturn(response, true);

    return data.ok;
}

export {
    AssignCurrentUserAsOwner
}