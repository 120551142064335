import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { cookieNames, userRoles } from '../../constants';
import { FormatDate, GetBearer, GetCookie, GetErrorToastComponent, HandleDataReturn, HandleResponseReturn } from '../../functions';

import {
    CButton,
    CFormSelect,
    CInputGroup,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
} from '@coreui/react'

// Typeahead
import { Menu, AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

/**
 * Top Typeahead search bar searching for companies. Only credit can view deleted companies, 
 * everyone else will see leads/accounts that are active
 * @param {useStaete} setToast Used when search encounters an error
 */
export default function SearchBar({ setToast }) {

    //#region Variables

    // Typeahead Search
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const userId = useRef(parseInt(JSON.parse(GetCookie(cookieNames.userID))));
    const userRole = useRef(JSON.parse(GetCookie(cookieNames.userRole)));
    // Abort mapping
    const abortController = new useRef(null); // Stores AbortController()

    // Component Ref
    const searchType = useRef();

    const nav = useNavigate();

    //#endregion Variables

    //#region Functions

    /**
     * Runs search to find the companies associated and stores it in search results
     * @param {string} query Search term
     */
    async function onSearch(query) {
        // If there is pending fetch, abort
        if (abortController.current != null) {
            abortController.current.abort();
        }

        // Don't search if empty spaces
        var trimmedQuery = query.trim();
        if (trimmedQuery === '' || trimmedQuery.length < 2) return;

        setLoadingSearch(true);

        // Advanced search will not show up initially
        let searchData = {
            'SearchType': searchType.current.value,
            'SearchText': trimmedQuery,
            'AdvancedSearch': true // Removed advanced search toggle so default true now
        };

        // New abort controller for current fetch
        abortController.current = new AbortController();

        fetch('/api/Search', {
            headers: {
                'Authorization': 'Bearer ' + GetBearer(),
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(searchData),
            signal: abortController.current.signal
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data.length === 0) {
                    setSearchResults([]);
                }

                setSearchResults(data);
                setLoadingSearch(false);

                abortController.current = null; // Clear the no longer needed abort
            })
            .catch(err => {
                setLoadingSearch(false);

                if (err.name === 'AbortError') return;
                
                setToast(GetErrorToastComponent("An error has occurred while searching!", err.message));
                console.error(err);
            });
    }

    /**
     * When a row is clicked, allow navigation to corresponding profile
     * @param {int} id CompanyAccountID or CompanyLeadID 
     * @param {int} type What content type it is. 1: Account, 2: Lead
     */
    function onSearchRowClick(id, type) {
        switch (type) {
            case 1: // Account
                nav(`/company-accounts/${id}/profile`);
                break;
            case 2: // Lead
                nav(`/company-leads/${id}/profile`);
                break;
            default:
                console.log('Type was not an expected value. Was ' + type);
                break;
        }
    }

    /**
     * Typeahead custom menu dropdown
     * @param {obj[]} results The results found from search
     * @param {obj} state props regarding Typeahead state
     * @returns Component
     */
    function MenuDropdownComponent(results, state) {
        return <Menu id='search-dropdown' maxHeight='500px' className='search-menu p-0' style={{ top: '0' }}>
            {loadingSearch ?
                <div style={{ textAlign: 'center', padding: '15px 0px' }}>Searching...</div>
                :
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'white',
                        position: 'sticky',
                        padding: '15px 30px',
                    }}
                >
                    {results.length === 0 ?
                        <span className='pt-1'>
                            No matching results were found. Please try again with a different keyword
                        </span>
                        :
                        <span className='fs-5'>
                            {results.length < 100 ?
                                <><span className='fw-medium'>{results.length}</span> Results Found</>
                                :
                                <>More than <span className='fw-medium'>100</span> results found</>
                            }
                        </span>
                    }
                    <CButton
                        color='secondary'
                        onClick={() => {
                            nav('/search-list', { state: { searchResults: searchResults, searchText: state.text } });
                            state.hideMenu();
                        }}
                        disabled={results.length === 0}
                    >
                        See All Results
                    </CButton>
                </div>
            }
            {/* Table Results */}
            {loadingSearch || !results.length === 0 ? null :
                <CTable responsive hover>
                    <CTableHead>
                        <CTableRow>
                            <CTableHeaderCell scope='col'>Type</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>Name</CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='d-none d-lg-table-cell'>TMW ID</CTableHeaderCell>
                            <CTableHeaderCell scope='col' className='d-none d-lg-table-cell'>Last Sale</CTableHeaderCell>
                            <CTableHeaderCell scope='col'>Owner</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                        {results.map((option, i) => {
                            // Formatting 
                            let formattedSalesDate
                            if (option.LastSale != null) {
                                let lastSaleDate = new Date(option.LastSale);
                                formattedSalesDate = FormatDate(lastSaleDate);
                            }
                            return <CTableRow
                                key={`${i}-row`}
                                value={option.ID}
                                onClick={() => {
                                    // For Accounts, can navigate only if user is admin, owns account, or the account is owned by 'Available'
                                    // Logic done by disabling pointer events if not admin, owner, or account belongs to 'Available' below
                                    onSearchRowClick(option.ID, option.Type);
                                    state.hideMenu(); // Hide the dropdown menu
                                }}
                                style={{
                                    pointerEvents:
                                        option.Type === 1
                                            && userRole.current !== userRoles.Admin // User is not admin or credit
                                            && userRole.current !== userRoles.Credit
                                            && option.AccountOwnerID != userId.current // User is not owner
                                            && option.AccountOwner !== 'Available' // Account is not Available account
                                            ? 'none' : 'all'
                                }}
                            >
                                {/* Different Types to show */}
                                {option.Type === 1 ? <CTableDataCell key={`${i}-cell-type`}>
                                    {option.AccountDeleted ? <span className='fw-semibold'>Deleted </span> : null}
                                    Account - {option.EngagementType}
                                    </CTableDataCell> : null
                                }
                                {option.Type === 2 && <CTableDataCell key={`${i}-cell-type`}>Lead</CTableDataCell>}

                                <CTableDataCell key={`${i}-cell-name`}>{option.CompanyName}</CTableDataCell>
                                <CTableDataCell key={`${i}-cell-tmwId`} className='d-none d-lg-table-cell'>{option.TMWCustomerID}</CTableDataCell>
                                <CTableDataCell key={`${i}-cell-last-sale`} className='d-none d-lg-table-cell'>{formattedSalesDate}</CTableDataCell>
                                <CTableDataCell key={`${i}-cell-owner`} className=''>{option.AccountOwner}</CTableDataCell>
                            </CTableRow>
                        })}
                    </CTableBody>
                </CTable>
            }
        </Menu>;
    }

    //#endregion Functions

    return (<form className='m-2' onSubmit={(e) => onSearch(e)} style={{ width: '45%', maxWidth: '750px' }}>
        <CInputGroup>
            <CFormSelect
                ref={searchType}
                id='searchType'
                name='searchType'
                aria-label='Search Type'
                style={{ maxWidth: '125px' }}
                onChange={() => {
                    let query = document.querySelector('.header .rbt input.rbt-input-main').value;
                    onSearch(query); // Trigger search again with current search query
                }}
            >
                <option value='All'>All</option>
                <option value='Account'>Account</option>
                <option value='Lead'>Lead</option>
            </CFormSelect>
            <AsyncTypeahead
                id='searchText'
                options={searchResults}
                isLoading={loadingSearch}
                searchText='Searching...'
                emptyLabel='No matching results were found. Please try again with a different keyword'
                filterBy={() => true}
                useCache={false}
                minLength={2}
                onSearch={query => onSearch(query)}
                labelKey={option => option.CompanyName}
                renderMenu={(results, _menuProps, state) => MenuDropdownComponent(results, state)}
            />
        </CInputGroup>
    </form>)
}