import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    cookieNames,
    engagementTypeName,
    GetErrorMessages,
    infoModalText,
    localStoreNames,
    userRoles,
} from '../../constants';
import {
    GetCookie,
    GetPlaylists,
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    GetSetting,
    SetSetting,
    FormatDate,
    NavWithNewTab,
    UpdateSWFetch,
} from '../../functions';
import {
    ClearChecks,
    OnCheckChange,
    UpdateChecks
} from '../../checkFunctions';

import {
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCol,
    CDropdown,
    CDropdownDivider,
    CDropdownHeader,
    CDropdownItem,
    CDropdownItemPlain,
    CDropdownMenu,
    CDropdownToggle,
    CFormCheck,
    CFormLabel,
    CFormSelect,
    CHeader,
    CHeaderText,
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CToaster,
    CTooltip
} from '@coreui/react';

import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faAddressCard, faArrowRight, faClipboardList, faFile, faGreaterThan, faGripLinesVertical, faPen, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Table, { ClearSearchFilters, GetSearchParameters, UpdateSearchInTable } from '../../components/table/table';

import ChangeColumnsModal from '../../components/company/changeColumnsModal';
import ConfirmModal from '../../components/miscellaneous/confirmModal';
import InfoModal from '../../components/miscellaneous/infoModal';
import TodoModal from '../../components/todo/todoModal';
import AddToPlaylistModal from '../../components/company/AddToPlaylistModal';
import SetSearchFilterNameModal from '../../components/miscellaneous/setSearchFilterNameModal';
import RemoveSearchFilterModal from '../../components/miscellaneous/removeSearchFilterModal';
import ImportFromZoomInfoExcelModal from '../../components/lead/ImportFromZoomInfoExcelModal';

import '../../style/account.css';

const dayInMilliseconds = 86400000; // A day in milliseconds
const sales = 'Sales';
const leads = 'Leads';
const defaultHeadersToShow = {
    'Name': true,
    'Address': true,
    'Contact': true,
    'Company Grade': true,
    'Industry': true,
    'Source': true,
    'Description': false,
    'Last Modified': true,
    'Engagement Type': true,
    'Days In Current Engagement': true,
    'Days Since Creation': false,
    'Quote Type': false,
    'Annual Revenue': false,
    'More Info': false,
    'Primary Contact': false,
    'Account Owner': false,
    'Credit Status': false,
    'TMW Id': false,
};

// Prospect and Customer Account Page
// accountype (string): should be 'Customer' or 'Prospect'; specifies type of page
export default function Account() {

    //#region Variables
    const isAdmin = useRef(JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin); // checks if admin when page loads

    const [accounts, setAccounts] = useState(); // list with formatted elements of accounts
    const [showAllAccounts, setShowAllAccounts] = useState(isAdmin.current); // Show all accounts by default if admin 
    const ownedAccounts = useRef(null); // list of all accounts owned by user (loaded on initial load)
    const allAccounts = useRef(null); // list of all accounts (loaded for admin if 'All Prospects' or 'All Customers' button is clicked)

    const [salesList, setSalesList] = useState([]); // List of salespersons
    const [selectedSales, setSelectedSales] = useState([]); // Salesperson selected from dropdown. None selected will display current message
    const [showChangeColumnsModal, setShowChangeColumnsModal] = useState(false); // Sets show or hide for the columns displayed modal
    const [loading, setLoading] = useState(true); // is page loading (true means show skeleton table)
    const [activeTab, setActiveTab] = useState('All'); // sets which tab is active
    const [showReassignButtons, setShowReassignButtons] = useState(false); // Show/hide buttons that reassign prospects in All Accounts tab
    const [checkToggle, setCheckToggle] = useState('none') // Used to select all/none of the rows. Valid states are all, page, or none

    // Playlist
    const [playlists, setPlaylists] = useState(); // all the playlists for current user
    const [selectedPlaylistId, setSelectedPlaylistId] = useState();

    // Items used in todo and book meeting modals
    const [companyId, setCompanyId] = useState(); // Company Id of button clicked used to book meeting and create todo
    const [companyName, setCompanyName] = useState(); // Company Name to show on modal

    // Checkbox
    const checkedIds = useRef([]); // Array of checked elements used to keep track across page changes
    const filteredTableIds = useRef([]); // Array of ids present after searching (used in select all)

    // Confirm modal for reassigning prospects to another sales or back to leads
    const [showConfirmModal, setShowConfirm] = useState(false); // Sets show or hide for ConfirmModal
    const [convertTo, setConvertTo] = useState(null); // Specifies what to convert selected prosects to; 'Leads' or 'Sales'

    // Todo Modal 
    const [showTodoModal, setShowTodoModal] = useState(false); // Sets show or hide todo modal

    // Add to Playlist modal
    const [showAddToPlaylistModal, setShowPlaylistModal] = useState(false); // Sets show or hide playlist modal
    const [accountsSelected, setAccountsSelected] = useState(); // The companies selected to be added 

    // Delete playlist item Confirm Modal
    const [showDeleteItemsConfirmModal, setShowDeleteItemsConfirmModal] = useState(false);
    const [deleteItemsModalTitle, setDeleteItemsTitle] = useState();
    const [deleteItemsModalMessage, setDeleteItemsMessage] = useState();

    // Add Prospect From ZoomInfo Excel
    const [showZoomInfoImportModal, setShowZoomInfoImportModal] = useState(false);

    // Search Filter, Set Search Filter Name Modal, Remove Search Filter Modal
    const [searchFilters, setSearchFilters] = useState([]);
    const newSearchFilterName = useRef();
    const [showSetFilterNameModal, setShowSetFilterNameModal] = useState(false);
    const searchFilterIndex = useRef(null);
    const [showRemoveFilterModal, setShowRemoveFilterModal] = useState(false);

    // Element Refs
    const playlistTitle = useRef();

    // Default settings
    const loadedSettings = useRef(false); // Ensures setting updates only happen after initial load
    const saveSettingTimeoutId = useRef(); // Used with useTimeout()

    const abortController = new useRef(); // Stores AbortController()

    const navigate = useNavigate();
    const formatter = new Intl.NumberFormat('en-CA', { // Currency formatter used in Annual Revenue field
        style: 'currency',
        currency: 'CAD',
    });
    const [toast, setToast] = useState();
    const prospectTypes = Object.keys(engagementTypeName).splice(4);
    const [headersToShow, setHeadersToShow] = useState(defaultHeadersToShow);
    const headers = [
        {
            name: 'Check',
            moreThanJustText: true,
            headerClassName: 'text-light nav-buttons',
            columnClass: 'button-center',
        },
        {
            name: 'Name',
            show: headersToShow['Name'],
            headerClassName: 'name',
            colSort: true,
            moreThanJustText: true,
            hasSearch: true,
        },
        {
            name: 'Address',
            show: headersToShow['Address'],
            headerClassName: 'address',
            moreThanJustText: true,
            hasSearch: true,
        },
        {
            name: 'Contact',
            show: headersToShow['Contact'],
            headerClassName: 'contact',
            moreThanJustText: true,
            hasSearch: true,
        },
        {
            name: 'Company Grade',
            show: headersToShow['Company Grade'],
            headerClassName: 'company-grade',
            hasSearch: true,
        },
        {
            name: 'Industry',
            show: headersToShow['Industry'],
            headerClassName: 'industry',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Source',
            show: headersToShow['Source'],
            headerClassName: 'source',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Description',
            show: headersToShow['Description'],
            headerClassName: 'description',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Last Modified',
            show: headersToShow['Last Modified'],
            headerClassName: 'last-modified',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Engagement Type',
            show: headersToShow['Engagement Type'],
            headerClassName: 'engagement-type',
            colSort: true,
        },
        {
            name: 'Days In Current Engagement',
            show: headersToShow['Days In Current Engagement'],
            headerClassName: 'days-in-current-engagement',
            colSort: true,
            moreThanJustText: true,
        },
        {
            name: 'Days Since Creation',
            show: headersToShow['Days Since Creation'],
            headerClassName: 'days-since-creation',
            colSort: true,
            moreThanJustText: true,
        },
        {
            name: 'Quote Type',
            show: headersToShow['Quote Type'],
            headerClassName: 'quote-type',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Annual Revenue',
            show: headersToShow['Annual Revenue'],
            headerClassName: 'annual-revenue',
            colSort: true,
            hasSearch: true,
            moreThanJustText: true,
        },
        {
            name: 'More Info',
            show: headersToShow['More Info'],
            headerClassName: 'more-info',
            hasSearch: true,
            moreThanJustText: true,
        },
        {
            name: 'Primary Contact',
            show: headersToShow['Primary Contact'],
            headerClassName: 'primary-contact',
            moreThanJustText: true,
        },
        {
            name: 'Account Owner',
            show: headersToShow['Account Owner'],
            headerClassName: 'account-owner',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Credit Status',
            show: headersToShow['Credit Status'],
            headerClassName: 'credit-status',
            hasSearch: true,
            moreThanJustText: true,
        },
        {
            name: 'TMW Id',
            show: headersToShow['TMW Id'],
            headerClassName: 'tmw-customer-id',
            hasSearch: true,
        },
        {
            name: 'Button',
            headerClassName: 'text-light nav-buttons',
            moreThanJustText: true,
            columnClass: 'button-center',
        },
    ];

    //#endregion Variables

    //#region Functions

    //#region Get Accounts

    /**
     * Get all company accounts
     * @param {bool} showAll true gets all accounts of type specified. false gets all accounts of type owned by user
     * Will still cache the data fetched after
     */
    async function GetAccounts(showAll) {
        setLoading(true);
        abortController.current = new AbortController();
        const response = await fetch('/api/companyaccounts/get-all-accounts?showAll=' + showAll, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET',
            signal: abortController.current.signal
        });

        var data = await HandleResponseReturn(response);
        data = HandleDataReturn(data);

        var result = ProcessData(data);
        setLoading(false);

        return result;
    }

    /**
     * Do all the formatting and custom elements display here before passing to table
     * @param {obj[]} data
     * @returns Formatted data for table in obj[]
     */
    function ProcessData(data) {
        if (data == null) return;

        //#region Table Data Formatting

        data.forEach(row => {
            var valueWithElements = {};

            // Checkbox that can be used for various things
            valueWithElements['Check'] = <CFormCheck
                id={row.CompanyAccountID + '-check'}
                onChange={() => OnPageCheckChange(row.CompanyAccountID)}
                style={{ height: '22px', width: '22px' }}
            />;

            // Name
            // Format Company Name to include last modified by
            let lastModifiedUserName;

            if (typeof (row.LastModifiedUserName) === 'string') lastModifiedUserName = row.LastModifiedUserName.trim();

            row['Name'] = row.CompanyName;
            row.CompanyName = undefined;

            valueWithElements['Name'] = <>
                {row['Name'].trim()}
                <br />
                <p className='fw-lighter fst-italic small-text'>
                    {lastModifiedUserName == null || lastModifiedUserName === '' ? null :
                        <>
                            Last Modified By: {lastModifiedUserName}
                        </>
                    }
                </p>
            </>;

            // Company Address
            row['Address'] =
                row.AddressLine1 + ' ' + row.AddressLine2 + ' ' + row.City + ' ' + row.StateProvinceCode + ' '
                + row.StateProvinceName + ' ' + row.CountryName + ' ' + row.ZipPostalCode;

            let addressLine1 = row.AddressLine1 == null ?
                null
                : <>{row.AddressLine1}<br /></>;
            let addressLine2 = row.City == null && row.StateProvinceCode == null ?
                null
                : <>{row.City} {row.StateProvinceCode}<br /></>;
            let addressLine3 = row.CountryName == null && row.ZipPostalCode == null ?
                null
                : <>{row.CountryName} {row.ZipPostalCode}</>;

            // Delete unformated raw address values
            row.AddressLine1 = undefined;
            row.AddressLine2 = undefined;
            row.City = undefined;
            row.StateProvinceCode = undefined;
            row.StateProvinceName = undefined;
            row.CountryName = undefined;
            row.ZipPostalCode = undefined;

            valueWithElements['Address'] =
                <>
                    {addressLine1}
                    {addressLine2}
                    {addressLine3}
                </>;

            // Format Contacts tab
            if (headersToShow['Contact']) {
                row['Contact'] = row.PhoneNumber + row.PhoneNumberExtension + row.FaxNumber;

                let phoneNum = row.PhoneNumber == null || row.PhoneNumber.trim() === '' ?
                    null
                    :
                    <>
                        Phone: <a href={'tel:' + row.PhoneNumber} className='no-link-decor'>{row.PhoneNumber}</a>
                        {row.PhoneNumberExtension == null ? null : ' x' + row.PhoneNumberExtension}
                    </>
                let faxNum = row.FaxNumber == null ? null : <>Fax: {row.FaxNumber}</>;

                row.PhoneNumber = undefined;
                row.PhoneNumberExtension = undefined;
                row.FaxNumber = undefined;

                valueWithElements['Contact'] =
                    <>
                        {phoneNum}
                        {phoneNum == null || faxNum == null ? null : <br />}
                        {faxNum}
                    </>;
            }

            // Company Grade
            row['Company Grade'] = row.CompanyGrade;
            row.CompanyGrade = undefined;

            // Industry
            row['Industry'] = row.Industry ?? '';

            // Last Modified Time
            let date = new Date(row.LastModificationTime);
            row['Last Modified'] = FormatDate(date);
            row.LastModificationTime = undefined;

            // Engagement Type
            row['Engagement Type'] = row.EngagementType;
            row.EngagementType = undefined;

            // Quote Type
            row['Quote Type'] = row.QuoteType;
            row.QuoteType = undefined;

            // Days In Current Engagement
            let now = new Date(); // Current time
            let engagementTypeStartDate = new Date(row.EngagementTypeLastModificationTime);
            let daysAsCurrentType = Math.round((now.getTime() - engagementTypeStartDate.getTime()) / dayInMilliseconds);

            row['Days In Current Engagement'] = FormatDate(engagementTypeStartDate);
            valueWithElements['Days In Current Engagement'] = <>
                {daysAsCurrentType}
                <br />
                <p className='fw-lighter fst-italic small-text'>
                    Since: {row['Days In Current Engagement']}
                </p>
            </>;

            row.EngagementTypeLastModificationTime = undefined;

            // Days Since Creation
            let creationDate = new Date(row.CreationTime);
            let daysSinceCreation = Math.round((now.getTime() - creationDate.getTime()) / dayInMilliseconds);
            row['Days Since Creation'] = FormatDate(creationDate);
            valueWithElements['Days Since Creation'] = <>
                {daysSinceCreation}
                <br />
                <p className='fw-lighter fst-italic small-text'>
                    Since: {row['Days Since Creation']}
                </p>
            </>;

            row.CreationTime = undefined;

            // Annual Revenue
            row['Annual Revenue'] = row.AnnualRevenue;
            valueWithElements['Annual Revenue'] = row.AnnualRevenue ? formatter.format(row.AnnualRevenue) : '';
            row.AnnualRevenue = undefined;

            // More Info
            row['More Info'] = row.WebsiteURL + row.NumberOfEmployees + row.ParentCompany;

            let website = row.WebsiteURL == null || row.WebsiteURL.trim() === '' ? null : <><div className='fw-semibold'>Website: </div>{row.WebsiteURL}<br /></>;
            let numEmployees = row.NumberOfEmployees == null ? null : <><div className='fw-semibold'># of Employees: </div>{row.NumberOfEmployees}<br /></>;
            let parentCompany = row.ParentCompany == null ? null : <><div className='fw-semibold'>Parent Company: </div>{row.ParentCompany}</>;

            row.WebsiteURL = undefined;
            row.NumberOfEmployees = undefined;
            row.ParentCompany = undefined;

            valueWithElements['More Info'] =
                <div className='small-text'>
                    {website}
                    {numEmployees}
                    {parentCompany}
                </div>;

            // Primary Contact
            row['Primary Contact'] = (row.PrimaryContactName == null ? '' : row.PrimaryContactName.trim()) + (row.PrimaryContactPhone == null ? '' : row.PrimaryContactPhone.trim());
            valueWithElements['Primary Contact'] = <div>
                {row.PrimaryContactName != null && row.PrimaryContactName.trim() !== '' && <>Name: {row.PrimaryContactName}<br /></>}
                {row.PrimaryContactPhone != null && row.PrimaryContactPhone.trim() !== '' && <>Phone: <a href={'tel:' + row.PrimaryContactPhone} className='no-link-decor'>{row.PrimaryContactPhone}</a><br /></>}
            </div>;                
            row.PrimaryContactName = undefined;
            row.PrimaryContactPhone = undefined;

            // Account Owner
            row['Account Owner'] = row.OwnerName ?? '';
            row.OwnerName = undefined;

            // Credit Status
            row['Credit Status'] = row.CreditContactName + row.CreditApplicationReceivedFlag + row.CreditApplicationApprovedAmount + row.CreditApplicationAvailableAmount;

            let creditContact = row.CreditContactName == null || row.CreditContactName.trim() === '' ? null : <><div className='fw-semibold'>Contact: </div>{row.CreditContactName}</>;
            let creditAppReceived = <>
                <div className='fw-semibold'>App Recvd: </div>
                {row.CreditApplicationReceivedFlag ? <FontAwesomeIcon icon={faCheckSquare} /> : <FontAwesomeIcon icon={faSquare} />}
            </>;
            let creditAppApprvdAmt = <><div className='fw-semibold'>Apprvd Amt: </div>{formatter.format(row.CreditApplicationApprovedAmount)}</>;
            let creditAppAvalAmt = <><div className='fw-semibold'>Aval Amt: </div>{formatter.format(row.CreditApplicationAvailableAmount)}</>;

            row.CreditContactName = undefined;
            row.CreditApplicationReceivedFlag = undefined;
            row.CreditApplicationApprovedAmount = undefined;
            row.CreditApplicationAvailableAmount = undefined;

            valueWithElements['Credit Status'] =
                <div className='small-text'>
                    {creditContact}
                    {creditAppReceived}
                    {creditAppApprvdAmt}
                    {creditAppAvalAmt}
                </div>;

            // TMW Customer Id
            row['TMW Id'] = row.TMWCustomerId;
            row.TMWCustomerId = undefined;

            // Add the buttons
            valueWithElements['Button'] = <>
                <CRow>
                    <CCol className='button-col-format'>
                        <CTooltip content='Profile'>
                            <CButton onClick={(event) => NavWithNewTab(event, navigate, '/company-accounts/' + row.CompanyAccountID + '/profile')}>
                                <FontAwesomeIcon icon={faAddressCard} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                    <CCol className='button-col-format'>
                        <CTooltip content='Edit'>
                            <CButton
                                onClick={(event) =>
                                    NavWithNewTab(event, navigate, '/company-accounts/' + row.CompanyAccountID + '/edit')
                                }
                                color='warning'
                            >
                                <FontAwesomeIcon icon={faPen} style={{ color: 'white' }} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                    <CCol className='button-col-format'>
                        <CTooltip content='Create Todo'>
                            <CButton color='secondary' onClick={() => {
                                setShowTodoModal(true);
                                setCompanyId(row.CompanyAccountID);
                                setCompanyName(row.Name);
                            }}>
                                <FontAwesomeIcon icon={faClipboardList} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                </CRow>
            </>;

            // Append the custom formatted elements
            row['ValueWithElements'] = valueWithElements;
        })

        //#endregion Table Data Formatting

        return data;
    }

    //#endregion Get Accounts

    //#region Tab Change

    /**
     * When a new tab is clicked, show the corresponding filters for each step of the prospect stage
     * Or show accounts by playlist
     * This function is only used in Prospect page
     * @param {any} item
     * @returns
     */
    function OnTabChange(item) {
        if (showAllAccounts == null || loading || activeTab === item) return;

        // Reset the checked boxes
        ClearPageChecks();

        // Changing to a different tab
        if (activeTab === 'Playlists') {
            playlistTitle.current.innerText = ''; // Clear title 
        }

        // If currently active pipeline, remove 'active-pipeline-active' className
        if (activeTab === 'Active Pipeline') {
            document.getElementById('prospect-navigation').classList.remove('active-pipeline-active');
        } else if (item === 'Active Pipeline') { // Changing to active pipeline tab
            document.getElementById('prospect-navigation').classList.add('active-pipeline-active');
        }

        // If currently active pipeline, remove 'active-pipeline-active' className
        if (activeTab === 'Prospect') {
            document.getElementById('prospect-navigation').classList.remove('prospect-active');
        } else if (item === 'Prospect') { // Changing to active pipeline tab
            document.getElementById('prospect-navigation').classList.add('prospect-active');
        }

        // Show playlist items
        if (item === 'Playlists') {
            // If there are no active playlists don't change tabs
            if (playlists == null || playlists.length === 0) {
                setToast(GetToastComponent('There are no playlists to show'));
                return;
            }

            // If select all is toggled, then remove the toggle

            FilterByPlaylist(); // Show the first item in playlists 
        } else { // All other options
            // If no filter set, should be null
            let salesFilter = selectedSales.length === 0 ? null : selectedSales[0].DisplayName;

            // Get the filtered results
            let filteredResults = Filter(item, salesFilter);

            setAccounts(filteredResults); // Set the filtered results
        }

        // Set the new active tab
        setActiveTab(item);
    }

    //#endregion Tab Change

    //#region Toggle All/Current Account Display

    /**
     * Toggle between All and Current user accounts
     * Note: toggle only available to admin since sales can only view their own accounts
     * @param {bool} showAll true is show all accounts, false is show current user's accounts
     */
    function ToggleAccountDisplayTypes(showAll) {
        setShowAllAccounts(showAll);
        setSelectedSales([]); // Also unset selected sales filter
        setShowReassignButtons(false); // Cancel reassign prospects if showing
        ClearPageChecks();
        OnTabChange('All'); // Set tab to 'All'
    }

    //#endregion Toggle All/Current Account Display

    //#region Filtering

    /**
     * Filter by engagement type, will also determine whether to use all or owned accounts list
     * @param {string} type Engagement type to filter by, 'All' means no filter
     * @param {string} salesUser Optional filter by sales which is the Account Owner field. 
     * A null value will display all sales (only available for admin)
     * @param {int[]} accountIds Accounts ids to show. Overrides everything else and shows only accounts provided if non-empty array passed
     * @returns Filtered accounts
     */
    function Filter(type, salesUser, accountIds) {
        let data;

        // Determine which set of data to use
        if (showAllAccounts) data = allAccounts.current;
        else data = ownedAccounts.current;

        if (accountIds != null) { // override with account ids
            // Return only accounts with in accountIds list (used for playlists)
            return data.filter(company => {
                return accountIds.includes(company['CompanyAccountID']);
            });

        } else if (type === 'All') { // Show customers and prospects
            return data.filter(company => {
                // Filter specific sales user or show all if no sales passed
                return salesUser == null || salesUser === company['Account Owner'];
            });

        } else if (type === engagementTypeName.Customer) {
            return data.filter(company => {
                // Filter specific sales user or show all if no sales passed
                return ((engagementTypeName.Customer === company['Engagement Type']) && (salesUser == null || salesUser === company['Account Owner']));
            });

        } else if (type === 'Active Pipeline') {
            return data.filter(company => {
                // Filter prospect types by building interest onwards
                // and filter specific sales user or show all if no sales passed
                return (
                    company['Engagement Type'] === engagementTypeName.BuildingInterest ||
                    company['Engagement Type'] === engagementTypeName.DiscoveryMeeting ||
                    company['Engagement Type'] === engagementTypeName.RfpAndQuote ||
                    company['Engagement Type'] === engagementTypeName.PresentationAndNegotiate ||
                    company['Engagement Type'] === engagementTypeName.FirstLoadAndImplementation
                ) && (salesUser == null || salesUser === company['Account Owner'])
            });
        } else { // Specific prospect type or show all prospects
            return data.filter(company => {
                // Engagement type will show specific type or all accounts that are not Customers
                // (since there are only customers or the various prospect types)
                // Filter specific sales user or show all if no sales passed
                return (type === company['Engagement Type'] || (type === engagementTypeName.Prospect && company['Engagement Type'] !== engagementTypeName.Customer)) &&
                    (salesUser == null || salesUser === company['Account Owner']);
            });
        }
    }

    /**
     * When a salesperson filter is chosen/removed, 
     * @param {obj?} salesUser Obj with UserID and DisplayName of sales. null for empty
     */
    function FilterBySales(salesUser) {
        // Filter by owner or null depending on which is set as well as which type
        let data = Filter(activeTab, salesUser?.DisplayName);

        setAccounts(data);
        setSelectedSales(salesUser == null ? [] : [salesUser]);
        ClearPageChecks();
    }

    //#endregion Filtering

    //#region Playlist

    /**
     * Shows playlist specific accounts. When Playlist tab is selected, defaults to first item on list
     * @param {int} playlistId Id of playlist to show. If playlistId = null, first playlist in array will be used
     * @returns Filtered accounts that are from specified playlist
     */
    function FilterByPlaylist(playlistId) {
        if (playlists == null) return;

        var playlist;

        // If playlists.length = 0 and playlistId empty, then use empty array declared above
        if (playlistId != null) {
            playlist = playlists.find(x => x.PlaylistID === playlistId); // Get list associated with playlistId
            setSelectedPlaylistId(playlistId);
        } else if (playlists.length !== 0) { // If there is at least one item, use first item
            playlist = playlists[0];
            setSelectedPlaylistId(playlist.PlaylistID);
        }

        // Set Title
        if (playlist != null) playlistTitle.current.innerText = ' - Playlist: ' + playlist.PlaylistName;

        // Filter and show only CompanyAccountIDs
        // list = null only when there are no playlists
        let data = Filter(activeTab, null, playlist == null ? [] : playlist.CompanyAccountIDs);
        setAccounts(data);
    }

    /**
     * Gets the playlist associated with current user
     */
    function GetPlaylistsForUser() {
        UpdateSWFetch('/api/playlist/get', (x) => setPlaylists(x));
        GetPlaylists()
            .then(x => setPlaylists(x))
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(GetErrorMessages.Playlists, err.message));
            });
    }

    /**
     * Adds checked items to playlist if applicable
     */
    function OnAddAccountsToPlaylist() {
        // Check items are present to add
        if (checkedIds.current.length === 0) {
            setToast(GetToastComponent('Select at least one item to add to playlist'))
            return;
        }

        // Get the accounts that are selected from entire list rather than currently displayed list
        // since there can be accounts selected that are not showing
        var companiesSelected = [];
        let accountsFull = ownedAccounts.current.filter(x => {
            return checkedIds.current.includes(x.CompanyAccountID);
        }); // Accounts with all the data

        // Pass on only id and name
        accountsFull.forEach(x => {
            companiesSelected.push({
                CompanyAccountID: x.CompanyAccountID,
                Name: x.Name
            })
        });

        setAccountsSelected(companiesSelected);
        setShowPlaylistModal(true);
    }

    /**
     * Runs after account finishes adding
     */
    function OnAccountAdded() {
        setToast(GetToastComponent('Accounts added to playlist successfully'));
        setShowPlaylistModal(false);

        GetPlaylistsForUser(); // Fetch playlists again
        ClearPageChecks();
    }

    /**
     * Deletes the current playlist
     */
    function OnDeleteCurrentPlaylist() {
        if (selectedPlaylistId == null) {
            setToast(GetToastComponent('There are no playlists to delete'));
            return;
        }

        fetch('/api/playlist/delete-playlist?playlistId=' + selectedPlaylistId, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    // Change tab to all after delete
                    OnTabChange('All');

                    GetPlaylistsForUser(); // Refresh playlists
                    setToast(GetToastComponent('Playlist has been deleted'));
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not delete playlist', err.message));
            });
    }

    /**
     * Show delete confirm modal if delete conditions met
     */
    function OnDeleteCurrentPlaylistItems() {
        if (checkedIds.current == null || checkedIds.current.length === 0) {
            setToast(GetToastComponent('No items to delete from playlist', null, null, true, 5));
            return;
        }

        // Set the message to show in modal
        let title = playlists.find(x => x.PlaylistID === selectedPlaylistId).PlaylistName;
        let message = [];

        checkedIds.current.forEach((id, i) => {
            // Get all the company names to show in modal
            let account = accounts.find(x => x.CompanyAccountID === id);

            message.push(<span key={i}>
                <FontAwesomeIcon key={i + '-arrow'} icon={faArrowRight} className='me-2' />
                {account.Name}
                <br key={i + '-br'} />
            </span>);
        });

        setDeleteItemsTitle('Delete the following accounts from the playlist ' + title);
        setDeleteItemsMessage(message);
        setShowDeleteItemsConfirmModal(true); // Show Confirmation modal 
    }

    /**
     * Delete Playlist items 
     */
    function DeleteCurrentPlaylistItems() {
        let body = {
            PlaylistID: selectedPlaylistId,
            CompanyAccountIDs: checkedIds.current
        };

        fetch('/api/playlist/delete-items', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                // Delete successful
                if (data === true) {
                    setShowDeleteItemsConfirmModal(false);
                    setToast(GetToastComponent('Accounts have been deleted from playlist'));

                    GetPlaylistsForUser(); // Refresh playlists

                    // Since playlists is refreshed asyncronously, we filter old playlist and then update the accounts that should show
                    let index = playlists.findIndex(x => x.PlaylistID === selectedPlaylistId);
                    let beforeDeleteAccounts = playlists[index].CompanyAccountIDs; // accounts in the playlist before the delete

                    beforeDeleteAccounts = beforeDeleteAccounts.filter(id => !checkedIds.current.includes(id));

                    // Filter and show only CompanyAccountIDs that remain
                    let filteredAccounts = Filter(activeTab, null, beforeDeleteAccounts);
                    setAccounts(filteredAccounts);

                    ClearPageChecks();
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not delete playlist', err.message));
            });
    }

    //#endregion Playlist

    //#region Checks

    /**
     * Remove all checks from checkedIds array and update UI
     * @param {bool} dontSetToggle Optional parameter to set so that checkToggle is not set to none when set to true
     */
    function ClearPageChecks(dontSetToggle) {
        ClearChecks(checkedIds);

        if (dontSetToggle !== true) setCheckToggle('none'); // Reset the toggle
    }

    /**
     * Run whenever a check is clicked
     * @param {int} companyId Id of company that has been clicked
     */
    function OnPageCheckChange(companyId) {
        OnCheckChange(checkedIds, companyId);

        setCheckToggle('none'); // Remove select all or select page toggle
    }

    /**
     * Top button row toggling between 'all', 'page', or 'none' (this isn't passed but set)
     * @param {string} toggle 'all' or 'page' value accepted
     * @returns
     */
    function OnCheckToggle(toggle) {
        if ((toggle !== 'all' && toggle !== 'page') || accounts == null || loading) return;

        ClearPageChecks(true); // Clear checks to be set again if needed

        // If already that state, set to none
        if ((checkToggle === 'all' && toggle === 'all') || (checkToggle === 'page' && toggle === 'page')) {
            setCheckToggle('none');
            return;
        }

        // Toggle to all or none
        // Get all checkboxes on page
        var checksOnPage = document.querySelectorAll('table tbody tr td:first-child input');

        if (checksOnPage == null) {
            setToast(GetErrorToastComponent('Error: Could not find check boxes'));
            return;
        }

        if (toggle === 'all') { // All elements will be checked
            // Push all ids that are showing after filtering with table search
            filteredTableIds.current.forEach(x => checkedIds.current.push(x));

            // Could use UpdateChecks() to check needed boxes but will go through entire list 
            // Instead, only go through checks on page
            checksOnPage.forEach(x => x.checked = true);

        } else if (toggle === 'page') { // All elements on current page will be checked
            // Add accounts on page to checkedIds
            checksOnPage.forEach(x =>
                checkedIds.current.push(parseInt(x.id.replace('-check', '')))
            );
            UpdateChecks(checkedIds);
        }

        setCheckToggle(toggle);
    }

    //#endregion Checks

    //#region Exporting Accounts to Excel

    /**
     * Export accounts on page to excel .csv file. Will export everything on current page matching filters.
     * Filters by salesperson, engagement type, and search filters as applicable
     */
    function OnExportToExcel() {
        if (filteredTableIds.current.length === 0) {
            setToast(GetToastComponent('No accounts to export', 'No accounts found matching search criteria (if any)', 'warning'));
            return;
        }

        // Check if there are search filters applied on table columns
        let isContainSearch = Object.keys(GetSearchParameters(headers)).length > 0;
        if (isContainSearch && filteredTableIds.current.length > 2000) {// If exceeding the max CompanyIds count supported
            setToast(
                GetToastComponent('Company table column filtering limit of 2000 exceeded!',
                    'Narrow your table search so there are less than 2000 results or remove the table column filters',
                    'warning', false)
            );
            return;
        }

        let filteredUserId = null;
        if (selectedSales.length === 1) {
            filteredUserId = selectedSales[0].UserID;
        } else if (!showAllAccounts) {  // Check if showing owned accounts 
            filteredUserId = JSON.parse(GetCookie(cookieNames.userID));
        }

        let request = {
            CompanyIds: isContainSearch ? filteredTableIds.current : null,
            ShowAllUnderUserID: filteredUserId,
            ShowEngagementType: activeTab === 'All' ? null : activeTab
        };

        fetch('/api/companyaccounts/export-accounts-to-excel', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(request)
        })
            .then(response => HandleResponseReturn(response, true))
            .then(data => {
                data = HandleDataReturn(data);
                return data.blob();
            })
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                const date = new Date();
                let fileName = FormatDate(date, '_');

                // If there was sales filter, add sales name
                if (request.ShowAllUnderUserID != null) {
                    if (selectedSales.length === 1) {
                        fileName += `_${selectedSales[0].DisplayName.replace(' ', '_')}`;
                    } else { // If my accounts admin export
                        fileName += `_${JSON.parse(GetCookie(cookieNames.userName)).replace(' ', '_')}`;
                    }
                }

                // If there was engagement type filter that was not 'All', add engagement name
                if (request.ShowEngagementType != null) {
                    fileName += `_${request.ShowEngagementType.replace(/[\s]/g, '_')}`; // Replace space and / with -
                }

                // If there was search
                if (request.CompanyIds != null) fileName += '_With_Search';

                fileName += '_Accounts_Export.csv';

                a.href = url;
                a.download = fileName
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent("An error occurred while trying to export accounts", err.message));
            });
    }

    //#endregion Exporting Accounts to Excel

    //#region Reassigning Prospects

    /**
     * Show the confirmation modal summarizing the changes to be made before Reassigning
     * @param {string} convertTo Takes the value 'Leads' or 'Sales' where the first reassigns prospects
     * to Leads and the second reassigns to selected user in selectedSales setState
     */
    function ShowConfirmReassignProspects(convertTo) {
        if (convertTo !== leads && convertTo !== sales) {
            return;
        }

        // Check items are selected
        if (checkedIds.current.length === 0) {
            setToast(GetToastComponent('Select at least 1 account to convert', null, null, true, 5));
            return;
        }

        setConvertTo(convertTo); // Set the current convert to 
        setShowConfirm(true); // Show confirm modal before assigning
    }


    /**
     * Reassign selected prospects to another salesperson or back to leads depending on convertTo setState.
     * Runs after user confirmed the changes they wanted.
     * In the case of converting to another sales, they would also have chosen who to convert to in the modal
     */
    function ReassignProspects() {
        if (convertTo === sales) {
            let newSalesOwner = document.getElementById('new-sales-owner-select');

            if (newSalesOwner == null) return;

            if (newSalesOwner.value === '') {
                setToast(GetToastComponent('Select a salesperson to convert to', null, 'warning'));
                return;
            }

            let request = {
                CompanyIdsToUpdate: checkedIds.current,
                NewOwner: newSalesOwner.value
            };

            fetch('/api/companyaccounts/change-account-owner', {
                headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(request)
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    if (data === true) {
                        let newOwnerName = newSalesOwner.options[newSalesOwner.selectedIndex].text;
                        setToast(GetToastComponent('Converted account(s) to ' + newOwnerName));
                    } else {
                        throw new Error();
                    }
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent('An error has occurred when converting to different salesperson', err.message));
                })
                .finally(_ => {
                    setShowConfirm(false);
                    RefreshPageAfterReassign();
                });
        } else {
            // Convert checked prospects to leads
            fetch('/api/companyaccounts/convert-multiple-prospects-to-lead', {
                headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(checkedIds.current)
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);
                    let hasFailed = false;
                    let failedMessages = [];

                    // Check response object to see if there are errors
                    data.forEach(x => {
                        if (x.Converted !== true) {
                            hasFailed = true;
                            failedMessages.push(<><b>{x.CompanyName}</b>: {x.Message}<br /></>);
                        }
                    });

                    if (!hasFailed)
                        setToast(GetToastComponent('Converted accounts(s) back to lead'));
                    else
                        setToast(GetToastComponent('More than 1 account has failed to convert back to lead', failedMessages, 'danger', false, null, true));
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent(err.message));
                })
                .finally(_ => {
                    setShowConfirm(false);
                    RefreshPageAfterReassign();
                });
        }

        setShowReassignButtons(false);
    }

    /**
     * Refresh the page and reset the variables after reassigning prospects to another salesperson or back to leads
     */
    function RefreshPageAfterReassign() {
        // Reset the fields
        setConvertTo(null);
        setSelectedSales([]);
        ClearPageChecks();

        // Updates account page after fetch finishes
        UpdateSWFetch('/api/companyaccounts/get-all-accounts?showAll=true', (x) => {
            x = ProcessData(x); // Done in GetAccounts normally

            allAccounts.current = x; // update all accounts with updated data
            ownedAccounts.current = null // clear my accounts to fetch again
            setAccounts(Filter(activeTab, null));
        });

        GetAccounts(true)
            .then(x => {
                allAccounts.current = x; // update all accounts with updated data
                ownedAccounts.current = null // clear my accounts to fetch again
                setAccounts(Filter(activeTab, null));
            })
            .catch(err => {
                setToast(GetErrorToastComponent('Error: Could not get company accounts', err));
            });
    }

    /**
     * Check all pre conditions are met before allowing admin user to assign prospect
     */
    function ShowReassignProspects() {
        var errorMessage = null;

        // Check preconditions
        if (!isAdmin.current) {
            errorMessage = 'Only admins can reassign prospects';
        } else if (!showAllAccounts) {
            errorMessage = 'Must show all accounts before reassigning prospects';
        } else if (selectedSales.length === 0) {
            errorMessage = 'Must select a salesperson before reassigning prospects';
        }

        // Error message existing means preconditions not fulfilled
        if (errorMessage != null) {
            setToast(GetToastComponent(errorMessage, null, 'warning', true, 10));
            return;
        }

        setShowReassignButtons(true);
    }

    //#endregion Reassigning Prospects

    //#region Save/Load Search Parameters

    // Gets the settings and sets the states
    function GetSearchFilterSettings() {
        // Set Columns and search settings
        GetSetting(['AccountSummaryPage.Searches'])
            .then(x => {
                let searchFiltersTemp = JSON.parse(x[0]);

                // Save the filters if exist
                if (searchFiltersTemp != null) setSearchFilters(searchFiltersTemp);
            })
            .catch(console.error);
    }

    /**
     * Sets the settings to db and fetches updated settings again
     * @param {any} value value to save to db. In this case, it is the saved filters json
     */
    function SetSearchFilterSettings(value) {
        // Format the settings to save
        var settings = [{
            Name: 'AccountSummaryPage.Searches',
            Value: value
        }];

        SetSetting(settings)
            .finally(GetSearchFilterSettings) // Get updated settings
            .catch(console.error);
    }

    /**
     * When a filter is clicked
     * @param {int} index Which array element in the searchFilter list to show
     */
    function OnFilterChange(index) {
        var filter = { ...searchFilters[index].headersToShow };

        // If already on that filter, nothing to do
        if (headersToShow === filter) return;

        // If loading don't set filters 
        if (loading) {
            setToast(GetToastComponent('Wait until page finishes loading before setting filters'));
            return;
        }

        // Clear existing search input columns
        var inputElements = document.querySelectorAll('th.search input.form-control');
        inputElements.forEach(elem => {
            if (elem.value !== '') {
                elem.value = '';
                elem.click(); // Trigger click so that the Table search will filter accordingly 
            }
        });

        // Set index so that the use effect will know which searchFilter index to apply
        // Need to use useEffect that runs after headersToShow is set because otherwise,
        // some columns that haven't shown up yet won't display changes
        searchFilterIndex.current = index;

        // Clear any checks that have been set
        ClearPageChecks();

        // Update columns showing
        setHeadersToShow(filter);
    }

    /**
     * Runs to save the search parameters
     */
    function OnSaveSearchParameters() {
        // Check name does not already exist
        let nameExists = searchFilters.some(filter => filter.name === newSearchFilterName.current);
        if (nameExists) {
            setToast(GetToastComponent('Filter name already exists', null, 'warning'));
            return;
        }

        // Get all the search parameters
        var searchInput = GetSearchParameters(headers);

        // Append the new filter
        let value = JSON.stringify([...searchFilters, {
            name: newSearchFilterName.current,
            headersToShow: headersToShow,
            searchInput: searchInput
        }]);

        SetSearchFilterSettings(value);
    }

    /**
     * When deleting a filter item
     * @param {string} name Filter name to remove from search filters list
     */
    function OnRemoveSearchFilterItem(name) {
        let index = searchFilters.findIndex(x => x.name === name); // Find index of item to remove
        let tempSearchFilters = [...searchFilters];
        tempSearchFilters.splice(index, 1);

        SetSearchFilterSettings(JSON.stringify(tempSearchFilters));
    }

    //#endregion Save/Load Search Parameters

    //#region Save/Load Default Columns/Search Parameters

    /**
     * Gets default columns to show and search params of those columns from cookies
     */
    function GetDefaultColumnAndSearchSettings() {
        let defaultSetting = JSON.parse(localStorage.getItem(localStoreNames.accountPageDefault));

        if (defaultSetting != null) {
            // Check if new columns added
            // If new columns added and we don't check, it will not show up
            if (Object.keys(defaultHeadersToShow).length !== Object.keys(defaultSetting.headersToShow).length) {
                // Clear existing if so. headersToShow is already showing default so no need to set
                UpdateDefaultColumnAndSearchSettings();
            } else {
                // Update the columns to show
                setHeadersToShow(defaultSetting.headersToShow);

                // Update search inputs in table
                UpdateSearchInTable(defaultSetting.searchInput);
            }          
        }
    }

    /**
     * Updates the settings as needed into cookies
     */
    function UpdateDefaultColumnAndSearchSettings() {
        // Don't run any update until inital load has finished
        if (loadedSettings.current === false) return;

        var settings = {
            headersToShow: headersToShow,
            searchInput: GetSearchParameters(headers)
        };

        localStorage.setItem(localStoreNames.accountPageDefault, JSON.stringify(settings));
    }

    //#endregion Save/Load Default Columns/Search Parameters

    //#region Component Functions

    /**
     * Modal for adding prospects and accounts to playlist 
     * @returns Component
     */
    function AddToPlaylistModalComponent() {
        if (playlists == null || ownedAccounts.current == null) return null;

        return <AddToPlaylistModal
            companiesSelected={accountsSelected}
            playlists={playlists}
            showModal={showAddToPlaylistModal}
            setShowModal={setShowPlaylistModal}
            addedFn={OnAccountAdded}
            cancelFn={() => setShowPlaylistModal(false)}
            setToast={setToast}
        />
    }

    /**
     * Confirm modal for converting prospects back to leads or another sales
     * @returns Component
     */
    function ConfirmConvertModalComponent() {
        const memoConfirm = useMemo(() => {
            if ((convertTo !== leads && convertTo !== sales) || checkedIds.current.length === 0) return null;

            let title = null;
            let message = [];

            // Set message depending on which it is
            if (convertTo === leads) {
                title = 'Convert the following from ' + selectedSales[0].DisplayName + ' to back to leads';
            } else {
                title = 'Convert the following prospects from ' + selectedSales[0].DisplayName;

                // Add a select to choose which salesperson to convert to
                let salesSelect = <>
                    <CFormLabel>Select salesperson to convert prospects to</CFormLabel>
                    <CFormSelect id='new-sales-owner-select' className='mb-2'>
                        <option />
                        {salesList.map((sales) => {
                            return <option key={sales.UserID} value={sales.UserID} disabled={selectedSales[0].DisplayName === sales.DisplayName}>{sales.DisplayName}</option>
                        })}
                    </CFormSelect>
                </>;

                message.push(salesSelect);
            }

            // Get all the company names selected
            checkedIds.current.forEach((id, i) => {
                let prospect = accounts.find(x => x.CompanyAccountID === id);

                message.push(<span key={i}>
                    <FontAwesomeIcon key={i + '-arrow'} icon={faArrowRight} className='me-2' />
                    {prospect.Name}
                    <br key={i + '-br'} />
                </span>);
            });

            return <ConfirmModal
                title={title}
                message={message}
                showModal={showConfirmModal}
                confirmFunction={ReassignProspects}
                cancelFunction={() => {
                    setShowConfirm(false);
                    setConvertTo(null);
                    ClearPageChecks();
                }}
                confirmColour='danger'
            />
        }, []);

        return memoConfirm;
    }

    /**
     * Confirms user wants to delete playlist items before delete happens
     * @returns Component
     */
    function ConfirmDeletePlaylistItemsModalComponent() {
        if (checkedIds.current == null || selectedPlaylistId == null || playlists == null || accounts == null) return null;

        return <ConfirmModal
            title={deleteItemsModalTitle}
            message={deleteItemsModalMessage}
            showModal={showDeleteItemsConfirmModal}
            confirmFunction={DeleteCurrentPlaylistItems}
            cancelFunction={() => {
                setShowDeleteItemsConfirmModal(false);
                setConvertTo(null);
                ClearPageChecks();
            }}
            confirmColour='danger'
        />
    }

    /**
     * The disabled arrows in the TabsComponent
     * @returns Component
     */
    const NavArrowComponent = useMemo(() => {
        return () => <CNavItem><CNavLink disabled><FontAwesomeIcon icon={faGreaterThan} size='xs' /></CNavLink></CNavItem>;
    }, []);

    /**
     * The vertical bar divider in the TabsComponent
     * @returns Component
     */
    const NavDividerComponent = useMemo(() => {
        return () => <CNavItem><CNavLink disabled><FontAwesomeIcon icon={faGripLinesVertical} /></CNavLink></CNavItem>;
    }, []);

    //#endregion Component Functions

    //#endregion Functions

    //#region Use Effect

    // Get CompanyAccount data when showAllAccount changes (and on first load)
    useEffect(() => {
        let getData = false;

        // Check if there is a need to fetch
        if ((showAllAccounts && allAccounts.current == null) || (showAllAccounts === false && ownedAccounts.current == null))
            getData = true;

        if (getData) {
            // Updates account page after fetch finishes
            UpdateSWFetch('/api/companyaccounts/get-all-accounts?showAll=' + showAllAccounts, (x) => {
                x = ProcessData(x); // Done in GetAccounts normally

                // Save data for reaccess
                if (showAllAccounts) allAccounts.current = x;
                else ownedAccounts.current = x;

                setAccounts([...Filter('All', null)]);
            });

            // Gets the account Data data
            GetAccounts(showAllAccounts)
                .then(x => {
                    // Save data for reaccess
                    if (showAllAccounts) allAccounts.current = x;
                    else ownedAccounts.current = x;

                    setAccounts([...Filter('All', null)]);
                })
                .catch(err => {
                    setToast(GetErrorToastComponent('Error: Could not get company accounts', err));
                });
        } else {
            // Sets account data from saved data
            setAccounts([...Filter('All', null)]);
        }

        return () => {
            if (abortController.current != null) abortController.current.abort(); // Abort on navigate away
        }
    }, [showAllAccounts]);

    // Show toast if applicable, set if admin flag, get playlists, and get saved filters 
    useEffect(() => {
        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);

        GetPlaylistsForUser();

        GetSearchFilterSettings();

        // Gets the salespeople preemptively if user is admin
        if (isAdmin.current) {
            fetch('/api/sales/get-all-salespeople-with-accounts', {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);
                    setSalesList(data);
                })
                .catch(err => {
                    setToast(GetErrorToastComponent('Error: Could not get salespeople!', err));
                });
        }
    }, []);

    // Continuation of OnFilterChange function running only after new headers are set
    // Sets the new text if applicable
    useEffect(() => {
        // If search filter is null, it means OnFilterChange() did not run
        if (searchFilterIndex.current == null) return;

        var filter = searchFilters[searchFilterIndex.current];

        // Update search inputs in table
        UpdateSearchInTable(filter.searchInput);

        searchFilterIndex.current = null;
    }, [headersToShow]);

    // Get default settings when accounts are first loaded
    useEffect(() => {
        if (accounts == null && loadedSettings.current === false) return;

        loadedSettings.current = true;
        GetDefaultColumnAndSearchSettings();
    }, [accounts]);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top-end' />

        {/* Used when adding a todo for a company */}
        <TodoModal
            visible={showTodoModal}
            setVisible={setShowTodoModal}
            setToast={setToast}
            companyId={companyId}
            companyName={companyName}
        />

        {/* Used for changing which columns are displayed on the table */}
        <ChangeColumnsModal
            headersToShow={headersToShow}
            setHeadersToShow={setHeadersToShow}
            showModalVar={showChangeColumnsModal}
            hideModal={() => setShowChangeColumnsModal(false)}
        />

        {/* Used for confirming convert back to leads or to another sales */}
        <ConfirmConvertModalComponent />

        {/* Used for adding companies selected to playlist */}
        <AddToPlaylistModalComponent />

        {/* Used for confirming delete accounts from playlist */}
        <ConfirmDeletePlaylistItemsModalComponent />

        {/* Used for importing prospects from ZoomInfo using excel format */}
        <ImportFromZoomInfoExcelModal visible={showZoomInfoImportModal} setVisible={setShowZoomInfoImportModal} setToast={setToast} />

        {/* Used for setting a name used to save current search & column settings */}
        <SetSearchFilterNameModal
            showModal={showSetFilterNameModal}
            setShowModal={setShowSetFilterNameModal}
            selectedName={newSearchFilterName}
            confirmFunction={OnSaveSearchParameters}
            setToast={setToast}
        />

        {/* Used for removing search filter */}
        <RemoveSearchFilterModal
            showModal={showRemoveFilterModal}
            setShowModal={setShowRemoveFilterModal}
            searchFilters={searchFilters}
            confirmFunction={OnRemoveSearchFilterItem}
            setToast={setToast}
        />

        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>
                Company Accounts
                <span ref={playlistTitle} className='fs-6'></span>
            </CHeaderText>
            <top-button-group style={{ display: 'flex' }}>
                {/* Show playlist filtering */}
                {activeTab === 'Playlists' && showAllAccounts === false ? <>
                    <CButton className='m-2' color='danger' variant='outline' onClick={OnDeleteCurrentPlaylistItems}>Delete Playlist Items</CButton>
                    <CDropdown className='m-2'>
                        <CDropdownToggle color='secondary'>
                            Playlists
                        </CDropdownToggle>
                        <CDropdownMenu>
                            {playlists == null ? <CDropdownItemPlain disabled>No playlists to show</CDropdownItemPlain> :
                                playlists.map((x) => {
                                    return <CDropdownItem id={x.PlaylistID} key={x.PlaylistID} onClick={() => FilterByPlaylist(x.PlaylistID)}>{x.PlaylistName}</CDropdownItem>
                                })
                            }
                        </CDropdownMenu>
                    </CDropdown>
                </> : null}

                {!isAdmin.current ? null : <>
                    {/* If show all accounts is selected, also show dropdown to filter by sales since only admin can filter by sales */}

                    {/* Buttons that show only in 'All Account' */}
                    {showAllAccounts === true ? <>
                        <Typeahead
                            id='filter-by-salesperson-typeahead'
                            options={salesList}
                            labelKey={option => `${option.DisplayName}`}
                            onChange={(x) => FilterBySales(x.length === 1 ? x[0] : null)}
                            selected={selectedSales}
                            emptyLabel='No sales rep found'
                            placeholder='Select a Salesperson'
                            clearButton={true}
                            className='m-2'
                            style={{ width: '250px' }}
                        />
                    </> : null}

                    <CButtonGroup className='m-2' style={{ whiteSpace: 'nowrap' }}>
                        <CFormCheck
                            label='All Accounts'
                            id='all-accounts-button'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='radio'
                            name='btnradio account-type-to-show'
                            onChange={() => {
                                if (!loading) {
                                    ToggleAccountDisplayTypes(true);
                                }
                            }}
                            readOnly
                            checked={showAllAccounts}
                        />
                        <CFormCheck
                            label='My Accounts'
                            id='my-accounts-button'
                            button={{ color: 'secondary', variant: 'outline' }}
                            type='radio'
                            name='btnradio account-type-to-show'
                            onChange={() => { if (!loading) ToggleAccountDisplayTypes(false); }}
                            readOnly
                            checked={showAllAccounts === false}
                        />
                    </CButtonGroup>
                </>}

                {/* Selects search and column filters */}
                <CDropdown className='m-2'>
                    <CDropdownToggle color='secondary'>
                        Saved Filters
                    </CDropdownToggle>
                    <CDropdownMenu>
                        {searchFilters.length === 0 ? <CDropdownHeader>No Filters to Show</CDropdownHeader> :
                            searchFilters.map((filter, i) => <CDropdownItem key={i} onClick={() => OnFilterChange(i)}>{filter.name}</CDropdownItem>)
                        }
                    </CDropdownMenu>
                </CDropdown>

                {/* Select all or none of the checks buttons */}
                <CButtonGroup className='m-2'>
                    <CTooltip content='Select all'>
                        <CButton color='secondary' variant='outline' onClick={() => OnCheckToggle('all')} active={checkToggle === 'all'}>
                            {checkToggle === 'all' ? <FontAwesomeIcon icon={faCheckSquare} /> : <FontAwesomeIcon icon={faSquare} />}
                        </CButton>
                    </CTooltip>
                    <CTooltip content='Select all on page'>
                        <CButton color='secondary' variant='outline' onClick={() => OnCheckToggle('page')} active={checkToggle === 'page'}>
                            <FontAwesomeIcon icon={faFile} className='me-1' />
                            {checkToggle === 'page' ? <FontAwesomeIcon icon={faCheckSquare} /> : <FontAwesomeIcon icon={faSquare} />}
                        </CButton>
                    </CTooltip>
                </CButtonGroup>

                {/* Clears input fields on table */}
                <CTooltip content='Clear Search Inputs'>
                    <CButton color='secondary' className='m-2' onClick={ClearSearchFilters}>Clear</CButton>
                </CTooltip>

                {/* Settings dropdown */}
                <CDropdown className='m-2'>
                    <CDropdownToggle color='secondary'>
                        <FontAwesomeIcon icon={faWrench} className='me-1' />
                    </CDropdownToggle>
                    <CDropdownMenu>
                        <CDropdownHeader>Options</CDropdownHeader>
                        <CDropdownDivider />

                        {/* Change which columns are shown */}
                        <CDropdownItem onClick={() => setShowChangeColumnsModal(true)}>Change Table Columns Shown</CDropdownItem>

                        {/* Reset to default columns shown */}
                        <CDropdownItem onClick={() => setHeadersToShow(defaultHeadersToShow)}>Reset Columns to Default</CDropdownItem>

                        <CDropdownDivider />

                        {/* Let user add to playlist only if they are viewing their own account in Prospect page */}
                        {showAllAccounts === false ? <>
                            <CDropdownItem onClick={OnAddAccountsToPlaylist}>Add to Playlist</CDropdownItem>
                            {/* Only allow deleting if on playlist tab */}
                            {activeTab === 'Playlists' ?
                                <CDropdownItem onClick={OnDeleteCurrentPlaylist}>Delete Current Playlist</CDropdownItem>
                                : null}
                            <CDropdownDivider />
                        </> : null}

                        {/* Save or remove search filters */}
                        <CDropdownItem onClick={() => setShowSetFilterNameModal(true)}>Save Search Filter</CDropdownItem>
                        <CDropdownItem onClick={() => setShowRemoveFilterModal(true)}>Remove Search Filter</CDropdownItem>
                        <CDropdownDivider />
                        <CDropdownItem onClick={(e) => NavWithNewTab(e, navigate, '/company-accounts/new')}>
                            Add Prospect
                        </CDropdownItem>
                        <CDropdownItem onClick={() => setShowZoomInfoImportModal(true)}>
                            Add Prospect from ZoomInfo
                        </CDropdownItem>

                        {/* Exports all company account fields currently showing */}
                        {isAdmin.current ?
                            <>
                                <CDropdownDivider />
                                <CDropdownItem onClick={OnExportToExcel}>
                                    Export to Excel
                                </CDropdownItem>
                            </>
                            : null
                        }

                        {/* Can only reassign when in 'All Accounts' */}
                        {showAllAccounts === true && isAdmin.current ?
                            <CTooltip content='Move prospects to another sales or back to leads' placement='left'>
                                <CDropdownItem onClick={ShowReassignProspects}>Reassign Prospects</CDropdownItem>
                            </CTooltip>
                            : null
                        }

                    </CDropdownMenu>
                </CDropdown>

                <InfoModal modalContent={infoModalText.AccountsPage} />
            </top-button-group>
            {/* showReassignButtons = true only when 'Reassign Prospects' has been selected and user is admin in view all accounts */}
            {/* Let admin user convert selected prospects back to leads or assign to another sales */}
            {showReassignButtons === true ?
                <reassign-button-group style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                    <CButton
                        color='warning'
                        className='m-2'
                        onClick={() => {
                            ClearPageChecks();
                            setShowReassignButtons(false);
                        }}
                    >
                        Cancel Convert
                    </CButton>
                    <CButtonGroup className='m-2'>
                        <CButton color='danger' variant='outline' onClick={() => ShowConfirmReassignProspects(leads)}>Convert To Leads</CButton>
                        <CButton color='danger' variant='outline' onClick={() => ShowConfirmReassignProspects(sales)}>Convert To Another Salesperson</CButton>
                    </CButtonGroup>
                </reassign-button-group>
                : null}
        </CHeader>
        <CCard>
            <CCardBody>
                {/* Top navigation bar */}
                <CNav variant='pills' className='account-type-navigation justify-content-center mb-2' color='secondary'>
                    {/* Only show playlist option when showing 'My Accounts' */}
                    {showAllAccounts === true ? null : <>
                        <CNavItem>
                            <CNavLink active={activeTab === 'Playlists'} onClick={() => OnTabChange('Playlists')}>Playlists</CNavLink>
                        </CNavItem>
                        <NavDividerComponent />
                    </>}

                    <CNavItem>
                        <CNavLink active={activeTab === 'All'} onClick={() => OnTabChange('All')}>All</CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink
                            id='customers'
                            active={activeTab === engagementTypeName.Customer}
                            onClick={() => OnTabChange(engagementTypeName.Customer)}
                        >
                            Customers
                        </CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink
                            id='prospects'
                            active={activeTab === engagementTypeName.Prospect}
                            onClick={() => OnTabChange(engagementTypeName.Prospect)}
                        >
                            Prospects
                        </CNavLink>
                    </CNavItem>

                    <CNavItem>
                        <CNavLink
                            id='active-pipeline'
                            active={activeTab === 'Active Pipeline'}
                            onClick={() => OnTabChange('Active Pipeline')}
                        >
                            Active Pipeline
                        </CNavLink>
                    </CNavItem>
                </CNav>
                <CNav id='prospect-navigation' variant='pills' className='account-type-navigation justify-content-center mb-2' color='secondary'>
                    {prospectTypes.map((x, i) => {
                        return <React.Fragment key={i}>
                            <CNavItem key={`nav-item-${i}`}>
                                <CNavLink
                                    id={engagementTypeName[x].toLowerCase().replace(/[ \/]/g, '-')}
                                    key={`nav-link-${i}`}
                                    active={activeTab === engagementTypeName[x]}
                                    onClick={() => OnTabChange(engagementTypeName[x])}
                                >
                                    {engagementTypeName[x]}
                                </CNavLink>
                            </CNavItem>
                            {engagementTypeName[x] === engagementTypeName.FirstLoadAndImplementation ?
                                null : <NavArrowComponent />
                            }
                        </React.Fragment>
                    })}
                </CNav>

                <Table
                    headers={headers}
                    body={accounts}
                    hover={true}
                    color={'light'}
                    striped={true}
                    hasColSort={true}
                    hasPages={true}
                    hasSearchRow={true}
                    tableClass='align-middle'
                    onChange={() => {
                        UpdateChecks(checkedIds); // Updates checkboxes

                        // Update settings on a timer
                        clearTimeout(saveSettingTimeoutId.current); // Clear the previous timeout and create a new one
                        saveSettingTimeoutId.current = setTimeout(UpdateDefaultColumnAndSearchSettings, [300]);
                    }}
                    hasReturnFilteredItems={true}
                    returnPropertyName='CompanyAccountID'
                    filteredItems={filteredTableIds}
                    isLoading={loading}
                    placeholderProps={{ width: '100px' }}
                />
            </CCardBody>
        </CCard>
    </>;
}