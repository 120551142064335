import React, { useEffect, useState } from 'react';

import {
    CButton,
    CFormCheck,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

import '../../style/changeColumnsModal.css';

/**
 * Modal for setting which columns are shown
 * @param {obj} headersToShow  the headers keys (name of header) and value which is whether to show it or not
 * @param {setState} setHeadersToShow Set state for headersToShow
 * @param {bool} showModalVar  show or don't show modal
 * @param {fn} hideModal passes the setState to hide modal. Sets the showModalVar setState to false to hide modal
 * Note: Handle variable passed to showModal in parent component done in Confirm/Cancel DeleteFunction
 * Note: headersToShow follow the following format where headerName is the name of the header and the value is a boolean
 *       specifying whether to show or hide the column.
 * { headerName1: bool, headerName2: bool }
 * @returns Component
 */
export default function ChangeColumnsModal({ headersToShow, setHeadersToShow, showModalVar, hideModal }) {
    const [headerColumnMap, setHeaderColumnMap] = useState();

    //#region Functions

    /**
     * Map the data back to headersToShow using provided setHeadersToShow
     */
    function ConfirmChange() {
        // Headers to show at this point will not be empty
        let tempHeaders = { ...headersToShow };

        headerColumnMap.forEach(map => {
            tempHeaders[map.headerName] = map.show;
        });

        setHeadersToShow(tempHeaders);
        hideModal();
    }

    /**
     * Check or uncheck all columns to show/hide all columns
     * @param {bool} checkAll true = show all columns, false = hide all columns
     */
    function CheckUncheckAll(checkAll) {
        setHeaderColumnMap(
            headerColumnMap.map(header => {
                return { ...header, show: checkAll }
            })
        );
    }

    /**
     * Updates headerColumnMap with new values
     * @param {int} index location in array to change
     * @param {bool} isShowCol show or hide column
     */
    function UpdateColumnSelected(index, isShowCol) {
        setHeaderColumnMap(
            headerColumnMap.map((header, i) =>
                i === index ? { ...header, show: isShowCol } : header
            )
        );
    }

    //#endregion Functions

    // Sets mapping every time headersToShow change
    useEffect(() => {
        let tempMap = [];

        // Create a mapping to show checkbox options
        for (let prop in headersToShow) {
            tempMap.push({
                headerName: prop,
                show: headersToShow[prop]
            });
        }

        setHeaderColumnMap(tempMap);
    }, [headersToShow]);

    return (<CModal
        alignment='center'
        scrollable={true}
        visible={showModalVar}
        onClose={hideModal}
    >
        <CModalHeader closeButton={false}>
            <CModalTitle>Change Columns</CModalTitle>
        </CModalHeader>
        <CModalBody>
            {headerColumnMap == null ? null :
                headerColumnMap.map((map, i) => {
                    return <div key={'div-' + i}>
                        <CFormCheck
                            id={map.headerName.replace(/ /g, '-')}
                            key={i}
                            label={map.headerName}
                            inline
                            defaultChecked={map.show}
                            onClick={(e) => UpdateColumnSelected(i, e.target.checked)}
                        />
                        <br key={'br-' + i} />
                    </div>
                })
            }
        </CModalBody>
        <CModalFooter className='justify-content-between'>
            <div>
                <CButton color='secondary' className='modal-button' variant='outline' onClick={() => CheckUncheckAll(false)}>Hide All</CButton>
                <CButton color='secondary' className='modal-button' variant='outline' onClick={() => CheckUncheckAll(true)}>Show All</CButton>
            </div>
            <div>
                <CButton color='secondary' className='modal-button' variant='outline' onClick={hideModal}>Cancel</CButton>
                <CButton color='danger' className='modal-button' onClick={ConfirmChange}>Yes</CButton>
            </div>
        </CModalFooter>
    </CModal>);
}