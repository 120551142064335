import React, { useEffect, useState } from 'react';
import { infoModalText } from '../../constants';
import { GetToastComponentFromCookiesValues } from '../../functions';

import {
    CCard,
    CHeader,
    CHeaderText,
    CToaster
} from '@coreui/react';

import InfoModal from '../../components/miscellaneous/infoModal';
import Todo from '../../components/todo/todo';

export default function TaskManager() {

    //#region Variables

    const [toast, setToast] = useState(null);

    //#endregion Variables

    //#region Use Effect

    // Get any toast elements that may exist from cookie
    useEffect(() => {
        setToast(GetToastComponentFromCookiesValues(true));
    }, []);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top-end' />
        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>
                To-Dos
            </CHeaderText>
            <InfoModal modalContent={infoModalText.TodoPage} />
        </CHeader>
        <CCard>
            <Todo setToast={setToast} />
        </CCard>
    </>;
}