import React, { useEffect, useState } from 'react';
import { GetToastComponentFromCookiesValues } from '../../functions';

import {
    CButton,
    CCard,
    CHeader,
    CHeaderText,
    CToaster
} from '@coreui/react';

const salesDashboardUrl = 'https://app.powerbi.com/reportEmbed?reportId=95eef18e-eee0-45c9-8071-daec2fe02c34&autoAuth=true&ctid=43a2ef7f-ca77-4dbb-a270-ddb807884133';

/**
 * Embeds Power BI Report
 */
export default function SalesDashboard() {

    //#region Variables

    const [toast, setToast] = useState(null);

    //#endregion Variables

    //#region Use Effect

    // Get any toast elements that may exist from cookie
    useEffect(() => {
        setToast(GetToastComponentFromCookiesValues(true));
    }, []);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top-end' />
        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>Sales Dashboard</CHeaderText>
            <CButton onClick={() => window.open(salesDashboardUrl, '_blank')}>Open In New Tab</CButton>
        </CHeader>
        <CCard>
            <iframe
                title='Sales Dashboard'
                width='100%'
                height='100%'
                src={salesDashboardUrl}
                frameBorder='0'
                allowFullScreen={true}
            />
        </CCard>
    </>;
}