import React, { useEffect, useRef, useState } from 'react';
import { cookieNames } from '../../constants';

import {
    GetCookie,
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn
} from '../../functions';

import {
    CButton,
    CButtonGroup,
    CDropdown,
    CDropdownItem,
    CDropdownItemPlain,
    CDropdownMenu,
    CDropdownToggle,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CListGroup,
    CListGroupItem,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

// Modal to add companies to existing or new playlist. Handles choosing playlist to add to as well as adding it

// companiesSelected (array) = array of objects in the format of {CompanyAccountID, Name } company accounts passed 
//                             to account.js where CompanyAccountID is an int and Name is a string
// playlists (array) = user's playlists and content
// showModal (bool) = show or hide modal
// cancelFn (fn) = function to run when cancelling add to playlist. Also handle hiding modal in function
// addedFn (fn) = function to run when finished adding to playlist. Also handle hiding modal in function
// setToast (useState) = toast component in parent component to set toasts 
export default function AddToPlaylistModal({ companiesSelected, playlists, showModal, cancelFn, addedFn, setToast }) {

    const [toggleAddToExisting, onToggleAddToExisting] = useState(true); // Add to existing or new playlist
    const [selectedPlaylist, setSelectedPlaylist] = useState();

    // Ref
    const newPlaylistName = useRef();

    //#region Functions

    // POST to add to existing playlist
    function AddToExistingPlaylist() {
        if (playlists == null || playlists.length === 0) {
            setToast(GetToastComponent('No existing playlist to add to', 'Add items to new playlist', 'warning'));
            return;
        }

        var companyIds = [];
        let currentPlaylistItemIds = playlists.find(x => x === selectedPlaylist).CompanyAccountIDs; // Ids of playlist items to add to

        // Get all the ids to add to playlist that are not already in playlist
        companiesSelected.forEach(x => {
            // Account is already in playlist so no need to add
            if (currentPlaylistItemIds.includes(x.CompanyAccountID)) {
                setToast(GetToastComponent(x.Name + ' already in playlist'));
            } else { // Add to item to add to array
                companyIds.push(x.CompanyAccountID);
            }
        });

        // All items to add already exist so no need to add fetch
        if (companyIds.length === 0) {
            setToast(GetToastComponent('All accounts already exist in playlist'));
            return;
        }

        let body = {
            PlaylistID: selectedPlaylist.PlaylistID,
            CompanyAccountIDs: companyIds
        };

        fetch('/api/playlist/add-items', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    addedFn(); // Function passed by parent to run after adding to playlist
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not add accounts to playlist', err.message));
            });
    }

    // POST to add to new playlist
    function AddToNewPlaylist() {
        var companyIds = [];
        let name = newPlaylistName.current.value;

        if (name == null || name.trim() === '') {
            setToast(GetToastComponent('New Playlist Name field cannot be empty', null, 'warning', true, 5));
            return;
        }

        // Get all the selected company account ids
        companiesSelected.forEach(x => companyIds.push(x.CompanyAccountID));

        let body = {
            PlaylistName: name,
            CreatedByUserID: JSON.parse(GetCookie(cookieNames.userID)),
            CompanyAccountIDs: companyIds
        };

        fetch('/api/playlist/add-playlist', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === true) {
                    addedFn(); // Function passed by parent to run after adding to playlist
                } else {
                    throw new Error();
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not add accounts to new playlist', err.message));
            });
    }

    // Add selected items to existing or new playlist
    function OnAddToPlaylist() {
        if (toggleAddToExisting) {
            AddToExistingPlaylist();
        } else {
            AddToNewPlaylist();
        }
    }

    //#endregion Functions

    // Sets the initial map to be rendered
    useEffect(() => {
        if (playlists == null || playlists.length === 0) {
            onToggleAddToExisting(false); // Default to add new since no playlist existing
            setSelectedPlaylist();
            return;
        }

        setSelectedPlaylist(playlists[0]);
    }, [playlists]);

    return (<CModal
        alignment='center'
        visible={showModal}
        onClose={cancelFn}
    >
        <CModalHeader closeButton={false}>
            <CModalTitle>Add To Playlist</CModalTitle>
            <CButtonGroup className='mb-2'>
                <CFormCheck
                    label='Existing'
                    id='add-to-existing-toggle'
                    button={{ color: 'secondary', variant: 'outline' }}
                    type='radio'
                    name='btnradio'
                    onClick={() => onToggleAddToExisting(true)}
                    checked={toggleAddToExisting}
                    readOnly
                />
                <CFormCheck
                    label='New'
                    id='add-to-new-toggle'
                    button={{ color: 'secondary', variant: 'outline' }}
                    type='radio'
                    name='btnradio'
                    onClick={() => onToggleAddToExisting(false)}
                    checked={!toggleAddToExisting}
                    readOnly
                />
            </CButtonGroup>
        </CModalHeader>
        <CModalBody>
            {toggleAddToExisting ?
                <>
                    <CFormLabel>Playlist To Add To</CFormLabel>
                    <CDropdown>
                        <CDropdownToggle color='secondary' style={{ whiteSpace: 'normal' }}>
                            {selectedPlaylist == null ? 'No playlists' : selectedPlaylist.PlaylistName}
                        </CDropdownToggle>
                        <CDropdownMenu> 
                            {playlists == null ? <CDropdownItemPlain disabled>No playlists to show</CDropdownItemPlain> :
                                playlists.map((x) => {
                                    return <CDropdownItem id={x.PlaylistID} key={x.PlaylistID} onClick={() => setSelectedPlaylist(x)}>{x.PlaylistName}</CDropdownItem>
                                })
                            }
                        </CDropdownMenu>
                    </CDropdown>
                </>
                :
                <CFormInput ref={newPlaylistName} floatingLabel='New Playlist Name' maxLength={75} />
            }
            <CFormLabel className='mt-2'>Companies Selected</CFormLabel>
            <CListGroup className='list-group-scroll-400'>
                {companiesSelected == null || companiesSelected.length === 0 ? 'No Companies Selected' : companiesSelected.map((company, i) => {
                    return <CListGroupItem key={i}>{company.Name}</CListGroupItem>
                })}
            </CListGroup>
        </CModalBody>
        <CModalFooter>
            <CButton color='secondary' variant='outline' onClick={cancelFn}>Cancel</CButton>
            <CButton color='success' onClick={OnAddToPlaylist} style={{ whiteSpace: 'nowrap' }}>Add To Playlist</CButton>
        </CModalFooter>
    </CModal>);
}