import React, { Suspense, useEffect, useState } from 'react';
import { GetErrorToastComponent, HandleDataReturn, HandleResponseReturn } from '../../functions';

import {
    CButton,
    CModal,
    CModalBody,
    CModalFooter,
} from '@coreui/react';

const File = React.lazy(() => import('../miscellaneous/file'));

export default function ChangeLog({ visible, setVisible, setToast }) {
    const [changeLogFile, setChangeLogFile] = useState(null); // Change log in base64 form

    // Get Change Log only when modal shows
    useEffect(() => {
        if (!visible || changeLogFile != null) return;

        fetch('/api/file/change-log', {
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);
                setChangeLogFile(data);
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not get change log', err));
            });
    }, [visible]); 

    return <CModal
        alignment='center'
        scrollable={true}
        size='xl'
        visible={visible}
        onClose={() => setVisible(false)}
    >
        <CModalBody>
            <Suspense fallback={<div>Loading...</div>}>
                <File file={'data:application/pdf;base64,' + changeLogFile} />
            </Suspense>
        </CModalBody>
        <CModalFooter>
            <CButton style={{ width: '90px' }} onClick={() => setVisible(false)}>Close</CButton>
        </CModalFooter>
    </CModal>;
}