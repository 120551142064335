import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { cookieNames, userRoles } from '../../constants';
import {
    GetCookie,
    GetErrorToastComponent,
    GetBearer,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    GetToastComponent,
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CHeader,
    CHeaderText,
    CRow,
    CToaster,
    CTooltip
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faPen, faTrash} from '@fortawesome/free-solid-svg-icons';

import EmailBodyPreviewModal from '../../components/template/emailBodyPreviewModal';
import Table from '../../components/table/table';
import SkeletonTable from '../../components/table/skeletonTable';
import ConfirmModal from '../../components/miscellaneous/confirmModal';

export default function CampaignTemplate() {

    //#region Variables
    const [templates, setTemplates] = useState();

    // Body Preview Modal
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState();
    const [modalBody, setModalBody] = useState();

    // Confirm Delete Modal
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const templateToDelete = useRef();

    const isAdmin = useRef(JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin);
    const userId = useRef(parseInt(JSON.parse(GetCookie(cookieNames.userID))));
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState();
    const navigate = useNavigate();
    const headers = [
        {
            name: 'Name',
            headerClassName: 'name',
            colSort: true,
        },
        {
            name: 'Subject',
            headerClassName: 'subject',
        },
        {
            name: 'Body',
            headerClassName: 'body',
            moreThanJustText: true,
        },
        {
            name: 'Access',
            headerClassName: 'access',
        },
        {
            name: 'Button',
            headerClassName: 'text-light nav-buttons',
            moreThanJustText: true,
            columnClass: 'button-center'
        },
    ];

    //#endregion Variables

    //#region Functions

    // Delete template 
    // Only owner or admin can delete template
    function DeleteCampaignTemplate() {
        setShowConfirmModal(false);

        fetch('/api/campaign/delete-campaign-template?templateId=' + templateToDelete.current, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                // Delete successful will return true
                if (data === true) {
                    setToast(GetToastComponent('Email template deleted successfully!'));
                    GetTemplates(); // refresh templates
                } else {
                    throw new Error(data);
                }
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not delete template!', err.message));
            });
    }

    // Gets email templates
    function GetTemplates() {
        const ProcessData = (data) => {
            if (data == null) return;

            data.forEach((row, i) => {
                var valueWithElements = {};

                // Name
                row['Name'] = row.EmailTemplateName;
                row.EmailTemplateName = undefined;

                // Subject
                row['Subject'] = row.EmailTemplateSubject;
                row.EmailTemplateSubject = undefined;

                // Body
                row['Body'] = row.EmailTemplateHtmlBody;
                valueWithElements['Body'] = <CButton color='secondary' onClick={() => ShowPreview(row['Subject'], row['Body'])} disabled={row['Body'] === ''}>Preview</CButton>
                row.EmailTemplateHtmlBody = undefined;

                // Access
                row['Access'] = row.Permission;
                row.Permission = undefined;

                // Buttons
                valueWithElements['Button'] = <CRow>
                    <CCol>
                        <CTooltip content='Open Template'>
                            <CButton onClick={() => navigate(`/campaigns/templates/${row.EmailTemplateID}/profile`)} className='my-1'>
                                <FontAwesomeIcon icon={faAddressCard} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                    <CCol>
                        {/* Non admin can only edit templates they own. Admins can edit any template */}
                        <CTooltip content='Edit'>
                            <CButton
                                color='warning'
                                onClick={() => navigate(`/campaigns/templates/${row.EmailTemplateID}/edit`)}
                                className='my-1'
                                disabled={isAdmin.current === false && row.OwnerUserID !== userId.current}
                            >
                                <FontAwesomeIcon icon={faPen} style={{ color: 'white' }} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                    <CCol>
                        {/* Non admin can only delete templates they own. Admins can delete any template */}
                        <CTooltip content='Delete'>
                            <CButton
                                color='danger'
                                onClick={() => {
                                    setShowConfirmModal(true);
                                    templateToDelete.current = row.EmailTemplateID;
                                }}
                                className='my-1'
                                disabled={isAdmin.current === false && row.OwnerUserID !== userId.current}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                </CRow>;

                row['ValueWithElements'] = valueWithElements;
            })

            return data;
        }

        // Get campaign templates 
        setLoading(true);
        fetch('/api/campaign/get-campaign-templates?', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                const result = ProcessData(data);

                setTemplates(result);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not get templates!', err.message));
            });
    }

    // Show preview of email body in modal
    function ShowPreview(title, body) {
        // No body so nothing to show
        if (body === '') return;

        setShowModal(true);
        setModalTitle(title);
        setModalBody(body);
    }

    //#endregion Functions

    //#region Use Effect

    // Get Campaign Templates, show toast if applicable and set if admin
    useEffect(() => {
        GetTemplates(); // Get templates 

        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);
    }, []);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />

            {/* Show body preview modal */}
            <EmailBodyPreviewModal
                modalBody={modalBody}
                modalTitle={modalTitle}
                showModal={showModal}
                setShowModal={setShowModal}
            />

            {/* Show confirm delete modal */}
            <ConfirmModal 
                confirmFunction={DeleteCampaignTemplate}
                cancelFunction={() => setShowConfirmModal(false)}
                title='Delete Confirmation?'
                showModal={showConfirmModal}
                confirmColour='danger'
            />

            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Templates</CHeaderText>
                <CButton onClick={() => navigate('/campaigns/templates/add')}>Add Template</CButton>
            </CHeader>
            <CCard>
                <CCardBody>
                    {loading ?
                        <SkeletonTable numCols={4} numRows={14} tableColor={'light'} />
                        :
                        <Table
                            headers={headers}
                            body={templates}
                            hover={true}
                            color={'light'}
                            striped={true}
                            hasColSort={true}
                            hasPages={true}
                            tableClass='align-middle'
                        />
                    }
                </CCardBody>
            </CCard>
        </>
    );
}