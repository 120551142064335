import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { infoModalText, cookieNames, userRoles } from '../../constants';
import {
    FormatDate,
    GetCookie,
    GetErrorToastComponent,
    GetBearer,
    GetToastComponent,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    NavWithNewTab,
    UpdateSWFetch,
} from '../../functions';
import { AssignCurrentUserAsOwner } from '../../creditFunctions'

import {
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCol,
    CFormCheck,
    CHeader,
    CHeaderText,
    CRow,
    CToaster,
    CTooltip
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faCreditCard } from '@fortawesome/free-solid-svg-icons';

import InfoModal from '../../components/miscellaneous/infoModal';
import Table, { ClearSearchFilters } from '../../components/table/table';

export default function Credit() {

    //#region Variables
    const unfilteredCreditApps = useRef(); // List of all credit apps before Status filtering
    const [creditApps, setCreditApps] = useState(); // Credit apps
    const [statusFilter, setStatus] = useState('PND');

    const isCredit = useRef(JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Credit);
    const dayInMilliseconds = 86400000; // A day in milliseconds
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [toast, setToast] = useState();
    const headers = [
        {
            name: 'Company Name',
            headerClassName: 'company-name',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Request Type',
            headerClassName: 'request-type',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Salesperson',
            headerClassName: 'salesperson',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Last Modified By',
            headerClassName: 'last-modified-by',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Last Modified Date',
            headerClassName: 'last-modified-date',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Date Submitted',
            headerClassName: 'date-submitted',
            colSort: true,
            hasSearch: true,
            moreThanJustText: true,
        },
        {
            name: 'Assigned To',
            headerClassName: 'assigned-to',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Button',
            headerClassName: 'text-light nav-buttons',
            moreThanJustText: true,
            columnClass: 'button-center'
        },
    ];

    //#endregion Variables

    //#region Functions

    /** 
     * Get all credit applications, format data, and set into useState
     * @param {boolean} resetStatus defaults to true, true will set status to 'PND'
     * and false will keep status as is after fetch
     */
    function GetCreditApplications(resetStatus) {
        const ProcessData = (data) => {
            if (data == null) return;

            var dateNow = new Date();

            data.forEach((row, i) => {
                var valueWithElements = {};

                // Company Name
                row['Company Name'] = row.CompanyName;
                row.CompanyName = undefined;

                // Request Type
                row['Request Type'] = row.CompanyApprovalType;
                row.CompanyApprovalType = undefined;

                // Salesperson
                row['Salesperson'] = row.CreatedByName ?? '';
                row.CreatedByName = undefined;

                // Last Modified By
                row['Last Modified By'] = row.LastModifiedByName ?? '';
                row.LastModifiedByName = undefined;

                // Last Modified Date
                let modifyDate = new Date(row.LastModificationTime);
                row['Last Modified Date'] = FormatDate(modifyDate);
                row.LastModificationTime = undefined;

                // Date Submitted
                let submitDate = new Date(row.CreationTime);
                let dateString = FormatDate(submitDate);
                let difference = (dateNow.getTime() - submitDate.getTime()) / dayInMilliseconds;
                row['Date Submitted'] = dateString;

                // Assigned To 
                row['Assigned To'] = row.AssignedToName;
                row.AssignedToName = undefined;

                // If 15 days or more since submission date for PND and PRP
                if (difference >= 15 && (row.CompanyApprovalStatus === 'PND' || row.CompanyApprovalStatus === 'PRP')) {
                    valueWithElements['Date Submitted'] = <mark-yellow>{dateString}</mark-yellow>;
                }

                row.CreationTime = undefined;

                // Buttons
                valueWithElements['Button'] = <CRow>
                    <CCol className='button-col-format'>
                        <CTooltip content='View Credit Request'>
                            <CButton onClick={(e) => NavWithNewTab(e, navigate, `/credit/${row.CompanyApprovalProcessID}`)}>
                                <FontAwesomeIcon icon={faCreditCard} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                    <CCol className='button-col-format'>
                        <CTooltip content='Assign Request to Me'>
                            <CButton
                                color='secondary'
                                disabled={!isCredit.current}
                                onClick={() => {
                                    AssignCurrentUserAsOwner(row.CompanyApprovalProcessID)
                                        .then(result => {
                                            if (result) {
                                                GetCreditApplications(false);
                                                setToast(GetToastComponent('Credit application assigned'));
                                            } else {
                                                setToast(GetErrorToastComponent('Error: could not assign'));
                                            }
                                        })
                                        .catch(console.error);
                                }}
                            >
                                <FontAwesomeIcon icon={faClipboardCheck} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                </CRow>;

                row['ValueWithElements'] = valueWithElements;
            });

            return data;
        }
        const runAfterFetch = (data, reset) => {
            let result = ProcessData(data);

            unfilteredCreditApps.current = result;

            if (reset === false) OnStatusChange(statusFilter)
            else OnStatusChange('PND'); // Show pending status by default on load
        }

        let url = '/api/credit/get-credit-applications';
        UpdateSWFetch(url, (x) => runAfterFetch(x, resetStatus));
        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);
                runAfterFetch(data, resetStatus);
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not get credit applications!', err.message));
            })
            .finally(_ => setLoading(false));
    }

    /** 
     * Runs when a different status button is selected and filters 
     * the Credit Apps to match the status chosen
     * @param {string} status Takes 'PND', 'REV', 'REJ', and 'CMP' 
     */
    function OnStatusChange(status) {
        if (unfilteredCreditApps.current == null) return;

        var result = unfilteredCreditApps.current.filter(app => app.CompanyApprovalStatus === status);

        setStatus(status);
        setCreditApps(result);
    }

    //#endregion Functions

    //#region Use Effect

    // Get credit applications and show toast if applicable
    useEffect(() => {
        GetCreditApplications();

        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);
    }, []);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Credit</CHeaderText>
                <InfoModal modalContent={infoModalText.CreditPage} />
            </CHeader>
            <CCard>
                <CCardBody>
                    <div className='d-flex justify-content-between'>
                        {/* Status buttons */}
                        <CButtonGroup className='ms-3'>
                            <CFormCheck
                                label='Pending'
                                id='pending-button'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio'
                                onChange={() => OnStatusChange('PND')}
                                readOnly
                                checked={statusFilter === 'PND'}
                            />
                            <CFormCheck
                                label='Prepaid'
                                id='prepaid-button'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio'
                                onChange={() => OnStatusChange('PRP')}
                                readOnly
                                checked={statusFilter === 'PRP'}
                            />
                            <CFormCheck
                                label='Revising'
                                id='revising-button'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio'
                                onChange={() => OnStatusChange('REV')}
                                readOnly
                                checked={statusFilter === 'REV'}
                            />
                            <CFormCheck
                                label='Complete'
                                id='complete-button'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio'
                                onChange={() => OnStatusChange('CMP')}
                                readOnly
                                checked={statusFilter === 'CMP'}
                            />
                            <CFormCheck
                                label='Rejected'
                                id='rejected-button'
                                button={{ color: 'secondary', variant: 'outline' }}
                                type='radio'
                                name='btnradio'
                                onChange={() => OnStatusChange('REJ')}
                                readOnly
                                checked={statusFilter === 'REJ'}
                            />
                        </CButtonGroup>

                        {/* Clears input fields on table */}
                        <CTooltip content='Clear Search Inputs'>
                            <CButton color='secondary' className='me-4' onClick={ClearSearchFilters}>Clear</CButton>
                        </CTooltip>
                    </div>

                    <Table
                        headers={headers}
                        body={creditApps}
                        hover={true}
                        color={'light'}
                        striped={true}
                        hasColSort={true}
                        hasPages={true}
                        hasSearchRow={true}
                        tableClass='align-middle'
                        isLoading={loading}
                    />
                </CCardBody>
            </CCard>
        </>
    );
}