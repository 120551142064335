import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Carousel from 'react-bootstrap/Carousel';
import { GetErrorMessages, infoModalText, cookieNames, userRoles } from '../../constants';
import {
    GetCookie,
    GetContactsByIds,
    GetCountries,
    GetEngagementType,
    GetGrades,
    GetIndustries,
    GetParentCompanies,
    GetQuoteType,
    GetStateProvinces,
    GetSources,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    GetErrorToastComponent,
    GetToastComponent,
    SetToastComponentValuesInCookie,
    NavWithNewTab,
} from '../../functions';
import { AssignCurrentUserAsOwner } from '../../creditFunctions';

import {
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardTitle,
    CCol,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CHeader,
    CHeaderText,
    CInputGroup,
    CInputGroupText,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CNav,
    CNavItem,
    CNavLink,
    CRow,
    CTabContent,
    CTabPane,
    CToaster,
    CTooltip
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

import Notes from '../../components/company/notes';
import CreditRequestHistory from '../../components/company/creditRequestHistory';
import CollectionDetails from '../../components/company/collectionDetails';
import InfoModal from '../../components/miscellaneous/infoModal';
import ShowMoreInfoContactModal from '../../components/contact/showMoreInfoContactModal';

import '../../style/edit.css';
import '../../style/typeahead.css';
import '../../custom.css';
import Table from '../../components/table/table';

export default function CreditRequestEdit() {

    //#region Variables

    const [creditApp, setCreditApp] = useState({
        'CompanyApprovalProcessID': null,
        'CompanyAccountID': null,
        'CompanyApprovalStatus': null,
        'CompanyApprovalType': null,
        'CompletionDate': null,
        'CreationTime': null,
        'CreatedByUserID': null,
        'LastModifiedByUserID': null,
        'LastModificationTime': null,
        'ApprovedByUserId': null,
        'ApprovedAmount': null,
        'AssignedToUserId': null
    }); // application information
    const [account, setAccount] = useState({
        'CompanyAccountID': null,
        'CompanyName': '',
        'Description': null,
        'CompanyAccountEngagementTypeID': null,
        'CompanyAccountQuoteTypeID': null,
        'SourceID': null,
        'AnnualRevenue': null,
        'CompanyGradeID': null,
        'WebsiteURL': null,
        'IndustryID': null,
        'IndustryAbbr': null,
        'NumberOfEmployees': null,
        'CompanyLeadID': null,
        'ParentCompanyAccountID': null,
        'EnabledFlag': true,
        'DeletedFlag': false,
        'CreditApplicationReceivedFlag': false,
        'CreditApplicationRequestedAmount': null,
        'CreditApplicationApprovedAmount': null,
        'CreditApplicationAvailableAmount': null,
        'PrimaryContactID': null,
        'CreditContactID': null,
        'PhoneNumber': null,
        'PhoneNumberExtension': null,
        'FaxNumber': null,
        'AddressLine1': null,
        'AddressLine2': null,
        'City': null,
        'StateProvinceID': null,
        'ZipPostalCode': null,
        'AccountOwnerUserID': 0,
        'TMWCustomerId': null,
        'CreatedByUserID': 0,
        'LastModifiedByUserID': JSON.parse(GetCookie(cookieNames.userID)),
        'CountryID': null // Other fields not in CompanyAccount but useful
    }); // Company info associated with the credit application
    const [primaryContact, setPrimaryContact] = useState(null); // company primary contact info
    const [creditContact, setCreditContact] = useState(null); // company credit contact info
    const [accountOwner, setAccountOwner] = useState(null);
    const [validated, setValidated] = useState(false); // Form validation
    const companyAccountId = useRef(null); // Company account id
    const { companyApprovalId } = useParams();
    const navigate = useNavigate();
    const role = useRef(JSON.parse(GetCookie(cookieNames.userRole)));
    const dayInMilliseconds = 86400000; // A day in milliseconds

    // Component Refs
    const assignAppButton = useRef(); // Assign credit app to me button Ref
    const appStatusLabel = useRef();
    const form = useRef();

    // Dropdown menu options
    const [countryList, setCountryList] = useState([]);
    const [stateProvinceList, setStateProvinceList] = useState([]);
    const [sources, setSources] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [engagementTypes, setEngagementTypes] = useState([]);
    const [quoteTypes, setQuoteTypes] = useState([]);
    const [grades, setGrades] = useState([]);
    const [parentCompanies, setParentCompanies] = useState([]);

    // Selected option for dropdown menu
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [selectedParentCompany, setSelectedParentCompany] = useState([]);

    // Toast
    const [toast, setToast] = useState(null);

    // Contacts Show More Information Modal
    const tooltipContent = 'Show more information about contact. Can also edit contact information';
    const [showMoreInfoContact, setShowInfoContact] = useState(false); // bool indicating whether to display the modal
    const showMoreInfoSpread = useRef({}); //spread to be added to component

    // Credit Submit Extras Modal
    const [showCreditSubmitModal, setShowCreditSubmitModal] = useState(false);
    const [modalTitle, setModalTitle] = useState();
    const [modalCarouselIndex, setCarouselIndex] = useState(0);
    const [submitType, setSubmitType] = useState(); // Type of submission: REJ, REV, CMP, or Save
    const creditNote = useRef(null);
    const approvedCreditAmount = useRef(null);
    const tmwId = useRef(null); // Empty means create new company in TMW

    // Tabs
    const [activeTab, setActiveTab] = useState('Notes'); // Default shows notes

    const formatter = new Intl.NumberFormat('en-CA', { // Currency formatter
        style: 'currency',
        currency: 'CAD',
    });

    //#endregion Variables

    //#region Functions

    /** 
     * Gets the credit application and disables "Assign to Me" button accordingly
     */
    function GetCreditApplication() {
        fetch('/api/credit/get-credit-application?companyAppId=' + companyApprovalId, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                companyAccountId.current = data.CompanyAccountID;

                // If credit app assigned to user id matches userId, then assigned to current user
                if (data.AssignedToUserId != null) {
                    let userId = JSON.parse(GetCookie(cookieNames.userID));
                    if (data.AssignedToUserId === parseInt(userId)) {
                        assignAppButton.current.innerText = 'Already Assigned To Me';
                        assignAppButton.current.disabled = true;
                    }
                }

                // Assign button disabled if user is not credit 
                if (role.current !== userRoles.Credit) {
                    assignAppButton.current.disabled = true;
                }

                // Set credit status
                appStatusLabel.current.innerText = "Status:";
                let appStatus;
                switch (data.CompanyApprovalStatus) {
                    case 'PND':
                        appStatus = 'Pending';
                        break;
                    case 'PRP':
                        appStatus = 'Prepaid';
                        break;
                    case 'REV':
                        appStatus = 'Revising';
                        break;
                    case 'CMP':
                        appStatus = 'Completed';
                        break;
                    case 'REJ':
                        appStatus = 'Rejected';
                        break;
                    default:
                        appStatus = 'Unknown Status';
                        break;
                }
                appStatusLabel.current.innerText += ` ${appStatus}`;
                setCreditApp(data);
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not get credit application!', err.message));
            });
    }

    /**
     * Get the accounts associated with Company owner
     * @param {int} ownerAccountId Id of owner of account
     * @param {bool} initialLoad Is this first load. First load has additional functions like setting
     * the default value in the Typeahead component
     */
    function GetParentCompaniesToOwner(parentCompanyId, ownerAccountId, initialLoad) {
        // Empty id means no parent company to choose
        if (ownerAccountId === '') {
            setParentCompanies(null);
            return;
        }

        GetParentCompanies(companyAccountId.current, ownerAccountId)
            .then(x => {
                setParentCompanies(x);

                if (initialLoad) {
                    // Find the default selected value if exist for Typeahead component
                    if (parentCompanyId != null) {
                        let defaultParentCompany = x.find(p => p.CompanyAccountID === parentCompanyId);

                        // Set default value if exist
                        if (defaultParentCompany != null) {
                            setSelectedParentCompany([defaultParentCompany]);
                        }
                    }
                } else { // Calling after changing owners and not initial load
                    setSelectedParentCompany([]);
                }
            })
            .catch((err) => GetErrorToastComponent(GetErrorMessages.ParentCompanies, err.message));
    }

    /**
     * Runs update for useState on CompanyAccount data change
     * @param {event} e
     * @param {any} value Optional value that will be used if provided instead of e.target.value
     */
    function onAccDataChange(e, value) {
        // If Country is changed, need to reset StateProvince
        if (e.target.name === 'CountryID' && e.target.value !== account.CountryID) {
            // Get the first value for new country id to set in StateProvince
            let stateProvince = stateProvinceList.find(({ CountryID }) => CountryID === parseInt(e.target.value));

            // Set country and reset StateProvince to first state province in list
            setAccount({
                ...account,
                [e.target.name]: e.target.value,
                StateProvinceID: stateProvince == null ? null : stateProvince.StateProvinceID
            });

            // Enables or disables the province/state dropdown depending on whether there is a country selected
            const countryDropdown = document.getElementById('country-select');
            const stateProvinceDropdown = document.getElementById('state-province-select');

            if (countryDropdown == null || stateProvinceDropdown == null) return;

            // If no value is selected for country
            if (countryDropdown.value === '') {
                stateProvinceDropdown.disabled = true;
            } else {
                stateProvinceDropdown.disabled = false;
            }
        } else { // All other inputs]
            let newValue = value ?? e.target.value;
            // Saves value if provided or uses e.target.value
            setAccount({ ...account, [e.target.name]: newValue === '' ? null : newValue });
        }
    }

    /**
     * Shows modal to add credit note and tmw id if all fields are valid. 
     * If there are invalid fields, show appropriate validation message
     * @param {event} e
     * @param {string} type The type being submitted. Save, CMP, REV, or REJ
     */
    function OnInitialSubmit(e, type) {
        if (!ValidateForm(e)) return;

        setSubmitType(type);
        setModalTitle(`Add Note in ${type} Email`);
        setCarouselIndex(0);
        setShowCreditSubmitModal(true);
    }

    /**
     * Submit button click modal. Different behaviour depending on which carousel page is showing.
     * First page is the credit note, second is selecting tmw id
     * @param {event} e
     */
    function OnModalSubmit(e) {
        /**
         * Closes modal and updates Credit App
         * @param {event} e
         */
        const CloseModalAndUpdateApp = (e) => {
            setShowCreditSubmitModal(false);
            UpdateCreditApplication(e, submitType);
        };

        /**
         * Runs before showing the carosel that lets user select tmw id
         * Makes sure it should actually show and if not, just runs submit
         * @param {event} e
         */
        const PreTmwIdSelect = (e) => {
            // If there already exists a tmw id, don't need to select one
            if (submitType === 'CMP' && account.TMWCustomerId == null) {
                setCarouselIndex(2);
                setModalTitle('Choose TMW Id Or Generate New One');
            } else {
                CloseModalAndUpdateApp(e);
            }
        };

        // modalCarouselIndex
        // 0 = Credit Note
        // 1 = Approved Credit Amount
        // 2 = Choose Existing or New TMW ID

        // If currently in email credit note section
        if (modalCarouselIndex === 0) {
            // If clicking Approve or Prepaid option which completes credit app
            if (submitType === 'CMP') {
                // If Prepaid status, does not need to set credit amount since it will be 0
                // Note: PRP does not have REV option so will never go to REV status
                if (creditApp.CompanyApprovalStatus === 'PRP') {
                    approvedCreditAmount.current = 0;
                    PreTmwIdSelect(e);
                } else { // Approving with credit
                    setCarouselIndex(1);
                    setModalTitle('Set Approved Credit Amount');
                }

            } else { // If REV or REJ, don't need to proceed to next steps and just save
                // If rejecting, approved credit should be 0
                if (submitType === 'REJ') approvedCreditAmount.current = 0;

                CloseModalAndUpdateApp(e);
            }

            // If currently in credit amount section. Will only proceed to this stage if CMP is Approve clicked
        } else if (modalCarouselIndex === 1) {
            // Validate credit amount
            if (approvedCreditAmount.current == null || approvedCreditAmount.current === '') {
                setToast(GetToastComponent('Approved Credit Amount cannot be empty', null, 'warning'));
                return;
            } else if (account.CreditApplicationApprovedAmount != null && parseInt(approvedCreditAmount.current) <= account.CreditApplicationApprovedAmount) {
                setToast(GetToastComponent('Approved Credit amount must be higher than $' + account.CreditApplicationApprovedAmount, null, 'warning'));
                return;
            }

            PreTmwIdSelect(e);

            // If currently in TMW ID select section
        } else if (modalCarouselIndex === 2) { // After TMW carosel section for Approve or Prepaid button 
            var useExistingChecked = document.getElementById('use-existing-toggle').checked;

            // If showing tmw id selection, use existing is toggled but nothing selected
            if (useExistingChecked && tmwId.current === null) {
                setToast(GetToastComponent('Please select a Tmw Id if using existing', null, 'warning'));
                return;
            }

            CloseModalAndUpdateApp(e);
        }
    }

    /**
     * Show modal with options to change contacts
     * @param {int} buttonId Id of Details button. Either 'primary-contact-options' or 'credit-contact-options'.
     * Shows corresponding modal
     */
    function ShowContactOptions(buttonId) {
        // Set the contact type
        let type;
        switch (buttonId) {
            case 'primary-contact-options':
                type = 'Primary';
                break;
            case 'credit-contact-options':
                type = 'Credit';
                break;
        }

        showMoreInfoSpread.current.contactType = type;

        // Pass on the contact information relating to type
        if (type === 'Primary' && primaryContact.ContactID != null) {
            showMoreInfoSpread.current.contactId = primaryContact.ContactID;
        } else if (type === 'Credit' && creditContact.ContactID != null) {
            showMoreInfoSpread.current.contactId = creditContact.ContactID;
        }

        // Show modal if id exists
        if (showMoreInfoSpread.current.contactId != null) {
            setShowInfoContact(true);
        } else { // Show error toast since id does not exist
            setToast(GetToastComponent(`Cannot show '` + type + ` Contact' details since it has not been set`, null, 'warning'));
        }
    }

    /**
     * Updates Credit application with updated information passed in form
     * @param {event} e
     * @param {string} action The action type that is running the update. Save, CMP, REV, or REJ
     */
    function UpdateCreditApplication(e, action) {
        e.preventDefault();

        if (action === 'Save' && !ValidateForm(e)) return;

        const formObj = {
            Account: {
                ...account,
                ParentCompanyAccountID: selectedParentCompany.length === 0 ? null : selectedParentCompany[0].CompanyAccountID,
                IndustryID: selectedIndustry.length === 0 ? null : selectedIndustry[0].IndustryID,
                TMWCustomerID: account.TMWCustomerId ?? tmwId.current, // Use default tmw ID or chosen tmw id if exist
                CreditApplicationApprovedAmount: approvedCreditAmount.current
            },
            PrimaryContactFirstName: primaryContact.FirstName,
            PrimaryContactLastName: primaryContact.LastName,
            PrimaryContactEmail: primaryContact.EmailAddress,
            CreditContactFirstName: creditContact.FirstName,
            CreditContactLastName: creditContact.LastName,
            CreditContactEmail: creditContact.EmailAddress,
            CompanyApprovalProcessID: creditApp.CompanyApprovalProcessID,
            CompanyApprovalStatus: action === 'Save' ? creditApp.CompanyApprovalStatus : action, // New status if not save action
            CompanyApprovalType: creditApp.CompanyApprovalType,
            SaveOnly: action === 'Save',
            CreditNote: creditNote.current
        };

        // Reset ref values from modal
        approvedCreditAmount.current = null;
        creditNote.current = null;
        tmwId.current = null

        fetch('/api/credit/update-application', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(formObj)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data.Errors.length === 0) {
                    setToast(GetToastComponent(data.Status, null, 'success'));
                    GetCreditApplication(); // Refresh page
                } else {
                    throw new Error(JSON.stringify(data.Errors));
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('An error has occurred when updating!', err.message));
            });
    }

    /**
     * Validates the form data
     * @param {any} e Event
     * @returns true meaning valid data or false
     */
    function ValidateForm(e) {
        e.preventDefault();

        //Checks if all required fields are filled
        if (form.current.checkValidity() === false) {
            e.stopPropagation()
            setValidated(true); // show validation

            // Get the first invalid element
            var firstInvalidElem = document.querySelector('input:invalid, select:invalid');

            // Scroll to element
            if (firstInvalidElem != null) {
                firstInvalidElem.focus();
            }

            return false;
        }

        return true;
    }

    //#region Section Components

    /**
     * Modal to add credit note before submitting
     * @param {bool} includeTMWSearch true will include the tmw search component
     * @returns
     */
    function SubmitCreditExtrasModalComponent(includeTMWSearch) {
        return (<CModal
            alignment='center'
            visible={showCreditSubmitModal}
            onClose={() => setShowCreditSubmitModal(false)}
            size='lg'
            scrollable
        >
            <CModalHeader closeButton={false}>
                <CModalTitle>{modalTitle}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <Carousel activeIndex={modalCarouselIndex} controls={false} indicators={false}>
                    <Carousel.Item>
                        <CFormInput name='CreditNote' onChange={e => creditNote.current = e.target.value} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <CRow className='mb-2'>
                            <CCol>
                                <span className='fw-medium'>Requested Credit:</span>
                                <span className='ms-1 fw-light'>
                                    {account.CreditApplicationRequestedAmount == null ? 'N/A' : formatter.format(account.CreditApplicationRequestedAmount)}
                                </span>
                            </CCol>
                            <CCol>
                                <span className='fw-medium'>Credit Amount Approved:</span>
                                <span className='ms-1 fw-light'>
                                    {account.CreditApplicationApprovedAmount == null ? 'N/A' : formatter.format(account.CreditApplicationApprovedAmount)}
                                </span>
                            </CCol>
                        </CRow>
                        <CFormInput name='Account.CreditApplicationApprovedAmount' onChange={e => approvedCreditAmount.current = e.target.value} type='number' />
                    </Carousel.Item>
                    {includeTMWSearch ?
                        <Carousel.Item>
                            <TMWSelectTable companyId={companyAccountId.current} tmwId={tmwId} setToast={setToast} />
                        </Carousel.Item>
                        : null
                    }
                </Carousel>
            </CModalBody>
            <CModalFooter>
                <CButton
                    color='secondary'
                    style={{ width: '90px' }}
                    onClick={() => {
                        setShowCreditSubmitModal(false);

                        // Reset input refs
                        creditNote.current = null;
                        approvedCreditAmount.current = null;
                        tmwId.current = null;
                    }}
                    className='me-3'
                >
                    Cancel
                </CButton>
                <CButton color='success' style={{ width: '90px' }} onClick={e => OnModalSubmit(e)}>
                    Submit
                </CButton>
            </CModalFooter>
        </CModal>);
    }

    /**
     * The field at the top of the page under Submitter Information section
     * @returns Component
     */
    function DaysSinceRequestMadeComponent() {
        if (creditApp == null) return <span className='pe-5'>N/A</span>;

        // Set the value for days since application created
        let now = new Date();
        let submitDate = new Date(creditApp.CreationTime);
        let difference = Math.round((now.getTime() - submitDate.getTime()) / dayInMilliseconds);

        if (difference >= 15) {
            return <mark-yellow className='pe-5'>{difference}</mark-yellow>
        } else {
            return <span className='pe-5'>{difference}</span>
        }
    }

    //#endregion Section Components

    //#endregion Functions

    //#region Use Effect

    // Get credit application on component load
    useEffect(() => {
        GetCreditApplication(); // Initial credit app

        // Dropdown fields
        GetCountries()
            .then(x => setCountryList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Countries, err.message)));

        GetStateProvinces()
            .then(x => setStateProvinceList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.StateProvince, err.message)));

        GetSources()
            .then(x => setSources(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Sources, err.message)));

        GetEngagementType()
            .then(x => setEngagementTypes(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.EngagementType, err.message)));

        GetGrades()
            .then(x => setGrades(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Grades, err.message)));

        GetQuoteType()
            .then(x => setQuoteTypes(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.QuoteType, err.message)));
    }, []);

    // Get all the data for the drop down select fields
    useEffect(() => {
        if (companyAccountId.current == null) return;

        const getCompanyAccountInfo = async () => {
            const response = await fetch('/api/companyaccounts/get-company-by-id?id=' + companyAccountId.current, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            })

            const data = await HandleResponseReturn(response);
            return HandleDataReturn(data);
        }

        if (creditApp == null) return;

        getCompanyAccountInfo()
            .then(acc => {
                setAccount(acc);

                //#region Contacts

                // Get the contacts if exist
                let contactIds = [];

                if (acc.PrimaryContactID != null) {
                    contactIds.push(acc.PrimaryContactID);
                } else { // Set primary contact with empty fields
                    setPrimaryContact({
                        FirstName: null,
                        LastName: null,
                        EmailAddress: null
                    });
                }

                if (acc.CreditContactID != null) {
                    contactIds.push(acc.CreditContactID);
                } else { // Set credit contact with empty fields
                    setCreditContact({
                        FirstName: null,
                        LastName: null,
                        EmailAddress: null
                    });
                }

                if (contactIds.length > 0) {
                    GetContactsByIds(contactIds)
                        .then(data => {
                            data.forEach(c => {
                                if (c.ContactID === acc.PrimaryContactID) {
                                    setPrimaryContact(c);
                                } else if (c.ContactID === acc.CreditContactID) {
                                    setCreditContact(c);
                                }
                            });
                        })
                        .catch(console.error);
                }

                //#endregion Contacts

                //#region Parent Companies and owner info

                // Get Account owner info as well as parent companies to owner
                if (acc.AccountOwnerUserID != null) {
                    // Load the parent companies initially if exist
                    GetParentCompaniesToOwner(acc.ParentCompanyAccountID, acc.AccountOwnerUserID, true);

                    fetch('/api/user/get-owner-info-by-id?userId=' + acc.AccountOwnerUserID, {
                        headers: { 'Authorization': 'Bearer ' + GetBearer() },
                        method: 'GET'
                    })
                        .then(response => HandleResponseReturn(response))
                        .then(data => {
                            data = HandleDataReturn(data);

                            setAccountOwner(data);
                        })
                        .catch(err => {
                            console.error(err);
                            setToast(GetErrorToastComponent('Error: Could not get account owner info', err.message));
                        });
                }

                //#endregion Parent Companies

                //#region Industries

                GetIndustries()
                    .then(x => {
                        setIndustries(x);

                        // Find if value already exist
                        if (acc.IndustryID != null) {
                            let defaultIndustry = x.find(i => i.IndustryID === acc.IndustryID);

                            // Set default value if exist
                            if (defaultIndustry != null) {
                                setSelectedIndustry([defaultIndustry]);
                            }
                        }
                    })
                    .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Industries, err.message)));

                //#endregion 

            })
            .catch(err => {
                console.log(err);
                SetToastComponentValuesInCookie('Error: Could not get company account information!', err.message, 'danger', false);

                // Redirect to company accounts page if there is error fetching data
                navigate('/company-accounts');
            });


    }, [companyAccountId.current]);

    // Sets CountryID on first load if StateProvince is populated
    useEffect(() => {
        if (account == null || stateProvinceList.length === 0 || account.StateProvinceID == null) return;

        // This only happens on first load
        if (account.CountryID == null) {
            // Get the country of the selected stateprovince
            let countryId = stateProvinceList.find(({ StateProvinceID }) => StateProvinceID === account.StateProvinceID).CountryID;
            setAccount({ ...account, CountryID: countryId.toString() });
        }
    }, [account, stateProvinceList]);

    //#endregion Use Effect

    return (<>
        {/* Show more info about the contact */}
        <ShowMoreInfoContactModal
            closeModal={() => setShowInfoContact(false)}
            navigateToEditContact={(contactId) => navigate(`/contacts/${contactId}/edit`)}
            showModal={showMoreInfoContact}
            {...showMoreInfoSpread.current}
        />

        {/* Submit with credit message (and choose tmwId for approve if needed)  */}
        {SubmitCreditExtrasModalComponent(submitType === 'CMP')}

        <CToaster push={toast} placement='top-end' />
        <CHeader container='fluid'>
            <CHeaderText className='fs-3 justify-content-between title-display-content'>
                <span>
                    Credit Application
                    <span ref={appStatusLabel} className='fs-6 ms-2' />
                </span>
                <div>
                    <CButton
                        onClick={(e) => NavWithNewTab(e, navigate, `/company-accounts/${account.CompanyAccountID}/profile`)}
                        style={{ backgroundColor: '#337ab7' }}
                        className='me-2'
                        disabled={account.CompanyAccountID == null}
                    >
                        Company Profile
                    </CButton>
                    <CButton
                        ref={assignAppButton}
                        color='secondary'
                        onClick={() => {
                            AssignCurrentUserAsOwner(creditApp.CompanyApprovalProcessID)
                                .then(result => {
                                    if (result) {
                                        GetCreditApplication();
                                        setToast(GetToastComponent('Credit application assigned'));
                                    } else {
                                        setToast(GetErrorToastComponent('Error: could not assign'));
                                    }
                                })
                                .catch(console.error);
                        }}
                    >
                        Assign To Me
                    </CButton>
                    <InfoModal modalContent={infoModalText.CreditEditPage} />
                </div>
            </CHeaderText>
        </CHeader>
        <CCard>
            <CCardBody className='px-5'>
                {account.DeletedFlag ?
                    <mark-yellow>Note: This account has been deleted</mark-yellow> : null
                }
                <CForm
                    ref={form}
                    className='mt-3'
                    method='POST'
                    validated={validated}
                >
                    {/* Basic Company Info Section */}
                    {accountOwner == null ? null :
                        <div className='mb-4 fs-6'>
                            <span className='fw-semibold fs-5 pe-5'>Submitter Information</span>
                            <span className='fw-semibold'>Name: </span><span className='pe-5'>{accountOwner.Name ?? 'N/A'}</span>
                            <span className='fw-semibold'>Division: </span><span className='pe-5'>{accountOwner.Division ?? 'N/A'}</span>
                            <span className='fw-semibold'>Phone #: </span><span className='pe-5'>{accountOwner.PhoneNumber ?? 'N/A'}</span>
                            <span className='fw-semibold'>Email: </span><span className='pe-5'>{accountOwner.Email ?? 'N/A'}</span>
                            <span className='fw-semibold'>Days Since Request Made: </span>
                            <DaysSinceRequestMadeComponent />
                        </div>
                    }

                    {/* Required Information Section */}
                    <CCardTitle className='justify-content-between title-display-content'>
                        Required Information
                        <CButton
                            name='CreditApplicationReceivedFlag'
                            type='button'
                            className='my-2'
                            color='secondary'
                            active={account.CreditApplicationReceivedFlag}
                            onClick={e => onAccDataChange(e, !account.CreditApplicationReceivedFlag)}
                            disabled={role.current !== userRoles.Admin && role.current !== userRoles.Credit}
                        >
                            {
                                account.CreditApplicationReceivedFlag ?
                                    <FontAwesomeIcon className='me-2' icon={faCheckSquare} />
                                    : <FontAwesomeIcon className='me-2' icon={faSquare} />
                            }
                            Credit Application Received
                        </CButton>
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3 required-label'>Company Name</CFormLabel>
                            <CFormInput
                                type='text'
                                id='Name'
                                name='CompanyName'
                                value={account.CompanyName}
                                onChange={onAccDataChange}
                                maxLength='150'
                                feedbackInvalid='Required field'
                                required
                            />
                            <CRow>
                                <CCol>
                                    <CFormLabel className='mt-3 required-label'>Engagement Type</CFormLabel>
                                    <CFormSelect
                                        value={account.CompanyAccountEngagementTypeID}
                                        name='CompanyAccountEngagementTypeID'
                                        onChange={onAccDataChange}
                                        required
                                    >
                                        {engagementTypes.map((engagement) => {
                                            return (
                                                <option
                                                    key={engagement.CompanyAccountEngagementTypeID}
                                                    value={engagement.CompanyAccountEngagementTypeID}
                                                >
                                                    {engagement.Name}
                                                </option>
                                            )
                                        })}
                                    </CFormSelect>
                                </CCol>
                                <CCol>
                                    {account.CompanyAccountEngagementTypeID == 1 ||
                                        account.CompanyAccountEngagementTypeID == 9 ||
                                        account.CompanyAccountEngagementTypeID == 10 ||
                                        account.CompanyAccountEngagementTypeID == 11 ? <CFormLabel className='mt-3 required-label'>Quote Type</CFormLabel> : <CFormLabel className='mt-3'>Quote Type</CFormLabel>}
                                    <CFormSelect
                                        name='CompanyAccountQuoteTypeID'
                                        value={account.CompanyAccountQuoteTypeID}
                                        onChange={onAccDataChange}
                                        required={
                                            account.CompanyAccountEngagementTypeID == 1 ||
                                            account.CompanyAccountEngagementTypeID == 9 ||
                                            account.CompanyAccountEngagementTypeID == 10 ||
                                            account.CompanyAccountEngagementTypeID == 11
                                        }
                                    >
                                        <option />
                                        {quoteTypes.map((quoteType) => {
                                            return (
                                                <option
                                                    key={quoteType.CompanyAccountQuoteTypeID + '-qoute-type'}
                                                    value={quoteType.CompanyAccountQuoteTypeID}
                                                >
                                                    {quoteType.QuoteTypeName}
                                                </option>
                                            )
                                        })}
                                    </CFormSelect>
                                </CCol>
                            </CRow>
                        </CCol>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3'>TMW Customer Id</CFormLabel>
                            <CFormInput type='text' value={account.TMWCustomerId} onChange={onAccDataChange} name="TMWCustomerId" placeholder='Filled in when approved' disabled />

                            <CFormLabel className='mt-3 required-label'>Grade</CFormLabel>
                            <CFormSelect value={account.CompanyGradeId} onChange={onAccDataChange} name='CompanyGradeId' required>
                                <option />
                                {grades.map((grade) => {
                                    return (
                                        <option
                                            key={grade.CompanyGradeID}
                                            value={grade.CompanyGradeID}
                                        >
                                            {grade.Grade} {grade.Description}
                                        </option>
                                    )
                                })}
                            </CFormSelect>
                        </CCol>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3 required-label label-auto-width'>Primary Contact Name</CFormLabel>
                            <CTooltip content={tooltipContent}>
                                <CButton
                                    id='primary-contact-options'
                                    className='ms-2'
                                    color='secondary'
                                    size='sm'
                                    type='button'
                                    onClick={(e) => ShowContactOptions(e.target.id)}
                                >
                                    Details
                                </CButton>
                            </CTooltip>
                            {primaryContact == null ? null : (
                                <>
                                    <CRow>
                                        <CCol sm={12} md={6}>
                                            <CFormInput
                                                type='text'
                                                name='PrimaryContactFirstName'
                                                value={primaryContact.FirstName}
                                                onChange={(e) => setPrimaryContact({ ...primaryContact, FirstName: e.target.value })}
                                                placeholder='First Name'
                                                feedbackInvalid='Required field'
                                                maxLength='75'
                                                required
                                            />
                                        </CCol>
                                        <CCol sm={12} md={6}>
                                            <CFormInput
                                                type='text'
                                                name='PrimaryContactLastName'
                                                value={primaryContact.LastName}
                                                onChange={(e) => setPrimaryContact({ ...primaryContact, LastName: e.target.value })}
                                                placeholder='Last Name'
                                                feedbackInvalid='Required field'
                                                maxLength='75'
                                                required
                                            />
                                        </CCol>
                                    </CRow>
                                    <CFormLabel className='mt-3 required-label'>Primary Contact Email</CFormLabel>
                                    <CFormInput
                                        type='email'
                                        name='PrimaryContactEmail'
                                        value={primaryContact.EmailAddress}
                                        onChange={(e) => setPrimaryContact({ ...primaryContact, EmailAddress: e.target.value })}
                                        maxLength='150'
                                        feedbackInvalid='Required field with valid email format'
                                        required />
                                </>
                            )}
                        </CCol>
                    </CRow>

                    {/* Address Section */}
                    <CCardTitle className='justify-content-between title-display-content mt-5'>
                        Address
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3 required-label'>Address</CFormLabel>
                            <CFormInput type='text' name='AddressLine1' onChange={onAccDataChange} value={account.AddressLine1} maxLength='75' feedbackInvalid='Required field' required />
                            <CFormInput type='text' name='AddressLine2' onChange={onAccDataChange} value={account.AddressLine2} maxLength='75' className='mt-3' />
                        </CCol>
                        <CCol md={12} lg={4}>
                            <div>
                                <CFormLabel className='mt-3 required-label'>City</CFormLabel>
                                <CFormInput type='text' name='City' onChange={onAccDataChange} value={account.City} maxLength='75' feedbackInvalid='Required field' required />
                            </div>
                            <div>
                                <CFormLabel className='mt-3 required-label'>Province/State</CFormLabel>
                                <CFormSelect
                                    id='state-province-select'
                                    value={account.StateProvinceID}
                                    name='StateProvinceID'
                                    onChange={onAccDataChange}
                                    feedbackInvalid='Required field'
                                    required
                                >
                                    {stateProvinceList.map((stateProvince) => {
                                        // Only map if the country matches
                                        if (stateProvince.CountryID.toString() === account.CountryID) {
                                            return (<option key={stateProvince.StateProvinceID} value={stateProvince.StateProvinceID}>{stateProvince.StateProvinceName}</option>)
                                        }
                                        return null;
                                    })}
                                </CFormSelect>
                            </div>
                        </CCol>
                        <CCol md={12} lg={4}>
                            <div>
                                <CFormLabel className='mt-3 required-label'>Country</CFormLabel>
                                <CFormSelect
                                    id='country-select'
                                    value={account.CountryID}
                                    name='CountryID'
                                    onChange={onAccDataChange}
                                    feedbackInvalid='Required field'
                                    required
                                >
                                    <option />
                                    {countryList.map((country) => {
                                        return (<option key={country.CountryID} value={country.CountryID}>{country.CountryName}</option>)
                                    })}
                                </CFormSelect>
                            </div>
                            <div>
                                <CFormLabel className='mt-3 required-label'>Postal/Zip Code</CFormLabel>
                                <CFormInput type='text' name='ZipPostalCode' onChange={onAccDataChange} value={account.ZipPostalCode} maxLength='15' feedbackInvalid='Required field' required />
                            </div>
                        </CCol>
                    </CRow>

                    {/* Optional Section */}
                    <CCardTitle className='justify-content-between title-display-content mt-5'>
                        Optional Information
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3'>Parent Company</CFormLabel>
                            <div id='parent-company-typeahead'>
                                <Typeahead
                                    name='Typeahead.ParentCompany'
                                    options={parentCompanies}
                                    labelKey={option => option.TmwCustomerId != null ?
                                        `${option.CompanyName} - ${option.TmwCustomerId}` : `${option.CompanyName}`
                                    }
                                    onChange={setSelectedParentCompany}
                                    selected={selectedParentCompany}
                                    id='parent-company'
                                    clearButton={true}
                                    emptyLabel='No matches found, only options chosen from dropdown will be saved'
                                    renderMenuItemChildren={option => (<div className='d-flex justify-content-between'>
                                        <span>{option.CompanyName}</span>
                                        {option.TmwCustomerId != null && <span className='fw-b'>{option.TmwCustomerId}</span>}
                                    </div>)}
                                />
                            </div>

                            <CFormLabel className='mt-3 required-label'>Account Source</CFormLabel>
                            <CFormSelect value={account.SourceID} name='SourceID' onChange={onAccDataChange} required>
                                <option />
                                {sources.map((source) => {
                                    return (<option key={source.SourceID} value={source.SourceID}>{source.Name}</option>)
                                })}
                            </CFormSelect>
                        </CCol>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3 required-label'>Industry</CFormLabel>
                            <div id='industry-typeahead'>
                                <Typeahead
                                    name='Typeahead.Industry'
                                    options={industries}
                                    labelKey={option => `${option.Industry}`}
                                    onChange={setSelectedIndustry}
                                    selected={selectedIndustry}
                                    id='industry'
                                    emptyLabel='No matches found, only options chosen from dropdown will be saved'
                                    isInvalid={validated && selectedIndustry.length === 0}
                                    inputProps={{ required: true }}
                                />
                                <div className='invalid-feedback required-field'>Required field that is one of the options listed</div>
                            </div>

                            <CFormLabel className='mt-3'>Website</CFormLabel>
                            <CInputGroup>
                                <CInputGroupText>https://</CInputGroupText>
                                <CFormInput type='text' name='WebsiteURL' value={account.WebsiteURL} onChange={onAccDataChange} maxLength='255' />
                            </CInputGroup>
                        </CCol>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3'>Number Of Employees</CFormLabel>
                            <CFormInput
                                type='text'
                                name='NumberOfEmployees'
                                value={account.NumberOfEmployees}
                                onChange={onAccDataChange}
                                pattern='^\d*$'
                                feedbackInvalid='Only positive integer values allowed'
                            />

                            <CFormLabel className='mt-3'>Annual Revenue</CFormLabel>
                            <CInputGroup>
                                <CInputGroupText>$</CInputGroupText>
                                <CFormInput type='number' name='AnnualRevenue' value={account.AnnualRevenue} onChange={onAccDataChange} />
                            </CInputGroup>

                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol md={12} lg={8}>
                            <CFormLabel className='mt-3'>Description</CFormLabel>
                            <CFormTextarea type='text' name='Description' value={account.Description} onChange={onAccDataChange} />
                        </CCol>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3'>Phone Number</CFormLabel>
                            <CInputGroup>
                                <CFormInput type='text' name='PhoneNumber' maxLength='20' value={account.PhoneNumber} onChange={onAccDataChange} />
                                <div className='input-group-spacer' style={{ width: '5%' }} />
                                <CInputGroupText>Ext</CInputGroupText>
                                <CFormInput type='text' name='PhoneNumberExtension' value={account.PhoneNumberExtension} onChange={onAccDataChange} maxLength='6' style={{ maxWidth: '30%' }} />
                            </CInputGroup>

                            <CFormLabel className='mt-3'>Fax Number</CFormLabel>
                            <CFormInput type='text' name='FaxNumber' value={account.FaxNumber} onChange={onAccDataChange} maxLength='15' />
                        </CCol>
                    </CRow>

                    {/* Credit Information Section */}
                    <CCardTitle className='justify-content-between title-display-content mt-5'>
                        Credit Information
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3'>Requested Credit Amount</CFormLabel>
                            <CFormInput
                                value={creditApp.CompanyApprovalStatus === 'PRP' ? 'No Credit for Prepaid' : account.CreditApplicationRequestedAmount}
                                disabled
                            />

                            <CFormLabel className='mt-3'>Available Credit Amount</CFormLabel>
                            <CFormInput type='text' value={account.CreditApplicationAvailableAmount ?? 0} disabled />
                        </CCol>
                        <CCol md={12} lg={4}>
                            <CFormLabel className='mt-3 label-auto-width'>AP Credit Contact</CFormLabel>
                            <CTooltip content={tooltipContent} >
                                <CButton
                                    id='credit-contact-options'
                                    className='ms-2'
                                    color='secondary'
                                    size='sm'
                                    type='button'
                                    onClick={(e) => ShowContactOptions(e.target.id)}
                                >
                                    Details
                                </CButton>
                            </CTooltip>
                            {creditContact == null ? null : (
                                <>
                                    <CRow>
                                        <CCol sm={12} md={6}>
                                            <CFormInput
                                                type='text'
                                                name='CreditContactFirstName'
                                                value={creditContact.FirstName}
                                                onChange={(e) => setCreditContact({ ...creditContact, FirstName: e.target.value })}
                                                placeholder='First Name'
                                                maxLength='75'
                                            />
                                        </CCol>
                                        <CCol sm={12} md={6}>
                                            <CFormInput
                                                type='text'
                                                name='CreditContactLastName'
                                                value={creditContact.LastName}
                                                onChange={(e) => setCreditContact({ ...creditContact, LastName: e.target.value })}
                                                placeholder='Last Name'
                                                maxLength='75'
                                            />
                                        </CCol>
                                    </CRow>
                                    <CFormInput
                                        type='text'
                                        className='mt-3'
                                        name='CreditContactEmail'
                                        placeholder='Email'
                                        value={creditContact.EmailAddress}
                                        onChange={(e) => setCreditContact({ ...creditContact, EmailAddress: e.target.value })}
                                        maxLength='150' />
                                </>
                            )}
                        </CCol>
                        {creditApp.CompanyApprovalStatus === 'PRP' ? null :
                            <CCol md={12} lg={4}>
                                <CFormLabel className='mt-3'>Current Approved Credit Amount</CFormLabel>
                                <CFormInput type='text' name='CreditApplicationApprovedAmount' value={account.CreditApplicationApprovedAmount} disabled />
                                {creditApp.CompanyApprovalType === 'Credit Increase' ? <>
                                    <CFormLabel className='mt-3'>Requested Increase</CFormLabel>
                                    <CFormInput type='text' value={account.CreditApplicationRequestedAmount - account.CreditApplicationApprovedAmount} disabled />
                                </> : null}
                            </CCol>
                        }
                    </CRow>

                    {/* Button Section */}
                    <CRow className='mt-4 bottom-button-group justify-content-center'>
                        <CButton color='secondary' onClick={() => navigate('/credit')}>Cancel</CButton>
                        <CButton
                            value='Save'
                            style={{ backgroundColor: '#337ab7' }}
                            onClick={e => {
                                setSubmitType('Save');
                                UpdateCreditApplication(e, 'Save');
                            }}
                        >
                            Save
                        </CButton>
                        {/* Prepaid will show approve button as 'Prepaid' and don't have 'Revise' option */}
                        {creditApp.CompanyApprovalStatus === 'PRP' ?
                            <CButton
                                color='success'
                                disabled={role.current !== userRoles.Credit}
                                onClick={(e) => OnInitialSubmit(e, 'CMP')}
                            >
                                Prepaid
                            </CButton>
                            :
                            <>
                                <CButton
                                    color='warning'
                                    disabled={role.current !== userRoles.Credit || creditApp.CompanyApprovalStatus === 'CMP'}
                                    onClick={(e) => OnInitialSubmit(e, 'REV')}
                                >
                                    Revise
                                </CButton>
                                <CButton
                                    color='success'
                                    disabled={role.current !== userRoles.Credit || creditApp.CompanyApprovalStatus === 'CMP'}
                                    onClick={(e) => OnInitialSubmit(e, 'CMP')}
                                >
                                    Approve
                                </CButton>
                            </>
                        }
                        <CButton
                            color='danger'
                            disabled={role.current !== userRoles.Credit || creditApp.CompanyApprovalStatus === 'CMP'}
                            onClick={(e) => OnInitialSubmit(e, 'REJ')}
                        >
                            Reject
                        </CButton>
                    </CRow>
                </CForm>

                {/* Tabs Section */}
                <CNav variant='tabs'>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Notes'} onClick={() => setActiveTab('Notes')}>Notes</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Credit Request History'} onClick={() => setActiveTab('Credit Request History')}>Credit Request History</CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink active={activeTab === 'Collection Details'} onClick={() => setActiveTab('Collection Details')}>Collection Details</CNavLink>
                    </CNavItem>
                </CNav>
                <CTabContent>
                    <CTabPane role='tabpanel' visible={activeTab === 'Notes'}>
                        {account.CompanyAccountID == null ? null :
                            <Notes
                                companyId={account.CompanyAccountID}
                                disableAddFile={account == null || account.TMWCustomerId == null}
                                setToast={setToast}
                            />
                        }
                    </CTabPane>
                    <CTabPane role='tabpanel' visible={activeTab === 'Credit Request History'}>
                        {account.CompanyAccountID == null ? null : <CreditRequestHistory companyId={account.CompanyAccountID} setToast={setToast} />}
                    </CTabPane>
                    <CTabPane role='tabpanel' visible={activeTab === 'Collection Details'}>
                        {account.CompanyAccountID == null ?
                            null
                            :
                            <>
                                {account.TMWCustomerId == null ? 'No Collection details because TMW ID field is empty' :
                                    <CollectionDetails tmwCustomerId={account.TMWCustomerId} useDetails={false} setToast={setToast} />
                                }
                            </>
                        }
                    </CTabPane>
                </CTabContent>
            </CCardBody>
        </CCard>
    </>);
}

/**
 * Generates the tmw ids found and lets user select which one they want
 * @param {int} companyId Id of company to do search for
 * @param {useRef} tmwId Stores tmw id if selected
 * @param {useState} setToast state that is used for showing toast messages
 * @returns Component
 */
function TMWSelectTable({ companyId, tmwId, setToast }) {

    //#region Variables

    const [useNewTmwId, setUseNewTmwId] = useState(false);
    const [body, setBody] = useState();
    const tmwIdInputRef = useRef();
    const headers = [
        {
            name: 'Company Name',
            headerClassName: 'company-name',
        },
        {
            name: 'TMW Id',
            headerClassName: 'tmw-id',
        },
        {
            name: 'Address Line 1',
            headerClassName: 'address-line-1',
        },
        {
            name: 'Postal/Zip Code',
            headerClassName: 'postal-zip-code',
        },
        {
            name: 'Phone Number',
            headerClassName: 'phone-number',
        },
        {
            name: 'Owner',
            headerClassName: 'owner',
            moreThanJustText: true
        },
        {
            name: 'Bill To',
            headerClassName: 'bill-to',
            moreThanJustText: true
        }
    ];

    //#endregion Variables

    // Initial load
    useEffect(() => {
        function ProcessData(data) {
            if (data == null) return;

            data.forEach(row => {
                var valueWithElements = {};

                row['Company Name'] = row.cmp_name;
                row['TMW Id'] = row.cmp_id;
                row['Address Line 1'] = row.cmp_address1;
                row['Postal/Zip Code'] = row.cmp_zip;
                row['Phone Number'] = row.cmp_primaryphone;
                row['Bill To'] = row.cmp_billto;
                valueWithElements['Bill To'] = row.cmp_billto === 'Y' ?
                    <mark-yellow>
                        <FontAwesomeIcon style={{ color: "#6C757D" }} className='mx-1' icon={faCheckSquare} />
                    </mark-yellow>
                    :
                    <FontAwesomeIcon style={{ color: "#6C757D" }} className='m-1' icon={faSquare} />;
                row['Owner'] = row.OwnerName == null ? 'N/A' : <mark-yellow>{row.OwnerName}</mark-yellow>;

                row['ValueWithElements'] = valueWithElements;

                row.cmp_name = undefined;
                row.cmp_id = undefined;
                row.cmp_address1 = undefined;
                row.cmp_zip = undefined;
                row.cmp_primaryphone = undefined;
                row.cmp_billto = undefined;
                row.OwnerName = undefined;
            });

            return data;
        }

        fetch(`/api/credit/search-for-tmw-id?companyId=${companyId}`, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET',
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setBody(ProcessData(data));
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(err.message));
            });
    }, []);

    return <>
        <CRow className='mx-1'>
            <CCol sm={4} className='mb-2'>
                <CButtonGroup className='center-content' style={{ width: '100%' }}>
                    <CFormCheck
                        id='use-new-toggle'
                        label='Use New'
                        button={{ color: 'secondary', variant: 'outline' }}
                        type='radio'
                        name='existingNewRadio'
                        onChange={() => {
                            setUseNewTmwId(true);
                            tmwId.current = null; // Clear id selected since using new id
                            tmwIdInputRef.current.value = null;
                        }}
                        readOnly
                        checked={useNewTmwId === true}
                    />
                    <CFormCheck
                        id='use-existing-toggle'
                        label='Use Existing'
                        button={{ color: 'secondary', variant: 'outline' }}
                        type='radio'
                        name='existingNewRadio'
                        onChange={() => setUseNewTmwId(false)}
                        readOnly
                        checked={useNewTmwId === false}
                    />
                </CButtonGroup>
            </CCol>
            <CCol sm={8} className='mb-2'>
                <CFormInput
                    ref={tmwIdInputRef}
                    label='TMW ID Selected'
                    disabled
                />
            </CCol>
        </CRow>

        <div>Note: Items ordered by best match</div>
        <Table
            headers={headers}
            body={body}
            tableClass='align-middle'
            hover={true}
            onRowClick={(index) => {
                tmwIdInputRef.current.value = body[index]['TMW Id']; // Set input value to show
                tmwId.current = body[index]['TMW Id']; // Store id to use
                setUseNewTmwId(false); // Change toggle to use existing

                // Check that the row selected is not already assigned to someone and has a bill to
                // If it does, there is a good chance that cannot use this tmw Id
                if (body[index]['Bill To'] === 'Y' && body[index]['Owner'].type !== 'N/A') {
                    setToast(
                        GetToastComponent(
                            'The TMW ID selected is assigned to a different user will likely not be assignable',
                            'Check that you have selected the right company',
                            'warning',
                            true,
                            20)
                    );
                }
            }}
        />
    </>;
}