import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { GetErrorMessages, cookieNames, userRoles } from '../../constants';
import {
    GetCookie,
    GetCompanyContacts,
    GetCompanyOwners,
    GetContactsByIds,
    GetCountries,
    GetEngagementType,
    GetGrades,
    GetIndustries,
    GetParentCompanies,
    GetQuoteType,
    GetStateProvinces,
    GetSources,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    GetErrorToastComponent,
    GetToastComponent,
    SetToastComponentValuesInCookie,
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CCol,
    CDropdown,
    CDropdownHeader,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CForm,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CHeader,
    CHeaderText,
    CInputGroup,
    CInputGroupText,
    CListGroup,
    CListGroupItem,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
    CRow,
    CToaster,
    CTooltip
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';

import ConfirmModal from '../../components/miscellaneous/confirmModal';
import ShowMoreInfoContactModal from '../../components/contact/showMoreInfoContactModal';

import '../../style/edit.css';
import '../../style/typeahead.css';

export default function AccountEdit(props) {

    //#region Variables

    const [companyInfo, setCompanyInfo] = useState({
        Account: {
            CompanyAccountID: '',
            CompanyName: '',
            Description: '',
            CompanyAccountEngagementTypeID: '',
            CompanyAccountQuoteTypeID: '',
            SourceID: '',
            AnnualRevenue: '',
            CompanyGradeId: '',
            WebsiteURL: '',
            IndustryID: '',
            IndustryAbbr: '',
            NumberOfEmployees: '',
            CompanyLeadID: '',
            ParentCompanyAccountID: '',
            EnabledFlag: true,
            DeletedFlag: false,
            CreditApplicationReceivedFlag: false,
            CreditApplicationFileAttachmentID: '',
            CreditApplicationRequestedAmount: '',
            CreditApplicationApprovedAmount: '',
            CreditApplicationAvailableAmount: '',
            PrimaryContactID: '',
            CreditContactID: '',
            PhoneNumber: '',
            PhoneNumberExtension: '',
            FaxNumber: '',
            AddressLine1: '',
            AddressLine2: '',
            City: '',
            StateProvinceID: '',
            ZipPostalCode: '',
            AccountOwnerUserID: '',
            TMWCustomerId: '',
        },
        CountryID: '',
    });
    const [primaryContact, setPrimaryContact] = useState({
        FirstName: '',
        LastName: '',
        EmailAddress: ''
    });
    const [creditContact, setCreditContact] = useState({
        FirstName: '',
        LastName: '',
        EmailAddress: ''
    });
    const [validated, setValidated] = useState(false); // Form validation
    const formRef = useRef(); // CForm
    const auxiliaryLoadingDone = useRef(false); // Has additional loading (that rely on CompanyAccount) been completed after loading CompanyAccount
    const companyAccountId = useRef(null); // Company account id
    //const hasFetchedFormFromLocalStorage = useRef(false); // Has form data from localStorage saved from previous edit been fetched yet (if exist)
    const { companyId } = useParams();
    const navigate = useNavigate();
    const role = useRef(JSON.parse(GetCookie(cookieNames.userRole)));
    const isAdmin = useRef(role.current === userRoles.Admin);
    const isCustomer = useRef(false);

    // Top Button Row 
    const [isEnabled, setEnabled] = useState(false);
    const [creditAppReceived, setCreditAppReceived] = useState(false);

    // Dropdown menu options
    const [countryList, setCountryList] = useState([]);
    const [stateProvinceList, setStateProvinceList] = useState([]);
    const [sources, setSources] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [engagementTypes, setEngagementTypes] = useState([]);
    const [grades, setGrades] = useState([]);
    const [quoteTypes, setQuoteTypes] = useState([]);
    const [companyOwners, setCompanyOwners] = useState([]);
    const [parentCompanies, setParentCompanies] = useState([]);

    // Selected option for dropdown menu
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [selectedParentCompany, setSelectedParentCompany] = useState([]);

    // Toast
    const [toast, setToast] = useState(null);

    // Contacts Show More Information
    const tooltipContent = 'Show more information about contact. Can also edit contact information';
    const [showMoreInfoContact, setShowInfoContact] = useState(false); // bool indicating whether to display the modal
    const [showMoreInfoSpread, setShowMoreInfoSpread] = useState({}); //spread to be added to component

    // Contact Change for primary/credit contact
    const [showChangeContact, setShowChangeContact] = useState(false); // Show/hide modal to change primary/credit contact
    const [companyContacts, setCompanyContacts] = useState(); // Contacts that belong to the company 
    const [newContactSelected, setNewContactSelected] = useState(); // Selected contact from dropdown in modal
    const [isPrimary, setIsPrimary] = useState(null); // specifies if changing primary or credit contact

    // Confirm Modal
    const [showEnrichConfirmModal, setShowEnrichConfirmModal] = useState(false);
    const [confirmModalBody, setConfirmModalBody] = useState(null);
    const [isEnrichUpdate, setIsEnrichUpdate] = useState(false); // This is used to run update fetch calls in useEffect after updating companyInfo
    const enrichDataFormatted = useRef(null);

    //#endregion Variables

    //#region Functions

    /**
     * Get the accounts associated with Company owner
     * @param {int} ownerAccountId Account owner to get owned accounts for
     * @param {bool} initialLoad First load or not. First load will set default parent company. Subsequent changes 
     * will set default parent companies to unselected 
     * @param {int} initialParentCompanyValue Needs to be passed if initial load is true. Prevents race 
     * condition with useState companyInfo by providing value directly
     */
    function GetParentCompaniesToOwner(ownerAccountId, initialLoad, initialParentCompanyValue) {
        // Empty id means no parent company to choose
        if (ownerAccountId === '') {
            setParentCompanies(null);
            return;
        }

        GetParentCompanies(companyAccountId.current, ownerAccountId)
            .then(x => {
                setParentCompanies(x);

                if (initialLoad) {
                    // Find the default selected value if exist for Typeahead component
                    if (initialParentCompanyValue != null) {
                        let defaultParentCompany = x.find(p => p.CompanyAccountID === initialParentCompanyValue);

                        // Set default value if exist
                        if (defaultParentCompany != null) {
                            setSelectedParentCompany([defaultParentCompany]);
                        }
                    }
                } else { // Calling after changing owners and not initial load
                    setSelectedParentCompany([]);
                }

            })
            .catch((err) => GetErrorToastComponent(GetErrorMessages.ParentCompanies, err.message));
    }

    // The following fields don't use this function and has their own method of management: Parent Company, Industry
    function OnChange(e) {
        const { name, value } = e.target;
        if (e.target == null) {
            console.log('e.target null');
            return;
        }

        if (name.includes('.')) {
            const keys = name.split('.');

            setCompanyInfo({
                ...companyInfo,
                [keys[0]]: {
                    ...companyInfo[keys[0]],
                    [keys[1]]: value === '' ? null : value
                }
            });
        } else {
            // Country dropdown has additional changes that need to be made
            if (name === 'CountryID') {
                const stateProvinceDropdown = document.getElementById('state-province-select');

                // If no value is selected for country, disable StateProvince Selection
                if (e.target.value === '') {
                    stateProvinceDropdown.disabled = true;
                } else {
                    stateProvinceDropdown.disabled = false;
                }

                // Also clear StateProvinceID
                setCompanyInfo({
                    ...companyInfo,
                    Account: {
                        ...companyInfo.Account,
                        StateProvinceID: ''
                    },
                    CountryID: value
                });
            } else { // Default
                setCompanyInfo({ ...companyInfo, [name]: value });
            }
        }
    }

    // Show dialog with options to change contacts
    function ShowContactOptions(buttonId) {
        // Set the contact type
        let type;
        switch (buttonId) {
            case 'primary-contact-options':
                type = 'Primary';
                break;
            case 'credit-contact-options':
                type = 'Credit';
                break;
            default:
        }

        let moreInfo = { contactType: type, contactId: null };

        // Pass on the contact information relating to type
        if (type === 'Primary' && companyInfo.Account.PrimaryContactID != null) {
            moreInfo.contactId = companyInfo.Account.PrimaryContactID;
        } else if (type === 'Credit' && companyInfo.Account.CreditContactID != null) {
            moreInfo.contactId = companyInfo.Account.CreditContactID;
        }

        // Show modal if id exists
        if (moreInfo.contactId != null) {
            setShowInfoContact(true);
            setShowMoreInfoSpread({ ...moreInfo });
        } else { // Show error toast since id does not exist
            setToast(GetToastComponent(`Cannot show '${type} Contact' details since it has not been set`, null, 'warning'));
        }
    }

    /**
     * Updates company account as well as primary and credit contact info (first/last name and email)
     * Will not run if submission is not triggered by button with id 'account-edit-submit'. 
     * Exception is when UpdateCompany is maually triggered with callback function
     * @param {obj?} e SyntheticBaseEvent 
     * @param {fn} callbackFn Optional callback function for after update runs and before navigating
     */
    function UpdateCompany(e, callbackFn) {
        const form = formRef.current;
        e?.preventDefault();

        // If submit is not triggered by button, don't submit. Exception is when UpdateCompany is maually triggered with callback function
        if (document.activeElement.id !== 'account-edit-submit' && callbackFn == null) return;

        // Checks if all required fields are filled and industry is filled
        if (form.checkValidity() === false) {
            e?.stopPropagation();
            setValidated(true); // show validation

            // Get the first invalid element
            var firstInvalidElem = document.querySelector('input:invalid, select:invalid');

            // Scroll to element
            if (firstInvalidElem != null) {
                firstInvalidElem.focus();
            }
        } else {
            var info = { ...companyInfo };

            //#region Add Additional Info

            // Update contacts
            info.PrimaryContactFirstName = primaryContact.FirstName;
            info.PrimaryContactLastName = primaryContact.LastName;
            info.PrimaryContactEmail = primaryContact.EmailAddress;

            // Only add credit contact if there is at least one filled field (if new contact) or update if already exists
            if ((info.Account.CreditContactID == null && (creditContact.FirstName !== '' || creditContact.LastName !== '' || creditContact.EmailAddress !== ''))
                || info.Account.CreditContactID != null) {
                info.CreditContactFirstName = creditContact.FirstName;
                info.CreditContactLastName = creditContact.LastName;
                info.CreditContactEmail = creditContact.EmailAddress;
            }
            // Append Industry if selected
            if (selectedIndustry.length === 1) {
                info.Account.IndustryID = selectedIndustry[0].IndustryID;
            }

            // Append Parent Company if selected
            if (selectedParentCompany.length === 1) {
                info.Account.ParentCompanyAccountID = selectedParentCompany[0].CompanyAccountID;
            }

            info.Account.LastModifiedByUserID = JSON.parse(GetCookie(cookieNames.userID));// Add the user editing information

            //#endregion Add Additional Info 

            fetch('/api/companyaccounts/update-company', {
                headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
                method: form.method,
                body: JSON.stringify(info)
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    if (data === 'Update Company Successful') {
                        let userId = parseInt(JSON.parse(GetCookie(cookieNames.userID)));
                        let userRole = JSON.parse(GetCookie(cookieNames.userRole));

                        // Optional callback function
                        if (callbackFn != null) callbackFn();

                        SetToastComponentValuesInCookie('Company account update successful');

                        // If sales and account owner no longer same user, then go straight to account summary page
                        if (userRole === userRoles.Sales && info.Account.AccountOwnerUserID !== userId) {
                            navigate('/company-accounts');
                        } else {
                            navigate('/company-accounts/' + companyAccountId.current + '/profile');
                        }
                    } else {
                        throw new Error('An error has occurred when updating!');
                    }
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent(err.message));
                });
        }
    }

    // Updates the on screen values with values stored from previous edit session
    function UpdateFormValuesFromFormData(form, formData) {
        let elements = form.elements;

        Array.from(formData.entries()).forEach(data => {
            // If there is a value saved that is different from current
            if (data[1].length > 0 && elements[data[0]] != null && data[1] !== elements[data[0]].value) {

                // Set buttons active if they have 'true'
                if (elements[data[0]].type === 'button') {
                    switch (data[0]) {
                        case 'Account.EnabledFlag':
                            setEnabled(data[1] === 'true');
                            break;
                        case 'Account.CreditApplicationReceivedFlag':
                            setCreditAppReceived(data[1] === 'true');
                            break;
                        default:
                    }
                } else {
                    elements[data[0]].value = data[1];
                }
            }
        });

        // Typeahead dropdown elements need to manually be set since it doesn't show up on form
        let industryValue = JSON.parse(formData.get('Typeahead.Industry')); // Already in array form required
        let parentCompanyValue = JSON.parse(formData.get('Typeahead.ParentCompany'));

        if (industryValue !== null) {
            setSelectedIndustry(industryValue);
        }

        if (parentCompanyValue !== null) {
            setSelectedParentCompany(parentCompanyValue);
        }

        //TODO: check if contacts have been changed and if they have load the new values and show toast
    }

    //#region Change Primary/Credit Contact

    // Changes contact to new type
    function OnChangeContact() {
        let url = `/api/contacts/change-primary-credit-contact?contactId=${newContactSelected.ContactID}&isSetToPrimary=${isPrimary}`;

        fetch(url, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                if (data === 'Update Contact Successful') {
                    // Update the fields to match what is in backend
                    // Prevents need to refresh entire page
                    GetContactsByIds([newContactSelected.ContactID])
                        .then(data => {
                            if (isPrimary) {
                                setCompanyInfo({
                                    ...companyInfo,
                                    Account: {
                                        ...companyInfo.Account,
                                        PrimaryContactID: data[0].ContactID
                                    }
                                });
                                setPrimaryContact(data[0]);
                            } else {
                                setCompanyInfo({
                                    ...companyInfo,
                                    Account: {
                                        ...companyInfo.Account,
                                        CreditContactID: data[0].ContactID
                                    }
                                });
                                setCreditContact(data[0]);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                            setToast(GetErrorToastComponent('Could not get changed contact information'))
                        });

                    OnCloseChangeContactModal();
                } else {
                    throw new Error(data);
                }
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(`Error: Could not change ${isPrimary ? 'primary' : 'credit'} contact`, err.message));
            });
    }

    // Closes change primary/credit contact modal 
    function OnCloseChangeContactModal() {
        setShowChangeContact(false);

        // Reset variables for next use
        setNewContactSelected(null);
        setCompanyContacts(null);
        setIsPrimary(null);
    }

    // Closes change primary/credit contact modal 
    function OnOpenChangeContactModal(idToChange, isPrimary) {
        const onContactData = (data) => {
            if (data.length === 0) {
                setToast(GetToastComponent('No contacts to change to!', null, 'warning'));
            } else {
                setCompanyContacts(data);
                setIsPrimary(isPrimary);
                setShowChangeContact(true);
            }
        };

        GetCompanyContacts(companyId, (x) => onContactData(x), idToChange)
            .then(data => onContactData(data))
            .catch(err => {
                console.log(err)
                setToast(GetErrorToastComponent('Could not get company contacts!'));
            });
    }

    //#endregion Change Primary/Credit Contact

    //#region Zoom Info Enrich Company

    /**
     * Update Company Account fields, call UpdateCompany, and add new contacts.
     * NOTE: will also set primary contact if set
     */
    function EnrichCompanyConfirm() {
        let primaryContactCheckboxes = document.querySelectorAll('.primary-contact-select input');

        for (let elem of primaryContactCheckboxes) {
            if (elem.checked === true) {
                // Set now to be used in useEffect on update
                // Needs to be set before modal hides
                enrichDataFormatted.current.SelectedPrimaryContactIndex = elem.id.substring(23); // primary-contact-select-
                break;
            }
        }

        setShowEnrichConfirmModal(false);
        setIsEnrichUpdate(true); // useEffect will run after set
        setCompanyInfo({ // Update info to call UpdateCompany in useEffect
            ...companyInfo,
            Account: {
                ...companyInfo.Account,
                AnnualRevenue: enrichDataFormatted.current.CompanyInfo.AnnualRevenue,
                NumberOfEmployees: enrichDataFormatted.current.CompanyInfo.NumberOfEmployees,
                PhoneNumber: enrichDataFormatted.current.CompanyInfo.PhoneNumber,
                WebsiteURL: enrichDataFormatted.current.CompanyInfo.WebsiteURL
            }
        });
    }

    /**
     * Takes the raw data and outputs items to be updated
     * @param {obj} companyData
     * @param {obj[]} {
     * @returns formatted object with results. In CompanyInfo, only changed items will be updated.  In all contacts will be added new
     */
    function FormatEnrichedData(companyData, contactData) {
        let updatedCompanyInfo = {
        };
        let newContacts = [];

        // Company Data
        if (companyData.EmployeeCount !== '') updatedCompanyInfo.NumberOfEmployees = companyData.EmployeeCount;

        if (companyData.Phone !== '') updatedCompanyInfo.PhoneNumber = companyData.Phone;

        if (companyData.Revenue !== '') updatedCompanyInfo.AnnualRevenue = companyData.Revenue;

        if (companyData.Website !== '') updatedCompanyInfo.WebsiteURL = companyData.Website;

        // Contact Data
        contactData.forEach(x => {
            newContacts.push({
                FirstName: x.Data[0].FirstName,
                LastName: x.Data[0].LastName,
                EmailAddress: x.Data[0].Email,
                Title: x.Data[0].JobTitle,
                PhoneNumber: x.Data[0].Phone,
                MobilePhoneNumber: x.Data[0].MobilePhone
            });
        });

        return {
            CompanyInfo: updatedCompanyInfo,
            Contacts: newContacts
        };
    }

    function GetEnrichedCompanyWithZoomInfo() {
        // Guard
        if (companyInfo.Account.CompanyName === '' || companyInfo.Account.StateProvinceID === '') {
            setToast(GetToastComponent('Ensure Company Name, State/Province, and Country are filled before enriching', 'For best results, fill Website as well', 'warning'));
            return;
        }

        // If there are invalid fields
        if (formRef.current.checkValidity() === false) {
            setValidated(true); // show validation

            // Get the first invalid element
            var firstInvalidElem = document.querySelector('input:invalid, select:invalid');

            // Scroll to element
            if (firstInvalidElem != null) {
                firstInvalidElem.focus();
            }

            setToast(GetToastComponent('Fill all required fields before enriching', null, 'warning'));
            return;
        } 

        // Fetch enriched company data
        let stateProvince = stateProvinceList.find(x => x.StateProvinceID === companyInfo.Account.StateProvinceID);
        let body = {
            CompanyName: companyInfo.Account.CompanyName,
            CompanyWebsite: companyInfo.Account.WebsiteURL ?? '',
            Country: countryList.find(x => x.CountryID === stateProvince.CountryID).CountryName,
            State: stateProvince.StateProvinceName
        };

        fetch('/api/zoominfo/enrich-company', {
            headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(body)
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                var result = FormatEnrichedData(data.Company.Data.Result[0].Data[0], data.Contacts[0].Data.Result);

                enrichDataFormatted.current = result; // This is stored to be used after confirming
                setShowEnrichConfirmModal(true);

                // Set message body
                setConfirmModalBody(<>
                    <div key={'account-field-div'}>
                        <h5 key={'account-field-h'}>Enriched Company Account Fields</h5>
                        <CListGroup key={'account-field'}>
                            {Object.keys(result.CompanyInfo).map((key, i) => <CListGroupItem key={`${i}-account-field-item`}>
                                <b key={`${i}-account-field-item-b`}>{key.replace(/([a-z])([A-Z])/g, "$1 $2")}</b>:
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                {companyInfo.Account[key] == null ? 'N/A' : companyInfo.Account[key]}
                                &nbsp;&nbsp;{'->'}&nbsp;&nbsp;
                                {result.CompanyInfo[key]}
                            </CListGroupItem>)}
                        </CListGroup>
                    </div>
                    <div key={'contact-div'} className='mt-3'>
                        <h5 key={'contact-h'}>Contacts to Add</h5>
                        <CListGroup key={'contact'}>
                            {result.Contacts.map((c, i) => <CListGroupItem key={`${i}-contact-item`} className='pb-4'>
                                <CRow key={`${i}-contact-item-row1`}>
                                    <CCol key={`${i}-contact-item-row1-col1`} className='d-flex justify-content-end'>
                                        <CFormCheck
                                            key={`${i}-contact-item-col1-select`}
                                            id={`primary-contact-select-${i}`}
                                            className={'primary-contact-select'}
                                            label='Primary Contact'
                                            reverse
                                            onClick={(e) => {
                                                // Current just got checked, uncheck all else
                                                if (e.target.checked) {
                                                    let checkboxes = document.querySelectorAll('.primary-contact-select input');
                                                    for (let elem of checkboxes) {
                                                        if (elem.id !== e.target.id) elem.checked = false;
                                                    }
                                                }
                                            }}
                                        />
                                    </CCol>
                                </CRow>
                                <CRow key={`${i}-contact-item-row2`} className='mb-2'>
                                    <CCol key={`${i}-contact-item-row2-col1`}>
                                        <b key={`${i}-contact-item-row2-col1-b`}>First Name:</b> {c.FirstName}
                                    </CCol>
                                    <CCol key={`${i}-contact-item-row2`}>
                                        <b key={`${i}-contact-item-row2-col2-b`}>Last Name:</b> {c.LastName}
                                    </CCol>
                                </CRow>
                                <CRow key={`${i}-contact-item-row3`} className='my-2'>
                                    <CCol key={`${i}-contact-item-row3-col1`}>
                                        <b key={`${i}-contact-item-row3-col1-b`}>Email:</b> {c.EmailAddress}
                                    </CCol>
                                </CRow>
                                <CRow key={`${i}-contact-item-row4`} className='my-2'>
                                    <CCol key={`${i}-contact-item-row4-col1`}>
                                        <b key={`${i}-contact-item-row4-col1-b`}>Title:</b> {c.Title}
                                    </CCol>
                                </CRow>
                                <CRow key={`${i}-contact-item-row5`} className='mt-2'>
                                    <CCol key={`${i}-contact-item-row5-col1`}>
                                        <b key={`${i}-contact-item-row5-col1-b`}>Phone:</b> {c.PhoneNumber}
                                    </CCol>
                                    <CCol key={`${i}-contact-item-row5-col2`}>
                                        <b key={`${i}-contact-item-row5-col2-b`}>Mobile:</b> {c.MobilePhoneNumber}
                                    </CCol>
                                </CRow>
                            </CListGroupItem>)}
                        </CListGroup>
                    </div>
                </>);
            })
            .catch(err => {
                console.log(err);
                setToast(
                    GetErrorToastComponent(
                        'Error: Could not get enrichment data! Check input fields',
                        'Check that Company Name, Website, State/Province and Country are filled correctly',
                        err.message)
                );
            })
    }

    //#endregion Zoom Info Enrich Company

    //#endregion Functions

    //#region Use Effect

    // Gets the company account
    useEffect(() => {
        companyAccountId.current = companyId == null ? props.searchProfile : companyId;

        fetch('/api/companyaccounts/get-company-by-id?id=' + companyAccountId.current, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(info => {
                info = HandleDataReturn(info);

                setCompanyInfo({ ...companyInfo, Account: info });
            })
            .catch(err => {
                console.log(err);
                SetToastComponentValuesInCookie('Error: Could not get company account information!', err.message, 'danger', false);

                // Redirect to company accounts page if there is error fetching data
                navigate('/company-accounts');
            });
    }, [props.searchProfile]);

    // Get all the data for the drop down select fields
    useEffect(() => {
        GetCountries()
            .then(x => setCountryList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Countries, err.message)));

        GetStateProvinces()
            .then(x => setStateProvinceList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.StateProvince, err.message)));

        GetSources()
            .then(x => setSources(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Sources, err.message)));

        GetGrades()
            .then(x => setGrades(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Grades, err.message)));

        GetQuoteType()
            .then(x => setQuoteTypes(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.QuoteType, err.message)));

        GetCompanyOwners()
            .then(x => setCompanyOwners(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.CompanyOwner, err.message)));
    }, []);

    // Set Country ID (if exist) and get Engagement types, Industries, Parent Companies (if exist), and Primary/Credit Contacts
    useEffect(() => {
        // If this useEffect has run before or company or stateProvinceList is still loading, then return
        if (auxiliaryLoadingDone.current || stateProvinceList.length === 0 || companyInfo.Account.CompanyAccountID === '') return;

        auxiliaryLoadingDone.current = true; // Set so that loading does not trigger again

        //#region Gets that don't require changing companyInfo

        GetEngagementType()
            .then(x => {
                // Check if account is a customer
                var index = x.findIndex(engagement => engagement.Name === 'Customer');
                isCustomer.current = companyInfo.Account.CompanyAccountEngagementTypeID === x[index].CompanyAccountEngagementTypeID;

                // If not a customer and not admin, remove customer as an engagement type option
                if (!isCustomer.current && !isAdmin.current) x.splice(index, 1);

                setEngagementTypes(x);
            })
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.EngagementType, err.message)));

        GetIndustries()
            .then(x => {
                setIndustries(x);

                // Find if value already exist
                if (companyInfo.Account.IndustryID != null) {
                    let defaultIndustry = x.find(i => i.IndustryID === companyInfo.Account.IndustryID);

                    // Set default value if exist
                    if (defaultIndustry != null) {
                        setSelectedIndustry([defaultIndustry]);
                    }
                }
            })
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Industries, err.message)));

        //#region Get Primary/Credit contacts 

        let contactIds = [];

        if (companyInfo.Account.PrimaryContactID != null) {
            contactIds.push(companyInfo.Account.PrimaryContactID);
        }

        if (companyInfo.Account.CreditContactID != null) {
            contactIds.push(companyInfo.Account.CreditContactID);
        }

        if (contactIds.length > 0) {
            GetContactsByIds(contactIds)
                .then(data => {
                    data.forEach(c => {
                        if (c.ContactID === companyInfo.Account.PrimaryContactID) {
                            setPrimaryContact({
                                FirstName: c.FirstName,
                                LastName: c.LastName,
                                EmailAddress: c.EmailAddress
                            });
                        } else if (c.ContactID === companyInfo.Account.CreditContactID) {
                            setCreditContact({
                                FirstName: c.FirstName,
                                LastName: c.LastName,
                                EmailAddress: c.EmailAddress
                            });
                        }
                    });
                })
                .catch(console.error);
        }

        //#endregion Get Primary/Credit contacts

        //#endregion Gets that don't require changing companyInfo

        const accountTemp = { ...companyInfo }; // Used to store all changes below before updating

        // If there is StateProvince set Country Id
        if (companyInfo.Account.StateProvinceID != null) {
            // Get the country of the selected stateprovince
            let countryId = stateProvinceList.find(({ StateProvinceID }) => StateProvinceID === companyInfo.Account.StateProvinceID).CountryID;
            accountTemp.CountryID = countryId.toString();
        } else {
            accountTemp.Account.StateProvinceID = '';
        }

        // Load the parent companies initially if exist
        if (companyInfo.Account.AccountOwnerUserID != null)
            GetParentCompaniesToOwner(companyInfo.Account.AccountOwnerUserID, true, companyInfo.Account.ParentCompanyAccountID);

        setCompanyInfo({ ...accountTemp });
    }, [companyInfo, stateProvinceList]);

    // Used to update company account as well as add contacts
    useEffect(() => {
        // Not updating enrichment
        if (!isEnrichUpdate) return;

        const updateContactsAndAddNote = () => { // Update Contacts
            enrichDataFormatted.current.Contacts.forEach((c, i) => {
                let newContact = {
                    ...c,
                    CompanyAccountID: companyInfo.Account.CompanyAccountID,
                    Type: enrichDataFormatted.current.SelectedPrimaryContactIndex == i ? 'Primary' : 'Other',
                }

                // Check if Phone number has extension
                if (c.PhoneNumber.includes('ext.')) {
                    let splitPhone = c.PhoneNumber.split(' ext. ');
                    newContact.PhoneNumber = splitPhone[0];
                    newContact.PhoneNumberExtension = splitPhone[1];
                }

                fetch('/api/contacts/add-contact-from-body', {
                    headers: { 'Authorization': 'Bearer ' + GetBearer(), 'Content-Type': 'application/json' },
                    method: 'POST',
                    body: JSON.stringify(newContact)
                })
                    .then(response => HandleResponseReturn(response))
                    .then(data => {
                        data = HandleDataReturn(data);
                    })
                    .catch(console.error);
            });

            // Add note
            var formData = new FormData();
            formData.append('CompanyAccountID', companyId);
            formData.append('NoteText', 'Enriched Company Account');
            formData.append('NoteCreatedByUserID', parseInt(JSON.parse(GetCookie(cookieNames.userID))));
            formData.append('IsSystemNote', true);

            fetch('/api/companyaccounts/add-note', {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'POST',
                body: formData
            })
                .catch(console.error);
        }

        // Update Company and contacts
        UpdateCompany(null, updateContactsAndAddNote);
    }, [isEnrichUpdate, companyInfo])

    //#endregion Use Effect

    return (<>
        {/* Shows modal to view primary/credit contact details */}
        <ShowMoreInfoContactModal
            closeModal={() => setShowInfoContact(false)}
            navigateToEditContact={(contactId) => navigate(`/contacts/${contactId}/edit`)}
            showModal={showMoreInfoContact}
            {...showMoreInfoSpread}
        />

        <ConfirmModal
            cancelFunction={() => setShowEnrichConfirmModal(false)}
            confirmColour='success'
            confirmFunction={EnrichCompanyConfirm}
            title='Confirm Account Enrichment'
            message={confirmModalBody}
            showModal={showEnrichConfirmModal}
        />

        {/* Shows modal to change primary/credit contact */}
        <CModal
            alignment='center'
            visible={showChangeContact}
            onClose={() => setShowChangeContact(false)}
        >
            <CModalHeader closeButton={false}>
                <CModalTitle>Change {isPrimary ? 'Primary' : 'Credit'} Contact</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CDropdown className='d-flex justify-content-center m-2' label='sdfsf'>
                    <CDropdownToggle color='secondary'>
                        {newContactSelected == null ?
                            <>Select New {isPrimary ? 'Primary' : 'Credit'} Contact</>
                            :
                            <>{newContactSelected.FirstName} {newContactSelected.LastName}</>
                        }
                    </CDropdownToggle>
                    <CDropdownMenu>
                        {companyContacts == null ? <CDropdownHeader>No company contacts to show</CDropdownHeader> :
                            companyContacts.map((contact, i) => {
                                // Cannot change to contacts that are already a primary or credit contact
                                return <CDropdownItem
                                    key={i + 'change-contact'}
                                    onClick={() => setNewContactSelected(contact)}
                                    disabled={contact.ContactID === companyInfo.Account.PrimaryContactID || contact.ContactID === companyInfo.Account.CreditContactID}
                                >
                                    {contact.FirstName} {contact.LastName}
                                </CDropdownItem>
                            })
                        }
                    </CDropdownMenu>
                </CDropdown>
                <h6 className='ms-2'>Note: Change will be applied as soon as "Confirm" is clicked</h6>
            </CModalBody>
            <CModalFooter>
                <CButton color='secondary' variant='outline' style={{ width: '90px' }} onClick={() => OnCloseChangeContactModal()}>Cancel</CButton>
                <CButton color='success' style={{ width: '90px' }} onClick={() => OnChangeContact()}>Confirm</CButton>
            </CModalFooter>
        </CModal>

        <CToaster push={toast} placement='top-end' />

        <CForm
            ref={formRef}
            id='company-account-form'
            className='mt-3'
            onSubmit={UpdateCompany}
            method='POST'
            noValidate
            validated={validated}
        >
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Company Account Edit</CHeaderText>
                <div>
                    <CButton
                        type='button'
                        className='my-2'
                        color='secondary'
                        active={companyInfo.Account.CreditApplicationReceivedFlag}
                        onClick={() =>
                            setCompanyInfo({
                                ...companyInfo,
                                'Account': {
                                    ...companyInfo.Account,
                                    'CreditApplicationReceivedFlag': !companyInfo.Account.CreditApplicationReceivedFlag
                                }
                            })
                        }
                        disabled={isAdmin.current === false && role.current !== userRoles.Credit}
                    >
                        {companyInfo.Account.CreditApplicationReceivedFlag ?
                            <FontAwesomeIcon className='me-2' icon={faCheckSquare} />
                            : <FontAwesomeIcon className='me-2' icon={faSquare} />
                        }
                        Credit Application Received
                    </CButton>
                </div>
            </CHeader>
            <CCard>
                <CCardBody className='px-5'>
                    {/* Company Information Section */}
                    <CCardTitle className='justify-content-between title-display-content mb-0'>
                        Company Information
                        <CButton onClick={GetEnrichedCompanyWithZoomInfo}>Enrich Company</CButton>
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol xs={12} sm={6}>
                            <CFormLabel className='mt-3 required-label'>Company Name</CFormLabel>
                            <CFormInput
                                type='text'
                                id='Name'
                                name='Account.CompanyName'
                                value={companyInfo.Account.CompanyName}
                                onChange={OnChange}
                                maxLength='150'
                                feedbackInvalid='Required field'
                                required
                            />
                            <CRow>
                                <CCol>
                                    <CFormLabel className='mt-3 required-label'>Engagement Type</CFormLabel>
                                    <CFormSelect
                                        name='Account.CompanyAccountEngagementTypeID'
                                        value={companyInfo.Account.CompanyAccountEngagementTypeID}
                                        onChange={OnChange}
                                        required
                                        disabled={companyInfo.Account.CompanyAccountEngagementTypeID === 1 && !isAdmin.current}
                                    >
                                        {engagementTypes.map((engagement) => {
                                            return (
                                                <option
                                                    key={engagement.CompanyAccountEngagementTypeID + '-engagement'}
                                                    value={engagement.CompanyAccountEngagementTypeID}
                                                >
                                                    {engagement.Name}
                                                </option>
                                            )
                                        })}
                                    </CFormSelect>
                                </CCol>
                                <CCol>
                                    {companyInfo.Account.CompanyAccountEngagementTypeID == 1 ||
                                        companyInfo.Account.CompanyAccountEngagementTypeID == 9 ||
                                        companyInfo.Account.CompanyAccountEngagementTypeID == 10 ||
                                        companyInfo.Account.CompanyAccountEngagementTypeID == 11 ? <CFormLabel className='mt-3 required-label'>Quote Type</CFormLabel> : <CFormLabel className='mt-3'>Quote Type</CFormLabel>}
                                    <CFormSelect
                                        name='Account.CompanyAccountQuoteTypeID'
                                        value={companyInfo.Account.CompanyAccountQuoteTypeID}
                                        onChange={OnChange}
                                        required={
                                            companyInfo.Account.CompanyAccountEngagementTypeID == 1 ||
                                            companyInfo.Account.CompanyAccountEngagementTypeID == 9 ||
                                            companyInfo.Account.CompanyAccountEngagementTypeID == 10 ||
                                            companyInfo.Account.CompanyAccountEngagementTypeID == 11
                                        }
                                    >
                                        <option />
                                        {quoteTypes.map((quoteType) => {
                                            return (
                                                <option
                                                    key={quoteType.CompanyAccountQuoteTypeID + '-qoute-type'}
                                                    value={quoteType.CompanyAccountQuoteTypeID}
                                                >
                                                    {quoteType.QuoteTypeName}
                                                </option>
                                            )
                                        })}
                                    </CFormSelect>
                                </CCol>
                            </CRow>
                            <CFormLabel className='mt-3 required-label'>Grade</CFormLabel>
                            <CFormSelect name='Account.CompanyGradeId' value={companyInfo.Account.CompanyGradeId} onChange={OnChange} required>
                                <option />
                                {grades.map((grade) => {
                                    return (
                                        <option
                                            key={grade.CompanyGradeID + '-grade'}
                                            value={grade.CompanyGradeID}
                                        >
                                            {grade.Grade} {grade.Description}
                                        </option>
                                    )
                                })}
                            </CFormSelect>

                            <CFormLabel className='mt-3 required-label label-auto-width'>Primary Contact Name</CFormLabel>
                            <CTooltip content={tooltipContent}>
                                <CButton
                                    id='primary-contact-options'
                                    className='ms-2'
                                    color='secondary'
                                    size='sm'
                                    type='button'
                                    onClick={(e) => ShowContactOptions(e.target.id)}
                                >
                                    Details
                                </CButton>
                            </CTooltip>
                            <CTooltip content='Change primary contact to another one from this in account'>
                                <CButton
                                    id='primary-contact-change'
                                    className='ms-2'
                                    color='secondary'
                                    size='sm'
                                    type='button'
                                    onClick={() => OnOpenChangeContactModal(companyInfo.PrimaryContactID, true)}
                                >
                                    Change
                                </CButton>
                            </CTooltip>
                            <CRow>
                                <CCol sm={12} md={6}>
                                    <CFormInput
                                        id='primary-input-first-name'
                                        type='text'
                                        value={primaryContact.FirstName}
                                        onChange={(e) => setPrimaryContact({ ...primaryContact, FirstName: e.target.value })}
                                        placeholder='First Name'
                                        feedbackInvalid='Required field'
                                        maxLength='75'
                                        required
                                    />
                                </CCol>
                                <CCol sm={12} md={6}>
                                    <CFormInput
                                        id='primary-input-last-name'
                                        type='text'
                                        value={primaryContact.LastName}
                                        onChange={(e) => setPrimaryContact({ ...primaryContact, LastName: e.target.value })}
                                        placeholder='Last Name'
                                        feedbackInvalid='Required field'
                                        maxLength='75'
                                        required
                                    />
                                </CCol>
                            </CRow>
                            <CFormLabel className='mt-3 required-label'>Primary Contact Email</CFormLabel>
                            <CFormInput
                                id='primary-input-email'
                                type='email'
                                value={primaryContact.EmailAddress}
                                onChange={(e) => setPrimaryContact({ ...primaryContact, EmailAddress: e.target.value })}
                                maxLength='150'
                                feedbackInvalid='Required field with valid email format'
                                required
                            />
                            <CFormLabel className='mt-3 required-label'>Industry</CFormLabel>
                            <div id='industry-typeahead'>
                                <Typeahead
                                    name='Typeahead.Industry'
                                    options={industries}
                                    labelKey={option => `${option.Industry}`}
                                    onChange={(value) => setSelectedIndustry(value)}
                                    selected={selectedIndustry}
                                    id='industry'
                                    emptyLabel='No matches found, only options chosen from dropdown will be saved'
                                    isInvalid={validated && selectedIndustry.length === 0}
                                    inputProps={{ required: true }}
                                />
                                <div className='invalid-feedback required-field'>Required field that is one of the options listed</div>
                            </div>

                            <CFormLabel className='mt-3'>Phone Number</CFormLabel>
                            <CInputGroup>
                                <CFormInput type='text' name='Account.PhoneNumber' value={companyInfo.Account.PhoneNumber} onChange={OnChange} maxLength='20' />
                                <div className='input-group-spacer' style={{ width: '5%' }} />
                                <CInputGroupText>ext</CInputGroupText>
                                <CFormInput type='text' name='Account.PhoneNumberExtension' value={companyInfo.Account.PhoneNumberExtension} onChange={OnChange} maxLength='6' style={{ maxWidth: '30%' }} />
                            </CInputGroup>
                            <CFormLabel className='mt-3'>Fax Number</CFormLabel>
                            <CFormInput type='text' name='Account.FaxNumber' value={companyInfo.Account.FaxNumber} onChange={OnChange} maxLength='15' />
                        </CCol>
                        <CCol xs={12} sm={6}>
                            <CFormLabel className='mt-3 required-label'>Company Owner</CFormLabel>
                            <CFormSelect
                                name='Account.AccountOwnerUserID'
                                value={companyInfo.Account.AccountOwnerUserID}
                                feedbackInvalid='Required field'
                                required
                                onChange={(e) => {
                                    OnChange(e);
                                    GetParentCompaniesToOwner(e.target.value, false);
                                }}
                            >
                                {companyOwners.map((owner, i) => {
                                    return (<option key={`${i}-owner`} value={owner.UserID}>{owner.DisplayName}</option>)
                                })}
                            </CFormSelect>

                            <CFormLabel className='mt-3'>Parent Company</CFormLabel>
                            <div id='parent-company-typeahead'>
                                <Typeahead
                                    name='Typeahead.ParenCompany'
                                    options={parentCompanies}
                                    labelKey={option => option.TmwCustomerId != null ?
                                        `${option.CompanyName} - ${option.TmwCustomerId}` : `${option.CompanyName}`
                                    }
                                    onChange={setSelectedParentCompany}
                                    selected={selectedParentCompany}
                                    id='parent-company'
                                    emptyLabel='No matches found, only options chosen from dropdown will be saved'
                                    renderMenuItemChildren={option => (<div className='d-flex justify-content-between'>
                                        <span>{option.CompanyName}</span>
                                        {option.TmwCustomerId != null && <span className='fw-b'>{option.TmwCustomerId}</span>}
                                    </div>)}
                                />
                            </div>

                            <CFormLabel className='mt-3 required-label'>Account Source</CFormLabel>
                            <CFormSelect name='Account.SourceID' value={companyInfo.Account.SourceID} onChange={OnChange} required>
                                <option />
                                {sources.map((source) => {
                                    return (<option key={source.SourceID + '-source'} value={source.SourceID}>{source.Name}</option>)
                                })}
                            </CFormSelect>
                            <CFormLabel className='mt-3'>Annual Revenue</CFormLabel>
                            <CInputGroup>
                                <CInputGroupText>$</CInputGroupText>
                                <CFormInput type='number' name='Account.AnnualRevenue' value={companyInfo.Account.AnnualRevenue ?? 0} onChange={OnChange} />
                            </CInputGroup>
                            <CFormLabel className='mt-3'>Number Of Employees</CFormLabel>
                            <CFormInput type='text' name='Account.NumberOfEmployees' value={companyInfo.Account.NumberOfEmployees} onChange={OnChange} pattern='^\d*$' feedbackInvalid='Only positive integer values allowed' />
                            <CFormLabel className='mt-3'>Website</CFormLabel>
                            <CInputGroup>
                                <CInputGroupText>https://</CInputGroupText>
                                <CFormInput type='text' name='Account.WebsiteURL' value={companyInfo.Account.WebsiteURL} onChange={OnChange} maxLength='255' />
                            </CInputGroup>
                            <CFormLabel className='mt-3'>Description</CFormLabel>
                            <CFormTextarea type='text' name='Account.Description' value={companyInfo.Account.Description} onChange={OnChange} />
                        </CCol>
                    </CRow>

                    {/* Credit Information Section */}
                    <CCardTitle className='justify-content-between title-display-content mt-5'>
                        Credit Information
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol>
                            <CFormLabel className='mt-3'>Requested Credit Amount</CFormLabel>
                            <CFormInput type='number' value={companyInfo.Account.CreditApplicationRequestedAmount} disabled />
                            <CFormLabel className='mt-3'>Approved Credit Amount</CFormLabel>
                            <CFormInput type='text' value={companyInfo.Account.CreditApplicationApprovedAmount} disabled />
                            <CFormLabel className='mt-3'>Available Credit Amount</CFormLabel>
                            <CFormInput type='text' value={companyInfo.Account.CreditApplicationAvailableAmount} disabled />
                        </CCol>
                        <CCol>
                            <CFormLabel className='mt-3 label-auto-width'>AP Credit Contact</CFormLabel>
                            <CTooltip content={tooltipContent}>
                                <CButton
                                    id='credit-contact-options'
                                    className='ms-2'
                                    color='secondary'
                                    size='sm'
                                    type='button'
                                    onClick={(e) => ShowContactOptions(e.target.id)}
                                >
                                    Details
                                </CButton>
                            </CTooltip>
                            <CTooltip content='Change credit to another one from this account'>
                                <CButton
                                    id='credit-contact-change'
                                    className='ms-2'
                                    color='secondary'
                                    size='sm'
                                    type='button'
                                    onClick={() => OnOpenChangeContactModal(companyInfo.CreditContactID, false)}
                                >
                                    Change
                                </CButton>
                            </CTooltip>
                            <CRow>
                                <CCol sm={12} md={6}>
                                    <CFormInput
                                        id='credit-input-first-name'
                                        type='text'
                                        value={creditContact.FirstName}
                                        onChange={(e) => setCreditContact({ ...creditContact, FirstName: e.target.value })}
                                        placeholder='First Name'
                                        maxLength='75'
                                    />
                                </CCol>
                                <CCol sm={12} md={6}>
                                    <CFormInput
                                        id='credit-input-last-name'
                                        type='text'
                                        value={creditContact.LastName}
                                        onChange={(e) => setCreditContact({ ...creditContact, LastName: e.target.value })}
                                        placeholder='Last Name'
                                        maxLength='75'
                                    />
                                </CCol>
                            </CRow>
                            <CFormInput
                                id='credit-input-email'
                                type='email'
                                className='mt-3'
                                placeholder='Email'
                                value={creditContact.EmailAddress}
                                onChange={(e) => setCreditContact({ ...creditContact, EmailAddress: e.target.value })}
                                maxLength='150'
                                feedbackInvalid='Valid email format required'
                            />
                            <CFormLabel className='mt-3'>TMW Customer Id</CFormLabel>
                            <CFormInput
                                type='text'
                                name='Account.TMWCustomerId'
                                onChange={OnChange}
                                defaultValue={companyInfo.Account.TMWCustomerId}
                                disabled={!isAdmin.current && role.current !== userRoles.Credit}
                                maxLength='8'
                            />
                        </CCol>
                    </CRow>

                    {/* Address Section */}
                    <CCardTitle className='justify-content-between title-display-content mt-5'>
                        Address
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol>
                            <CFormLabel className='mt-3 required-label'>Address</CFormLabel>
                            <CFormInput
                                type='text'
                                name='Account.AddressLine1'
                                value={companyInfo.Account.AddressLine1}
                                onChange={OnChange}
                                maxLength='75'
                                feedbackInvalid='Required field'
                                required
                            />
                            <CFormInput
                                type='text'
                                name='Account.AddressLine2'
                                className='mt-3'
                                value={companyInfo.Account.AddressLine2}
                                onChange={OnChange}
                                maxLength='75'
                            />
                            <CRow>
                                <CCol className='pe-4'>
                                    <div>
                                        <CFormLabel className='mt-3 required-label'>City</CFormLabel>
                                        <CFormInput
                                            type='text'
                                            name='Account.City'
                                            value={companyInfo.Account.City}
                                            onChange={OnChange}
                                            maxLength='75'
                                            feedbackInvalid='Required field'
                                            required
                                        />
                                    </div>
                                    <div>
                                        <CFormLabel className='mt-3 required-label'>Province/State</CFormLabel>
                                        <CFormSelect
                                            id='state-province-select'
                                            name='Account.StateProvinceID'
                                            value={companyInfo.Account.StateProvinceID}
                                            onChange={OnChange}
                                            feedbackInvalid='Required field'
                                            required
                                        >
                                            {companyInfo.CountryID === '' || companyInfo.CountryID == null ? null :
                                                stateProvinceList.map((stateProvince) => {
                                                    // Only map if the country matches
                                                    if (stateProvince.CountryID.toString() === companyInfo.CountryID) {
                                                        return (<option key={stateProvince.StateProvinceID + '-state-province'} value={stateProvince.StateProvinceID}>{stateProvince.StateProvinceName}</option>)
                                                    }
                                                    return null;
                                                })
                                            }
                                        </CFormSelect>
                                    </div>
                                </CCol>
                                <CCol className='ps-4'>
                                    <div>
                                        <CFormLabel className='mt-3 required-label'>Country</CFormLabel>
                                        <CFormSelect
                                            id='country-select'
                                            value={companyInfo.CountryID}
                                            name='CountryID'
                                            onChange={OnChange}
                                            feedbackInvalid='Required field'
                                            required
                                        >
                                            <option />
                                            {countryList.map((country) => {
                                                return (<option key={country.CountryID + '-country'} value={country.CountryID}>{country.CountryName}</option>)
                                            })}
                                        </CFormSelect>
                                    </div>
                                    <div>
                                        <CFormLabel className='mt-3 required-label'>Postal/Zip Code</CFormLabel>
                                        <CFormInput
                                            type='text'
                                            name='Account.ZipPostalCode'
                                            value={companyInfo.Account.ZipPostalCode}
                                            onChange={OnChange}
                                            maxLength='15'
                                            feedbackInvalid='Required field'
                                            required
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>

                    {/* Button Section */}
                    <CRow className='my-5'>
                        <CCol style={{ textAlign: 'end' }}>
                            <CButton onClick={() => navigate(`/company-accounts/${companyAccountId.current}/profile`)} color='secondary' style={{ width: '100px' }} >Cancel</CButton>
                        </CCol>
                        <CCol>
                            <CButton id='account-edit-submit' type='submit' style={{ backgroundColor: '#337ab7', width: '100px' }} >Save</CButton>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CForm>
    </>);
}