import React, { useEffect } from 'react';

import '../../style/profile.css';

export default function PaymentQualityBar(props) {

    //#region Functions

    function SetPaymentQualityBarColour(paymentTerms, companyAging) {
        let bar = document.getElementsByTagName('payment-quality-bar')[0];

        // Not a customer yet
        if (paymentTerms == null) {
            bar.textContent = 'Not a customer yet, need more information to check credit status.';
            bar.style.backgroundColor = '#3498DB';
            return;
        }

        // No aging data or no aging, customer within payment terms
        if (companyAging == null || companyAging.AgingTotal == 0) {
            bar.textContent = 'Your customer is within payment terms.';
            bar.style.backgroundColor = '#198754';
            return;
        }

        const hasAging31To60 = companyAging.Aging31To60 > 0;
        const hasAging61To90 = companyAging.Aging61To90 > 0;
        const hasAging91To120 = companyAging.Aging91To120 > 0;
        const hasAging121To150 = companyAging.Aging121To150 > 0;
        const hasAgingOver150 = companyAging.AgingOver150 > 0;
        const agingTotal = companyAging.AgingTotal;
        const agingCurrent = companyAging.AgingCurrent;
        let isWarning = false; // late
        let isDanger = false; // very late
        paymentTerms = paymentTerms.trim();

        // If it has reached this far, there is aging

        // Check payment terms and if there aging over defined terms
        if (paymentTerms === 'NET 15') {
            // If total aging is higher than current, then aging in other buckets and so is overdue
            if (agingTotal > agingCurrent) {
                isDanger = true;
            } else { // Not over so must be equal to since there is no other aging
                isWarning = true;
            }

        // If aging is not equal to current, then it is at least a warning
        } else if (paymentTerms === 'NET 30' && agingTotal != agingCurrent) {
            // If there is aging in any of these categories, it is way overdue
            if (hasAging61To90 || hasAging91To120 || hasAging121To150 || hasAgingOver150) {
                isDanger = true;
            } else { //hasAging31To60 is the other option
                isWarning = true;
            }

        } else if (paymentTerms === 'NET 45') {
            if (hasAging61To90 || hasAging91To120 || hasAging121To150 || hasAgingOver150) {
                isDanger = true;
            } else if (hasAging31To60) {
                isWarning = true;
            }

        } else if (paymentTerms === 'NET 60') {
            if (hasAging91To120 || hasAging121To150 || hasAgingOver150) {
                isDanger = true;
            } else if (hasAging61To90) {
                isWarning = true;
            }

        } else if (paymentTerms === 'NET 90') {
            if (hasAging121To150 || hasAgingOver150) {
                isDanger = true;
            } else if (hasAging91To120) {
                isWarning = true;
            }

        } else if (paymentTerms === 'NET 120') {
            if (hasAgingOver150) { 
                isDanger = 'danger';
            } else if (hasAging121To150) { 
                isDanger = 'warning'
            }
        }

        if (isDanger) {
            bar.textContent = 'DANGER: Your customer owes quite a bit of money, and hasn\'t paid in a while!';
            bar.style.backgroundColor = '#dc3545'; // red
        } else if (isWarning) {
            bar.textContent = 'WARNING: Your customer is outside of payment terms and owes money!';
            bar.style.backgroundColor = '#ffc107'; // yellow
        } else { 
            bar.textContent = 'Your customer is within payment terms.';
            bar.style.backgroundColor = '#198754'; // green
        }
    }

    //#endregion Functions

    //#region Use Effect

    // Get the payment quality information for company

    useEffect(() => {
        SetPaymentQualityBarColour(props.PaymentTerms, props.CompanyAging);
    }, [props.PaymentTerms, props.CompanyAging]);

    useEffect(() => {
        if (props.Updating) {
            let bar = document.getElementsByTagName('payment-quality-bar')[0];
            bar.textContent = 'Loading...';
            bar.style.backgroundColor = '#3498DB';
        }
    }, [props.Updating]);

    //#endregion

    return (
        <payment-quality-bar className='mx-2' />
    );
}