import React, { Suspense, useEffect, useState } from 'react';
import { Base64ToBlob, GetErrorToastComponent, HandleDataReturn, HandleResponseReturn } from '../../functions';

import {
    CButton,
    CModal,
    CModalBody,
    CModalHeader,
    CTooltip,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const File = React.lazy(() => import('../miscellaneous/file'));

export default function Tutorial({ visible, setVisible, setToast }) {
    const [howToFile, setHowToFile] = useState(null); // Change log in base64 form

    /**
     * Downloads how to file
     */
    function Download() {
        if (howToFile == null) return;

        var pdfBlob = Base64ToBlob(howToFile, 'application/pdf');
        const url = URL.createObjectURL(pdfBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Skynet How Tos.pdf';
        a.click();
        URL.revokeObjectURL(url);
    }

    // Get Change Log
    useEffect(() => {
        if (!visible || howToFile != null) return;

        fetch('/api/file/how-tos', {
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);
                setHowToFile(data);
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent('Error: Could not get tutorial file', err));
            });
    }, [visible]);

    return <CModal
        alignment='center'
        scrollable={true}
        size='xl'
        visible={visible}
        onClose={() => setVisible(false)}
    >
        <CModalHeader>
            <CTooltip content='Download File'>
                <CButton color='secondary' onClick={Download}>
                    <FontAwesomeIcon icon={faFileDownload} />
                </CButton>
            </CTooltip>
        </CModalHeader>
        <CModalBody>
            <Suspense fallback={<div>Loading...</div>}>
                <File file={'data:application/pdf;base64,' + howToFile} />
            </Suspense>
        </CModalBody>
    </CModal>;
}