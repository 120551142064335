import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { GetErrorMessages, cookieNames, userRoles } from '../../constants';
import { 
    GetCookie,
    GetBearer, 
    GetErrorToastComponent,
    GetToastComponentFromCookiesValues, 
    HandleDataReturn,
    HandleResponseReturn,
    GetContactsByIds,
    GetParentCompanies,
    SetToastComponentValuesInCookie 
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CCol,
    CHeader,
    CHeaderText,
    CRow,
    CToaster
} from '@coreui/react';

import { Typeahead } from 'react-bootstrap-typeahead';
import '../../style/typeahead.css';
import ConfirmModal from '../../components/miscellaneous/confirmModal';

// Constants
const nullCompanyContact = { 'PrimaryFirstName': null, 'PrimaryLastName': null, 'CreditFirstName': null, 'CreditLastName': null }; // null contact template

export default function CampaignTemplateProfile() {

    //#region Variables

    const [template, setTemplate] = useState(null);
    const [selectedAccountToPreview, setSelectedAccountToPreview] = useState([]); // selected company to preview (only one selection allowed)
    const [ownedAccounts, setOwnedAccounts] = useState(); // accounts that user owns and can select for preview
    const [formattedEmailTemplate, setFormattedEmailTemplate] = useState(''); // template.EmailTemplateHtmlBody with placeholder fields replaced
    const iframe = useRef(); // frame reference

    // Info used for populating Body template
    const [selectedCompanyInfo, setSelectedCompanyInfo] = useState(); // Company Info for template
    const [selectedCompanyContact, setSelectedCompanyContact] = useState(nullCompanyContact); // Contact names if exist
    const [selectedUserInfo, setSelectedUserInfo] = useState(); // User Info for template

    // Confirm delete modal
    const [visibleConfirmModal, setVisibleConfirmModal] = useState(false);

    const { templateId } = useParams(); 
    const isAdmin = useRef(JSON.parse(GetCookie(cookieNames.userRole)) === userRoles.Admin);
    const userId = useRef(parseInt(JSON.parse(GetCookie(cookieNames.userID))));
    const navigate = useNavigate();
    const [toast, setToast] = useState(null);

    //#endregion Variables

    //#region Functions

    function OnDeleteTemplate() {
        setVisibleConfirmModal(false);

        fetch('/api/campaign/delete-campaign-template?templateId=' + template.EmailTemplateID, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                // Delete successful will return true
                if (data === true) {
                    SetToastComponentValuesInCookie('Email template deleted successfully!');
                    navigate('/campaigns/templates');
                } else {
                    throw new Error(data);
                }
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not delete template!', err.message));
            });
    }

    //#endregion Functions

    //#region Use Effect

    // Get template info, get user info, and check for toast items to show on first load
    useEffect(() => {
        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);

        // Get template
        fetch('/api/campaign/get-campaign-template?templateId=' + templateId, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setFormattedEmailTemplate(data.EmailTemplateHtmlBody); // set it with not formatting initially
                setTemplate(data);
            })
            .catch(err => {
                console.error(err);
                SetToastComponentValuesInCookie('Error: Could not get template information!', err.message);
                navigate('/campaigns/templates');
            });

        // Get owned accounts 
        GetParentCompanies(null, userId.current)
            .then(x => {
                setOwnedAccounts(x);
            })
            .catch((err) => {
                console.log(err.message);
                GetErrorToastComponent(GetErrorMessages.ParentCompanies, err.message)
            });
    }, [navigate, templateId]);

    // Get all the account information needed
    useEffect(() => {
        // Only get company where there is a company selected
        if (iframe.current == null ) return;

        // If account preview is deselected, clear data
        if (selectedAccountToPreview.length === 0) {
            setSelectedCompanyInfo(null);
            setSelectedCompanyContact(nullCompanyContact);
            setFormattedEmailTemplate(template.EmailTemplateHtmlBody);
        } else { // Account selected
            // Get company info
            fetch('/api/companyaccounts/get-company-by-id?id=' + selectedAccountToPreview[0].CompanyAccountID, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    setSelectedCompanyInfo(data);
                })
                .catch(err => {
                    console.error(err);
                    SetToastComponentValuesInCookie('Error: Could not get company information to preview!', err.message);
                });
        }

        // Get Owner Info if didn't already
        if (selectedUserInfo == null) {
            fetch('/api/administration/get-user?userId=' + userId.current, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    setSelectedUserInfo(data);
                })
                .catch(err => {
                    console.error(err);
                    SetToastComponentValuesInCookie('Error: Could not get user account information to preview', err.message);
                });
        }
    }, [selectedAccountToPreview]);

    // Get contact information after getting company info
    useEffect(() => {
        if (selectedCompanyInfo == null) return;

        // Get Company Contact Info
        let contactIds = [];

        if (selectedCompanyInfo.PrimaryContactID != null) contactIds.push(selectedCompanyInfo.PrimaryContactID);
        if (selectedCompanyInfo.CreditContactID != null) contactIds.push(selectedCompanyInfo.CreditContactID);

        // If no contacts to fetch
        if (contactIds.length === 0) return;

        GetContactsByIds(contactIds)
            .then(data => {
                var tempContact = nullCompanyContact;

                data.forEach(contact => {
                    if (contact.ContactID === selectedCompanyInfo.PrimaryContactID) {
                        tempContact.PrimaryFirstName = contact.FirstName
                        tempContact.PrimaryLastName = contact.LastName;
                    }

                    if (contact.ContactID === selectedCompanyInfo.CreditContactID) {
                        tempContact.CreditFirstName = contact.FirstName
                        tempContact.CreditLastName = contact.LastName;
                    }
                })

                setSelectedCompanyContact(tempContact);
            })
            .catch(err => {
                console.error(err);
                SetToastComponentValuesInCookie('Error: Could not get contact information to preview!', err.message);
            }); 
    }, [selectedCompanyInfo]);

    // After all fetches are made when account is selected
    useEffect(() => {
        // If company info or user info have not been fetched return.
        // Also return if contact id exists but corresponding name in selectedCompanyContact is null
        if (selectedCompanyInfo == null ||
            selectedUserInfo == null ||
            (selectedCompanyContact.PrimaryFirstName == null && selectedCompanyContact.PrimaryLastName == null && selectedCompanyInfo.PrimaryContactID != null) ||
            (selectedCompanyContact.CreditFirstName == null && selectedCompanyContact.CreditLastName == null && selectedCompanyInfo.CreditContactID != null)
        )
            return;

        // Replace the placeholder fields in email body
        var htmlBody = template.EmailTemplateHtmlBody;
        let primaryContactFullName = '';
        let creditContactFullName = '';

        // If there exists a primary contact
        if (selectedCompanyInfo.PrimaryContactID != null) {
            primaryContactFullName = primaryContactFullName.concat(selectedCompanyContact.PrimaryFirstName ?? '',
                selectedCompanyContact.PrimaryFirstName != null && selectedCompanyContact.PrimaryLastName != null ? ' ' : '',
                selectedCompanyContact.PrimaryLastName ?? '');
        }

        // If there exists a credit contact
        if (selectedCompanyInfo.CreditContactID != null) {
            creditContactFullName = creditContactFullName.concat(selectedCompanyContact.CreditFirstName ?? '',
                selectedCompanyContact.CreditFirstName != null && selectedCompanyContact.CreditLastName != null ? ' ' : '',
                selectedCompanyContact.CreditLastName ?? '');
        }

        htmlBody = htmlBody.replaceAll('{{CompanyName}}', selectedCompanyInfo.CompanyName ?? '');
        htmlBody = htmlBody.replaceAll('{{AccountOwnerFullName}}', selectedUserInfo.DisplayName ?? '');
        htmlBody = htmlBody.replaceAll('{{AccountOwnerFirstName}}', selectedUserInfo.FirstName ?? '');
        htmlBody = htmlBody.replaceAll('{{AccountOwnerLastName}}', selectedUserInfo.LastName ?? '');
        htmlBody = htmlBody.replaceAll('{{AccountOwnerPhone}}', selectedUserInfo.PhoneNumber ?? '');
        htmlBody = htmlBody.replaceAll('{{AccountOwnerEmail}}', selectedUserInfo.PrimaryEmailAddress ?? '');
        htmlBody = htmlBody.replaceAll('{{AccountOwnerJobTitle}}', selectedUserInfo.JobTitle ?? '');

        htmlBody = htmlBody.replaceAll('{{CreditContactFullName}}', creditContactFullName); 
        htmlBody = htmlBody.replaceAll('{{CreditContactFirstName}}', selectedCompanyContact.CreditFirstName ?? ''); 
        htmlBody = htmlBody.replaceAll('{{CreditContactLastName}}', selectedCompanyContact.CreditLastName ?? ''); 

        htmlBody = htmlBody.replaceAll('{{ContactFullName}}', primaryContactFullName); 
        htmlBody = htmlBody.replaceAll('{{ContactFirstName}}', selectedCompanyContact.PrimaryFirstName ?? ''); // primary contact
        htmlBody = htmlBody.replaceAll('{{ContactLastName}}', selectedCompanyContact.PrimaryLastName ?? ''); // primary contact

        setFormattedEmailTemplate(htmlBody);
    }, [selectedCompanyInfo, selectedCompanyContact, selectedUserInfo]);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />

            {/* Confirm delete modal */}
            <ConfirmModal
                cancelFunction={() => setVisibleConfirmModal(false)}
                confirmFunction={OnDeleteTemplate}
                title={'Are you sure you want to delete this template?'}
                showModal={visibleConfirmModal}
                confirmColour='danger'
            />

            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Campaign Template</CHeaderText>
            </CHeader>
            <CCard>
                {template == null ? null :
                    <CCardBody>
                        <CCardTitle className='justify-content-between title-display-content'>
                            {template.EmailTemplateName}

                            {/* Only show edit and delete buttons if user owns template or is admin */}
                            {isAdmin.current === true || template.OwnerUserID === userId.current ?
                                <div>
                                    <CButton
                                        onClick={() => navigate(`/campaigns/templates/${template.EmailTemplateID}/edit`)}
                                        color='success'
                                        className='mx-2 mb-2'
                                        style={{ width: '90px' }}
                                    >
                                        Edit
                                    </CButton>
                                    <CButton
                                        color='danger'
                                        style={{ width: '90px' }}
                                        className='mx-2 mb-2'
                                        onClick={() => setVisibleConfirmModal(true)}
                                    >
                                        Delete
                                    </CButton>
                                </div>
                            : null}
                        </CCardTitle>
                        <hr />

                        {/* Template Elements */}
                        <CRow>
                            <CCol xs={4} md={3} xl={1} className='justify-content-end d-flex mb-3'>
                                <b>Subject:</b>
                            </CCol>
                            <CCol xs={8} md={3} xl={3} className='mb-3'>
                                {template.EmailTemplateSubject}
                            </CCol>

                            <CCol xs={4} md={3} xl={1} className='justify-content-end d-flex'>
                                <b>Permission:</b>
                            </CCol>
                            <CCol xs={8} md={3} xl={2} className='mb-3'>
                                {template.Permission}
                            </CCol>

                            <CCol xs={4} md={3} xl={2} className='justify-content-end d-flex mb-3'>
                                <b>Preview By Account:</b>
                            </CCol>
                            <CCol xs={8} md={3} xl={3} className='mb-3'>
                                {ownedAccounts == null ? null :
                                    <Typeahead
                                        name='Typeahead.AccountsOwned'
                                        options={ownedAccounts}
                                        labelKey={option => `${option.CompanyName}`}
                                        onChange={setSelectedAccountToPreview}
                                        selected={selectedAccountToPreview}
                                        id='preview-by-account'
                                        clearButton={true}
                                        emptyLabel='Choose An Account to Preview'
                                    />
                                }
                            </CCol>
                        </CRow>

                        {/* Template Body */}
                        <CRow>
                            <CCol sm={12} lg={1} className='justify-content-end d-flex'>
                                <b>Body:</b>
                            </CCol>
                            <CCol sm={12} lg={11}>
                                {formattedEmailTemplate === '' ?
                                    'N/A'
                                    :
                                    <iframe
                                        ref={iframe}
                                        srcDoc={formattedEmailTemplate}
                                        title='Template Body'
                                        width='100%'
                                        style={{ minHeight: '500px', resize:'vertical', overflow:'auto' }}
                                    />
                                }
                            </CCol>
                        </CRow>
                    </CCardBody>
                }
            </CCard>
        </>
    );       
}