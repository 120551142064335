import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { cookieNames, userRoles } from '../../constants';
import {
    GetErrorToastComponent,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    NavWithNewTab,
    GetCookie,
} from '../../functions';

import { CButton, CTooltip } from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

import Table from '../table/table';

export default function CreditRequestHistory({ companyId, setToast }) {

    //#region Variables
    const [requests, setRequests] = useState();
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    const headers = [
        {
            name: '#',
            headerClassName: '#',
            colSort: true,
        },
        {
            name: 'Request Type',
            headerClassName: 'request-type',
            colSort: true,
        },
        {
            name: 'Status',
            headerClassName: 'status',
            colSort: true,
        },
        {
            name: 'Approved By',
            headerClassName: 'approved-by',
            colSort: true,
        },
        {
            name: 'Approved Amount',
            headerClassName: 'approved-amount',
            colSort: true,
        },
        {
            name: 'Last Modified By',
            headerClassName: 'last-modified-by',
            colSort: true,
        },
        {
            name: 'Date Submitted',
            headerClassName: 'date-submitted',
            colSort: true,
        },
        {
            name: 'Button',
            headerClassName: 'text-white nav-buttons',
            moreThanJustText: true,
        },
    ];

    const formatter = new Intl.NumberFormat('en-CA', { // Currency formatter used in Annual Revenue field
        style: 'currency',
        currency: 'CAD',
    });

    //#endregion Variables

    //#region Use Effect

    // Get Credit Request History
    useEffect(() => {
        const ProcessData = (data) => {
            if (data == null) return;

            let userRole = JSON.parse(GetCookie(cookieNames.userRole));
            data.forEach(row => {
                var valueWithElements = {};

                // Company Application Id
                row['#'] = row.CompanyApprovalProcessID;
                row.CompanyApprovalProcessID = undefined;

                // Request Type
                row['Request Type'] = row.CompanyApprovalType;
                row.CompanyApprovalType = undefined;

                // Status
                row['Status'] = row.CompanyApprovalStatus;
                row.CompanyApprovalStatus = undefined;

                // Approved By
                row['Approved By'] = row.ApproveUserName;
                row.ApproveUserName = undefined;

                // Approved Amount
                row['Approved Amount'] = row.ApprovedAmount != null ? formatter.format(row.ApprovedAmount) : '';
                row.CompanyApprovalStatus = undefined;

                // Last Modified By
                row['Last Modified By'] = row.LastModifiedByName;
                row.LastModifiedByName = undefined;

                // Date Submitted
                let creationDate = new Date(row.CreationTime);
                row['Date Submitted'] = `${creationDate.getFullYear()}/${creationDate.getMonth() + 1}/${creationDate.getDate()}`;
                row.CreationTime = undefined;

                // Nav Button
                valueWithElements['Button'] = <CTooltip content='View Credit Request'>
                    <CButton onClick={(e) => NavWithNewTab(e, navigate, `/credit/${row['#']}`)} disabled={userRole === userRoles.Sales}>
                        <FontAwesomeIcon icon={faCreditCard} />
                    </CButton>
                </CTooltip>;

                row['ValueWithElements'] = valueWithElements;
            })

            return data;
        }

        fetch('/api/credit/get-request-history?companyId=' + companyId, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                setRequests(ProcessData(data));
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(err.message));
            })
            .finally(setLoading(false));
    }, [companyId]);

    //#endregion Use Effect

    return <Table
        headers={headers}
        body={requests}
        hover={true}
        hasColSort={true}
        tableClass='align-middle'
        isLoading={loading}
    />;
}