import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
    GetBearer,
    GetErrorToastComponent,
    GetToastComponent,
    HandleDataReturn,
    HandleResponseReturn
} from '../../functions';

import {
    CButton,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';

/**
 * Opens a modal to import from excel
 * @param {bool} visible Show or hide modal
 * @param {set useState} setVisible Sets the state of visible
 * @param {set useState} setToast
 * @returns Import from excel modal
 */
export default function ImportFromZoomInfoExcelModal({ visible, setVisible, setToast }) {

    //#region Fields

    const importInput = useRef();
    const navigate = useNavigate();

    //#endregion Fields

    //#region Functions

    // Import the excel file to add to leads
    function OnImport() {
        // Check there is a file to pass
        if (importInput.current.value === '') {
            setToast(GetToastComponent('Select a file to import first'));
            return;
        }

        const formData = new FormData();

        formData.append('file', importInput.current.files[0]);

        // First process the leads file
        fetch('/api/zoominfo/create-enrich-company', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST',
            body: formData
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                // data is new CompanyAccountID
                navigate(`/company-accounts/${data}/profile`)
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Could not import!', err));
            });
    }

    //#endregion Functions

    return (<CModal
        alignment='center'
        visible={visible}
        onClose={() => setVisible(false)}
    >
        <CModalHeader closeButton={false}>
            <CModalTitle>
                Import From ZoomInfo
            </CModalTitle>
        </CModalHeader>
        <CModalBody>
            <CFormInput ref={importInput} type='file' name='FileImport' label='Excel file to import:' accept='.csv' />
            <br /><strong>Note: </strong> Can only import one account at a time
        </CModalBody>
        <CModalFooter>
            <CButton color='secondary' variant='outline' style={{ width: '90px' }} onClick={() => setVisible(false)}>Cancel</CButton>
            <CButton type='submit' style={{ width: '90px' }} onClick={OnImport}>Import</CButton>
        </CModalFooter>
    </CModal>);
}