import React, { useRef } from 'react';

import {
    CButton,
    CFormInput,
    CModal,
    CModalBody,
    CModalFooter,
    CModalHeader,
    CModalTitle,
} from '@coreui/react';
import { GetToastComponent } from '../../functions';

// Modal to set the name of new filter
// showModal (bool) = show or don't show modal
// setShowModal (useState) = set state for showModal
// selectedName (useRef) = selected name that should be set
// confirmFunction (fn) = runs this after confirming action
// setToast (useState) =  used to set error toasts
export default function SetSearchFilterNameModal({ showModal, setShowModal, selectedName, confirmFunction, setToast }) {

    const nameInput = useRef();

    // Confirming name selected
    function OnSetClicked() {
        // Should not be empty string
        if (nameInput.current.value === '') {
            setToast(GetToastComponent('Filter name should not be empty', null, 'warning'));
            return;
        }

        selectedName.current = nameInput.current.value;
        setShowModal(false);

        confirmFunction();
    }

    return (
        <>
            <CModal
                alignment='center'
                visible={showModal}
                onClose={() => setShowModal(false)}
            >
                <CModalHeader closeButton={false}>
                    <CModalTitle>Set Search Filter Name</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <CFormInput ref={nameInput} />
                </CModalBody>
                <CModalFooter>
                    <CButton color='secondary' variant='outline' style={{ width: '90px' }} onClick={() => setShowModal(false)}>Cancel</CButton>
                    <CButton color='success' style={{ width: '90px' }} onClick={OnSetClicked}>Set</CButton>
                </CModalFooter>
            </CModal>
        </>
    );
}