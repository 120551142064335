import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { GetErrorMessages, cookieNames, userRoles } from '../../constants';
import {
    GetCookie,
    GetCompanyOwners,
    GetCountries,
    GetEngagementType,
    GetGrades,
    GetIndustries,
    GetParentCompanies,
    GetQuoteType,
    GetStateProvinces,
    GetSources,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    GetErrorToastComponent,
    GetToastComponent,
    NavWithNewTab,
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CHeader,
    CHeaderText,
    CInputGroup,
    CInputGroupText,
    CRow,
    CToaster,
} from '@coreui/react';

import '../../style/edit.css';
import '../../style/typeahead.css';

/**
 * Add New Company Account
 */
export default function AccountNew() {

    //#region Variables

    const [validated, setValidated] = useState(false); // Form validation
    const role = useRef(JSON.parse(GetCookie(cookieNames.userRole)));

    // Input field refs
    const companyOwnerSelect = useRef();
    const stateProvinceSelect = useRef();

    // Dropdown values
    const [selectedCountryId, setSelectedCountryId] = useState('');
    const [selectedEngagementTypeId, setSelectedEnagementTypeId] = useState(null);

    // Dropdown menu options
    const [countryList, setCountryList] = useState([]);
    const [stateProvinceList, setStateProvinceList] = useState([]);
    const [sources, setSources] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [engagementTypes, setEngagementTypes] = useState([]);
    const [grades, setGrades] = useState([]);
    const [quoteTypes, setQuoteTypes] = useState([]);
    const [companyOwners, setCompanyOwners] = useState([]);
    const [parentCompanies, setParentCompanies] = useState([]);

    // Selected option for dropdown menu
    const [selectedIndustry, setSelectedIndustry] = useState([]);
    const [selectedParentCompany, setSelectedParentCompany] = useState([]);

    const [toast, setToast] = useState(null);
    const navigate = useNavigate();

    //#endregion Variables

    //#region Functions

    function AddCompany(e) {
        e.preventDefault();
        var formData = new FormData(e.target);

        if (e.target.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true); // show validation

            // Get the first invalid element
            var firstInvalidElem = document.querySelector('input:invalid, select:invalid');

            // Scroll to element
            if (firstInvalidElem != null) {
                firstInvalidElem.focus();
            }
        } else {
            // Add typeahead values to FormData
            if (selectedParentCompany.length > 0)
                formData.append('Account.ParentCompanyAccountID', selectedParentCompany[0].CompanyAccountID);
            if (selectedIndustry.length > 0)
                formData.append('Account.IndustryID', selectedIndustry[0].IndustryID);

            fetch('/api/companyaccounts/new', {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: e.target.method,
                body: formData
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    if (data.Errors.length === 0) {
                        let body = <CButton
                            color='secondary'
                            onClick={(e) => NavWithNewTab(e, navigate, `/company-accounts/${data.CompanyAccountID}/profile`)}
                        >
                            Go to Account
                        </CButton>;

                        setToast(GetToastComponent('Company Added Successfully', body, 'success', false, null, true));
                    } else {
                        throw new Error(JSON.stringify(data.Errors));
                    }
                })
                .catch(err => {
                    console.error(err);
                    setToast(GetErrorToastComponent('An error has occurred when adding account!', err.message));
                });
        }
    }

    /**
     * Get the accounts associated with Company Owner selected
     * @param {int} ownerAccountId Account owner to get owned accounts for
     */
    function GetParentCompaniesToOwner(ownerAccountId) {
        // Empty id means no parent company to choose
        if (ownerAccountId === '') {
            setParentCompanies([]);
            return;
        }

        GetParentCompanies(null, ownerAccountId)
            .then(x => {
                setParentCompanies(x);
                setSelectedParentCompany([]);
            })
            .catch((err) => GetErrorToastComponent(GetErrorMessages.ParentCompanies, err.message));
    }

    //#endregion Functions

    //#region Use Effect

    // Get all the data for the drop down select fields
    useEffect(() => {
        GetCountries()
            .then(x => setCountryList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Countries, err.message)));

        GetStateProvinces()
            .then(x => setStateProvinceList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.StateProvince, err.message)));

        GetSources()
            .then(x => setSources(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Sources, err.message)));

        GetGrades()
            .then(x => setGrades(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Grades, err.message)));

        GetQuoteType()
            .then(x => setQuoteTypes(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.QuoteType, err.message)));

        GetCompanyOwners()
            .then(x => setCompanyOwners(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.CompanyOwner, err.message)));

        GetEngagementType()
            .then(x => setEngagementTypes(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.EngagementType, err.message)));

        GetIndustries()
            .then(x => setIndustries(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Industries, err.message)));

        GetParentCompaniesToOwner(parseInt(JSON.parse(GetCookie(cookieNames.userID))));
    }, []);

    // When company owner is set, should also update default value to current user
    useEffect(() => {
        if (companyOwners.length === 0) return;

        companyOwnerSelect.current.value = JSON.parse(GetCookie(cookieNames.userID));
    }, [companyOwners]);

    //#endregion Use Effect

    return <>
        <CToaster push={toast} placement='top-end' />

        <CForm
            id='company-account-form'
            className='mt-3'
            onSubmit={AddCompany}
            method='POST'
            noValidate
            validated={validated}
        >
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Add New Company Account</CHeaderText>
            </CHeader>
            <CCard>
                <CCardBody className='px-5'>
                    {/* Company Information Section */}
                    <CCardTitle className='justify-content-between title-display-content'>
                        Company Information
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol xs={12} sm={6}>
                            <CFormLabel className='mt-3 required-label'>Company Name</CFormLabel>
                            <CFormInput
                                type='text'
                                id='Name'
                                name='Account.CompanyName'
                                maxLength='150'
                                feedbackInvalid='Required field'
                                required
                                tabIndex={1}
                            />
                            <CRow>
                                <CCol>
                                    <CFormLabel className='mt-3 required-label'>Engagement Type</CFormLabel>
                                    <CFormSelect
                                        name='Account.CompanyAccountEngagementTypeID'
                                        required
                                        onChange={e => setSelectedEnagementTypeId(parseInt(e.target.value))}
                                        tabIndex={3}
                                    >
                                        <option />
                                        {engagementTypes.map((engagement) => {
                                            // Only admins can add customer accounts directly
                                            if (engagement.Name === 'Unqualified' || (engagement.Name === 'Customer' && role.current !== userRoles.Admin))
                                                return null;
                                            else
                                                return <option
                                                    key={engagement.CompanyAccountEngagementTypeID}
                                                    value={engagement.CompanyAccountEngagementTypeID}
                                                >
                                                    {engagement.Name}
                                                </option>;
                                        })}
                                    </CFormSelect>
                                </CCol>
                                <CCol>
                                    {selectedEngagementTypeId === 1 ||
                                        selectedEngagementTypeId === 9 ||
                                        selectedEngagementTypeId === 10 ||
                                        selectedEngagementTypeId === 11 ? <CFormLabel className='mt-3 required-label'>Quote Type</CFormLabel> : <CFormLabel className='mt-3'>Quote Type</CFormLabel>}
                                    <CFormSelect
                                        name='Account.CompanyAccountQuoteTypeID'
                                        required={
                                            selectedEngagementTypeId === 1 ||
                                            selectedEngagementTypeId === 9 ||
                                            selectedEngagementTypeId === 10 ||
                                            selectedEngagementTypeId === 11
                                        }
                                        tabIndex={4}
                                    >
                                        <option />
                                        {quoteTypes.map((quoteType) => {
                                            return (
                                                <option
                                                    key={quoteType.CompanyAccountQuoteTypeID + '-qoute-type'}
                                                    value={quoteType.CompanyAccountQuoteTypeID}
                                                >
                                                    {quoteType.QuoteTypeName}
                                                </option>
                                            )
                                        })}
                                    </CFormSelect>
                                </CCol>
                            </CRow>
                            <CFormLabel className='mt-3 required-label'>Grade</CFormLabel>
                            <CFormSelect name='Account.CompanyGradeId' required tabIndex={5}>
                                <option />
                                {grades.map((grade) => {
                                    return (
                                        <option
                                            key={grade.CompanyGradeID}
                                            value={grade.CompanyGradeID}
                                        >
                                            {grade.Grade} {grade.Description}
                                        </option>
                                    )
                                })}
                            </CFormSelect>

                            <CFormLabel className='mt-3 required-label label-auto-width'>Primary Contact Name</CFormLabel>
                            <CRow>
                                <CCol sm={12} md={6}>
                                    <CFormInput
                                        name='PrimaryContactFirstName'
                                        type='text'
                                        placeholder='First Name'
                                        feedbackInvalid='Required field'
                                        maxLength='75'
                                        required
                                        tabIndex={7}
                                    />
                                </CCol>
                                <CCol sm={12} md={6}>
                                    <CFormInput
                                        name='PrimaryContactLastName'
                                        type='text'
                                        placeholder='Last Name'
                                        feedbackInvalid='Required field'
                                        maxLength='75'
                                        required
                                        tabIndex={8}
                                    />
                                </CCol>
                            </CRow>
                            <CFormLabel className='mt-3 required-label'>Primary Contact Email</CFormLabel>
                            <CFormInput
                                name='PrimaryContactEmail'
                                type='email'
                                maxLength='150'
                                feedbackInvalid='Required field with valid email format'
                                required
                                tabIndex={10}
                            />
                            <CFormLabel className='mt-3 required-label'>Industry</CFormLabel>
                            <div id='industry-typeahead'>
                                <Typeahead
                                    options={industries}
                                    labelKey={option => `${option.Industry}`}
                                    onChange={(value) => setSelectedIndustry(value)}
                                    selected={selectedIndustry}
                                    id='industry'
                                    emptyLabel='No matches found, only options chosen from dropdown will be saved'
                                    isInvalid={validated && selectedIndustry.length === 0}
                                    inputProps={{ required: true, tabIndex: '12' }}
                                />
                                <div className='invalid-feedback required-field'>Required field that is one of the options listed</div>
                            </div>

                            <CFormLabel className='mt-3'>Phone Number</CFormLabel>
                            <CInputGroup>
                                <CFormInput type='text' name='Account.PhoneNumber' maxLength='20' tabIndex={14} />
                                <div className='input-group-spacer' style={{ width: '5%' }} />
                                <CInputGroupText>ext</CInputGroupText>
                                <CFormInput
                                    type='text'
                                    name='Account.PhoneNumberExtension'
                                    maxLength='6'
                                    style={{ maxWidth: '30%' }}
                                    tabIndex={15}
                                />
                            </CInputGroup>
                            <CFormLabel className='mt-3'>Fax Number</CFormLabel>
                            <CFormInput type='text' name='Account.FaxNumber' maxLength='15' tabIndex={17} />
                        </CCol>
                        <CCol xs={12} sm={6}>
                            <CFormLabel className='mt-3 required-label'>Company Owner</CFormLabel>
                            <CFormSelect
                                ref={companyOwnerSelect}
                                name='Account.AccountOwnerUserID'
                                feedbackInvalid='Required field'
                                required
                                onChange={(e) => GetParentCompaniesToOwner(e.target.value, false)}
                                tabIndex={2}
                            >
                                <option />
                                {companyOwners.map((owner) => {
                                    return (<option key={owner.UserID} value={owner.UserID}>{owner.DisplayName}</option>)
                                })}
                            </CFormSelect>

                            <CFormLabel className='mt-3'>Parent Company</CFormLabel>
                            <div id='parent-company-typeahead'>
                                <Typeahead
                                    id='parent-company'
                                    options={parentCompanies}
                                    labelKey={option => `${option.CompanyName}`}
                                    onChange={setSelectedParentCompany}
                                    selected={selectedParentCompany}
                                    emptyLabel='No matches found, only options chosen from dropdown will be saved'
                                    inputProps={{ tabIndex: '4' }}
                                />
                            </div>

                            <CFormLabel className='mt-3 required-label'>Account Source</CFormLabel>
                            <CFormSelect name='Account.SourceID' tabIndex={6} feedbackInvalid='Required field' required>
                                <option />
                                {sources.map((source) => {
                                    return (<option key={source.SourceID} value={source.SourceID}>{source.Name}</option>)
                                })}
                            </CFormSelect>
                            <CFormLabel className='mt-3'>Annual Revenue</CFormLabel>
                            <CInputGroup>
                                <CInputGroupText>$</CInputGroupText>
                                <CFormInput type='text' name='Account.AnnualRevenue' tabIndex={9} />
                            </CInputGroup>
                            <CFormLabel className='mt-3'>Number Of Employees</CFormLabel>
                            <CFormInput
                                type='text'
                                name='Account.NumberOfEmployees'
                                pattern='^\d*$'
                                feedbackInvalid='Only positive integer values allowed'
                                tabIndex={11}
                            />
                            <CFormLabel className='mt-3'>Website</CFormLabel>
                            <CInputGroup>
                                <CInputGroupText>https://</CInputGroupText>
                                <CFormInput type='text' name='Account.WebsiteURL' maxLength='255' tabIndex={13} />
                            </CInputGroup>
                            <CFormLabel className='mt-3'>Description</CFormLabel>
                            <CFormTextarea type='text' name='Account.Description' tabIndex={16} />
                        </CCol>
                    </CRow>

                    {/* Address Section */}
                    <CCardTitle className='justify-content-between title-display-content mt-5'>
                        Address
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol>
                            <CFormLabel className='mt-3 required-label'>Address</CFormLabel>
                            <CFormInput
                                type='text'
                                name='Account.AddressLine1'
                                maxLength='75'
                                feedbackInvalid='Required field'
                                required
                                tabIndex={18}
                            />
                            <CFormInput
                                type='text'
                                name='Account.AddressLine2'
                                className='mt-3'
                                maxLength='75'
                                tabIndex={19}
                            />
                            <CRow>
                                <CCol className='pe-4'>
                                    <div>
                                        <CFormLabel className='mt-3 required-label'>City</CFormLabel>
                                        <CFormInput
                                            type='text'
                                            name='Account.City'
                                            maxLength='75'
                                            feedbackInvalid='Required field'
                                            required
                                            tabIndex={20}
                                        />
                                    </div>
                                    <div>
                                        <CFormLabel className='mt-3 required-label'>Province/State</CFormLabel>
                                        <CFormSelect
                                            ref={stateProvinceSelect}
                                            name='Account.StateProvinceID'
                                            feedbackInvalid='Required field'
                                            required
                                            disabled={selectedCountryId === ''}
                                            tabIndex={22}
                                        >
                                            {stateProvinceList.map((stateProvince) => {
                                                // Only map if the country matches
                                                if (stateProvince.CountryID == parseInt(selectedCountryId))
                                                    return <option
                                                        key={stateProvince.StateProvinceID}
                                                        value={stateProvince.StateProvinceID}
                                                    >
                                                        {stateProvince.StateProvinceName}
                                                    </option>;
                                                else
                                                    return null;
                                            })}
                                        </CFormSelect>
                                    </div>
                                </CCol>
                                <CCol className='ps-4'>
                                    <div>
                                        <CFormLabel className='mt-3 required-label'>Country</CFormLabel>
                                        <CFormSelect
                                            feedbackInvalid='Required field'
                                            required
                                            onChange={(e) => setSelectedCountryId(e.target.value)}
                                            tabIndex={21}
                                        >
                                            <option />
                                            {countryList.map((country) => {
                                                return (<option key={country.CountryID} value={country.CountryID}>{country.CountryName}</option>)
                                            })}
                                        </CFormSelect>
                                    </div>
                                    <div>
                                        <CFormLabel className='mt-3 required-label'>Postal/Zip Code</CFormLabel>
                                        <CFormInput
                                            type='text'
                                            name='Account.ZipPostalCode'
                                            maxLength='15'
                                            feedbackInvalid='Required field'
                                            required
                                            tabIndex={23}
                                        />
                                    </div>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>

                    {/* Credit Information Section */}
                    <CCardTitle className='justify-content-between title-display-content mt-5'>
                        Credit Information
                    </CCardTitle>
                    <hr />
                    <CRow>
                        <CCol sm={6}>
                            <CFormLabel className='mt-3 label-auto-width'>AP Credit Contact</CFormLabel>
                            <CRow>
                                <CCol sm={12} md={6}>
                                    <CFormInput
                                        name='CreditContactFirstName'
                                        type='text'
                                        placeholder='First Name'
                                        maxLength='75'
                                        tabIndex={24}
                                    />
                                </CCol>
                                <CCol sm={12} md={6}>
                                    <CFormInput
                                        name='CreditContactLastName'
                                        type='text'
                                        placeholder='Last Name'
                                        maxLength='75'
                                        tabIndex={25}
                                    />
                                </CCol>
                            </CRow>
                            <CFormInput
                                name='CreditContactEmail'
                                type='email'
                                className='mt-3'
                                placeholder='Email'
                                maxLength='150'
                                feedbackInvalid='Valid email format required'
                                tabIndex={26}
                            />

                            {/* Do a check when tmw id is entered */}
                            <CFormLabel className='mt-3'>TMW Customer Id</CFormLabel>
                            <CFormInput
                                type='text'
                                name='Account.TMWCustomerId'
                                maxLength='8'
                                disabled={role.current !== userRoles.Admin}
                                tabIndex={27}
                            />
                        </CCol>
                    </CRow>

                    {/* Button Section */}
                    <CRow className='my-5'>
                        <CCol style={{ textAlign: 'end' }}>
                            <CButton color='secondary' style={{ width: '100px' }} onClick={() => navigate(`/company-accounts/`)}>Cancel</CButton>
                        </CCol>
                        <CCol>
                            <CButton type='submit' style={{ backgroundColor: '#337ab7' }}>Add Company</CButton>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CForm>
    </>;
}