import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { infoModalText, cookieNames, userRoles } from '../../constants';
import { FormatDate, GetCookie } from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CHeader,
    CHeaderText,
    CTooltip,
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

import InfoModal from '../../components/miscellaneous/infoModal';

import SkeletonTable from '../../components/table/skeletonTable';
import Table from '../../components/table/table';

export default function SearchList() {

    //#region Variables

    const [results, setResults] = useState();
    const [searchText, setSearchText] = useState();
    const userRole = useRef(JSON.parse(GetCookie(cookieNames.userRole)));
    const userId = useRef(parseInt(JSON.parse(GetCookie(cookieNames.userID))));

    const location = useLocation();
    const navigate = useNavigate();

    const headers = [
        {
            name: 'Type',
            hasSearch: true,
            headerClassName: 'type',
            colSort: true,
            moreThanJustText: true,
        },
        {
            name: 'Company Name',
            hasSearch: true,
            headerClassName: 'company-name',
            colSort: true,
        },
        {
            name: 'TMW ID',
            hasSearch: true,
            headerClassName: 'tmw-id',
            colSort: true,
        },
        {
            name: 'Last Sale',
            hasSearch: true,
            headerClassName: 'last-sale',
            colSort: true,
        },
        {
            name: 'Owner',
            hasSearch: true,
            headerClassName: 'owner',
            colSort: true,
        },
        {
            name: 'Last Modified',
            hasSearch: true,
            headerClassName: 'last-modified',
            colSort: true,
        },
        {
            name: 'Last Modified By',
            hasSearch: true,
            headerClassName: 'last-modified-by',
            colSort: true,
        },
        {
            name: 'Creation Time',
            hasSearch: true,
            headerClassName: 'creation-time',
            colSort: true,
        },
        {
            name: 'Created By',
            hasSearch: true,
            headerClassName: 'created-by',
            colSort: true,
        },
        {
            name: 'Button',
            headerClassName: 'text-light nav-buttons',
            moreThanJustText: true,
            columnClass: 'button-center',
        },
    ]

    //#endregion Variables

    //#region Use Effect

    useEffect(() => {
        if (location.state.searchResults == null && results != null) return;

        // Process the data for table
        var results = location.state.searchResults;

        results.forEach(row => {
            // 1 is account and 2 is lead
            row['Type'] = row.Type === 1 ?
                <>
                    {row.AccountDeleted ? <span className='fw-semibold'>Deleted </span> : null}
                    {row.EngagementType}
                </>
                : 'Lead';
            row.EngagementType = undefined;

            row['Company Name'] = row.CompanyName;
            row.CompanyName = undefined;

            row['TMW ID'] = row.TMWCustomerID;
            row.TMWCustomerID = undefined;

            if (row.LastSale != null) {
                let lastSaleDate = new Date(row.LastSale);
                row['Last Sale'] = FormatDate(lastSaleDate);
            } else {
                row['Last Sale'] = '';
            }
            row.LastSale = undefined;

            row['Owner'] = row.AccountOwner ?? '';
            row.AccountOwner = undefined;

            let modifiedDate = new Date(row.LastModified);
            row['Last Modified'] = FormatDate(modifiedDate);
            row.LastModified = undefined;

            row['Last Modified By'] = row.LastModifiedBy;
            row.LastModifiedBy = undefined;

            let creationDate = new Date(row.CreationTime);
            row['Creation Time'] = FormatDate(creationDate);
            row.CreationTime = undefined;

            row['Created By'] = row.CreatedBy;
            row.CreatedBy = undefined;

            var buttonRoute = '';
            switch (row['Type']) {
                case 'Lead':
                    buttonRoute = 'company-leads';
                    break;
                default: // Company Account Routes
                    buttonRoute = 'company-accounts';
                    break;
            }

            // Admins and credit can see all profiles
            // Sales users can only see accounts they own or accounts under Available user or leads 
            row['ValueWithElements'] = {
                Button: <CTooltip content='Profile'>
                    <CButton
                        onClick={(event) => {
                            if (event.ctrlKey) {
                                window.open(`/${buttonRoute}/${row.ID}/profile`, '_blank');
                            } else {
                                navigate(`/${buttonRoute}/${row.ID}/profile`)
                            }
                        }}
                        disabled={
                            !(userRole.current === userRoles.Admin || userRole.current === userRoles.Credit) // admin and credit can view everything
                            && row['Type'] !== 'Lead' // Anyone can view leads
                            && row['AccountOwnerID'] != userId.current // sales can only view their own accounts
                            && row['Owner'] !== 'Available' // Anyone can view Available accounts
                        }
                    >
                        <FontAwesomeIcon icon={faAddressCard} />
                    </CButton>
                </CTooltip>
            };
        });

        // Show search text if exist
        if (location.state.searchText != null) setSearchText(location.state.searchText);

        setResults(results);
    }, [location.state.searchResults]);

    //#endregion Use Effect

    return <>
        <CHeader container='fluid'>
            <CHeaderText className='fs-3'>Search Results</CHeaderText>
            <InfoModal modalContent={infoModalText.SearchList} />
        </CHeader>
        <CCard>
            <CCardBody>
                {results == null ?
                    <SkeletonTable numCols={6} numRows={14} tableColor={'light'} />
                    :
                    <>
                        <CCardTitle>Showing results for "{searchText}"
                            <span className='ms-1 fs-6 fw-light'>{results.length} results found</span>
                        </CCardTitle>
                        <Table
                            headers={headers}
                            body={results}
                            color={'light'}
                            hasSearchRow={true}
                            hasColSort={true}
                            hasPages={true}
                            striped={true}
                        />
                    </>
                }
            </CCardBody>
        </CCard>
    </>;
}