import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    GetErrorToastComponent,
    GetBearer,
    GetToastComponentFromCookiesValues,
    HandleDataReturn,
    HandleResponseReturn,
    GetToastComponent,
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CHeader,
    CHeaderText,
    CRow,
    CToaster,
    CTooltip
} from '@coreui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faListUl, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import Table from '../../components/table/table';
import SkeletonTable from '../../components/table/skeletonTable';

export default function Campaign() {

    //#region Variables
    const [campaigns, setCampaigns] = useState();

    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState();
    const navigate = useNavigate();
    const headers = [
        {
            name: 'Name',
            headerClassName: 'name',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Owner',
            headerClassName: 'owner',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Targets',
            headerClassName: 'targets',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Last Run',
            headerClassName: 'last-run',
            colSort: true,
        },
        {
            name: 'Next Run',
            headerClassName: 'next-run',
            colSort: true,
        },
        {
            name: 'Status',
            headerClassName: 'status',
            colSort: true,
            hasSearch: true,
        },
        {
            name: 'Button',
            headerClassName: 'text-light nav-buttons',
            moreThanJustText: true,
            columnClass: 'button-center'
        },
    ];

    //#endregion Variables

    //#region Functions

    // Delete campaign
    // Only owner or admin can delete campaign
    function DeleteCampaign(campaignId) {
        fetch('/api/campaign/delete-campaign?campaignId=' + campaignId, {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'POST'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                // Delete successful will return true
                if (data === true) {
                    setToast(GetToastComponent('Campaign deleted successfully!'));
                    GetCampaigns(); // refresh campaigns
                } else {
                    throw new Error(data);
                }
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not delete campaign!', err.message));
            });
    }

    // Gets campaigns
    function GetCampaigns() {
        const ProcessData = (data) => {
            if (data == null) return;

            data.forEach((row, i) => {
                var valueWithElements = {};

                // Owner 
                row['Owner'] = row.AccountOwnerName;
                row.AccountOwnerName = undefined;

                // Targets
                row['Targets'] = row.IncludePlaylistName ?? '-';
                row.IncludePlaylistName = undefined;

                // Last Run
                if (row.PreviousEmailTaskTime == null) {
                    row['Last Run'] = '';
                } else {
                    let lastDate = new Date(row.PreviousEmailTaskTime);
                    row['Last Run'] = `${lastDate.getFullYear()}/${lastDate.getMonth() + 1}/${lastDate.getDate()}`;
                }
                row.PreviousEmailTaskTime = undefined;

                // Next Run
                if (row.NextEmailTaskTime == null) {
                    row['Next Run'] = '';
                } else {
                    let nextDate = new Date(row.NextEmailTaskTime);
                    row['Next Run'] = `${nextDate.getFullYear()}/${nextDate.getMonth() + 1}/${nextDate.getDate()}`;
                }
                row.NextEmailTaskTime = undefined;

                // Status
                row['Status'] = row['Next Run'] === '' ? 'Inactive' : 'Active';

                // Buttons
                valueWithElements['Button'] = <CRow>
                    <CCol>
                        <CTooltip content='Open Campaign'>
                            <CButton onClick={() => navigate(`/campaigns/${row.EmailPlanID}/profile`)} className='my-1' disabled>
                                <FontAwesomeIcon icon={faAddressCard} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                    <CCol>
                        <CTooltip content='Logs'>
                            <CButton onClick={() => navigate(`/campaigns/${row.EmailPlanID}/logs`)} className='my-1' color='secondary' disabled>
                                <FontAwesomeIcon icon={faListUl} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                    <CCol>
                        <CTooltip content='Edit'>
                            <CButton
                                color='warning'
                                onClick={() => navigate(`/campaigns/${row.EmailPlanID}/edit`)}
                                className='my-1'
                                disabled
                            >
                                <FontAwesomeIcon icon={faPen} style={{ color: 'white' }} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                    <CCol>
                        <CTooltip content='Delete'>
                            <CButton
                                color='danger'
                                onClick={() => DeleteCampaign(row.EmailPlanID)}
                                className='my-1'
                                disabled
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </CButton>
                        </CTooltip>
                    </CCol>
                </CRow>;

                row['ValueWithElements'] = valueWithElements;
            })

            return data;
        }

        // Get campaigns 
        setLoading(true);
        fetch('/api/campaign/get-campaigns?', {
            headers: { 'Authorization': 'Bearer ' + GetBearer() },
            method: 'GET'
        })
            .then(response => HandleResponseReturn(response))
            .then(data => {
                data = HandleDataReturn(data);

                const result = ProcessData(data);

                setCampaigns(result);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setToast(GetErrorToastComponent('Error: Could not get campaigns!', err.message));
            });
    }

    //#endregion Functions

    //#region Use Effect

    // Get Campaign, show toast if applicable and set if admin
    useEffect(() => {
        GetCampaigns();

        // Check for toast item to show
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);
    }, []);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>Campaigns</CHeaderText>
                <CButton onClick={() => navigate('/campaigns/add')} disabled>Add Campaign</CButton>
            </CHeader>
            <CCard>
                <CCardBody>
                    {loading ?
                        <SkeletonTable numCols={4} numRows={14} tableColor={'light'} />
                        :
                        <Table
                            headers={headers}
                            body={campaigns}
                            hover={true}
                            color={'light'}
                            striped={true}
                            hasColSort={true}
                            hasPages={true}
                            hasSearchRow={true}
                            tableClass='align-middle'
                        />
                    }
                </CCardBody>
            </CCard>
        </>
    );
}