import React, { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../context/authConfig';
import { useNavigate } from 'react-router-dom';

import {
    cookieNames,
    userRoles
} from '../../constants';
import {
    GetCookie,
    SetCookie,
    GetToastComponentFromCookiesValues,
    SetToastComponentValuesInCookie,
    HandleDataReturn,
    HandleResponseReturn,
} from '../../functions';

import {
    CButton,
    CCard,
    CContainer,
    CImage,
    CSpinner,
    CToaster,
} from '@coreui/react';

import titaniumLogo from '../../assets/TTGI_SQR_Cropped.png';

import '../../../src/index.css';
import '../../style/login.css';
import '../../custom.css';

/**
 * Login component showing the page the user will login with
 * @param {setState} setUserRole Sets the role of the user after login
 * @param {setState} setisLoggedIn Sets whether user is logged in or not
 * @param {setState} setShowSideBar Sets whether to show sidebar (will only show if logged in)
 * @returns Component
 */
export default function Login({ setUserRole, setisLoggedIn, setShowSideBar }) {

    //#region Variables

    const [loggingIn, setLoggingIn] = useState(false);
    const [toast, setToast] = useState();
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();   

    //#endregion Variables

    //#region Functions

    /**
     * Handle the login functionality with AD
     * @param {bool} usePopup Defaults to false
     */
    async function HandleLogin(usePopup) {
        try {
            setLoggingIn(true);

            // Login with different email
            if (usePopup === true) {
                instance.loginPopup({ ...loginRequest, account: accounts[0], prompt: 'select_account' })
                    .then(response => HandleLoginResponse(response))
                    .finally(_ => setLoggingIn(false));
            } else {
                // Tries silent login first
                instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] })
                    .then(response => HandleLoginResponse(response))
                    .catch(err => {
                        console.error(err);

                        // Fallback for if login requires user interaction
                        instance.acquireTokenPopup(loginRequest)
                            .then(response => HandleLoginResponse(response));
                    })
                    .finally(_ => setLoggingIn(false));
            }
        } catch (err) {
            console.log(err);
            navigate('/login');
        }
    }

    /**
     * Things to do after logging in with MSAL
     * @param {fetch response?} loginResponse
     */
    function HandleLoginResponse(loginResponse) {
        if (loginResponse == null) {
            SetToastComponentValuesInCookie('Error occured in Azure when attempting to login', null, 'danger', true, 10);
            navigate(0);
        } else {
            const userData = {
                Email: loginResponse.account.username,
                GroupIds: loginResponse.idTokenClaims.groups.toString(),
            };

            // Clear cache. 
            // NOTE: image-cache and api-cache-unchange are untouched
            caches.delete('api-cache');

            // Get Login info
            fetch('/api/Login/sso-login', {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(userData)
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    if (data.Role == null) throw new Error('User role missing');

                    // Store values into Cookie
                    SetCookie(cookieNames.accessToken, JSON.stringify(data.AccessToken), 1, true);
                    SetCookie(cookieNames.userID, JSON.stringify(data.UserId.toString()), 48, true);
                    SetCookie(cookieNames.isSuperUser, JSON.stringify(data.IsSuperUser.toString()), 48);
                    SetCookie(cookieNames.userName, JSON.stringify(loginResponse.account.name), 48);
                    SetCookie(cookieNames.userRole, JSON.stringify(data.Role), 48);
                    if (data.RoleSubdivision != null) // Set subdivision if exist
                        SetCookie(cookieNames.userRoleSubdivision, JSON.stringify(data.RoleSubdivision), 48);

                    setUserRole(data.Role);
                    setisLoggedIn(true);
                    setShowSideBar(true);

                    // If there is a url user at previously, go there
                    let prevUrl = sessionStorage.getItem('prevUrl');
                    if (prevUrl != null && prevUrl !== '/login') {
                        navigate(prevUrl);
                        return;
                    }

                    if (data.Role === userRoles.Sales) {
                        navigate('/todo');
                        return;
                    } else if (data.Role === userRoles.Credit) {
                        navigate('/credit');
                        return;
                    }

                    navigate('/company-accounts');
                })
                .catch(err => {
                    SetToastComponentValuesInCookie('Error occurred when attempting to login!', err.message, 'danger', true, 10);

                    // Remove cookies
                    GetCookie(cookieNames.accessToken, true);
                    GetCookie(cookieNames.userID, true);
                    GetCookie(cookieNames.isSuperUser, true);
                    GetCookie(cookieNames.userName, true);
                    GetCookie(cookieNames.userRole, true);

                    navigate(0);
                });
        }
    }

    //#endregion Functions

    //#region useEffect

    // Check for toast to show on load
    useEffect(() => {
        let toastItem = GetToastComponentFromCookiesValues(true);
        if (toastItem != null) setToast(toastItem);
    }, [])

    //#endregion useEffect

    return (<>
        <CToaster push={toast} placement='top-end' />
        <CContainer id='login-container' fluid>
            <CCard id='login-card' className='center-content p-4'>
                <CImage id='titanium-logo' src={titaniumLogo} align='center' className='mb-5' />
                <CButton id='login-btn' type='submit' onClick={HandleLogin}>
                    {loggingIn && <CSpinner size='sm' className='me-2' style={{ visibility: 'hidden' }} />}
                    Login
                    {loggingIn && <CSpinner size='sm' className='ms-2' />}
                </CButton>
                <CButton id='login-alternate-btn' type='submit' onClick={() => HandleLogin(true)} variant='ghost' className='mt-2'>
                    Login With A Different Email
                </CButton>
            </CCard>
        </CContainer>
    </>);
}