export const logoNegative = ['474 149', `
  <title>Titanium Transportation Group Inc.</title>
<g transform="translate(0.000000,149.000000) scale(0.100000,-0.100000)"
fill="#d1d7ed" stroke="none">
<path d="M2758 1403 l-3 -28 -1321 -3 -1321 -2 -7 -46 c-3 -26 -6 -62 -6 -80
l0 -34 95 0 95 0 0 -68 c0 -114 -20 -472 -40 -741 -11 -139 -20 -260 -20 -267
0 -11 27 -14 129 -14 l129 0 6 132 c3 73 8 221 12 328 3 107 12 283 20 390 7
107 14 205 14 218 l0 22 1107 -2 1108 -3 5 -27 5 -27 185 69 c102 38 188 71
192 72 8 3 -360 139 -374 138 -4 0 -8 -12 -10 -27z"/>
<path d="M720 1000 c0 -95 -18 -415 -25 -447 -5 -23 -4 -23 108 -23 l114 0 6
208 c4 114 10 237 13 275 l7 67 -112 0 -111 0 0 -80z"/>
<path d="M997 1073 c-4 -3 -7 -37 -7 -75 l0 -68 82 0 81 0 -6 -147 c-4 -82
-10 -172 -13 -200 l-7 -53 112 0 111 0 0 133 c0 72 3 162 7 200 l6 67 78 0 79
0 0 38 c0 20 3 54 6 75 l7 37 -265 0 c-146 0 -268 -3 -271 -7z"/>
<path d="M1669 1018 c-17 -35 -47 -92 -67 -128 -19 -36 -53 -99 -75 -140 -22
-41 -58 -108 -79 -148 l-40 -73 117 3 117 3 22 55 22 55 105 3 104 3 18 -61
17 -60 109 0 109 0 -38 107 c-22 60 -65 183 -97 275 l-58 168 -127 0 -128 0
-31 -62z m175 -140 c9 -37 16 -72 16 -78 0 -10 -102 -15 -112 -4 -3 3 10 41
30 85 19 44 39 76 42 72 4 -5 15 -38 24 -75z"/>
<path d="M2181 833 c-7 -137 -15 -260 -18 -275 l-5 -28 99 0 100 0 7 92 c3 50
6 111 6 135 0 23 4 43 8 43 5 0 38 -61 73 -135 l64 -134 126 -1 126 0 7 213
c4 116 11 240 15 275 l7 62 -92 0 -92 0 -6 -107 c-11 -185 -11 -185 -89 -29
l-69 136 -127 0 -127 0 -13 -247z"/>
<path d="M2886 998 c-3 -46 -8 -146 -11 -223 -3 -77 -8 -164 -12 -192 l-6 -53
112 0 111 0 0 43 c0 76 20 431 26 470 l6 37 -110 0 -110 0 -6 -82z"/>
<path d="M3197 928 c-14 -313 -10 -335 75 -380 58 -30 238 -38 320 -14 119 34
136 66 148 278 5 90 12 187 16 216 l5 52 -104 0 -105 0 -6 -82 c-3 -46 -8
-125 -12 -176 -5 -70 -11 -98 -26 -117 -39 -50 -111 -20 -112 48 0 17 3 98 8
180 l8 147 -104 0 -105 0 -6 -152z"/>
<path d="M3850 1023 c0 -58 -24 -354 -36 -445 l-7 -48 86 0 85 0 5 43 c4 23 9
102 13 175 4 72 10 132 13 132 4 0 18 -78 32 -172 l24 -173 92 -3 92 -3 9 33
c61 228 88 318 93 318 6 0 3 -186 -5 -302 l-3 -48 87 0 88 0 6 248 c4 136 9
259 12 275 l6 27 -144 0 -144 0 -35 -145 c-19 -80 -37 -145 -40 -145 -3 0 -14
57 -23 128 -10 70 -20 135 -22 145 -5 15 -21 17 -145 17 l-139 0 0 -57z"/>
</g>
`]
