import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { engagementTypeName } from '../../constants';
import {
    GetCompanyContacts,
    GetCountryById,
    GetStateProvinceById,
    SetToastComponentValuesInCookie,
    GetBearer,
    HandleDataReturn,
    HandleResponseReturn,
    GetErrorToastComponent,
    NavWithNewTab,
} from '../../functions';

import {
    CButton,
    CCol,
    CHeaderText,
    CImage,
    CListGroup,
    CListGroupItem,
    CRow,
    CToaster,
    CTooltip,
} from '@coreui/react';

import Placeholder from '../miscellaneous/placeholder';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEnvelope,
    faEnvelopeOpenText,
    faExternalLinkAlt,
    faFax,
    faFont,
    faMapMarkerAlt,
    faMobile,
    faPhoneAlt,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';

// Modals
import AddContactModal from '../contact/addContactModal';
import ConfirmModal from '../miscellaneous/confirmModal';

import minnow from '../../assets/fishScale/minnow.png';
import salmon from '../../assets/fishScale/salmon.png';
import dolphin from '../../assets/fishScale/dolphin.png';
import whale from '../../assets/fishScale/whale.png';

import '../../style/profile.css';

const lead = 'lead';
const account = 'account';
const contact = 'contact';
const dayInMilliseconds = 86400000; // A day in milliseconds

/**
 * Section showing the profile details as well as contacts
 * @param {bool} loading Will show placeholders when loading
 * @param {object} profileInfo The profile to display
 * @param {string} type account or lead type
 * @param {bool} hideButtons Show or hide edit & delete buttons
 * @returns Component
 */
export default function ProfileDetails({ loading, profileInfo, type, hideButtons }) {

    //#region Variables

    const [info, setInfo] = useState(null);
    const [contacts, setContacts] = useState(null);
    const infoSectionSize = useRef(8);
    const gotProvinceCountryOnce = useRef(false);
    const editUrl = useRef(null);
    const deleteUrl = useRef(null);

    // Confirm Delete Modal
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    // Add Contact Modal Show/Hide
    const [showAddContactModal, setShowAddContactModal] = useState(false);

    const [toast, setToast] = useState();
    const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
    });
    const navigate = useNavigate();

    //#endregion Variables

    //#region Functions

    /**
     * Gets all contacts for account
     */
    function GetContacts() {
        GetCompanyContacts(info.CompanyAccountID, (x) => setContacts(x), info.PrimaryContactID)
            .then(data => setContacts(data))
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(err.message));
            });
    }

    /**
     * Delete the current item
     */
    function DeleteItem() {
        const deleteItem = async () => {
            const response = await fetch(deleteUrl.current, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'DELETE'
            });

            var data = await HandleResponseReturn(response);
            data = HandleDataReturn(data);
            let successful = false;

            if ((type === account && data === 'Account deleted successfully') ||
                (type === lead && data === 'Lead deleted successfully') ||
                (type === contact && data === 'Contact deleted successfully')) {
                successful = true;
            }

            if (successful) {
                // Set toast to be displayed after naviagtion
                SetToastComponentValuesInCookie(data);

                if (type === account) navigate('/company-accounts');
                else if (type === lead) navigate('/company-leads');
                else if (type === contact) navigate('/contacts');
            } else {
                throw new Error('There was an issue deleting');
            }
        }

        // Hide confirm modal 
        setShowConfirmModal(false);

        deleteItem().catch(err => {
            console.error(err);
            setToast(GetErrorToastComponent(err.message));
        });
    }

    /**
     * Sets the colour of the engagement type label 
     * @param {string} status Engagement type 
     */
    function FormatStatusLabel(status) {
        let statusLabel = document.getElementById('status-label');
        let colour;

        if (statusLabel == null) return;

        switch (status) {
            case engagementTypeName.Customer:
                colour = '#84AFFF';
                break;
            case engagementTypeName.Research:
                colour = '#FFDDAE';
                break;
            case engagementTypeName.Outreach:
                colour = '#F0D7B8';
                break;
            case engagementTypeName.Connect:
                colour = '#E0D2C2';
                break;
            case engagementTypeName.BuildingInterest:
                colour = '#D1CCCC';
                break;
            case engagementTypeName.DiscoveryMeeting:
                colour = '#C2C6D7';
                break;
            case engagementTypeName.RfpAndQuote:
                colour = '#B2C0E1';
                break;
            case engagementTypeName.PresentationAndNegotiate:
                colour = '#A3BBEB';
                break;
            case engagementTypeName.FirstLoadAndImplementation:
                colour = '#93B5F5';
                break;
            case engagementTypeName.Unqualified:
                colour = '#f50b0b';
                break;
            case 'Open': // Leads are 'Open'
                colour = '#5cb85c';
                break;
            default:
                colour = '#898888';
                statusLabel.textContent = 'N/A';
        }

        statusLabel.style.backgroundColor = colour;
    }

    //#region Component Functions

    /**
     * Formats the address section of the display
     * @returns Component
     */
    function AddressComponent() {
        if (info == null) return null;

        let address1Div, address2Div, cityDiv, provinceCountryDiv, postalZipDiv;
        address1Div = address2Div = cityDiv = provinceCountryDiv = postalZipDiv = null;
        let icon = <FontAwesomeIcon className='me-2' icon={faMapMarkerAlt} />;

        //TODO: (low priority) fix the jank way of formatting address by displaying invisible icon

        if (info.AddressLine1 != null) {
            address1Div = <label>{icon}{info.AddressLine1}</label>;
            icon = <FontAwesomeIcon className='me-2' icon={faMapMarkerAlt} style={{ color: '#FFF' }} />;
        }

        if (info.AddressLine2 != null) {
            address2Div = <label>{icon}{info.AddressLine2}</label>;
            icon = <FontAwesomeIcon className='me-2' icon={faMapMarkerAlt} style={{ color: '#FFF' }} />;
        }

        if (info.City != null) {
            cityDiv = <label>{icon}{info.City}</label>;
            icon = <FontAwesomeIcon className='me-2' icon={faMapMarkerAlt} style={{ color: '#FFF' }} />;
        }

        if (info.Country != null) {
            let provinceCountry = [info.StateProvinceName, info.Country].join(', ');

            provinceCountryDiv = <label>{icon}{provinceCountry}</label>;
        }

        if (info.ZipPostalCode != null) {
            let formattedPostalZip = info.ZipPostalCode.length === 6 ?
                info.ZipPostalCode.replace(/^(.{3})(.*)$/, '$1 $2')
                : info.ZipPostalCode

            postalZipDiv = <label>{icon}{formattedPostalZip}</label>;
            icon = <FontAwesomeIcon className='me-2' icon={faMapMarkerAlt} style={{ color: '#FFF' }} />;
        }

        return <>
            {address1Div}
            {address2Div}
            {cityDiv}
            {provinceCountryDiv}
            {postalZipDiv}
        </>;
    }

    function PhoneComponent() {
        if (info.PhoneNumber == null) return null;

        let phoneNum = info.PhoneNumber;

        if (info.PhoneNumberExtension != null) phoneNum += ' ext. ' + info.PhoneNumberExtension;

        return (
            <CListGroupItem>
                <FontAwesomeIcon className='me-2' icon={faPhoneAlt} />
                <CTooltip content='Phone #'><a href={'tel:' + phoneNum} className='no-link-decor'>{phoneNum}</a></CTooltip>
            </CListGroupItem>
        );
    }

    function MobilePhoneComponent() {
        if (info.MobilePhoneNumber == null) return null;

        return (
            <CListGroupItem>
                <FontAwesomeIcon className='me-2 fa-w-16' icon={faMobile} />
                <CTooltip content='Mobile #'><a href={'tel:' + info.MobilePhoneNumber} className='no-link-decor'>{info.MobilePhoneNumber}</a></CTooltip>
            </CListGroupItem>
        );
    }

    function FaxComponent() {
        if (info.FaxNumber == null) return null;

        return (
            <CListGroupItem>
                <FontAwesomeIcon className='me-2' icon={faFax} />
                <CTooltip content='Fax'><span>{info.FaxNumber}</span></CTooltip>
            </CListGroupItem>
        );
    }

    function WebsiteComponent() {
        if (info.Website == null) return null;

        return <CListGroupItem>
            <FontAwesomeIcon className='me-2' icon={faExternalLinkAlt} />
            <CTooltip content='Contact Type'><span>{info.Website}</span></CTooltip>
        </CListGroupItem>;
    }

    function EmailComponent() {
        if (info.EmailAddress == null) return null;

        return (
            <CListGroupItem>
                <FontAwesomeIcon className='me-2' icon={faEnvelope} />
                <CTooltip content='Email Address'><span>{info.EmailAddress}</span></CTooltip>
            </CListGroupItem>
        );
    }

    function AutomatedEmailFrequencyComponent() {
        if (info.AutomatedEmailFrequency == null) return null;

        return (
            <CListGroupItem>
                <FontAwesomeIcon className='me-2 fa-w-16' icon={faEnvelopeOpenText} />
                <CTooltip content='Follow Up Frequency'><span>{info.AutomatedEmailFrequency}</span></CTooltip >
            </CListGroupItem>
        );
    }

    function ContactTypeComponent() {
        if (info.Type == null) return null;

        return <CListGroupItem>
            <FontAwesomeIcon className='me-2' icon={faFont} />
            <CTooltip content='Contact Type'><span>{info.Type}</span></CTooltip>
        </CListGroupItem>
    }

    /**
     * Adds Section header for each display
     * @param {string} text What to show in header
     * @returns Component
     */
    function SubsectionHeaderComponent({ text }) {
        return <CHeaderText className='fs-6 fw-semibold full-width mb-2'>{text}</CHeaderText>
    }

    /**
     * Sets images to the scale in the Grade section of the display
     * @returns Component
     */
    function FishScaleComponent() {
        if (info.Grade == null) return '-';

        let imgSource;
        let classes;

        switch (info.Grade) {
            case 'Minnow':
                imgSource = minnow;
                break;
            case 'Salmon':
                imgSource = salmon;
                break;
            case 'Dolphin':
                imgSource = dolphin;
                break;
            case 'Whale':
                imgSource = whale;
                classes = 'ms-2';
                break;
            default:
        }

        return <label>
            {info.Grade}
            <CImage src={imgSource} className={classes} width={90} height={45} />
        </label>;
    }

    function CreationTimeComponent() {
        let creationDate = new Date(info.CreationTime);

        return `${creationDate.getFullYear()} / ${creationDate.getMonth() + 1} / ${creationDate.getDate()}`;
    }

    /**
     * Component only for accounts
     * @returns Component
     */
    function DaysInCurrentEngagementTypeComponent() {
        if (type !== account) return null;

        let now = new Date(); // Current time
        let engagementTypeStartDate = new Date(info.EngagementTypeLastModificationTime);
        let daysAsCurrentType = Math.round((now.getTime() - engagementTypeStartDate.getTime()) / dayInMilliseconds);

        return <CListGroupItem>
            <SubsectionHeaderComponent text='Days In Current Engagement Type' />
            <label>{daysAsCurrentType}</label>
        </CListGroupItem>;
    }

    function ContactsComponent() {
        // If no contacts exist
        if (contacts == null || contacts.length === 0) {
            return <CListGroup>
                <CListGroupItem>
                    There are no contacts for this account. Click the plus icon to add one.
                </CListGroupItem>
            </CListGroup>;
        }

        var contactList = [];

        contacts.forEach((c, index) => {
            let fullName = [c.FirstName, c.LastName].join(' ');
            let telNum = c.PhoneNumber ?? '';

            let title, telephoneNumLink, mobileNumLink, emailLink;

            if (c.Title != null) title = `, ${c.Title}`;

            // Add phone extension if applicable
            if (c.PhoneNumberExtension != null) telNum += ' ext. ' + c.PhoneNumberExtension;

            // Populate and show the applicable links
            if (telNum !== '')
                telephoneNumLink = <a href={'tel:' + c.PhoneNumber} className='full-width no-link-decor'>Tel: {telNum}</a>;

            if (c.MobilePhoneNumber != null)
                mobileNumLink = <a href={'tel:' + c.MobilePhoneNumber} className='full-width no-link-decor'>Mobile: {c.MobilePhoneNumber}</a>;

            if (c.EmailAddress != null)
                emailLink = <a href={'mailto:' + c.EmailAddress} className='full-width no-link-decor'>Email: {c.EmailAddress}</a>;

            contactList.push(
                <CListGroupItem key={index}>
                    <CRow>
                        <CCol xs={12} sm={10}>
                            <CHeaderText className='fs-5 mb-1 full-width'><Link to={`/contacts/${c.ContactID}/profile`}>{fullName}</Link>{title}</CHeaderText>
                            {telephoneNumLink}
                            {mobileNumLink}
                            {emailLink}
                        </CCol>
                        {c.Type == null ? null :
                            <CCol className='fs-6 mb-1 full-width' sm={2}>
                                {c.Type}
                            </CCol>
                        }
                    </CRow>
                </CListGroupItem>
            );

        });

        //Check which items can be placed shown
        return <CListGroup className='scroll-list'>
            {contactList}
        </CListGroup>;
    }

    /**
     * Loading placeholder
     * @param {int} column Number of columns to show (max 12)
     * @param {int?} columnSize Out of 12, how many each should take up
     * @param {int} rows Number of rows to show with specified columns in each row
     */
    function LoadingComponent({ columns, columnSize, rows }) {
        let rowProps = {};
        if (columnSize != null) rowProps.sm = columnSize;

        return <CRow className='p-3 placeholder-row' {...rowProps}>
            {Array.from({ length: columns }, (_, i) =>
                <CCol key={`${i}-col`}>
                    <CListGroup key={`${i}-list-group`} flush>
                        {Array.from({ length: rows }, (_, j) =>
                            <CListGroupItem key={`{${i}-${j}-item`}><Placeholder height='30%' key={`{${i}-${j}-placeholder`}/></CListGroupItem>
                        )}
                    </CListGroup>
                </CCol>
            )}
        </CRow>;
    }

    //#endregion Component Functions

    //#endregion Functions

    //#region Use Effect

    // Set info when loaded
    useEffect(() => { setInfo(profileInfo) }, [profileInfo]);

    // Set the page redirect for edit and delete button
    useEffect(() => {
        if (info == null) return;

        // If hiding the buttons, no need to set urls
        if (hideButtons) return;

        switch (type) {
            case account:
                editUrl.current = '/company-accounts/' + info.CompanyAccountID;
                deleteUrl.current = '/api/companyaccounts/delete-company?id=' + info.CompanyAccountID;
                break;
            case lead:
                editUrl.current = '/company-leads/' + info.CompanyLeadID;
                deleteUrl.current = '/api/companyleads/delete-lead?id=' + info.CompanyLeadID;
                break;
            case contact:
                editUrl.current = '/contacts/' + info.ContactID;
                deleteUrl.current = '/api/contacts/delete-contact?id=' + info.ContactID;
                break;
            default:
        }

        editUrl.current += '/edit';
    }, [info]);

    // Set the size
    useEffect(() => {
        if (type === account) {
            infoSectionSize.current = 8;
        } else {
            infoSectionSize.current = 12;
        }
    }, [])

    // Format Status label
    useEffect(() => {
        if (info == null || loading) return;

        if (type === account || type === lead)
            FormatStatusLabel(info.EngagementType);
    }, [info, loading]);

    // Get the contacts for the company
    useEffect(() => {
        if (info != null && !loading && type === account) {
            GetContacts();
        }
    }, [info, loading]);

    // Get the province and country
    useEffect(() => {
        if (!gotProvinceCountryOnce.current && info != null && !loading && (type === lead || type === contact)) {
            // Only run if not set already
            if (info.Country != null || info.StateProvinceName != null) return;

            var countryId, stateProvinceId;

            switch (type) {
                case lead:
                    countryId = info.CountryId;
                    stateProvinceId = info.StateProvinceId;
                    break;
                case contact:
                    countryId = info.CountryID;
                    stateProvinceId = info.StateProvinceID;
                    break;
                default:
            }

            if (countryId != null) {
                GetCountryById(countryId)
                    .then(x => {
                        setInfo((prevState) => ({
                            ...prevState,
                            'Country': x.CountryName
                        }));
                    })
                    .catch(err => {
                        console.error(err);
                        setToast(GetErrorToastComponent(err.message));
                    });
            }

            if (stateProvinceId != null) {
                GetStateProvinceById(stateProvinceId)
                    .then(x => {
                        setInfo(prevState => ({
                            ...prevState,
                            'StateProvinceName': x.StateProvinceName
                        }))
                    })
                    .catch(err => {
                        console.error(err);
                        setToast(GetErrorToastComponent(err.message));
                    });
            }

            gotProvinceCountryOnce.current = true;
        }
    }, [info, loading]);

    // Get industry name given the industry id and save it to info
    useEffect(() => {
        const getIndustryById = async (id) => {
            const response = await fetch('/api/industry/get-industry-by-id?id=' + id, {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: 'GET'
            });
            const data = await HandleResponseReturn(response);
            return HandleDataReturn(data);
        };

        if (loading || info == null || info.IndustryID == null || info.Industry != null) return;

        getIndustryById(info.IndustryID)
            .then(x => {
                setInfo(prevState => ({
                    ...prevState,
                    'Industry': x.Industry
                }))
            })
            .catch(err => {
                console.error(err);
                setToast(GetErrorToastComponent(err.message));
            });
    }, [info, loading]);

    //#endregion

    return <>
        <CToaster push={toast} placement='top-end' />

        {/* Confirm deleting profile */}
        <ConfirmModal
            confirmFunction={DeleteItem}
            cancelFunction={() => setShowConfirmModal(false)}
            title='Delete Confirmation'
            showModal={showConfirmModal}
            confirmColour='danger'
        />

        {/* Add new contact to company account */}
        {info == null || loading ? null :
            <AddContactModal
                companyAccountId={info.CompanyAccountID}
                visible={showAddContactModal}
                setVisible={setShowAddContactModal}
                setToast={setToast}
                finishFn={GetContacts}
            />
        }

        <profile-details className='mx-2'>
            <CRow className='p-3'>
                {/* Main Information Section */}
                <CCol id='profile-details-info' xs={12} sm={infoSectionSize.current}>
                    <CRow>
                        {hideButtons ? null :
                            <div id='profile-details-info-button-row' className='d-flex justify-content-end'>
                                <CButton
                                    onClick={(e) => NavWithNewTab(e, navigate, editUrl.current)}
                                    color='success'
                                    disabled={loading}
                                    className='me-3'
                                    style={{ width: '90px' }}
                                >
                                    Edit
                                </CButton>
                                <CButton
                                    color='danger'
                                    disabled={loading}
                                    style={{ width: '90px' }}
                                    onClick={() => setShowConfirmModal(true)}
                                >
                                    Delete
                                </CButton>
                            </div>
                        }
                        {type === contact ?
                            info == null || loading ?
                                <LoadingComponent columns={1} columnSize={3} rows={5} />
                                :
                                <>
                                    <AddressComponent />
                                    <PhoneComponent />
                                    <MobilePhoneComponent />
                                    <FaxComponent />
                                    <WebsiteComponent />
                                    <EmailComponent />
                                    <AutomatedEmailFrequencyComponent />
                                    <ContactTypeComponent />
                                </>
                            :
                            info == null || loading ?
                                <LoadingComponent columns={3} columnSize={3} rows={5} />
                                :
                                <>
                                    <CCol xs={12} sm={4}>
                                        <CListGroup flush>
                                            <CListGroupItem>
                                                <SubsectionHeaderComponent text='Address' />
                                                <AddressComponent />
                                            </CListGroupItem>
                                            <PhoneComponent />
                                            <FaxComponent />
                                            <WebsiteComponent />
                                            {type === account ?
                                                <>
                                                    <CListGroupItem>
                                                        <SubsectionHeaderComponent text='TMW Id' />
                                                        <label>{info.TMWCustomerId ?? '-'}</label>
                                                    </CListGroupItem>
                                                    <CListGroupItem>
                                                        <SubsectionHeaderComponent text='Parent Company' />
                                                        <label>{info.ParentCompanyName ?? '-'}</label>
                                                    </CListGroupItem>
                                                    <CListGroupItem>
                                                        <SubsectionHeaderComponent text='Quote Type' />
                                                        <label>{info.QuoteType ?? '-'}</label>
                                                    </CListGroupItem>
                                                </>
                                                : null
                                            }
                                        </CListGroup>
                                    </CCol>
                                    <CCol xs={12} sm={4}>
                                        <CListGroup flush>
                                            <CListGroupItem className='pb-4'>
                                                <SubsectionHeaderComponent text='Status' />
                                                <status-label id='status-label'>{info.EngagementType}</status-label>
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <SubsectionHeaderComponent text='Grade' />
                                                <label>{FishScaleComponent()}</label>
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <SubsectionHeaderComponent text='Annual Revenue' />
                                                <label>{info.AnnualRevenue == null ? '-' : formatter.format(info.AnnualRevenue)}</label>
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <SubsectionHeaderComponent text='Source' />
                                                <label>{info.Source ?? '-'}</label>
                                            </CListGroupItem>
                                            {type === lead ?
                                                <CListGroupItem>
                                                    <SubsectionHeaderComponent text='Ownership' />
                                                    <label>{info.Ownership ?? '-'}</label>
                                                </CListGroupItem>
                                                : null
                                            }
                                        </CListGroup>
                                    </CCol>
                                    <CCol xs={12} sm={4}>
                                        <CListGroup flush>
                                            <CListGroupItem className='pb-4'>
                                                <SubsectionHeaderComponent text='Owner' />
                                                <label>{info.AccountOwner ?? '-'}</label>
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <SubsectionHeaderComponent text='Industry' />
                                                <label>{info.Industry ?? '-'}</label>
                                            </CListGroupItem>
                                            {type === lead ?
                                                <CListGroupItem>
                                                    <SubsectionHeaderComponent text='Ticker Symbol' />
                                                    <label>{info.TickerSymbol ?? '-'}</label>
                                                </CListGroupItem>
                                                : null
                                            }
                                            <CListGroupItem>
                                                <SubsectionHeaderComponent text='Website' />
                                                <label>{info.WebsiteURL ?? '-'}</label>
                                            </CListGroupItem>
                                            <CListGroupItem>
                                                <SubsectionHeaderComponent text='Creation Time' />
                                                <label><CreationTimeComponent /></label>
                                            </CListGroupItem>
                                            <DaysInCurrentEngagementTypeComponent />
                                        </CListGroup>
                                    </CCol>
                                </>
                        }
                    </CRow>
                </CCol>

                {/* Contacts Section */}
                {type === account ?
                    <CCol id='profile-details-contact' xs={12} sm={4}>
                        <div className='d-flex justify-content-between'>
                            <CHeaderText className='fs-4 mb-2'>Contacts</CHeaderText>
                            <CButton
                                shape='rounded-circle'
                                variant='ghost'
                                className='mb-1'
                                disabled={loading}
                                onClick={() => setShowAddContactModal(true)}
                            >
                                <FontAwesomeIcon icon={faPlus} className='my-auto' />
                            </CButton>
                        </div>
                        <ContactsComponent />
                    </CCol>
                    : null
                }
            </CRow>
            {type === contact || info == null || loading ?
                null
                :
                <CRow className='p-3'>
                    <SubsectionHeaderComponent text='Description' />
                    <label>{info.Description ?? '-'}</label>
                </CRow>
            }
        </profile-details>
    </>;
}