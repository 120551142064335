import { Workbox } from 'workbox-window';
import { UpdateSWCallback } from './functions';

export default function registerServiceWorker() {
    if ('production' !== process.env.NODE_ENV) return;

    // Check if the serviceWorker Object exists in the navigator object ( means if browser supports SW )
    if ('serviceWorker' in navigator) {
        const wb = new Workbox('/serviceWorker.js');

        wb.addEventListener('installed', event => {
            console.log(`A new service worker has installed`);
        });

        wb.addEventListener('waiting', event => {
            console.log(
                `A new service worker has installed, but it can't activate` +
                `until all tabs running the current version have fully unloaded.`
            );
        });

        wb.addEventListener('controlling', event => {
            console.log(`A new service worker has started controlling the page.`);
        });

        wb.addEventListener('activated', event => {
            // `event.isUpdate` will be true if another version of the service
            // worker was controlling the page when this version was registered.
            if (!event.isUpdate) {
                console.log('Service worker activated for the first time!');
                // If your service worker is configured to precache assets, those
                // assets should all be available now.
            }
        });

        wb.addEventListener('message', async event => {
            if (event.data.meta === 'workbox-broadcast-update') {
                const { cacheName, updatedURL } = event.data.payload;

                // Do something with cacheName and updatedURL.
                // For example, get the cached content and update
                // the content on the page.
                const cache = await caches.open(cacheName);
                const updatedResponse = await cache.match(updatedURL);
                const domain = process.env.REACT_APP_WEB_URL;
                let updatedText;

                // Note: this does not account for if response is returned as string directly (e.g response.text())
                // If your response does that, consider either changing the text return to json format
                try {
                    updatedText = await updatedResponse.json();
                } catch (_) {
                    updatedText = updatedResponse;
                }
                
                let message = {
                    url: updatedURL.replace(domain, ''),
                    newData: updatedText
                };
                
                UpdateSWCallback(message);
            }
        });

        wb.register();
    }
}