import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {
    GetCountries,
    GetIndustries,
    GetStateProvinces,
    GetSources,
    GetBearer,
    GetErrorToastComponent,
    HandleDataReturn,
    HandleResponseReturn,
    SetToastComponentValuesInCookie
} from '../../functions';

import {
    CButton,
    CCard,
    CCardBody,
    CCardTitle,
    CCol,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CHeader,
    CHeaderText,
    CInputGroup,
    CInputGroupText,
    CRow,
    CToaster,
} from '@coreui/react';

import { GetErrorMessages } from '../../constants';

import AddressEdit from '../../components/edit/addressEdit';

import '../../style/edit.css';
import '../../style/typeahead.css';

// Based on LeadEdit but stripped down

export default function LeadNew(props) {

    //#region Variables

    const [countryList, setCountryList] = useState([]);
    const [stateProvinceList, setStateProvinceList] = useState([]);
    const [sources, setSources] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [validated, setValidated] = useState(false);
    const [toast, setToast] = useState(0);
    const navigate = useNavigate();

    // Selected option for dropdown menu
    const [selectedIndustry, setSelectedIndustry] = useState([]);

    //#endregion Variables

    //#region Functions

    function NewLead(e) {
        const form = e.target;
        e.preventDefault();

        // If submit is not triggered by button, don't submit
        if (document.activeElement.id !== 'new-lead-submit') return;

        //checks if all required fields are filled
        if (form.checkValidity() === false) {
            e.stopPropagation()
            setValidated(true); // show validation
        } else {
            const formData = new FormData(form);

            // Append Industry if selected
            if (selectedIndustry.length === 1) {
                let industryInput = document.querySelector('#industry-typeahead .rbt input.rbt-input-main.rbt-input');
                // Append value if input box is not empty (which indicates no value selected)
                if (industryInput != null && industryInput.value.trim().length !== 0)
                    formData.append('IndustryID', selectedIndustry[0].IndustryID);
            }

            fetch('/api/companyleads/new-lead', {
                headers: { 'Authorization': 'Bearer ' + GetBearer() },
                method: form.method,
                body: formData
            })
                .then(response => HandleResponseReturn(response))
                .then(data => {
                    data = HandleDataReturn(data);

                    if (data.Message === 'Add Lead Successful') {
                        SetToastComponentValuesInCookie('Lead account added successfully');
                        navigate('/company-leads/' + data.NewLeadId + '/profile');
                    } else {
                        throw new Error();
                    }
                })
                .catch(err => {
                    setToast(GetErrorToastComponent('An error has occured when adding new lead!', err.message));
                });
        }
    }

    //#endregion Functions

    //#region Use Effect

    // Get all the data fields for dropdown
    useEffect(() => {
        GetCountries()
            .then(x => setCountryList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Countries, err)));

        GetStateProvinces()
            .then(x => setStateProvinceList(x))
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.StateProvince, err)));

        GetSources()
            .then(x => setSources(x))
            .catch((err) => setToast(GetErrorToastComponent('Error: Could not get sources!', err)));

        GetIndustries()
            .then(x => {
                setIndustries(x);
            })
            .catch((err) => setToast(GetErrorToastComponent(GetErrorMessages.Industries, err)));
    }, []);

    //#endregion Use Effect

    return (
        <>
            <CToaster push={toast} placement='top-end' />
            <CHeader container='fluid'>
                <CHeaderText className='fs-3'>New Lead</CHeaderText>
            </CHeader>
            <CCard>
                <CCardBody className='px-5'>

                    <CForm
                        className='mt-3'
                        onSubmit={NewLead}
                        method='POST'
                        noValidate
                        validated={validated}
                    >
                        <CCardTitle className='justify-content-between title-display-content'>
                            Lead Information
                        </CCardTitle>
                        <hr />
                        <CRow>
                            <CCol xs={12} sm={6}>
                                <CFormLabel htmlFor='Name' className='mt-3 required-label'>Lead Name</CFormLabel>
                                <CFormInput
                                    type='text'
                                    id='Name'
                                    name='Name'
                                    feedbackInvalid='Required field'
                                    required
                                    maxLength='150'
                                />
                                <CFormLabel className='mt-3'>Industry</CFormLabel>
                                <div id='industry-typeahead'>
                                    <Typeahead
                                        options={industries}
                                        labelKey={option => `${option.Industry}`}
                                        onChange={setSelectedIndustry}
                                        id='industry'
                                        clearButton={true}
                                        emptyLabel='No matches found, only options chosen from dropdown will be saved'
                                    />
                                </div>
                                <CFormLabel className='mt-3'>Lead Source</CFormLabel>
                                <CFormSelect name='SourceID'>
                                    {sources.map((source) => {
                                        return (<option key={source.SourceID} value={source.SourceID}>{source.Name}</option>)
                                    })}
                                </CFormSelect>
                                <CFormLabel className='mt-3'>Annual Revenue</CFormLabel>
                                <CInputGroup>
                                    <CInputGroupText>$</CInputGroupText>
                                    <CFormInput type='number' name='AnnualRevenue' />
                                </CInputGroup>
                            </CCol>
                            <CCol xs={12} sm={6}>
                                <CFormLabel className='mt-3'>Website</CFormLabel>
                                <CInputGroup>
                                    <CInputGroupText>https://</CInputGroupText>
                                    <CFormInput type='text' name='WebsiteURL' maxLength='255' />
                                </CInputGroup>
                                <CFormLabel className='mt-3'>Phone Number</CFormLabel>
                                <CFormInput type='text' name='PhoneNumber' maxLength='20' />
                                <CFormLabel className='mt-3'>Fax Number</CFormLabel>
                                <CFormInput type='text' name='FaxNumber' maxLength='16' />
                            </CCol>
                        </CRow>
                        <CRow className='mb-4 mt-3'>
                            <CCol>
                                <CFormLabel>Description</CFormLabel>
                                <CFormTextarea type='text' name='Description' />
                            </CCol>
                        </CRow>
                        {countryList.length === 0 || stateProvinceList.length === 0 ? null : 
                            <AddressEdit
                                addressLine1={null}
                                addressLine2={null}
                                city={null}
                                stateProvinceId={null}
                                countryId={null}
                                zipPostalCode={null}
                                countryList={countryList}
                                stateProvinceList={stateProvinceList}
                            />
                        }
                        <CRow className='mb-5'>
                            <CCol style={{ textAlign: 'end' }}>
                                <CButton onClick={() => navigate('/company-leads/')} color='secondary' style={{ width: '100px' }} >Cancel</CButton>
                            </CCol>
                            <CCol>
                                <CButton id='new-lead-submit' type='submit' style={{ backgroundColor: '#337ab7', width: '100px' }} >Add Lead</CButton>
                            </CCol>
                        </CRow>
                    </CForm>

                </CCardBody>
            </CCard>
        </>
    );
}