import React from 'react';

// Common errors messages for the Get functions found in functions.js
export const GetErrorMessages = {
    CompanyOwner: 'Error: Could not get company owners!',
    Countries: 'Error: Could not get countries!',
    EngagementType: 'Error: Could not get engagement types!',
    Grades: 'Error: Could not get company grades!',
    Industries: 'Error: Could not get industries!',
    QuoteType: 'Error: Could not get quote types!',
    ParentCompanies: 'Error: Could not get parent companies!',
    Playlists: 'Error: Could not get playlists!',
    StateProvince: 'Error: Could not get provinces and states!',
    Sources: 'Error: Could not get sources!',
}

// Engagement types
export const engagementTypeName = {
    Unknown: 'Unknown',
    Customer: 'Customer',
    Prospect: 'Prospect',
    Unqualified: 'Unqualified',
    Research: 'Research',
    Outreach: 'Outreach',
    Connect: 'Connect',
    BuildingInterest: 'Building Interest',
    DiscoveryMeeting: 'Discovery Meeting',
    RfpAndQuote: 'RFP/Quote',
    PresentationAndNegotiate: 'Presentation/Negotiate',
    FirstLoadAndImplementation: 'First Load/Implementation'
};

// HTML that shows up in the modals when the Info button is clicked 
export const infoModalText = {
    AccountsPage: <>
        <h4>Playlists</h4>
        Add/remove to/from playlists by checking the accounts that you wish to add/remove. The option can be found in settings.<br />
        <strong>Note:</strong> Only companies belonging to their corresponding engagement type will be shown in playlist. E.g., when viewing playlists
        from Customers page, only customers show up in playlist.<br /><br />
        <h4>Searching</h4>
        <strong>Address:</strong> You can search for provinces/states using the two letter code or full name.<br />
        <strong>Checkboxes:</strong> Search with <i>'true'</i> or <i>'false'</i>.<br />
        <strong>Money:</strong> Money values should be searched as is without any symbols or commas.<br />
        <strong>Multi-field Columns:</strong> In the <i>'More Info'</i> and <i>'Credit Status'</i> section, you can search by specific values.
        It doesn't support distinguish between types currently.<br /><br />
        <h4>Checkbox Select</h4>
        - When changing tabs, selections will clear<br />
        - Selected items will stay selected even if page or search filters change<br /><br />
        <h4>Buttons</h4>
        Profile and Edit account buttons support opening in new page by holding CTRL (on keyboard) while clicking it.<br /><br />
        <h4>Active Pipeline</h4>
        Shows all prospects that are in the "Building Interest" stage and onwards.
    </>,
    BookMeetingModalEmails: <>
        <strong>Emails:</strong> Only contacts associated with company that also has an email will show up.
    </>,
    ContactEditType: <>
        If there already exists a primary or credit contact for this company account, it will be replaced by this contact
    </>,
    CreditPage: <>
        Applications with <i>'Complete'</i> or <i>'Rejected'</i> status will only show for three months after date submitted.<br /><br />
        <strong>Date Submitted</strong> will be highlighted yellow if more than 15 days have past since submission date.
    </>,
    CreditEditPage: <>
        <h4>Buttons</h4> 
        <strong>Approve</strong> Fills out required info in TMW.
        An email will be sent to credit team, requester (sales), and customer.<br />
        <strong>Prepaid</strong> Approves request as prepaid and fills out required info in TMW. 
        An email will be sent to credit team, requester (sales), and customer.<br />
        <strong>Revise</strong> Updates credit request. An email will be sent to requester with a note.<br />
        <strong>Reject</strong> Rejects the credit request. An email will be sent to credit team as well as requester.<br />
        <strong>Save: </strong> Updates company information. Does not send an email.
    </>,
    LeadsPage: <>
        <strong>Address:</strong> You can search for provinces/states using the two letter code or full name.<br /><br />
        <strong>Annual Revenue:</strong> Filters for min/max are inclusive.<br /><br />
        <strong>Industry:</strong> Industry dropdown is not here in the new version but can still filter by industry.<br /><br />
        <strong>Uploading Leads:</strong> Supported upload formats are either the skynet template provided in the upload modal or exported leads from
        ZoomInfo.<br />
    </>,
    NotesComponent: <>
        <strong>Add File:</strong> Currently only supporting .pdf files officially. Might able to store other formats but won't
        be able to view in here. May still be able to download the file.
    </>,
    SearchList: <>
        <strong>Profile Navigation:</strong> Press CTRL while clicking profile icon to open in new tab.
    </>,
    ScoreboardPage: <>
        <h5>Highlighting for Booked, Pickup, and Delivery Columns</h5>
        <strong>Yellow:</strong> Booked, Delivery, or Pickup is today.<br />
        <strong>Red (Pickup Only):</strong> Pickup is happening within 3 hours and there is no carrier assigned. Overrides yellow highlight.<br />
        <strong>Bolded Font:</strong> Pickup/Delivery is more than 1 day late.<br /><br />
        <h5>Row Text Colour</h5>
        <strong>Orange:</strong> Margin amount as x where $0 &lt; x &lt; $51 or margin percentage as y where 
        0% &lt; y &#8804; 4%.<br />
        <strong>Red:</strong> Margin amount is less than $0.<br /><br />
        <h5>Columns</h5>
        <strong>XIN:</strong> Do not Invoice. T for temporary and P for permanant.<br />
        <strong>Remarks:</strong> Hover over to view full remark. "..." indicates there is more to the message on hover<br />
    </>,
    TodoPage: <>
        <strong>Edit:</strong> You can edit to-dos by double clicking or by clicking edit button.<br /><br />
        <strong>Undo Completed To-Dos:</strong> You can undo to-dos that have been completed by pressing CTRL + Z.
        This option is available so long as you remain on the page.
    </>,
}

// Cookie names that are used for session
export const cookieNames = {
    accessToken: 'accessToken',
    autoRefreshCounter: 'autoRefreshCounter',
    isAdminUser: 'isAdminUser',
    isSuperUser: 'isSuperUser',
    userID: 'userID',
    userEmail: 'userEmail',
    userName: 'userName',
    userRole: 'userRole',
    userRoleSubdivision: 'userRoleSubdivision',
}

//Local storage key names that are used in localStorage
export const localStoreNames = {
    changeLogMostRecentVersion: 'changeLogMostRecentVersion',
    accountPageDefault: 'accountPageDefault',
    leadPageDefault: 'leadPageDefault',
}

// Session storage key names that are used for sessionStorage
export const sessionStoreNames = {
    changeLogReadVersion: 'changeLogReadVersion', 
}

// Roles users can have
export const userRoles = {
    Admin: 'Admin',
    Sales: 'Sales',
    TLI: 'TLIAccess',
    TALI: 'TALIAccess',
    TTSI: 'TTSIAccess',
    Credit: 'Credit',
    Dispatch: 'Dispatch Access'
}